
// Identify page for a layer.
//
// NOTE:  for a single feature/page of that layer - each layer can have multiple features/pages.

import { IconButton, Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, TableHead, Tooltip, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain, theme_limeGreen, theme_textColorBlended } from "../Theme";
import { ILayer, IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import { GetVectorLayerAttribute } from "../AttributeOps";
import useStore from "../store";
import { FriendlyNumber, PARCEL_LAYER_NAME } from "../Globals";
import { IIdentifyAttribRowData, IIdentifyLayerFeature } from "./IdentifyInterfaces";
import IdentifyTableRow from "./IdentifyTableRow";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { mkConfig, generateCsv, asString } from "export-to-csv";

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface IdentifyLayerPageProps
{
  layer?: ILayer;
  feature?: IIdentifyLayerFeature;
}

interface IAttrib // used for sorting
{
  name: string;
  displayName: string;
}

//-------------------------------------------------------------------------------
// Identify page for a layer.
//
// NOTE:  for a single feature/page of that layer - each layer can have multiple features/pages.
//-------------------------------------------------------------------------------
const IdentifyLayerPage = (props: IdentifyLayerPageProps) => 
{
  // Get needed state data from the store
  const { store_project, 
  } = useStore();






  //-------------------------------------------------------------------------------
  // Returns a properly sorted list of attributes keys - the sorting is done using
  // the attributes' friendly names.
  //-------------------------------------------------------------------------------
  function GetSortedAttributeKeys(): string[]
  {
    if(!store_project || !store_project.user_settings || !props.feature || !props.feature.data) return [];

    if(props.layer?.name === PARCEL_LAYER_NAME && store_project.user_settings.identify_showOnlyParcelUserAttributes)
    {
      // If this is for the Parcels layer and the "showOnlyParcelUserAttributes" setting is TRUE, we
      // need to re-order they keys to match the user's order in project settings.

      const newKeys: string[] = [];
      for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
        newKeys.push(store_project.user_settings.parcel_attribute_list[i].name);

      return newKeys;
    }
    else
    {
      // We need to sort by the attributes' friendly/display names

      // Get the attribute keys (a list of attribute names)

      const keys = Object.keys(props.feature.data);

      // Generate a new list that has a pairing of attribute names and their friendly display names

      let attribList: IAttrib[] = [];
      for(let i=0; i < keys.length; i++)
      {
        const att: IVectorLayerAttribute | undefined = GetVectorLayerAttribute(props.layer, keys[i]);
        if(!att) 
        {
          attribList.push( { name: keys[i], displayName: keys[i] } );
          continue;
        }

        attribList.push( { name: keys[i], displayName: att.display_name } );
      }

      // Re-sort the new list by display name
    
      attribList = attribList.sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Return a list of attribute name, but sorted by display name

      const sortedKeys: string[] = [];
      for(let i=0; i < attribList.length; i++)
        sortedKeys.push(attribList[i].name);

      return sortedKeys;
    }
  }

  //-------------------------------------------------------------------------------
  // Returns a list of attribute rows ready for rendering.
  //-------------------------------------------------------------------------------
  function GetAllAttributeRows(): IIdentifyAttribRowData[]
  {
    if(!props.feature || !props.feature.data) return [];

    const outputArray: IIdentifyAttribRowData[] = [];

    // Get sorted list of attribute keys

    const attribKeys: string[] = GetSortedAttributeKeys();

    // For each attribute key, create a 'IIdentifyAttribRowData' item

    let nextID: number = 1;

    for(let i=0; i < attribKeys.length; i++)
    {
      const attrib: IVectorLayerAttribute | undefined = GetVectorLayerAttribute(props.layer, attribKeys[i]);
      if(!attrib) 
        continue;

      // If the attribute is hidden, skip it
      if(attrib.is_visible !== true) continue;

      // Option to skip attributes with empty values
      if(store_project && store_project.user_settings && store_project.user_settings.identify_showEmptyValues === false && 
         props && props.feature && props.feature.data &&
         (props.feature.data[attrib.attribute_name] === undefined || props.feature.data[attrib.attribute_name].length === 0))
        continue;

      const valueRaw: any = props.feature.data[attrib.attribute_name];
      let valueStr: string = valueRaw;

      if(attrib.data_type === 'number')
      {
        const valueNum: number = Number.parseFloat(valueRaw);
        valueStr = FriendlyNumber(valueNum, attrib.decimal_places !== undefined ? attrib.decimal_places : 0);
      }

      const newItem: IIdentifyAttribRowData = 
      {
        id: nextID++,
        attribName: attrib.display_name,
        attribValue: valueStr,
        units: attrib.unit,
        description: attrib.description,
        descriptionLink: attrib.info_url
      }

      outputArray.push(newItem);
    }

    return outputArray;
  }

  //-------------------------------------------------------------------------------
  // Copies the data to the clipboard (as CSV).
  //-------------------------------------------------------------------------------  
  function OnCopyData()
  {
    const rows: IIdentifyAttribRowData[] = GetAllAttributeRows();

    // Transfer the data into a JSON array

    const data: { name: string; value: string; }[] = [];
    rows.forEach(row => 
    {
      data.push(
        {
          name: row.attribName,
          value: row.attribValue
        });
    });

    // Create a CSV string from the JSON array (using export-to-csv package)

    // mkConfig merges your options with the defaults
    // and returns WithDefaults<ConfigOptions>
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(data);

    // This unpacks CsvOutput which turns it into a string before use
    const addNewLine = (s: string): string => s + "\n";
    const csvOutputWithNewLines = addNewLine(asString(csv));

    // Copy the string to the system clipboard
    navigator.clipboard.writeText(csvOutputWithNewLines);
  }

  








  // Return nothing if we have no layer or feature
  if(!props.layer || !props.feature || !props.feature.data) return null;

  // Main render

  return (

    <Stack sx={{ maxHeight: '275px', overflow: 'auto' }}>

      <Stack sx={{ position: 'absolute', ml: '-30px', mt: '-23px', width: '100%', alignItems: 'end' }}>
        <Tooltip title='Copy the data from this panel to the clipboard (CSV format).  It can then be pasted into emails or Excel.'>
          <IconButton sx={{ width: '17px', height: '17px' }} onClick={_=>OnCopyData()}>
            <ContentCopyIcon sx={{ width: '12px', height: '12px', color: theme_textColorBlended }} />
          </IconButton>
        </Tooltip>
      </Stack>

      {props.feature.singleRasterValue
        ?
          // Show only the 'singleRasterValue'

          <Typography sx={{ fontSize: '1.0rem', color: theme_limeGreen, textAlign: 'center', p: 0.8 }}>
            {props.feature.singleRasterValue}
          </Typography>

        :        
          // Show the full list of attributes in a table

          <TableContainer component={Paper} sx={{ bgcolor: theme_bgColorMain }} elevation={0}>

            {/* <Table size="small" aria-label="simple table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "none"} }}> */}
            <Table size="small" aria-label="identify table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "1", borderColor: theme_bgColorLight1 + '8F'} }}>

              <TableHead>
              </TableHead>

              <TableBody>

                {GetAllAttributeRows().map(attribRowData => 
                  <IdentifyTableRow key={attribRowData.id} attribRowData={attribRowData}/>
                )}

              </TableBody>

            </Table>
          </TableContainer>
      }
    </Stack>

  )
}


export default IdentifyLayerPage;
