// Parcel search component

import { Box, Button, Chip, CircularProgress, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography, styled, Checkbox, TextField } from "@mui/material";
import useStore from "../store";
import { theme_bgColorMain, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { IParcelSearchClause, IParcelSearchExpression, TParcelSearchAreaFilter } from "./ParcelInterfaces";
import CloseIcon from '@mui/icons-material/Close';
import { ExitParcelSearchResultsViewerMode, GetNewParcelSearchClause, GetParcelAttributes, RunParcelSearch } from "./ParcelOps";
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { ToastNotification } from "../ToastNotifications";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ReactNode, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ParcelSearchBuilder from "./ParcelSearchBuilder";
import TocIcon from '@mui/icons-material/Toc';


const PARCEL_SEARCH_MAX_CLAUSES = 10;

export type TParcelSearchMaxResults = 100 | 250 | 500 | 1000 | 2500 | 5000;
export const PARCEL_SEARCH_DEFAULT_MAX_RESULTS: TParcelSearchMaxResults = 500;


// NOTE: For now this only includes continental US (removed Alaska, Hawaii, Guam, Puerto Rico, US Virgin Islands, etc)
export const STATE_LIST: IState[] = 
[
  { fullName: 'Alabama', shortName: 'AL' },
  { fullName: 'Arizona', shortName: 'AZ' },
  { fullName: 'Arkansas', shortName: 'AR' },
  { fullName: 'California', shortName: 'CA' },
  { fullName: 'Colorado', shortName: 'CO' },
  { fullName: 'Connecticut', shortName: 'CT' },
  { fullName: 'Delaware', shortName: 'DE' },
  { fullName: 'District of Columbia', shortName: 'DC' },
  { fullName: 'Florida', shortName: 'FL' },
  { fullName: 'Georgia', shortName: 'GA' },
  { fullName: 'Idaho', shortName: 'ID' },
  { fullName: 'Illinois', shortName: 'IL' },
  { fullName: 'Indiana', shortName: 'IN' },
  { fullName: 'Iowa', shortName: 'IA' },
  { fullName: 'Kansas', shortName: 'KS' },
  { fullName: 'Kentucky', shortName: 'KY' },
  { fullName: 'Louisiana', shortName: 'LA' },
  { fullName: 'Maine', shortName: 'ME' },
  { fullName: 'Maryland', shortName: 'MD' },
  { fullName: 'Massachusetts', shortName: 'MA' },
  { fullName: 'Michigan', shortName: 'MI' },
  { fullName: 'Minnesota', shortName: 'MN' },
  { fullName: 'Mississippi', shortName: 'MS' },
  { fullName: 'Missouri', shortName: 'MO' },
  { fullName: 'Montana', shortName: 'MT' },
  { fullName: 'Nebraska', shortName: 'NE' },
  { fullName: 'Nevada', shortName: 'NV' },
  { fullName: 'New Hampshire', shortName: 'NH' },
  { fullName: 'New Jersey', shortName: 'NJ' },
  { fullName: 'New Mexico', shortName: 'NM' },
  { fullName: 'New York', shortName: 'NY' },
  { fullName: 'North Carolina', shortName: 'NC' },
  { fullName: 'North Dakota', shortName: 'ND' },
  { fullName: 'Ohio', shortName: 'OH' },
  { fullName: 'Oklahoma', shortName: 'OK' },
  { fullName: 'Oregon', shortName: 'OR' },
  { fullName: 'Pennsylvania', shortName: 'PA' },
  { fullName: 'Rhode Island', shortName: 'RI' },
  { fullName: 'South Carolina', shortName: 'SC' },
  { fullName: 'South Dakota', shortName: 'SD' },
  { fullName: 'Tennessee', shortName: 'TN' },
  { fullName: 'Texas', shortName: 'TX' },
  { fullName: 'Utah', shortName: 'UT' },
  { fullName: 'Vermont', shortName: 'VT' },
  { fullName: 'Virginia', shortName: 'VA' },
  { fullName: 'Washington', shortName: 'WA' },
  { fullName: 'West Virginia', shortName: 'WV' },
  { fullName: 'Wisconsin', shortName: 'WI' },
  { fullName: 'Wyoming', shortName: 'WY' },
];


export const USStateAbbreviations = 
[
  'AL', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',  
  'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 
  'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 
  'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' 
] as const;
  
export type TUSStateAbbreviation = typeof USStateAbbreviations[number];

interface IState
{
  fullName: string;
  shortName: TUSStateAbbreviation;
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelSearchProps
{
}

//-------------------------------------------------------------------------------
// Parcel search component
//-------------------------------------------------------------------------------
const ParcelSearch = (props: ParcelSearchProps) => 
{
  // Get needed state data from the store
  const { store_project, store_parcelSearchExpression, store_addParcelSearchClause,
          store_parcelSearchEnabled, store_setParcelSearchEnabled, 
          store_setParcelSearchExpressionOperator, store_parcelSearchIsRunning, 
          store_setParcelSearchIsRunning, store_setParcelSearchRunInstanceID, 
          store_setParcelSearchMaxResults, store_parcelSearchMaxResults, 
          store_parcelSearchStates, store_setParcelSearchStates, store_setParcelTableViewer, 
          store_parcelSearchAreaFilter, store_setParcelSearchAreaFilter, 
          store_parcelSearchResultsViewerMode, store_selectedParcels,
          store_setParcelSearchDbSortAttribName, store_parcelSearchDbSortAttribName,
          store_parcelSearchDbSortOrderDesc, store_setParcelSearchDbSortOrderDesc, 
          store_parcelSearchAOIBufferMiles, store_setParcelSearchAOIBufferMiles, 
          store_setParcelSearchExpression, 
        } = useStore();


  const [selStatesIsOpen, setSelStatesIsOpen] = useState(false);






  //-------------------------------------------------------------------------------
  // The user is adding a new search clause.
  //-------------------------------------------------------------------------------
  function OnAddSearchClause()
  {
    if(store_parcelSearchExpression.clauses.length >= PARCEL_SEARCH_MAX_CLAUSES)
    {
      ToastNotification('error', `You cannot have more than ${PARCEL_SEARCH_MAX_CLAUSES} search clauses`);
      return;
    }

    store_addParcelSearchClause(GetNewParcelSearchClause());
  }

  //-------------------------------------------------------------------------------
  // Run parcel search.
  //-------------------------------------------------------------------------------
  function OnRunSearch()
  {
    RunParcelSearch(store_parcelSearchExpression);
  }

  //-------------------------------------------------------------------------------
  // The user is closing the parcel search UI.
  //-------------------------------------------------------------------------------
  function OnCloseParcelSearch()
  {
    // If we have search results open, close out of that mode first
    ExitParcelSearchResultsViewerMode();

    // Close the parcel search UI
    store_setParcelSearchEnabled(false);
  }

  //-------------------------------------------------------------------------------
  // The user has toggle the main search expression operator (AND vs OR).
  //-------------------------------------------------------------------------------
  function OnToggleMainOperator()
  {
    if(store_parcelSearchExpression.operator === 'and')
      store_setParcelSearchExpressionOperator('or');
    else
      store_setParcelSearchExpressionOperator('and');
  }

  //-------------------------------------------------------------------------------
  // Cancel a running search.
  //
  // NOTE: It's not a "real" cancel.  The operation still completes server-side, but
  //       the UI no longer waits for it, and when it does complete, the app ignores it.
  //-------------------------------------------------------------------------------
  const OnCancelSearch = async () => 
  {
    // Clear out the search run instance ID saved in the state store
    store_setParcelSearchRunInstanceID(undefined);

    // Tell the UI we are no longer running the search
    store_setParcelSearchIsRunning(false);
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to the states combo box.
  //-------------------------------------------------------------------------------
  const onStateSelectionChanged = (event: SelectChangeEvent<typeof store_parcelSearchStates>) => 
  {
    const { target: { value },} = event;

    store_setParcelSearchStates(value as TUSStateAbbreviation[]);

    setSelStatesIsOpen(false);
  }

  //-------------------------------------------------------------------------------
  // Called when a state is removed.
  //-------------------------------------------------------------------------------
  const OnDeselectState = (deletedState: string) => 
  {
    store_setParcelSearchStates(store_parcelSearchStates.filter(state => state !== deletedState));
  }

  //-------------------------------------------------------------------------------
  // The user has changed the max results combo box.
  //-------------------------------------------------------------------------------
  const onMaxResultsChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(typeof event.target.value !== 'number') return;

    store_setParcelSearchMaxResults(event.target.value as TParcelSearchMaxResults);
  }

  //-------------------------------------------------------------------------------
  // The user has changed the area filter combo box.
  //-------------------------------------------------------------------------------
  const onAreaFilterChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    store_setParcelSearchAreaFilter(event.target.value as TParcelSearchAreaFilter);
  }

  //-------------------------------------------------------------------------------
  // View search results (in table form, in a large popup window).
  //-------------------------------------------------------------------------------
  function OnOpenParcelTableViewer()
  {
    store_setParcelTableViewer(true);
  }

  //-------------------------------------------------------------------------------
  // Close search results.
  //-------------------------------------------------------------------------------
  function OnCloseSearchResults()
  {
    ExitParcelSearchResultsViewerMode();
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function GetColorBasedOnResultsCount(): string
  {
    // No results (red)
    if(store_selectedParcels.length === 0)
      return '#BF5757A0';

    // Max results (orange)
    if(store_selectedParcels.length === store_parcelSearchMaxResults)
      return '#F09D0070';
    
    // Normal results returned (green)
    return '#2C9D4370';
  }

  //-------------------------------------------------------------------------------
  // Returns 
  //-------------------------------------------------------------------------------
  function GetStateList(): IState[]
  {
    if(!store_project || !store_project.boundary)
      return [];

    // If the boundary has no settings, we return all 50 states
    if(!store_project.boundary.settings)
      return STATE_LIST;

    const returnedStateList: IState[] = [];

    const boundaryStateAbbrList: TUSStateAbbreviation[] | undefined = store_project.boundary.settings.states;

    // If the states associated with the project boundary is empty, we return all 50 states
    if(!boundaryStateAbbrList || boundaryStateAbbrList.length === 0)
      return STATE_LIST;

    for(let i=0; i < boundaryStateAbbrList.length; i++)
    {
      const foundState: IState | undefined = STATE_LIST.find(state => state.shortName === boundaryStateAbbrList[i]);
      if(foundState)

      returnedStateList.push(foundState);
    }

    // Return the list (sorted)
    return returnedStateList.sort((a: IState, b: IState) => a.fullName.localeCompare(b.fullName));
  }

  //-------------------------------------------------------------------------------
  // The user has selected a different attribute for one of the search clauses.
  //-------------------------------------------------------------------------------
  function OnDbSortAttribChanged(event: SelectChangeEvent<any>)
  {
    let clickedAttributeName: string | undefined = event.target.value;
    store_setParcelSearchDbSortAttribName(clickedAttributeName);
  }

  //-------------------------------------------------------------------------------
  // The user has toggled the "db sort order descending" checkbox.
  //-------------------------------------------------------------------------------
  function OnDbSortDescChanged(checked: boolean)
  {
    store_setParcelSearchDbSortOrderDesc(!store_parcelSearchDbSortOrderDesc);
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to the AOI buffer value.
  //-------------------------------------------------------------------------------
  function OnAoiBufferChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
  {
    const newValue: string = event.target.value;
    store_setParcelSearchAOIBufferMiles(newValue);
  }

  //-------------------------------------------------------------------------------
  // Resets the search clauses.
  //-------------------------------------------------------------------------------
  function OnClearAllAttributes()
  {
    // Reset the clauses array in the current search expression

    const newClauses: IParcelSearchClause[] = [];
    newClauses.push(GetNewParcelSearchClause());

    const newParcelSearchExpression: IParcelSearchExpression =
    {
      ...store_parcelSearchExpression,
      clauses: newClauses
    }

    store_setParcelSearchExpression(newParcelSearchExpression);
  }












  // Parcel searching doesn't work without an active project
  if(!store_project) 
    return null;

  // The parcel search is not enabled
  if(!store_parcelSearchEnabled) 
    return null;

  // Search is running...
  if(store_parcelSearchIsRunning)
  return (
    <Stack sx={{ bgcolor: theme_textColorBlended, borderRadius: 1, mt: 1, boxShadow: 3, width: '240px' }}>
      <Stack direction='column' sx={{ bgcolor: theme_bgColorMain, borderRadius: 1, m: '1px', p: '7px', alignItems: 'center' }}>

        <Typography sx={{ fontSize: '1.1rem', color: theme_orange, opacity: 1, textTransform: 'none' }}>
          Searching Parcels...
        </Typography>

        <Stack sx={{ alignItems: 'center', my: 3 }}>
          <CircularProgress/>
        </Stack>

        {/* Cancel Search button */}
        <Stack sx={{ alignItems: 'center' }}>
          <Button size='small' variant='outlined' onClick={(_)=>OnCancelSearch()} 
                  sx={{ textTransform: 'none', color: theme_textColorBlended, width: '160px' }}>
            <SearchOffIcon sx={{ mr: 1, color: theme_errorRed, opacity: 0.7, width: '22px', height: '22px' }}/>
            Cancel Search
          </Button>
        </Stack>

      </Stack>
    </Stack>
  )

  // Main render - parcel search expression builder

  return (

    <Stack sx={{ bgcolor: theme_textColorBlended, borderRadius: 1, mt: 1, boxShadow: 3 }}>
      <Stack direction='column' sx={{ bgcolor: theme_bgColorMain, borderRadius: 1, m: '1px', p: '7px', alignItems: 'left' }}>

        <Stack direction='column'>

          {/* Title bar area */}

          <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>

            {/* Title and area filter */}

            <Stack direction='column'>

              {/* Title */}

              <Typography sx={{ mr: 1, fontSize: '1.1rem', color: theme_orange, opacity: 1, textTransform: 'none' }}>
                Search Parcels
              </Typography>

              {/* Area filter */}

              <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center', mt: 0.2 }}>

                <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8, mr: 1 }}>
                  Area Filter
                </Typography>

                <FormControl>
                  <ParcelSearchSelect
                    id="parcel-search-filter-select-name"
                    variant='standard' size='small' 
                    value={store_parcelSearchAreaFilter}
                    onChange={onAreaFilterChanged}
                    sx={{ color: theme_textColorMain, opacity: 0.5 }}
                    // input={<OutlinedInput label="States"/>}
                    // MenuProps={MenuProps}
                    renderValue={(selected) => (
                      <Box sx={{ }}>
                        {selected as string}
                      </Box>
                    )}
                  >
                    <MenuItem key={'None'} value={'None'} sx={{ color: theme_textColorMain, opacity: 0.5 }}>None</MenuItem>
                    <MenuItem key={'Viewport'} value={'Viewport'}>Active Viewport</MenuItem>
                    <MenuItem key={'AOI'} value={'AOI'}>Active Area of Interest (AOI)</MenuItem>
                  </ParcelSearchSelect>
                </FormControl>

              </Stack>

              {/* AOI Buffer (miles) */}

              {store_parcelSearchAreaFilter === 'AOI'
                ?
                  <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center', mt: 0.2 }}>

                    <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8, mr: 1 }}>
                      Buffer
                    </Typography>

                    <ParcelSearchTextField size='small' variant='standard' 
                      value={store_parcelSearchAOIBufferMiles} onChange={(e)=>OnAoiBufferChanged(e)}
                      inputProps={{style: { fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8 }}} // font size of input text
                      sx={{ width: '25px' }}/>

                    <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8, ml: 0.5 }}>
                      miles
                    </Typography>

                  </Stack>
                :null
              }
            </Stack>

            {/* States, max results, and close button */}

            <Stack direction='row'>

              {/* States selector (multi-select) */}

              <Stack>
                
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.8 }}>
                  States
                </Typography>

                <FormControl sx={{ minWidth: '10px', maxWidth: '140px' }}>
                  {/* <InputLabel id="parcel-search-states-select-label">States</InputLabel> */}
                  <Select
                    // labelId="parcel-search-states-select-label"
                    id="parcel-search-states-select-name"
                    variant='standard' size='small' multiple 
                    value={store_parcelSearchStates}
                    onChange={onStateSelectionChanged}
                    open={selStatesIsOpen}
                    onOpen={() => setSelStatesIsOpen(true)}
                    onClose={() => setSelStatesIsOpen(false)}
                    // input={<OutlinedInput label="States"/>}
                    sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: theme_textColorBlended },
                          '& .MuiSvgIcon-root': { color: theme_textColorBlended } }}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', gap: 0.5, overflow: 'hidden' }}>
                        {selected.map((value) => (
                          <Chip color='primary' size='small' variant='filled' key={value} label={value} 
                                onMouseDown={(event) => { event.stopPropagation(); }}
                                onClick={()=>setSelStatesIsOpen(true)}
                                onDelete={()=>OnDeselectState(value)}/>
                        ))}
                      </Box>
                    )}
                    // MenuProps={MenuProps}
                  >
                    {GetStateList().map((state) => (
                      <MenuItem key={state.shortName} value={state.shortName} sx={{ justifyContent: "space-between" }}>
                        {state.fullName}
                        {store_parcelSearchStates.includes(state.shortName) ? <CheckIcon sx={{ ml: 1, color: theme_orange }} /> : null}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {/* Max results selector */}

              <Stack sx={{ mx: 2 }}>
                
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.8 }}>
                  Max Results
                </Typography>

                <FormControl sx={{ minWidth: '10px', maxWidth: '140px' }}>
                  {/* <InputLabel id="parcel-search-max-results-select-label">Max Results</InputLabel> */}
                  <ParcelSearchSelect
                    // labelId="parcel-search-max-results-select-label"
                    id="parcel-search-max-results-select-name"
                    variant='standard' size='small' 
                    value={store_parcelSearchMaxResults}
                    onChange={onMaxResultsChanged}
                    sx={{ color: theme_textColorMain, opacity: 0.5 }}
                    // input={<OutlinedInput label="States"/>}
                    // MenuProps={MenuProps}
                  >
                    <MenuItem key={100} value={100}>{100}</MenuItem>
                    <MenuItem key={250} value={250}>{250}</MenuItem>
                    <MenuItem key={500} value={500}>{500}</MenuItem>
                    <MenuItem key={1000} value={1000}>{1000}</MenuItem>
                    <MenuItem key={2500} value={2500}>{2500}</MenuItem>
                    <MenuItem key={5000} value={5000}>{5000}</MenuItem>
                  </ParcelSearchSelect>
                </FormControl>

              </Stack>

              {/* Sort By */}

              <Stack>
                
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.8 }}>
                  Sort By
                </Typography>

                <FormControl variant="standard" sx={{ mr: 1, minWidth: 30 }}>
                  <ParcelSearchSelect variant='standard' size='small' labelId='attrib-sort-name-label' 
                                      value={store_parcelSearchDbSortAttribName} onChange={(e)=>OnDbSortAttribChanged(e)}>
                    <MenuItem key={undefined} value={undefined} sx={{ color: theme_textColorMain, opacity: 0.5, px: '5px' }}>No Sort</MenuItem>
                    {GetParcelAttributes().map(parcelAttribute =>
                      <MenuItem key={parcelAttribute.attribute_name} value={parcelAttribute.attribute_name} sx={{ px: '5px' }}>
                        {parcelAttribute.display_name}
                      </MenuItem>
                    )}
                  </ParcelSearchSelect>
                </FormControl>
              </Stack>

              {/* Sort Order */}

              <Stack>
                
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.8 }}>
                  Desc
                </Typography>

                <Checkbox size="small" checked={store_parcelSearchDbSortOrderDesc} onChange={(_,c)=>OnDbSortDescChanged(c)}
                          sx={{ p: '4px', color: theme_textColorBlended + 'A0', '&.Mui-checked': { color: theme_textColorBlended } }} />

              </Stack>

              {/* Close parcel search UI button */}

              <Stack direction='row' sx={{ justifyContent: 'right' }}>
                <IconButton size="small" onClick={(_)=>OnCloseParcelSearch()}
                            sx={{ padding: 0, bgcolor: theme_bgColorMain, width: '32px', height: '32px' }}>
                  <CloseIcon sx={{ opacity: 1, width: '25px', height: '25px', color: theme_textColorBlended }} />
                </IconButton>
              </Stack>

            </Stack>

          </Stack>

          {/* Search expression builder */}

          <Stack sx={{ mt: '-3px', mb: '-9px', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button size='small' variant='text' onClick={(_)=>OnClearAllAttributes()}
                    sx={{ m: 0, p: 0, height: '16px', minWidth: '50px', maxWidth: '50px' }}>
              <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, textTransform: 'none' }}>
                Clear All
              </Typography>
            </Button>
          </Stack>

          <ParcelSearchBuilder/>

          {/* Search expression botton bar: Match AND/OR, Add Clause, Run Search */}

          <Stack direction='row' sx={{ mt: 3, justifyContent: 'space-between'}}>

            <Button size='small' onClick={(_)=>OnAddSearchClause()} sx={{ textTransform: 'none', fontSize: '0.8rem' }}>
              <PlaylistAddIcon sx={{ mr: 0.7, color: theme_textColorBlended, width: '20px', height: '20px' }}/>
              Add Clause
            </Button>

            <Button size='small' onClick={(_)=>OnToggleMainOperator()} sx={{ textTransform: 'none', mx: 2 }}>
              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem' }}>
                Match:
              </Typography>
              <Typography sx={{ color: theme_limeGreen, ml: 0.6, fontSize: '0.7rem', opacity: 0.8 }}>
                {store_parcelSearchExpression.operator==='and'?'All clauses (AND)':'Any clause (OR)'}
              </Typography>
            </Button>

            <Button size='small' variant='outlined' onClick={(_)=>OnRunSearch()} 
                    sx={{ textTransform: 'none', color: theme_orange, width: '140px' }}>
              <SearchIcon sx={{ mr: 1, color: theme_limeGreen, width: '22px', height: '22px' }}/>
              Run Search
            </Button>
                          
          </Stack>

          {/* Search results notification area */}

          {store_parcelSearchResultsViewerMode
            ?
              <Stack direction='column' sx={{ mt: 3, bgcolor: GetColorBasedOnResultsCount(), borderRadius: 1, boxShadow: 1, p: 1, justifyContent: 'space-between' }}>
  
                {store_selectedParcels.length === 0
                  ?
                    <Typography sx={{ color: theme_textColorMain, fontSize: '1rem', opacity: 0.8 }}>
                      No matching parcels were found.
                    </Typography>
                  :
                    <Stack>
                      {store_selectedParcels.length === store_parcelSearchMaxResults
                        ?
                          <Typography sx={{ maxWidth: '400px', color: theme_textColorMain, fontSize: '0.9rem', opacity: 0.8 }}>
                            Showing only the first {store_parcelSearchMaxResults} matches.  Please refine your search or increase the max results returned.
                          </Typography>
                        :
                          <Stack direction='row' sx={{ alignItems: 'center', color: theme_textColorMain, fontSize: '1rem', opacity: 0.8 }}>
                            Found
                            <Typography sx={{ color: theme_orange, fontSize: '1.2rem', fontWeight: 'bold', opacity: 1, mx: 0.7 }}>
                              {store_selectedParcels.length}
                            </Typography>
                            matching parcels.
                          </Stack>
                      }
                    </Stack>
                }

                <Stack direction='row' sx={{ mt: 2, alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>

                  {store_selectedParcels.length > 0 
                    ?
                      <Button size='small' variant='outlined' onClick={(_)=>OnOpenParcelTableViewer()} 
                              sx={{ textTransform: 'none', color: theme_orange, width: '140px', bgcolor: theme_bgColorMain + '90' }}>
                        <TocIcon sx={{ mr: 1, color: theme_limeGreen, width: '22px', height: '22px' }}/>
                        View Details
                      </Button>
                    :null
                  }

                  <Button size='small' variant='outlined' onClick={(_)=>OnCloseSearchResults()} 
                          sx={{ ml: 2, textTransform: 'none', color: theme_textColorBlended, width: '140px', bgcolor: theme_bgColorMain + '90' }}>
                    <CloseIcon sx={{ mr: 1, color: theme_errorRed, width: '22px', height: '22px' }}/>
                    Close Results
                  </Button>

                </Stack>

              </Stack>
            :null
          }

        </Stack>

      </Stack>
    </Stack>
  )
}



export default ParcelSearch;




// Customized MUI Select
export const ParcelSearchSelect = styled(Select)(() => (
{
  fontSize: '0.8rem', 
  color: theme_textColorMain, 
  opacity: 0.8,

  // These change the underline color when using the standard variant
  ':before': { borderBottomColor: theme_textColorBlended },
  ':after': { borderBottomColor: theme_textColorBlended },

  '& .MuiSvgIcon-root': { color: theme_textColorBlended }
}));

// Customized MUI TextField
const ParcelSearchTextField = styled(TextField)(() => (
{
  // These change the underline color when using the standard variant
  '& .MuiInput-underline:before': { borderBottomColor: theme_textColorBlended },
  '& .MuiInput-underline:after': { borderBottomColor: theme_textColorBlended },
}));
