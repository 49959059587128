import * as React from 'react';
import Button from '@mui/material/Button';
import { Stack, FormGroup, FormControlLabel, Switch, Divider, Typography, Dialog, DialogContent, DialogActions, DialogTitle, IconButton } from '@mui/material';
import useStore from './store';
import SettingsIcon from '@mui/icons-material/Settings';
import { UpdateMapScaleState, UpdateMapCompassState, UpdateMapGeolocateState, UpdateMapRulerState } from './Map/MapOps';
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange } from './Theme';


export type TAppUnits = 'metric' | 'imperial';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AppSettingsDialogProps
{
}

//-------------------------------------------------------------------------------
// App settings dialog Component
//-------------------------------------------------------------------------------
const AppSettingsDialog = (props: AppSettingsDialogProps) =>
{
  // Get needed state data from the store
  const { store_mapScaleControl, store_userProfile, store_appSettingsOpen, 
          store_setUserProfileMapScale, store_setUserProfileAppUnits, 
          store_setUserProfileMapCompass, store_setUserProfileIsDirty, 
          store_setUserProfileMapGeolocate, store_setAppSettingsOpen, 
          store_setUserProfileMapRuler 
        } = useStore();



  //-------------------------------------------------------------------------------
  // Map scale setting was changed.
  //-------------------------------------------------------------------------------
  const onMapScaleChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  { 
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapScale(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapScaleState(event.target.checked, store_userProfile.app_units);
  }

  //-------------------------------------------------------------------------------
  // Map compass setting was changed.
  //-------------------------------------------------------------------------------
  const onMapCompassChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapCompass(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapCompassState(event.target.checked);
  }

  //-------------------------------------------------------------------------------
  // App units (metric vs imperial) setting was changed.
  //-------------------------------------------------------------------------------
  const onAppUnitsChanged = () => 
  {
    if(!store_userProfile) return;

    const newValue: TAppUnits = store_userProfile.app_units === 'metric' ? 'imperial' : 'metric';

    store_setUserProfileAppUnits(newValue);
    store_setUserProfileIsDirty(true);

    if(store_mapScaleControl)
      store_mapScaleControl?.setUnit(newValue);
    
    if(store_userProfile.map_ruler === true)
      UpdateMapRulerState(store_userProfile.map_ruler, newValue);
  }

  //-------------------------------------------------------------------------------
  // Map geolocation setting was changed.
  //-------------------------------------------------------------------------------
  const onMapGeolocateChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapGeolocate(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapGeolocateState(event.target.checked);
  }
  
  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onCloseDialog = () => 
  {
    store_setAppSettingsOpen(false);
  }

  //-------------------------------------------------------------------------------
  // Map ruler setting was changed.
  //-------------------------------------------------------------------------------
  const onMapRulerChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapRuler(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapRulerState(event.target.checked, store_userProfile.app_units);
  }
    
    





  if(!store_userProfile) return null;

  // Main render

  return (

    <Dialog disablePortal 
            open={store_appSettingsOpen===true}
            onClose={onCloseDialog} maxWidth='md' 
            PaperProps={{ sx: { maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <SettingsIcon sx={{ color: theme_textColorBlended }}/>
            <Typography sx={{ ml: 1, fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain+'F0' }}>
              App Settings
            </Typography>
          </Stack>

          <IconButton size="small" onClick={onCloseDialog}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>      

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>

        <Stack direction='row' sx={{ mt: 2 }}>

          <FormGroup>

            {/* Units (metric vs imperial) */}

            <Stack direction='row' sx={{ alignItems: 'center', mb: 2 }}>
              <Typography sx={{ color: theme_textColorBlended }}>
                Units
              </Typography>
              <Button size='small' variant='outlined' sx={{ ml: 1, width: '80px', height: '26px', color: theme_orange+'C0' }} 
                      onClick={() => { onAppUnitsChanged(); }}>
                  {store_userProfile.app_units}
                </Button>
            </Stack>

            {/* Map scale */}

            <FormControlLabel control={<Switch size='small'
                              checked={store_userProfile.map_scale} onChange={ onMapScaleChanged } />}
                              label={<Typography sx={{ ml: 1, color: theme_textColorMain+'B0'  }}>Show map scale bar</Typography>} />

            {/* Map compass */}

            <FormControlLabel control={<Switch size='small' checked={store_userProfile.map_compass}
                              onChange={ onMapCompassChanged } />} sx={{ mt: '2px' }}
                              label={<Typography sx={{ ml: 1, color: theme_textColorMain+'B0' }}>Show compass tool</Typography>}/>

            {/* Map geolocate */}

            <FormControlLabel control={<Switch size='small' checked={store_userProfile.map_geolocate}
                              onChange={ onMapGeolocateChanged } />} sx={{ mt: '2px' }}
                              label={<Typography sx={{ ml: 1, color: theme_textColorMain+'B0' }}>Show "find my location" tool</Typography>}/>

            {/* Map ruler */}

            <FormControlLabel control={<Switch size='small' checked={store_userProfile.map_ruler}
                              onChange={ onMapRulerChanged } />} sx={{ mt: '2px' }}
                              label={<Typography sx={{ ml: 1, color: theme_textColorMain+'B0' }}>Show distance measuring tool</Typography>}/>

          </FormGroup>

        </Stack>

        <Divider sx={{ mt: 2, mb: 0 }}/>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

          {/* CLOSE button */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={onCloseDialog} sx={{ mr: 3, width: '100px' }}>
                Close
              </Button>
            </Stack>

        </Stack>

      </DialogActions>

    </Dialog>
  );
}



export default AppSettingsDialog;




