// Org management operations

import { CallServer, CallServerResults } from "../../CallServer";
import Debug from "../../Debug";
import { ToastNotification } from "../../ToastNotifications";
import { IOrgManagement_Boundary, IOrgManagement_Data, IOrgManagement_Org } from "./OrgManagementInterfaces";


//-------------------------------------------------------------------------------
// Load org and boundary data.
//-------------------------------------------------------------------------------
export async function LoadAdminOrgAndBoundaryData(setIsRunning: any = undefined): Promise<IOrgManagement_Data | undefined>
{
  // Call the server to get the data

  const server = new CallServer();

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('get', '/orgs');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    const data: IOrgManagement_Data | undefined = result.data;
    if(!data)
    {
      ToastNotification('error', "Unable to load admin org and boundary data")
      return undefined;
    }

    // Sort the lists

    const sortedData: IOrgManagement_Data =
    {
      ...data,
      orgs: data.orgs.sort((a: IOrgManagement_Org, b: IOrgManagement_Org) => a.name.localeCompare(b.name)),
      boundaries: data.boundaries.sort((a: IOrgManagement_Boundary, b: IOrgManagement_Boundary) => a.name.localeCompare(b.name)),
    }

    // Success
    return sortedData;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load admin org and boundary data")
    return undefined;
  }
}

//-------------------------------------------------------------------------------
// Create/update an org.
//-------------------------------------------------------------------------------
export async function CreateOrUpdateOrg(org: IOrgManagement_Org , setIsRunning: any = undefined): Promise<boolean>
{
  if(!org) return false;

  // Remove a couple of things that don't need to be sent back (like 'expired' and 'stats')
  const fixedOrg = 
  {
    id: org.id,
    name: org.name,
    state: org.state,
    boundary_id: org.boundary_id ?? null,
    expiration_date: org.expiration_date ?? null,
    settings: org.settings
  }

  // Call the server

  const server = new CallServer();
  server.Add('org', fixedOrg);

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('post', '/orgs');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    if(org.id === null)  // We created a new org
    {
      const newOrgID: number | undefined = result.data.org_id;
      if(newOrgID === undefined)
        Debug.warn('Received invalid new org ID');
      else
        org.id = newOrgID;
    }

    ToastNotification('success', org.id === undefined ? 'New organization created' : 'Organization updated' );

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to create or update org data")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete an org.
//-------------------------------------------------------------------------------
export async function DeleteOrg(org: IOrgManagement_Org, setIsRunning: any = undefined): Promise<boolean>
{
  if(!org) return false;

  // Call the server

  const server = new CallServer();
  server.Add('org_id', org.id);

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('delete', '/org');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    ToastNotification('success', 'The organization has been deleted');

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete org")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete a boundary.
//-------------------------------------------------------------------------------
export async function DeleteBoundary(boundary: IOrgManagement_Boundary, setIsRunning: any = undefined): Promise<boolean>
{
  if(!boundary) return false;

  // Call the server

  const server = new CallServer();
  server.Add('boundary_id', boundary.id);

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('delete', '/boundary');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    ToastNotification('success', 'The boundary has been deleted');

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete boundary")
    return false;
  }
}