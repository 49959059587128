// UserMenuProps

import useStore from '../store';
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import React from 'react';
import { PerformLogout } from './UserOps';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface UserMenuProps
{
}

//-------------------------------------------------------------------------------
// User menu component
//-------------------------------------------------------------------------------
export function UserMenu(props: UserMenuProps) 
{
  // Get needed state data from the store
  const { store_isLoggedIn, store_userInfo, store_accountMenuAnchorElement,
          store_setAccountMenuAnchorElement, store_username, store_setAppSettingsOpen,
          store_setLayersPanelAnchorElement, store_setAccountSettingsOpen, 
          store_setShowTermsOfServiceWindow, store_userProfile,  
          store_setShowAdminToolsWindow, 
        } = useStore();












  //-------------------------------------------------------------------------------
  // The account menu button was clicked.
  //-------------------------------------------------------------------------------
  const onAccountMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    // Set the anchor element of the Popover control to the base map panel button.
    store_setAccountMenuAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|undefined));
  }

  //-------------------------------------------------------------------------------
  // The account menu needs to be closed.
  //-------------------------------------------------------------------------------
  const onAccountMenuClose = () => 
  {
    // Setting the anchor element to null will close the menu
    store_setAccountMenuAnchorElement(undefined);
  }
  









  // The user menu only appears if a  user is logged in and we have user info loaded
  if(store_isLoggedIn != true || !store_userInfo) return null;

  // Main render

  return (
    
    <Box sx={{ position: 'absolute', top: 25, right: 15 }}>

      <Box sx={{ padding: '3px', bgcolor: '#1C305A', boxShadow: 3, borderRadius: 20 }}>
        <IconButton sx={{ padding: 0, margin: 0, boxShadow: 0, width: 35, height: 35,
                          color: '#1C305A', bgcolor: '#E7A44F', fontSize: '1.2rem',
                          ':hover': { bgcolor: '#ecd5ac', color: '#1C305A' } }} 
                    onClick={onAccountMenuButtonClick}>
            {`${store_userInfo?.first_name[0]}${store_userInfo?.last_name[0]}`}
        </IconButton>
      </Box>

      <Menu id="account-menu" anchorEl={store_accountMenuAnchorElement} open={Boolean(store_accountMenuAnchorElement)}
            onClose={onAccountMenuClose} MenuListProps={{'aria-labelledby': 'basic-button'}}>

        <Stack direction='column' sx={{ bgcolor: theme_bgColorLight1, borderRadius: 1, ml: 1, 
                                        mr: 1,  p: 1, mb: 1, alignItems: 'center' }}>

          <Typography sx={{ color: theme_orange, ml: 1, mr: 1, textAlign: 'center' }}>
            {store_userInfo?.first_name} {store_userInfo?.last_name}
          </Typography>

          <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem' }}>
            {store_username}
          </Typography>

          {store_userInfo.organizations.length === 1
            ?
              <Typography sx={{ color: theme_limeGreen, mx: '5px', fontSize: '0.7rem', textAlign: 'center', maxWidth: '200px' }}>
                {store_userInfo.organizations[0].name}
              </Typography>
            :
              <Tooltip arrow placement='left-start' title=
                      {
                        store_userInfo.organizations.map(org =>
                          <Typography key={org.id} sx={{ color: theme_textColorMain+'B0', mx: '5px', fontSize: '0.8rem', textAlign: 'center' }}>
                            {org.name}
                          </Typography>
                        )
                      }>
                <Typography sx={{ color: theme_limeGreen, mx: '5px', fontSize: '0.7rem', textAlign: 'center', maxWidth: '200px' }}>
                  Member of {store_userInfo.organizations.length}  organizations
                </Typography>
              </Tooltip>
          }

        </Stack>

        {store_userInfo && store_userInfo.admin === true
          ?
            <MenuItem onClick={() => { store_setShowAdminToolsWindow(true); onAccountMenuClose(); }}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" sx={{ color: theme_textColorBlended }}/>
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ color: theme_errorRed, fontWeight: 'bold', opacity: 0.8 }}>
                  Admin
                </Typography>
              </ListItemText>
            </MenuItem>
          :null
        }

        <MenuItem onClick={() => { store_setShowTermsOfServiceWindow(true); onAccountMenuClose(); }}>
          <ListItemIcon>
            <GavelIcon fontSize="small" sx={{ color: theme_textColorBlended }}/>
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ color: theme_textColorBlended }}>
              End User Agreement
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => { store_setAppSettingsOpen(true); onAccountMenuClose(); }} disabled={!store_userProfile}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" sx={{ color: theme_textColorBlended }}/>
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => { store_setAccountSettingsOpen(true); onAccountMenuClose(); }}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" sx={{ color: theme_textColorBlended }}/>
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>

        <Divider />
        
        <MenuItem onClick={() => { PerformLogout(); store_setLayersPanelAnchorElement(undefined); }}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" sx={{ color: theme_textColorBlended }}/>
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>

      </Menu>
    </Box>
  )
}


