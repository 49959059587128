// Layer List component

import { FormGroup, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStore from "../store";
import { ILocalLayerItem, LayerListItem } from "./LayerListItem";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import Debug from "../Debug";
import { AddLayerToMap, GetLayerByID, RemoveLayerFromMap } from "./LayerOps";
import { ILayer } from "./LayerInterfaces";
import { GetMapSymbolLayerID } from "../Map/MapOps";
import MyCircularProgress from "../Components/CircularProgress";
import { theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayersListProps 
{
}

//-------------------------------------------------------------------------------
// LayersList component
//-------------------------------------------------------------------------------
const LayersList = (props: LayersListProps) => 
{
  // Get needed state data from the store
  const { store_map, store_layers, store_setLayers, store_baseMap, store_isLoggedIn, 
          store_bundleIsLoading, store_userProfileIsLoading, store_projectIsLoading, 
          store_setProjectIsDirty, store_project, 
        } = useStore();
  
  const [sortableLayerList, setSortableLayerList] = useState<ILocalLayerItem[]>([]);


  // Used for the layer drag-and-drop reordering
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates, })
  );



  // function setLayerExpandState(id: number, newValue: boolean)
  // {
  //   // Rebuild the list, but using the new value for the specified layer

  //   const newLayerList : ILocalLayerItem[] = [];

  //   for(let i=0; i < sortableLayerList.length; i++)
  //   {
  //     if(sortableLayerList[i].id === id)
  //       newLayerList.push(
  //         { 
  //           id: sortableLayerList[i].id, 
  //           layer: sortableLayerList[i].layer, 
  //           expanded: newValue
  //         });
  //     else
  //       newLayerList.push(sortableLayerList[i]);
  //   }

  //   setSortableLayerList(newLayerList);
  // }

  //-------------------------------------------------------------------------------
  // Handle:  A layer has been moved by the user.
  //-------------------------------------------------------------------------------
  function handleSortableItemDragEnd(event: any) 
  {
    if(!event || !store_map) return;

    const {active, over} = event;

    if(!active || !over) return;
    
    if (active.id !== over.id) 
    {
      // To reorder the actual map layers, we can't simply swap the 2 map layers 
      // that were moved.  Either the source or dest layers (or both) could be 
      // inactive, and some layers in between may be active.
      //
      // Mapbox provides the 'moveLayer' method, which can move a layer directly
      // underneath another specified layer, but using it with drag-and-drop can
      // be tricky.  When dragging a layer DOWN on top of another layer, we can
      // just do moveLayer on it, however when a layer is dragged UPWARDS onto
      // another layer, we need to move our layer ABOVE it, which we can't do with
      // moveLayer, so we need to find the first layer above that layer, and move
      // our layer underneath it to get the proper order.
      //
      // This is further complicated by the fact that in the UI some layers are
      // inactive, and thus not added as a layer in the actual map, so we need to 
      // search upwards from an index until we find the first active layer.  For 
      // DOWN drags we start searching right at the destination index.  For UP 
      // drags, we start one item upwards of the dest index.
      //
      // Another issue to consider is the base map symbol layers.  For now, we add
      // all layers under the symbol layers to keep things simple.  In the future
      // this might change, with some layers going on top of roads, but under labels,
      // so moving layers around with this algorithm may no longer work correctly as-is.

      const sourceLayerItem: ILocalLayerItem | null = GetLayerItem(sortableLayerList, active.id);
      const destLayerItem: ILocalLayerItem | null = GetLayerItem(sortableLayerList, over.id);

      if(!sourceLayerItem || !destLayerItem) return;

      // Find the array index of both source and destination layers

      let sourceIndex: number = -1;
      let destIndex: number = -1;
      for(let i=0; i < sortableLayerList.length; i++)
      {
        if(sortableLayerList[i].id === active.id)
          sourceIndex = i;
        if(sortableLayerList[i].id === over.id)
          destIndex = i;
      }

      if(sourceIndex < 0 || destIndex < 0) return;

      // If the layer being moved is inactive, no need to change any layer 
      // order in the map.

      if(sourceLayerItem.layer.enabled)
      {
        // Start at the dest index and search upwards, looking for the 1st active layer

        // If we are dragging our layer UPWARDS, we will start the search one item higher
        const movingUP : boolean = destIndex < sourceIndex;
        const indexStartOffset = movingUP ? 1 : 0;

        let foundLayerItem : ILocalLayerItem | null = null;
        for(let i=destIndex-indexStartOffset; i >= 0; i--)
          if(sortableLayerList[i].layer.enabled)
          {
            foundLayerItem = sortableLayerList[i];
            break;
          }

        // If we found a layer item, and it's NOT the source layer, we can
        // proceed to move the map layer.

        if(foundLayerItem)
        {
          if(foundLayerItem.id !== sourceLayerItem.id)  // Not self
            store_map.moveLayer(sourceLayerItem.layer.id.toString(), foundLayerItem.layer.id.toString());
        }
        else // layer not found (we reached the top)
        {
          // Move the item to the top (under the base map symbol layer)
          store_map.moveLayer(sourceLayerItem.layer.id.toString(), GetMapSymbolLayerID(store_map, store_baseMap));
        }
      }

      // We also have to update the UI and store states.
      //
      // Re-sort the local state list by swapping the 2 affected array elements.
      // The same swap also happens in the list that is in the app's state store)

      let localLayersNewSort = sortableLayerList;
      let storeLayersNewSort = store_layers;
        
      localLayersNewSort = arrayMove(sortableLayerList, sourceIndex, destIndex);
      storeLayersNewSort = arrayMove(store_layers, sourceIndex, destIndex);

      setSortableLayerList(localLayersNewSort);
      store_setLayers(storeLayersNewSort);

      store_setProjectIsDirty(true);


      // NOTE: Old lazy method (easy to implement, but the map has to do a lot 
      //       more work to re-add all the layers, and the map flashes as it does it).
      // 
      // To update the layer order in the actual map, for now just remove all 
      // active layer and re-add them in correct order.

      // if(!sourceLayerItem.layer.enabled) return;  // If the source item is inactive, no need to update the map

      // for(let i=0; i < localLayersNewSort.length; i++)
      //   if(localLayersNewSort[i].layer.enabled)
      //     store_map.removeLayer(localLayersNewSort[i].layer.name);

      // for(let i=localLayersNewSort.length-1; i>= 0; i--)
      //   if(localLayersNewSort[i].layer.enabled)
      //     AddLayer(store_map, localLayersNewSort[i].layer, false);
    }
  }

  //-------------------------------------------------------------------------------
  // Get the ILayerItem in the list based on an id.
  //-------------------------------------------------------------------------------
  function GetLayerItem(layerItems: ILocalLayerItem[], itemID: number) : ILocalLayerItem | null
  {
    for(let i=0; i < layerItems.length; i++)
      if(layerItems[i].id === itemID)
        return layerItems[i];

    return null;
  }

  //-------------------------------------------------------------------------------
  // Map init (only if the map is null).
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initialize the list of local layer items (that can be drag-and-drop re-ordered)

    let layerID = 1;
    const initSortableLayers: ILocalLayerItem[] = [];

    for(let i=0; i < store_layers.length; i++)
    {
      //if(store_layers[i].activeInProject) // only add layers currently "active" in the project
        initSortableLayers.push({ id: layerID++, layer: store_layers[i] });
    }

    setSortableLayerList(initSortableLayers);

  // eslint-disable-next-line
  }, [store_layers, setSortableLayerList]);

  //-------------------------------------------------------------------------------
  // The user clicked on a layer check box.
  //-------------------------------------------------------------------------------
  const onLayerCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_map) return;

    // The layer ID will be in 'event.target.id' (but as a string)
    Debug.log("Layers.onLayerCheckBox> %s | %s", event.target.id, event.target.checked);
    const clickedLayerID: number = Number.parseFloat(event.target.id);
    if(!clickedLayerID) return;

    // Find what layer was clicked and change that layer's state
    const clickedLayer: ILayer | null = GetLayerByID(clickedLayerID);

    if(!clickedLayer)
    {
      Debug.warn("Layers.onLayerCheckBox> Unable to determine clicked layer");
      return;
    }

    Debug.log("Layers.onLayerCheckBox> id=%d | name=%s | enabled=%s", clickedLayer.id, clickedLayer.name, clickedLayer.enabled);

    if(event.target.checked) 
      AddLayerToMap(clickedLayer.id);
    else 
      RemoveLayerFromMap(clickedLayer.id);

    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // Return a list of layers currently active (in the active project).
  //------------------------------------------------------------------------------- 
  // function GetActiveLayers(): ILayer[]
  // {
  //   const activeLayers: ILayer[] = [];
  //   for(let i=0; i < store_layers.length; i++)
  //     if(store_layers[i].activeInProject)
  //       activeLayers.push(store_layers[i]);

  //   return activeLayers;
  // }

  //-------------------------------------------------------------------------------
  // Return the number of layers currently active (in the active project).
  //------------------------------------------------------------------------------- 
  function GetActiveLayerCount(): number
  {
    let activeLayerCount: number = 0;
    for(let i=0; i < store_layers.length; i++)
      if(store_layers[i].activeInProject)
        activeLayerCount++;

    return activeLayerCount;
  }








  //-------------------------------------------------------------------------------
  // No user logged in.
  //-------------------------------------------------------------------------------
  if(!store_isLoggedIn)
  return (

    <Stack direction='row' sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 1 }}>
      <Typography sx={{ fontSize: '0.9rem', color: theme_textColorBlended, textAlign: 'center' }}>
        Please log in to access the full layer library.
      </Typography>
    </Stack>
  )

  //-------------------------------------------------------------------------------
  // Loading...
  //-------------------------------------------------------------------------------
  if(store_userProfileIsLoading || store_projectIsLoading || store_bundleIsLoading)
  return (

    <Stack direction='row' sx={{ alignItems: 'center', ml: 0.2, mt: 2, mb: 2.7 }}>
      <MyCircularProgress circleSize={20}/>
      <Typography sx={{ ml: 1, fontSize: '0.8rem', color: theme_textColorBlended }}>
        Loading layers...
      </Typography>
    </Stack>
  )

  //-------------------------------------------------------------------------------
  // No saved/active project (a new project is being created).
  //-------------------------------------------------------------------------------

  if(!store_project || !store_project.project_id)
  return (

    <Stack direction='row' sx={{ alignItems: 'center', mt: 2, mb: 1, justifyContent: 'center' }}>

      <WestIcon sx={{ ml: '5px', mr: '10px', width: '30px', height: '30px', color: theme_orange }}/>      

      <Stack direction='row' sx={{ ml: 1, mr:1, fontSize: '0.9rem', color: theme_limeGreen, alignItems: 'center' }}>
        <Typography sx={{ fontSize: '1rem', color: theme_errorRed, opacity: 1, maxWidth: '240px' }}>
           Please create a new project to gain access to the layer library.
        </Typography>
      </Stack>

    </Stack>
  )

  //-------------------------------------------------------------------------------
  // No active layers.
  //-------------------------------------------------------------------------------

  if(GetActiveLayerCount() === 0)
  return (

    <Stack direction='row' sx={{ alignItems: 'center', mt: 7, mb: 0, justifyContent: 'center' }}>
      <Stack direction='row' sx={{ ml: 1, fontSize: '0.9rem', color: theme_limeGreen, alignItems: 'center' }}>
        <Typography sx={{ fontSize: '0.9rem', color: theme_textColorMain, opacity: 0.7 }}>
            Use the
        </Typography>
        <Typography sx={{ ml: '5px', fontSize: '1rem', color: theme_orange, fontWeight: 'bold' }}>
            Layer Library
        </Typography>
        <Typography sx={{ ml: '5px', fontSize: '0.9rem', color: theme_textColorMain, opacity: 0.7 }}>
            to add layers
        </Typography>
      </Stack>
      <EastIcon sx={{ ml: '10px', mr: '5px', width: '30px', height: '30px', color: theme_orange }}/>

    </Stack>
  )

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (

    <Stack sx={{ minWidth: '200px', maxWidth: '350px' }}>

      <Stack direction='row' alignItems='center' sx={{ mr: 1, mt: -1 }}>
        <Typography sx={{ width: '100%', textAlign: 'right', fontSize: '0.5rem', color: theme_textColorBlended, opacity: 0.6 }}>
          Drag-and-drop layers to change the map draw order
        </Typography>
        <Typography sx={{ ml: '3px', mb: '-6px', fontSize: '1.0rem', color: theme_limeGreen, opacity: 0.6 }}>
        ➘  {/* ↴🡖➘➘⤵⮯🡶⮛⮟🠇 */}
        </Typography>
      </Stack>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleSortableItemDragEnd} 
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
        <SortableContext items={sortableLayerList} strategy={verticalListSortingStrategy}>

          <FormGroup>
            {sortableLayerList.map(localLayerItem => 
              <LayerListItem key={localLayerItem.id} localLayerItem={localLayerItem} onLayerCheckBox={onLayerCheckBox}/>
            )}
          </FormGroup>

        </SortableContext>
      </DndContext>
      
    </Stack>
  )
}


export default LayersList;
