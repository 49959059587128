// Color gradient bar component

import { Stack } from "@mui/material";
import { HexToRgb } from "../../../Globals";
import { IPortfolioMapColorScheme_Gradient } from "../PortfolioMapInterfaces";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ColorGradientBarProps
{
  gradient?: IPortfolioMapColorScheme_Gradient
}

//-------------------------------------------------------------------------------
// Color gradient bar component
//-------------------------------------------------------------------------------
export const ColorGradientBar = (props: ColorGradientBarProps) =>
{









  return (

    <Stack sx={{ width: '100%', height: '100%', borderRadius: 1, boxShadow: 2, bgcolor: 'black' }}>
      <Stack sx={{ m: '1px', height: '100%', borderRadius: 0.8,  
                   background: props.gradient ? GenerateLinearGradientCSS(props.gradient) : 'black'
                }}>
      </Stack>
    </Stack>
  );
}



//-------------------------------------------------------------------------------
// Generates a linear-gradient CSS string (to be used with the 'background' property of UI elements).
//
// Ex: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)
//-------------------------------------------------------------------------------
export function GenerateLinearGradientCSS(gradient: IPortfolioMapColorScheme_Gradient): string
{
  let str: string = 'linear-gradient(90deg, ';

  // First we need to know the min and max values

  let minValue: number = Number.MAX_VALUE;
  let maxValue: number = Number.MIN_VALUE;

  for(let i=0; i < gradient.items.length; i++)
  {
    if(gradient.items[i].value < minValue)
      minValue = gradient.items[i].value;
    if(gradient.items[i].value > maxValue)
      maxValue = gradient.items[i].value;
  }

  for(let i=0; i < gradient.items.length; i++)
  {
    const rgb = HexToRgb(gradient.items[i].color)
    if(!rgb) return ''; // error

    const gradientPercent: number = (gradient.items[i].value - minValue) / (maxValue - minValue) * 100.0;

    str += `rgba(${rgb.r},${rgb.g},${rgb.b},1) ${gradientPercent}%`;

    if(i < gradient.items.length-1)
      str += ', ';  // Add divider, as there is another color stop
  }  

  str += ')';

  return str;
}