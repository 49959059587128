import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Stack, Typography, Dialog, DialogTitle, IconButton } from '@mui/material';
import useStore from '../store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ChangePwdDialog from './ChangePwdDialog';
import { useState } from 'react';
import { theme_bgColorLight1, theme_textColorBlended, theme_textColorMain, theme_bgColorGradient2 } from '../Theme';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from '../LayerLibrary/EditLayer/EditLayer';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AccountSettingsDialogProps
{
  setCurrTheme: React.Dispatch<React.SetStateAction<any>>;
}

//-------------------------------------------------------------------------------
// Account settings dialog Component
//-------------------------------------------------------------------------------
const AccountSettingsDialog = (props: AccountSettingsDialogProps) =>
{
  // Get needed state data from the store
  const { store_username, store_userInfo, store_accountSettingsOpen,
          store_setAccountSettingsOpen,
        } = useStore();

  

  const [changePwdDialogOpen, setChangePwdDialogOpen] = useState<boolean>(false);



  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onCloseDialog = () => 
  {
    store_setAccountSettingsOpen(false);
  }

  //-------------------------------------------------------------------------------
  // Change password handler.
  //-------------------------------------------------------------------------------
  const onChangePwdButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    setChangePwdDialogOpen(true);
  }



  

  // ******************************************************************************************
  // Main render
  // ******************************************************************************************

  if(store_userInfo)
  return (


    <Dialog disablePortal 
            open={store_accountSettingsOpen===true}
            onClose={onCloseDialog} maxWidth='md' 
            PaperProps={{ sx: { maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <AdminPanelSettingsIcon sx={{ color: theme_textColorBlended }}/>
            <Stack sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: '1.3rem', fontWeight:'bold', color: theme_textColorMain+'F0' }}>
                Account
              </Typography>
              <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                {store_username}
              </Typography>
            </Stack>
          </Stack>

          <IconButton size="small" onClick={onCloseDialog}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>      

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>              

        <Stack direction='column' sx={{ mt: 2 }}>

          {/* Name (First + Last) */}

          <Stack direction='row' sx={{ mt: 2 }}>

            <CustomTextField size='small' id="firstName" label="First Name" name="firstName" fullWidth 
                             value={store_userInfo.first_name} sx={{ mr: 2 }} />

            <CustomTextField size='small' id="lastName" label="Last Name" name="lastName" fullWidth 
                             value={store_userInfo.last_name}/>

            <Button sx={{ ml: 2, textTransform: 'none' }}>
              Update
            </Button>

          </Stack>

          {/* Change Password Button */}

          <Stack direction='row' sx={{ mt: 2 }}>

            {/* <CustomTextField autoComplete="password" name="password" type='password' value='password'
                             id="password" label="Password" autoFocus disabled={true}/> */}

            <Button size='small' variant='outlined' onClick={onChangePwdButtonClick} sx={{ mt: 2, textTransform: 'none' }}>
              Change Password...
            </Button>

          </Stack>

        </Stack>

        {/* Change password dialog */}

        <ChangePwdDialog changePwdDialogOpen={changePwdDialogOpen} setChangePwdDialogOpen={setChangePwdDialogOpen}/>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        {/* CLOSE button */}

        <Button variant='outlined' onClick={onCloseDialog} sx={{ mr: 3, width: '100px' }}>
          Close
        </Button>

      </DialogActions>

    </Dialog>
  )

  // ******************************************************************************************
  // Main render - fall through case (should never happen)
  // ******************************************************************************************

  return (
    <Typography>
      Bundle unhandled state
    </Typography>
  )
}



export default AccountSettingsDialog;
