// Identify for an AOI.

import { IconButton, Paper, Stack, Table, TableBody, tableCellClasses, TableContainer, TableHead, Tooltip } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain, theme_textColorBlended } from "../Theme";
import useStore from "../store";
import { IAoi } from "../Aois/AoiInterfaces";
import { IIdentifyAttribRowData } from "./IdentifyInterfaces";
import IdentifyTableRow from "./IdentifyTableRow";
import { IAoiAttribute } from "../Aois/AoiGroupAndPortfolioMap/AoiGroupInterfaces";
import { GetAoiAttributeValueForAnAoi_StateStore } from "../Aois/AoiGroupAndPortfolioMap/AoiAttributeOps";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { mkConfig, generateCsv, asString } from "export-to-csv";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface IdentifyAoiTableProps
{
  aoi?: IAoi;
}


 
//-------------------------------------------------------------------------------
// Identify table for an AOI.
//-------------------------------------------------------------------------------
const IdentifyAoiTable = (props: IdentifyAoiTableProps) => 
{
  // Get needed state data from the store
  const { store_aoiGroupProperties, store_project
  } = useStore();











  //-------------------------------------------------------------------------------
  // Returns a list of attribute rows ready for rendering.
  //-------------------------------------------------------------------------------
  function GetAllAttributeRows(): IIdentifyAttribRowData[]
  {
    if(!store_aoiGroupProperties || !props.aoi) return [];

    const outputArray: IIdentifyAttribRowData[] = [];

    // For each attribute, create a 'IIdentifyAttribRowData' item

    let nextID: number = 1;

    for(let i=0; i < store_aoiGroupProperties.attributes.length; i++)
    {
      const attrib: IAoiAttribute = store_aoiGroupProperties.attributes[i];

      // Ignore admin AOI attributes - they never get shown in identify
      if(attrib.is_admin) continue;

      let valueStr: string | undefined = GetAoiAttributeValueForAnAoi_StateStore(attrib.id, props.aoi.aoi_id)?.value;

      // Option to skip attributes with empty values
      if(store_project && store_project.user_settings && store_project.user_settings.identify_showEmptyValues === false && 
         (valueStr === undefined || valueStr.length === 0))
        continue;
      else if(valueStr === undefined)
        valueStr = '';  // We'll still show the attribute, but with an empty value

      const newItem: IIdentifyAttribRowData = 
      {
        id: nextID++,
        attribName: attrib.name,
        attribValue: valueStr,
        units: attrib.units,
        description: attrib.description,
        descriptionLink: undefined
      }

      outputArray.push(newItem);
    }

    return outputArray;
  }

  //-------------------------------------------------------------------------------
  // Copies the data to the clipboard (as CSV).
  //-------------------------------------------------------------------------------  
  function OnCopyData()
  {
    const rows: IIdentifyAttribRowData[] = GetAllAttributeRows();

    // Transfer the data into a JSON array

    const data: { name: string; value: string; }[] = [];
    rows.forEach(row => 
    {
      data.push(
        {
          name: row.attribName,
          value: row.attribValue
        });
    });

    // Create a CSV string from the JSON array (using export-to-csv package)

    // mkConfig merges your options with the defaults
    // and returns WithDefaults<ConfigOptions>
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(data);

    // This unpacks CsvOutput which turns it into a string before use
    const addNewLine = (s: string): string => s + "\n";
    const csvOutputWithNewLines = addNewLine(asString(csv));

    // Copy the string to the system clipboard
    navigator.clipboard.writeText(csvOutputWithNewLines);
  }










  // Return nothing if we have no layer or feature
  if(!props.aoi) return null;

  // Main render

  return (

    <Stack sx={{ maxHeight: '275px', overflow: 'auto' }}>

      <Stack sx={{ position: 'absolute', ml: '-30px', mt: '-23px', width: '100%', alignItems: 'end' }}>
        <Tooltip title='Copy the data from this panel to the clipboard using Comma Separated Values (CSV) format.  It can then be pasted into emails or imported into Excel.'>
          <IconButton sx={{ width: '17px', height: '17px' }} onClick={_=>OnCopyData()}>
            <ContentCopyIcon sx={{ width: '12px', height: '12px', color: theme_textColorBlended }} />
          </IconButton>
        </Tooltip>
      </Stack>

      <TableContainer component={Paper} sx={{ mt: 0.5, bgcolor: theme_bgColorMain }} elevation={0}>

        <Table size="small" aria-label="identify table" sx={{ [`& .${tableCellClasses.root}`]: {borderBottom: "1", borderColor: theme_bgColorLight1 + '8F'} }}>

          <TableHead>
          </TableHead>

          <TableBody>

            {GetAllAttributeRows().map(attribRowData => 
              <IdentifyTableRow key={attribRowData.id} attribRowData={attribRowData}/>
            )}

          </TableBody>

        </Table>
      </TableContainer>

    </Stack>
  )
}


export default IdentifyAoiTable;
