// Portfolio map color scheme editor (GRADIENT)

import { Button, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_textColorBlended, theme_orange, theme_errorRed, theme_textColorMain } from "../../../Theme";
import { ReactNode } from "react";
import { CustomTextField } from "../../../LayerLibrary/EditLayer/EditLayer";
import { CustomSelect, MuiColorInputStyled } from "../../../LayerLibrary/EditLayer/EditLayerStyle";
import { MuiColorInputColors } from "mui-color-input";
import { IPortfolioMapColorScheme, IPortfolioMapColorScheme_GradientItem, IPortfolioMapColorScheme_Gradient } from "../PortfolioMapInterfaces";
import { GetAoiAttributeMinValue, GetAoiAttributeMaxValue } from "../AoiGroupOps";
import Debug from "../../../Debug";
import { ColorGradientBar } from "./ColorGradientBar";
import { ColorGradientPresets } from "./ColorGradientPresets";
import { ToastNotification } from "../../../ToastNotifications";
import { InterpolateColors } from "../../../Globals";
import BigNumber from "bignumber.js";


const MAX_GRADIENT_STOPS = 6;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ColorSchemeEditorGradientProps
{
  colorScheme: IPortfolioMapColorScheme;
  setColorScheme: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Portfolio map color scheme editor (GRADIENT)
//-------------------------------------------------------------------------------
export function ColorSchemeEditor_Gradient(props: ColorSchemeEditorGradientProps) 
{





  const minValue: number | undefined = GetAoiAttributeMinValue(props.colorScheme.aoi_attribute_id);
  const maxValue: number | undefined = GetAoiAttributeMaxValue(props.colorScheme.aoi_attribute_id);
  if(minValue === undefined || maxValue === undefined)
    return null;







  //-------------------------------------------------------------------------------
  // A gradient color has changed.
  //-------------------------------------------------------------------------------
  function OnGradientColorChanged(value: string, colors: MuiColorInputColors, 
                                  gradientItem: IPortfolioMapColorScheme_GradientItem)
  {
    if(!props.colorScheme || !props.colorScheme.gradient) return;

    // Create an updated gradient item
    const newItem: IPortfolioMapColorScheme_GradientItem =
    {
      ...gradientItem,
      color: value,
    }

    // Replace it in the array
    const new_gradient_items: IPortfolioMapColorScheme_GradientItem[] = props.colorScheme.gradient.items.map(oldItem => oldItem.id === gradientItem.id ? newItem : oldItem);

    // Update the 'items' array
    const new_gradient: IPortfolioMapColorScheme_Gradient = 
    {
      ...props.colorScheme.gradient,
      preset_id: undefined,
      items: new_gradient_items
    }

    // Update the color scheme with the new 'gradient'
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      gradient: new_gradient,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // The number of gradient color stops has changed.
  //-------------------------------------------------------------------------------
  const OnGradientColorStopsChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(!props.colorScheme) return;

    const newColorStops: number = Number.parseInt(event.target.value as string);
    if(isNaN(newColorStops) || newColorStops < 2 || newColorStops > MAX_GRADIENT_STOPS)
    {
      Debug.error('OnGradientColorStopsChanged> Value is invalid or out of bounds');
      return;
    }

    const new_gradient = CreateColorGradientNoColors(props.colorScheme, newColorStops);
    if(!new_gradient)
      return;

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      gradient: new_gradient,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Re-assigns current gradient stop values based on a linear scale.
  // The colors are unchanged.
  //-------------------------------------------------------------------------------
  function OnRefreshValues()
  {
    if(!props.colorScheme || !props.colorScheme.gradient || !props.colorScheme.gradient.items) return;

    if(minValue === undefined || maxValue === undefined)
      return;

    const numColorStops: number = props.colorScheme.gradient.items.length;

    // NOTE:  Doing the math using the JS primitive 'number' often leads to weird
    //        inaccuracy in the ranges produced.  So instead we use the BigNumber
    //        external library to produce accurate number ranges.
    const minValue_big = new BigNumber(minValue);
    const maxValue_big = new BigNumber(maxValue);

    //const valueStep = (maxValue - minValue) / (props.colorScheme.gradient.items.length-1);
    const valueStep_big = maxValue_big.minus(minValue_big).dividedBy(numColorStops-1);

    // Rebuild the gradient stops, but using the new values

    const new_gradient_items: IPortfolioMapColorScheme_GradientItem[] = [];
    for(let i=0; i < numColorStops; i++)
      new_gradient_items.push(
        { 
          ...props.colorScheme.gradient.items[i],
          //value: minValue + i * valueStep,
          value: minValue_big.plus(valueStep_big.times(i)).toNumber(), 
        });

    const new_gradient: IPortfolioMapColorScheme_Gradient =
    {
      ...props.colorScheme.gradient,
      items: new_gradient_items
    }

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      gradient: new_gradient,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }
  
  //-------------------------------------------------------------------------------
  // Reverses the current gradient stop colors.  The values are unchanged.
  //-------------------------------------------------------------------------------
  function OnReverseColors()
  {
    if(!props.colorScheme || !props.colorScheme.gradient || !props.colorScheme.gradient.items) return;

    // Create a new array of all the colors, but in reverse order

    const reversedColors: string[] = [];
    for(let i=props.colorScheme.gradient.items.length-1; i >= 0; i--)
      reversedColors.push(props.colorScheme.gradient.items[i].color);

    // Rebuild the gradient stops, but using the new reversed colors

    const new_gradient_items: IPortfolioMapColorScheme_GradientItem[] = [];
    for(let i=0; i < props.colorScheme.gradient.items.length; i++)
      new_gradient_items.push(
        { 
          ...props.colorScheme.gradient.items[i],
          color: reversedColors[i]
        });

    const new_gradient: IPortfolioMapColorScheme_Gradient =
    {
      ...props.colorScheme.gradient,
      items: new_gradient_items
    }

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      gradient: new_gradient,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // A new gradient preset was selected.
  //-------------------------------------------------------------------------------
  function OnPresetChanged(gradient_preset: IPortfolioMapColorScheme_Gradient | undefined)
  {
    if(!gradient_preset) return;

    // Apply the new colors stops to the color scheme

    // The local gradient is based on a 0-100 value scale.  Before we apply the new
    // gradient to the color scheme, we must re-scale the values of each stop to the 
    // attribute's actual min/max values.

    const minValue: number | undefined = GetAoiAttributeMinValue(props.colorScheme.aoi_attribute_id);
    const maxValue: number | undefined = GetAoiAttributeMaxValue(props.colorScheme.aoi_attribute_id);
    if(minValue === undefined || maxValue === undefined)
      return;

    // NOTE:  Doing the math using the JS primitive 'number' often leads to weird
    //        inaccuracy in the values produced.  So instead we use the BigNumber
    //        external library to produce more accurate numbers.
    const minValue_big = new BigNumber(minValue);
    const maxValue_big = new BigNumber(maxValue);

    const new_items: IPortfolioMapColorScheme_GradientItem[] = [];

    for(let i=0; i < gradient_preset.items.length; i++)
    {
      // The preset gradients are based on a percent (0-100) scale.  Before we apply it, we must
      // re-scale the value based on our attribute's min/max.
      //
      // NOTE:  Doing the math using the JS primitive 'number' often leads to weird
      //        inaccuracy in the values produced.  So instead we use the BigNumber
      //        external library to produce more accurate numbers.

      //const scaledValue = ((maxValue - minValue) * gradient_preset.items[i].value / 100.0) + minValue;
      const scaledValue_big: BigNumber = maxValue_big.minus(minValue_big).times(gradient_preset.items[i].value).dividedBy(100).plus(minValue_big);

      new_items.push(
        {
          ...gradient_preset.items[i],
          value: scaledValue_big.toNumber(),
        })
    }

    const new_gradient: IPortfolioMapColorScheme_Gradient =
    {
      id: 1,
      name: undefined,
      preset_id: gradient_preset.id,
      items: new_items
    }
    
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      gradient: new_gradient,
    }
    
    props.setColorScheme(new_color_scheme);
    props.setChangesWereMade(true);
  }
















  if(!props.colorScheme || props.colorScheme.type !== 'gradient' || !props.colorScheme.gradient || 
     !props.colorScheme.gradient.items || props.colorScheme.aoi_attribute_id === undefined)
    return null;

  return (

    <Stack direction='column' sx={{ mt: 3, alignItems: 'left' }}>

      <Stack direction='row' sx={{ mt: 0, mb: 1, alignItems: 'center', justifyContent: 'left' }}>

        <Typography sx={{ color: theme_orange, opacity: 0.8, fontSize: '1.1rem', width: '300px' }}>
          Gradient Color Stops
        </Typography>

        {/* Select number of color stops */}

        <Stack direction='column' sx={{ ml: 5, width: '140px' }}>

          <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
            Color Stops
          </Typography>

          <CustomSelect variant='standard' size='small'
                        value={props.colorScheme.gradient.items.length}
                        disabled={!props.colorScheme.gradient || !props.colorScheme.aoi_attribute_id}
                        onChange={OnGradientColorStopsChanged}
                        sx={{ p: 0.5 }}>

            <MenuItem key='2' value='2' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              2
            </MenuItem>
            <MenuItem key='3' value='3' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              3
            </MenuItem>
            <MenuItem key='4' value='4' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              4
            </MenuItem>
            <MenuItem key='5' value='5' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              5
            </MenuItem>
            <MenuItem key='6' value='6' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
              6
            </MenuItem>
          </CustomSelect>
        </Stack>

        {/* Gradient presets */}

        <Stack sx={{ ml: 5, width: '100%' }}>
          <ColorGradientPresets allowEmptySelection={false}
                                selectedPresetID={props.colorScheme.gradient.preset_id}
                                OnPresetChanged={OnPresetChanged} />
        </Stack>

      </Stack>

      {/* Color gradient bar (not shown if a preset is active) */}
      {props.colorScheme.aoi_attribute_id && props.colorScheme.gradient.items.length >= 2 && !props.colorScheme.gradient.preset_id
        ?
          <Stack sx={{ my: 1, height: '18px' }}>
            <ColorGradientBar gradient={props.colorScheme.gradient} />
          </Stack>
        :null
      }

      {/* Create 2 side-by side panels, left and right */}

      <Stack direction='row'>

        {/* Left side - list of gradient stops (value/color pairs) */}

        <Stack direction='column'>

          {props.colorScheme.gradient.items.map(function(gradientItem, index)
          {
            return (
              
              <Stack key={gradientItem.id} direction='column' sx={{ my: 0.5 }}>

                <Stack direction='row'>

                  {/* Value */}

                  <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                      Value
                    </Typography>

                    <CustomTextField variant='standard' size='small' autoComplete='off'
                                      value={gradientItem.value}
                                      inputProps={{ type: 'number' }} 
                                      //onChange={OnGradientValueChanged}
                                      sx={{ p: 0, width: '100px' }}/>
                  </Stack>

                  {/* Color */}

                  <Stack sx={{ ml: 1 }}>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                      Color
                    </Typography>
                    <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                          disabled={props.colorScheme.gradient === undefined}
                                          value={gradientItem.color}
                                          onChange={(v,c)=>OnGradientColorChanged(v,c,gradientItem)}
                                          sx={{ width: '120px' }}/>
                  </Stack>

                  {/* Show an "add color stop" button for all but the last item */}
                  {/* {props.colorScheme.gradient && gradientItemIndex < props.colorScheme.gradient.items.length - 1
                    ?
                      <Button variant='outlined' onClick={(_)=>OnAddGradientColorStop(gradientItem)}
                              sx={{ ml: 2, px: '6px', height: '26px', textTransform: 'none', fontSize: '0.8rem' }}>
                        ↙  Add a color stop
                      </Button>
                    :null
                  } */}

                </Stack>

                {index === 0 && gradientItem.value !== minValue
                  ?
                    <Typography sx={{ fontSize: '0.7rem', color: theme_errorRed }}>
                      This is no longer the minimum value ({minValue})
                    </Typography>
                  :null
                }

                {props.colorScheme.gradient && index === props.colorScheme.gradient.items.length-1 && gradientItem.value !== maxValue
                  ?
                    <Typography sx={{ fontSize: '0.7rem', color: theme_errorRed }}>
                      This is no longer the maximum value ({maxValue})
                    </Typography>
                  :null
                }

              </Stack>
            )
          })}
        </Stack>

        {/* Right side */}

        <Stack sx={{ ml: 2, mt: 2, width: '100%', justifyContent: 'top', alignItems: 'start' }}>

          <Stack direction='row'>

            <Button variant='outlined' sx={{ textTransform: 'none' }} 
                    onClick={(_)=>OnRefreshValues()}>
              <Stack direction='column'>
                <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                  Refresh Values
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                  Linear, colors unchanged
                </Typography>
              </Stack>
            </Button>

            <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }} 
                    onClick={(_)=>OnReverseColors()}>
              <Stack direction='column'>
                <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                  Reverse Colors
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                  Values are unchanged
                </Typography>
              </Stack>
            </Button>

          </Stack>

        </Stack>

      </Stack>
    </Stack>
  )
}






//-------------------------------------------------------------------------------
// Validates the specified gradient color scheme.
//-------------------------------------------------------------------------------
export function ValidateColorScheme_Gradient(colorScheme: IPortfolioMapColorScheme): boolean
{
  if(colorScheme.type !== 'gradient')
    return false;

  if(colorScheme.gradient === undefined || colorScheme.gradient.items.length < 2)
  {
    ToastNotification('error', 'Gradient color schemes require at least 2 colors to be specified');
    return false;
  }

  const foundBadColor: IPortfolioMapColorScheme_GradientItem | undefined = colorScheme.gradient.items.find(gci => gci.color === undefined || gci.color === '');
  if(foundBadColor)
  {
    ToastNotification('error', 'One of the gradient colors is not defined');
    return false;
  }

  const foundBadValue: IPortfolioMapColorScheme_GradientItem | undefined = colorScheme.gradient.items.find(gci => gci.value === undefined || isNaN(gci.value));
  if(foundBadValue)
  {
    ToastNotification('error', 'One of the gradient values is not defined (or not a number)');
    return false;
  }

  // Validated
  return true;
}

//-------------------------------------------------------------------------------
// Creates a new color gradient based on the specified color scheme and number of 
// gradient color stops.  The colors are not filled in, only the values are.
//-------------------------------------------------------------------------------
export function CreateColorGradientNoColors(colorScheme: IPortfolioMapColorScheme, numColorStops: number): IPortfolioMapColorScheme_Gradient | undefined
{
  if(!colorScheme || !numColorStops || numColorStops <= 0 || numColorStops > MAX_GRADIENT_STOPS) 
    return;

  const minValue: number | undefined = GetAoiAttributeMinValue(colorScheme.aoi_attribute_id);
  const maxValue: number | undefined = GetAoiAttributeMaxValue(colorScheme.aoi_attribute_id);
  if(minValue === undefined || maxValue === undefined)
  {
    Debug.error('CreateColorGradient> Invalid min or max value');
    return undefined;
  }

  // NOTE:  Doing the math using the JS primitive 'number' often leads to weird
  //        inaccuracy in the values produced.  So instead we use the BigNumber
  //        external library to produce more accurate numbers.
  const minValue_big = new BigNumber(minValue);
  const maxValue_big = new BigNumber(maxValue);

  //const valueStep = (maxValue - minValue) / (numColorStops-1);
  const valueStep_big = maxValue_big.minus(minValue_big).dividedBy(numColorStops-1);

  const new_gradient_items: IPortfolioMapColorScheme_GradientItem[] = [];
  let nextID = 1;

  for(let i=0; i < numColorStops; i++)
  {
    new_gradient_items.push(
      { 
        id: nextID++, 
        value: minValue_big.plus(valueStep_big.times(i)).toNumber(), 
        color: ''
      });
  }

  const new_gradient: IPortfolioMapColorScheme_Gradient =
  {
    id: 1,
    name: undefined,
    preset_id: undefined,
    items: new_gradient_items
  }

  // Success
  return new_gradient;
}

//-------------------------------------------------------------------------------
// Returns the interpolated color for the specified value and gradient color scheme.
//-------------------------------------------------------------------------------
export function GetGradientColorForValue(value: number, gradient: IPortfolioMapColorScheme_Gradient): string | undefined
{
  if(value === undefined || gradient === undefined)
    return undefined;

  // Scan through the color stops until we find which 2 stops contain our value.
  //
  // NOTE!!!  The items in the gradient MUST be in ascending order by value.

  let stopMin: IPortfolioMapColorScheme_GradientItem | undefined = undefined;
  let stopMax: IPortfolioMapColorScheme_GradientItem | undefined = undefined;

  for(let i=0; i < gradient.items.length-1; i++)
    if(value >= gradient.items[i].value && value <= gradient.items[i+1].value)
    {
      stopMin = gradient.items[i];
      stopMax = gradient.items[i+1];
      break;
    }

  if(!stopMin || !stopMax)
    return undefined;

  const gradientPercent: number = (value - stopMin.value) / (stopMax.value - stopMin.value);

  const gradientColor: string | undefined = InterpolateColors(stopMin.color, stopMax.color, gradientPercent);
  if(gradientColor === undefined)
  {
    Debug.error('GetGradientColorForValue> Unable to interpolate colors');
    return undefined; // error
  }

  // Success
  return gradientColor;
}