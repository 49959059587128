// AOI attribute expression editor

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import useStore from "../../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_errorRed, theme_limeGreen, theme_bgColorMain } from "../../Theme";
import { useEffect, useState } from "react";
import { AoiExpressionNumberOperators, AoiExpressionStringOperators, IAoiAttribExpression, IAoiExpressionClause, TAoiExpressionStringOperator } from "./AoiAttribExpressionInterfaces";
import DeleteIcon from '@mui/icons-material/Delete';
import AttributeHelp from "../../Components/AttributeHelp";
import { ParcelSearchSelect } from "../../Parcels/ParcelSearch";
import { ToastNotification } from "../../ToastNotifications";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { IAoiAttribute, IAoiGroupProperties } from "./AoiGroupInterfaces";
import { ParcelSearchTextField } from "../../Parcels/ParcelSearchBuilder";
import { IsNumber } from "../../Globals";
import { IPortfolioMapColorScheme } from "./PortfolioMapInterfaces";
import { GetAoiAttribute } from "./AoiAttributeOps";


const AOI_ATTRIBUTE_EXPRESSION_MAX_CLAUSES: number = 10;


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiAttribExpressionEditorProps
{
  // attributes: IAoiAttribute[];  // input
  // search?: IAOISearch;  // input
  // setSearch: any;  // output

  aoiGroupProps: IAoiGroupProperties;
  colorScheme: IPortfolioMapColorScheme;  // Input data
  setColorScheme: any;  // Output

  showAoiExpressionEditor: boolean;
  setShowAoiExpressionEditor: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// AOI attribute expression editor
//-------------------------------------------------------------------------------
export function AoiAttribExpressionEditor(props: AoiAttribExpressionEditorProps)
{
  // Get needed state data from the store
  const { store_aoiGroupProperties
        } = useStore();


  const [localFilter, setLocalFilter] = useState<IAoiAttribExpression|undefined>(undefined);
  const [localChangesWereMade, setLocalChangesWereMade] = useState<boolean>(false);









  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initializes the local data
    if(props.colorScheme.filter === undefined)
    {
      // If the color scheme passed in has no filter, set up a default one
      const newFilter: IAoiAttribExpression =
      {
        clauses: [],
        operator: "and"
      }
      setLocalFilter(newFilter);
    }
    else // There is a filter sent it through props - use it to initialize the local copy
      setLocalFilter(props.colorScheme.filter);

  }, [props.showAoiExpressionEditor]);

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowAoiExpressionEditor(false);
    setLocalFilter(undefined);
    setLocalChangesWereMade(false);
  }

  //-------------------------------------------------------------------------------
  // Apply changes and close.
  //-------------------------------------------------------------------------------
  const OnAcceptChanges = async () => 
  {
    if(!localFilter)
       return;

    // Validate the search expression

    for(let i=0; i < localFilter.clauses.length; i++)
    {
      const clause: IAoiExpressionClause = localFilter.clauses[i];
      
      if(!clause.attribute_id)
      {
        ToastNotification('error','One of the search clauses does not have an attribute selected');
        return false;
      }

      if((!clause.value || clause.value.toString().trim().length === 0) && (clause.operator !== 'is empty' && clause.operator !== 'is not empty'))
      {
        ToastNotification('error','One of the search clauses does not have a value');
        return false;
      }

      if(clause.type && clause.type === 'number' && IsNumber(clause.value) === false)
      {
        ToastNotification('error',`Attribute "${GetAoiAttribute(props.aoiGroupProps.attributes, clause.attribute_id)?.name}" must have a numerical value`);
        return false;
      }

      if(clause.type && clause.type === 'number' && (clause.operator === 'is in range' || clause.operator === 'is not in range')  )
      {
        if(!clause.value_max)
        {
          ToastNotification('error','One of the search clauses does not have a MAX value');
          return false;
        }
    
        if(IsNumber(clause.value_max) === false)
        {
          ToastNotification('error',`Attribute "${GetAoiAttribute(props.aoiGroupProps.attributes,clause.attribute_id)?.name}" must have a numerical MAX value`);
          return false;
        }

        if(clause.value && Number.parseFloat(clause.value_max) < Number.parseFloat(clause.value as string))
        {
          ToastNotification('error',`The MIN value cannot exceed the MAX value for attribute "${GetAoiAttribute(props.aoiGroupProps.attributes,clause.attribute_id)?.name}"`);
          return false;
        }
      }

      // Trim whitespace for text values
      if(clause.type === 'text' && clause.value)
        clause.value = (clause.value as string).trim();
    }

    // If changes were made, save the changes to the local copy.
    // (that is passed in through param mechanism).

    if(localChangesWereMade === true)
    {
      // Replace the color scheme with the new updated version
      const updatedColorScheme: IPortfolioMapColorScheme = 
      {
        ...props.colorScheme,
        filter: localFilter
      }      

      // Save changes
      props.setColorScheme(updatedColorScheme);
      props.setChangesWereMade(true);
    }

    // Close the window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // Renders the search clause operator for the combo box (based on the attrib type).
  //-------------------------------------------------------------------------------
  function RenderSearchClauseOperatorComboBox(searchClause: IAoiExpressionClause)
  {
    if(!searchClause) return null;

    if(searchClause.type === 'text')
    return (
      AoiExpressionStringOperators.map(operatorStr => 
        <MenuItem key={operatorStr} value={operatorStr}>{operatorStr}</MenuItem>
      )
    )

    if(searchClause.type === 'number')
    return (
      AoiExpressionNumberOperators.map(operatorStr => 
        <MenuItem key={operatorStr} value={operatorStr}>{operatorStr}</MenuItem>
      )
    )

    return (
      <MenuItem value='is equal to'>is equal to</MenuItem>
    )
  }

  //-------------------------------------------------------------------------------
  // The user has selected a different attribute for one of the search clauses.
  //-------------------------------------------------------------------------------
  function OnAttributeChanged(event: SelectChangeEvent<any>, searchClause: IAoiExpressionClause)
  {
    if(!localFilter) return;

    const clickedAttributeID: number | undefined = event.target.value;
    if(!clickedAttributeID) return;

    const aoiAttribute: IAoiAttribute  | undefined = GetAoiAttribute(props.aoiGroupProps.attributes,clickedAttributeID);
    if(!aoiAttribute) return;

    const newSearchClause: IAoiExpressionClause = 
    {
      ...searchClause,
      attribute_id: aoiAttribute.id,
      type: aoiAttribute.type,
      operator: 'is equal to',
      value: '',
      value_max: ''
    }

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: localFilter.clauses.map(oldClause => oldClause.id === searchClause.id ? newSearchClause: oldClause)
    }

    // Update the local filter
    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a different operator for one of the search clauses.
  //-------------------------------------------------------------------------------
  function OnClauseOperatorChanged(event: SelectChangeEvent<any>, searchClause: IAoiExpressionClause)
  {
    if(!localFilter) return;

    const clickedOperator: TAoiExpressionStringOperator = event.target.value as TAoiExpressionStringOperator;
    if(!clickedOperator) return;

    if(!searchClause.attribute_id) return;

    const aoiAttribute: IAoiAttribute  | undefined = GetAoiAttribute(props.aoiGroupProps.attributes,searchClause.attribute_id);
    if(!aoiAttribute) return;

    const updatedSearchClause: IAoiExpressionClause = 
    {
      ...searchClause,
      operator: clickedOperator,
    }

    // If the selected operator is 'is empty' or 'is not empty', clear out any current value
    if(clickedOperator === 'is empty' || clickedOperator === 'is not empty')
    {
      updatedSearchClause.value = '';
      updatedSearchClause.value_max = '';
    }

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: localFilter.clauses.map(oldClause => oldClause.id === searchClause.id ? updatedSearchClause: oldClause)
    }

    // Update the local filter
    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The user is adding a new search clause.
  //-------------------------------------------------------------------------------
  function OnAddSearchClause()
  {
    if(!localFilter) return;

    if(localFilter.clauses.length >= AOI_ATTRIBUTE_EXPRESSION_MAX_CLAUSES)
    {
      ToastNotification('error', `You cannot have more than ${AOI_ATTRIBUTE_EXPRESSION_MAX_CLAUSES} clauses`);
      return;
    }

    // Find the current max id
    let maxID: number = 0;
    for(let i=0; i < localFilter.clauses.length; i++)
      if(localFilter.clauses[i].id > maxID)
        maxID = localFilter.clauses[i].id;

    const newClause: IAoiExpressionClause =
    {
      id: maxID+1,
      attribute_id: undefined,
      type: undefined,
      operator: "is equal to",
      value: undefined
    }

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: [...localFilter.clauses, newClause]
    }

    // Update the local filter
    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The user deleted a search clause.
  //-------------------------------------------------------------------------------
  function OnRemoveSearchClause(id: number)
  {
    if(!localFilter) return;

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: localFilter.clauses.filter(clause => clause.id !== id)
    }

    // Update the local filter
    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The user has toggle the main search expression operator (AND vs OR).
  //-------------------------------------------------------------------------------
  function OnToggleMainOperator()
  {
    if(!localFilter) return;

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      operator: localFilter.operator === 'and' ? 'or' : 'and'
    }

    // Update the local filter
    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Generate the label for the attribute "Value" UI element (min).
  //-------------------------------------------------------------------------------
  function GetValueLabelMin(searchClause: IAoiExpressionClause): string
  {
    let label: string = searchClause.operator==='is in range' || searchClause.operator==='is not in range' ? 'Min Value' : 'Value';

    // If the attribute has "units" specified, include that in the label

    const aoiAttribute: IAoiAttribute  | undefined = GetAoiAttribute(props.aoiGroupProps.attributes,searchClause.attribute_id);
    if(!aoiAttribute || !aoiAttribute.units)
      return label;

    return label + ' (' + aoiAttribute.units + ')';
  }

  //-------------------------------------------------------------------------------
  // Generate the label for the attribute "Value" UI element (max, for ranges only).
  //-------------------------------------------------------------------------------
  function GetValueLabelMax(searchClause: IAoiExpressionClause): string
  {
    let label: string = 'Max Value';

    // If the attribute has "units" specified, include that in the label

    const aoiAttribute: IAoiAttribute  | undefined = GetAoiAttribute(props.aoiGroupProps.attributes,searchClause.attribute_id);
    if(!aoiAttribute || !aoiAttribute.units)
      return label;

    return label + ' (' + aoiAttribute.units + ')';
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to an attribute value for one of the search clauses.
  //-------------------------------------------------------------------------------
  function OnAttribValueChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, searchClause: IAoiExpressionClause)
  {
    if(!localFilter || !searchClause.attribute_id) return;

    const newValue: string = event.target.value;

    const updatedSearchClause: IAoiExpressionClause = 
    {
      ...searchClause,
      value: newValue,
    }

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: localFilter.clauses.map(oldClause => oldClause.id === searchClause.id ? updatedSearchClause: oldClause)
    }

    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to an attribute's max value.
  //-------------------------------------------------------------------------------
  function OnAttribMaxValueChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, searchClause: IAoiExpressionClause)
  {
    if(!localFilter || !searchClause.attribute_id) return;

    const newValue: string = event.target.value;

    const updatedSearchClause: IAoiExpressionClause = 
    {
      ...searchClause,
      value_max: newValue,
    }

    const updatedFilter: IAoiAttribExpression =
    {
      ...localFilter,
      clauses: localFilter.clauses.map(oldClause => oldClause.id === searchClause.id ? updatedSearchClause: oldClause)
    }

    setLocalFilter(updatedFilter);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }













  if(!localFilter) return null;

  return (

    <Dialog disablePortal open={props.showAoiExpressionEditor===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '40%', width: '40%', maxWidth: '40%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              AOI Attribute Expression
            </Typography>

            {localChangesWereMade === true
              ?
                <Typography sx={{ mt: '5px', width:'90px', textAlign: 'center', fontSize: '0.6rem', color: theme_errorRed, fontWeight: 'bold', bgcolor: theme_bgColorMain, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  unsaved changes
                </Typography>
              :
                <Typography sx={{ mt: '5px', width:'60px', textAlign: 'center', fontSize: '0.6rem', color: theme_limeGreen, bgcolor: theme_bgColorMain, opacity: 0.7, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  no changes
                </Typography>
            }

          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, width: '100%' }}>

          {localFilter.clauses.length === 0
            ?
              <Typography sx={{ color: theme_textColorMain, opacity: 0.35 }}>
                There are no clauses defined.
              </Typography>
            :null
          }

          <Stack sx={{ overflow: 'auto', maxHeight: '250px', bgcolor: theme_bgColorLight1+'AA', 
                       borderRadius: 1, boxShadow: 1, px: 0.7 }}>

            {localFilter.clauses.map(searchClause =>

              <Stack key={searchClause.id} direction='column' sx={{ mt: 0.7, mb: 0.7 }}>

                <Stack direction='row' sx={{ alignItems: searchClause.operator==='is in range' || searchClause.operator==='is not in range' ? 'center' : 'flex-end' }}>

                  <Stack direction='row' sx={{ alignItems: 'flex-end' }}>

                    {/* Attribute selector */}

                    <FormControl variant="standard" sx={{ mr: 1, minWidth: 120 }}>
                      <InputLabel id="attrib-name-label" sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>Attribute</InputLabel>
                      <ParcelSearchSelect variant='standard' size='small' labelId='attrib-name-label' 
                                          value={searchClause.attribute_id} onChange={(e)=>OnAttributeChanged(e,searchClause)}>
                        {store_aoiGroupProperties?.attributes.map(function(aoiAttribute)
                        {
                          // Skip admin attributes
                          if(aoiAttribute.is_admin === true) return null;

                          return (
                            <MenuItem key={aoiAttribute.id} value={aoiAttribute.id} sx={{ px: '5px' }}>
                              <Stack direction='row' sx={{ alignItems: 'center' }}>
                                <AttributeHelp iconSize='16px'
                                              name={aoiAttribute.name} 
                                              description={aoiAttribute.description}
                                              units={aoiAttribute.units}
                                              url={undefined}/>
                                <Typography sx={{ ml: '6px' }}>
                                  {aoiAttribute.name}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )
                        }
                        )}
                      </ParcelSearchSelect>
                    </FormControl>

                    {/* Operator selector */}

                    <ParcelSearchSelect variant='standard' size='small' labelId='attrib-operator-label' 
                                        value={searchClause.operator} onChange={(e)=>OnClauseOperatorChanged(e,searchClause)}
                                        sx={{ fontSize: '0.8rem', mr: 1, color: theme_limeGreen }}>
                        {RenderSearchClauseOperatorComboBox(searchClause)}
                    </ParcelSearchSelect>

                  </Stack>

                  {/* Attribute Value: number, text */}

                  {searchClause.type === 'number' || searchClause.type === 'text'
                    ?
                      <Stack sx={{ width: '100%' }}>

                        <ParcelSearchTextField size='small' variant='standard' 
                            label={GetValueLabelMin(searchClause)}
                            disabled={(searchClause.operator === 'is empty' || searchClause.operator === 'is not empty')}
                            value={searchClause.value} onChange={(e)=>OnAttribValueChanged(e,searchClause)}
                            inputProps={{style: { fontSize: '1rem', color: theme_textColorMain, opacity: 0.8 }}} // font size of input text
                            InputLabelProps={{style: { fontSize: '0.8rem', color: theme_textColorBlended }}} // font size of input label
                            sx={{ minWidth: '120px', width: '100%' }}/>

                        {/* Max value (only for number ranges) */}
                        {searchClause.operator==='is in range' || searchClause.operator==='is not in range'
                          ?
                            <ParcelSearchTextField size='small' variant='standard' label={GetValueLabelMax(searchClause)}
                                value={searchClause.value_max} onChange={(e)=>OnAttribMaxValueChanged(e,searchClause)}
                                inputProps={{style: { fontSize: '1rem', color: theme_textColorMain, opacity: 0.8 }}} // font size of input text
                                InputLabelProps={{style: { fontSize: '0.8rem', color: theme_textColorBlended }}} // font size of input label
                                sx={{ minWidth: '120px', width: '100%' }}/>
                          :null
                        }
                      </Stack>
                    :null
                  }

                  {/* Attribute value: NO ATTRIBUTE SELECTED */}

                  {searchClause.type === undefined
                    ?
                      // Just a space placeholder so everything lines up in the UI
                      <Stack sx={{ width: '100%' }}>
                      </Stack>
                    :null
                  }

                  {/* Delete Clause button */}

                    <IconButton sx={{p: 0.1, ml: 1}} onClick={(_)=>OnRemoveSearchClause(searchClause.id)}>
                      <DeleteIcon sx={{ color: theme_textColorBlended, opacity: 0.7, p: 0.4 }}/>
                    </IconButton>

                </Stack>

              </Stack>
            )}

          </Stack>

          {/* Search expression botton bar: Match AND/OR, Add Clause */}

          <Stack direction='row' sx={{ mt: 3, justifyContent: 'space-between'}}>

            <Button size='small' onClick={(_)=>OnAddSearchClause()} sx={{ textTransform: 'none', fontSize: '0.8rem' }}>
              <PlaylistAddIcon sx={{ mr: 0.7, color: theme_textColorBlended, width: '20px', height: '20px' }}/>
              Add Clause
            </Button>

            <Button size='small' onClick={(_)=>OnToggleMainOperator()} sx={{ textTransform: 'none', mx: 2 }}>
              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem' }}>
                Match:
              </Typography>
              <Typography sx={{ color: theme_limeGreen, ml: 0.6, fontSize: '0.7rem', opacity: 0.8 }}>
                {localFilter.operator==='or'?'Any clause (OR)':'All clauses (AND)'}
              </Typography>
            </Button>

          </Stack>          

        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}
                      onClick={OnAcceptChanges}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>
  )
}

