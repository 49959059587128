// Portfolio Map interfaces

import { IAoiAttribExpression } from "./AoiAttribExpressionInterfaces";


export const PORTFOLIO_MAP_MANUAL_COLOR_SCHEME_ID: number = 0;  // the default entry in the color schemes combo box, always present

export const PORTFOLIO_MAP_FILTERED_OUT_DEFAULT_COLOR: string = '#707070';

export const PORTFOLIO_MAP_MIN_BORDER_THICKNESS: number = 0;
export const PORTFOLIO_MAP_MAX_BORDER_THICKNESS: number = 10;


export interface IPortfolioMapProperties
{
  border_thickness: number;
  border_halo: boolean;
  border_halo_color: string;
  fill_opacity: number;

  active_color_scheme_id: number;  // 0 is a special case - it means colors are set manually for all AOIs
  color_schemes: IPortfolioMapColorScheme[];

  // NEW TEMP - search-focused, instead of color scheme focused
  //active_search_id: number;
  //searches: IAOISearch[];
}

// export interface IAOISearch
// {
//   search_expression?: IAoiAttribExpression;
//   color_scheme?: IPortfolioMapColorScheme_NEW;
// }

// export interface IPortfolioMapColorScheme_NEW
// {
//   id: number;
//   name: string;
//   type: IColorSchemeType;
//   aoi_attribute_id?: number;
//   single_color?: IPortfolioMapColorScheme_SingleColor; // only used when mode='single color'
//   unique_values?: IPortfolioMapColorScheme_UniqueValues; // only used when mode='unique values'
//   classified?: IPortfolioMapColorScheme_Classified; // only used when mode='classified'
//   gradient?: IPortfolioMapColorScheme_Gradient; // only used when mode='gradient'
//   filtered_out_color: string;  // this color is used when a filter doesn't match (or null/empty attribute values)
// }








export type IColorSchemeType = 'single color' | 'unique values' | 'classified' | 'gradient';

export interface IPortfolioMapColorScheme
{
  id: number;
  name: string;
  type: IColorSchemeType;
  aoi_attribute_id?: number;
  single_color?: IPortfolioMapColorScheme_SingleColor; // only used when mode='single color'
  unique_values?: IPortfolioMapColorScheme_UniqueValues; // only used when mode='unique values'
  classified?: IPortfolioMapColorScheme_Classified; // only used when mode='classified'
  gradient?: IPortfolioMapColorScheme_Gradient; // only used when mode='gradient'
  filter?: IAoiAttribExpression;
  filtered_out_color: string;  // this color is used when a filter doesn't match (or null/empty attribute values)
}

export interface IPortfolioMapColorScheme_SingleColor
{
  // For text and numerical attributes
  color: string;
}

export interface IPortfolioMapColorScheme_UniqueValues
{
  // For text and numerical attributes
  items: IPortfolioMapColorScheme_UniqueValuesItem[];
}

export interface IPortfolioMapColorScheme_UniqueValuesItem
{
  // For text and numerical attributes
  id: number;
  value: string;
  color: string;
}

export interface IPortfolioMapColorScheme_Classified
{
  // For numerical attributes only
  preset_id?: number; // filled out if a preset is currently selected
  items: IPortfolioMapColorScheme_ClassifiedItem[];
}

export interface IPortfolioMapColorScheme_ClassifiedItem
{
  // For numerical attributes only
  id: number;
  value_min: number;
  value_max: number;
  color: string;
}

export interface IPortfolioMapColorScheme_Gradient
{
  // For numerical attributes only
  id: number, 
  name?: string;  // optional name, must be unique
  preset_id?: number; // filled out if a preset is currently selected
  items: IPortfolioMapColorScheme_GradientItem[];
}

export interface IPortfolioMapColorScheme_GradientItem
{
  // For numerical attributes only
  id: number;
  value: number;
  color: string;
}
