// Displays properties for a clicked map feature.

import { Accordion, AccordionDetails, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Box, IconButton,Pagination, Stack, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useStore from "../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight, theme_bgColorLight1, theme_bgColorMain, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IIdentifyItem } from "./IdentifyInterfaces";
import Draggable from 'react-draggable';
import { FriendlyCoords } from "../Globals";
import { GetLastSelectedIdentifyItem } from "./IdentifyOps";
import SettingsIcon from '@mui/icons-material/Settings';
import { HBV_PANEL_WIDTH_PIXELS } from "../HBV/HbvPanel";
import IdentifyLayerPage from "./IdentifyLayerPage";
import IdentifyAoiPage from "./IdentifyAoiPage";


// Initial position of the identify panel (after that, the user can move it)
const DEFAULT_HORIZONTAL_OFFSET = 10;
const DEFAULT_VERTICAL_OFFSET = 10;

const MAX_WIDTH = '300px';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface IdentifyProps
{
}

//-------------------------------------------------------------------------------
// Map property display component
//-------------------------------------------------------------------------------
const Identify = (props: IdentifyProps) => 
{
  // Get needed state data from the store
  const { store_setIdentify, store_identify, store_setLastIdentifySelectionID, 
          store_setIdentifySettingsOpen, store_hbvScenario, store_drawerOpen, 
          store_project
        } = useStore();

  const [currIdentifyItem, setCurrIdentifyItem] = useState<IIdentifyItem|undefined>(undefined);
  const [currFeaturePage, setCurrFeaturePage] = useState<number>(1);
  const [activeAccordionItem, setActiveAccordionItem] = useState<IIdentifyItem|undefined>();

  // Keep track of the position of the panel (x, y)
  const [panelPositionX, setPanelPositionX]= useState(DEFAULT_HORIZONTAL_OFFSET);
  const [panelPositionY, setPanelPositionY]= useState(DEFAULT_VERTICAL_OFFSET);

  //-------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {

    // Initialize to the first page of the first identify item in the list
    if(store_identify && store_identify.identifyItems.length >= 1)
    {
      const lastSelItem = GetLastSelectedIdentifyItem();

      setCurrIdentifyItem(lastSelItem);
      setActiveAccordionItem(lastSelItem);
      setCurrFeaturePage(1);
    }

  }, [store_identify])

  //-------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // If the scenario panel is visible, push the identify panel to the right

    if(store_hbvScenario)
      setPanelPositionX(DEFAULT_HORIZONTAL_OFFSET + HBV_PANEL_WIDTH_PIXELS);
    else
      setPanelPositionX(DEFAULT_HORIZONTAL_OFFSET);

    // If the main menu drawer is closed and there is no scenario panel, we need to 
    // push the identify panel down a bit (to be below the StratifyX open/close button)

    if(!store_drawerOpen && !store_hbvScenario)
      setPanelPositionY(DEFAULT_VERTICAL_OFFSET+75);
    else
      setPanelPositionY(DEFAULT_VERTICAL_OFFSET);

  }, [store_drawerOpen, store_hbvScenario])




  //-------------------------------------------------------------------------------
  // Handler: Feature page changed
  //-------------------------------------------------------------------------------  
  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => 
  {
    setCurrFeaturePage(value);
  }

  //-------------------------------------------------------------------------------
  // Handler: Close button pushed.
  //-------------------------------------------------------------------------------  
  const onCloseButton = () => 
  {
    store_setIdentify(undefined);
  }

  //-------------------------------------------------------------------------------
  // Handler: Settings button pushed.
  //-------------------------------------------------------------------------------  
  const onSettingsButton = () => 
  {
    store_setIdentifySettingsOpen(true);
  }
  
  //-------------------------------------------------------------------------------
  // Accordion change.
  //-------------------------------------------------------------------------------
  const onAccordionChange = (item: IIdentifyItem | undefined) => (event: React.SyntheticEvent, isExpanded: boolean) => 
  {
    setActiveAccordionItem(isExpanded ? item : undefined);

    if(item)
    {
      store_setLastIdentifySelectionID(item.id);
      setCurrIdentifyItem(item);
      setCurrFeaturePage(1);
    }
  }

  //-------------------------------------------------------------------------------
  // When the user stops moving the identify window, record the new location.
  //-------------------------------------------------------------------------------
  const handleDragStop = (event: any, dragElement: any) => 
  {
    setPanelPositionX(dragElement.x)
    setPanelPositionY(dragElement.y)
  }






  if(!store_project) return null;

  // Return EMPTY page (if nothing is clicked)
  if(!store_identify || store_identify.isLoading) return null;

  // If the identify list is empty, do nothing
  if(store_identify.identifyItems === undefined || store_identify.identifyItems.length === 0)
     return null;

  // We have identify data, but we can't do anything if the "current" item is undefined
  if(!currIdentifyItem) 
    return null;

  // Main render

  return (

    <Draggable handle='.identify-drag-handle' grid={[10, 10]} scale={1} bounds='parent'
               onStop={handleDragStop} position={{x: panelPositionX, y: panelPositionY}}>

      <Box sx={{ position: 'absolute', maxWidth: MAX_WIDTH, 
                 left: 0,
                 top: 0,
                 //left: DEFAULT_HORIZONTAL_OFFSET + (store_hbvScenario ? HBV_PANEL_WIDTH_PIXELS : 0), 
                 //top: store_drawerOpen ? 10 : 85,
                 boxShadow: 3, opacity: 1, borderRadius: 1.5, padding: 0.5, 
                 bgcolor: theme_bgColorMain, 
                 width: '100%', maxHeight: '85vh', overflow: 'auto'
              }}>

          {/* Title bar: drag-to-move + Close button */}

          <Stack direction='row' sx={{ width: '100%', height: '20px', justifyContent: 'space-between', alignItems: 'center' }}>

            <Stack direction='row' sx={{ width: '100%' }}>

              {/* Latitude and longitude */}

              <Typography sx={{ fontSize: '0.6rem', color: theme_orange, opacity: 0.7, width: '200px' }}>
                {FriendlyCoords(store_identify.latitude, store_identify.longitude)}
              </Typography>

              <Typography className="identify-drag-handle"
                          sx={{ cursor: 'grab', width: '100%', fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.4, textAlign: 'center' }}>
                Drag to move
              </Typography>

            </Stack>

            {/* Identify settings button */}

            <IconButton size="small" sx={{ color: theme_textColorBlended, p: 0 }} 
                        onClick={(_) => { onSettingsButton() }}>
              <SettingsIcon sx={{ opacity: 0.7, width: '18px', height: '18px', mt: '-3px' }}/>
            </IconButton>

            {/* Close button */}

            <IconButton size="small" sx={{ color: theme_textColorBlended, p: 0 }} 
                        onClick={(_) => { onCloseButton() }}>
              <CloseIcon sx={{ opacity: 0.7, width: '18px', height: '18px', mt: '-3px' }}/>
            </IconButton>

          </Stack>

          {/* Identify items */}

          {store_identify.identifyItems.map(identifyItem =>

            <Accordion key={identifyItem.id} disableGutters={true}
                            expanded={store_project.user_settings.identify_showAllPanelsAtOnce === true ? true : activeAccordionItem===identifyItem} 
                            onChange={onAccordionChange(identifyItem)}
                            sx={{ width: '100%', m:0, p:0, background: theme_bgColorMain }}>

              {/* ACCORDION SUMMARY */}

              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme_orange, opacity: 0.7 }} />} 
                                sx={{ width: '100%' }}>


                  {identifyItem.type === 'layer'
                    ?
                      <Typography noWrap
                                  sx={{ p:0, pl: '1px', m:0, maxWidth: 'calc(260px)', color: theme_textColorMain, 
                                        opacity: 0.8, fontSize: '0.8rem', textAlign: 'left' }}>
                          {identifyItem.identifyForLayer?.layer.friendlyName}
                        </Typography>
                    :
                      <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '0.8rem', color: theme_orange, mr: 1 }}>
                          AOI:
                        </Typography>
                        <Typography noWrap
                                    sx={{ p:0, pl: '1px', m:0, maxWidth: 'calc(260px)', color: theme_textColorMain, 
                                          opacity: 0.8, fontSize: '0.8rem', textAlign: 'left' }}>
                          {identifyItem.identifyForAOI?.aoi.aoi_name}
                        </Typography>
                      </Stack>
                  }

              </AccordionSummary>

              {/* ACCORDION CONTENT */}

              <AccordionDetails sx={{ p: 0.4, pt: 0, borderRadius: 1 }}>

                <Stack>

                  {/* Feature paging controls */}

                  {identifyItem.type === 'layer' && identifyItem.identifyForLayer && identifyItem.identifyForLayer.features.length > 1 
                    ? 
                      <Stack alignItems="center" sx={{ mt: 0.3, mb: 0 }}>
                        <Pagination size="small" count={identifyItem.identifyForLayer.features.length} 
                                    variant="outlined" color='primary'
                                    page={currFeaturePage} onChange={onPageChange}
                                    sx={{ alignItems:'center', 
                                          button: { color: theme_bgColorLight, borderColor: theme_bgColorLight+'77' } 
                                       }}/>
                      </Stack>
                    :null
                  }

                  {identifyItem.type === 'layer'
                    ?
                      <IdentifyLayerPage layer={identifyItem.identifyForLayer?.layer} feature={identifyItem.identifyForLayer?.features[currFeaturePage-1]}/>
                    :
                      <IdentifyAoiPage aoi={identifyItem.identifyForAOI?.aoi}/>
                  }

                </Stack>

              </AccordionDetails>
            </Accordion>
          )}

      </Box>

    </Draggable>
  )
}


export default Identify;





// Custom MUI style for the AccordionSummary
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  padding: 0, 
  minHeight: 20,
  maxHeight: 20,
  overflow: 'hidden',
  borderRadius: 3,

  color: 'red',
  backgroundColor: theme_bgColorLight1,
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 4,
  paddingRight: 0,

  marginTop: 2,
  marginBottom: 2,

  //p: 0, pl:0.5, pr:0.5, bgcolor: theme_bgColorMain

  '&.Mui-expanded': 
  {
    minHeight: 25,
    maxHeight: 25,
  },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}))
