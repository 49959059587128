// Import an AOI from a file.

import { Stack, Typography, Button } from "@mui/material";
import { theme_textColorBlended, theme_limeGreen, theme_orange, theme_textColorMain } from "../Theme";
import useStore from "../store";
import { useEffect, useState } from "react";
import { UploadAoiFromFile } from "./AoiOps";
import { ToastNotification } from "../ToastNotifications";
import MyCircularProgress from "../Components/CircularProgress";
import RoundedInputBox from "../Components/RoundedInputBox";
import { UploadFile } from "@mui/icons-material";
import { FriendlyFileSize_Short } from "../Globals";


export const FILE_UPLOAD_MAX_SIZE_MB = 100;
export const FILE_UPLOAD_MAX_SIZE_BYTES = FILE_UPLOAD_MAX_SIZE_MB * 1024 * 1024;
export type TAoiUploadFileFormat = 'geojson' | 'zip' | 'kml' | 'gpkg';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiUploadProps
{
  fileFormat: TAoiUploadFileFormat;
  uploadFile: File | null;
}

//-------------------------------------------------------------------------------
// AOI upload component
//-------------------------------------------------------------------------------
const AoiUpload = (props: AoiUploadProps) => 
{
  // Get needed state data from the store
   const { store_setAoiUIMode, store_aoiFileIsUploading, 
         } = useStore();

  const [newAoiName, setNewAoiName] = useState<string>('');






  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() =>
  {
    if(props.uploadFile)
      setNewAoiName(props.uploadFile.name);
    




  // eslint-disable-next-line
  }, []); // called only once

  //-------------------------------------------------------------------------------
  // Cancel AOI selection.
  //-------------------------------------------------------------------------------
  const onCancel = () => 
  {
    // Exit aoi selection mode
    store_setAoiUIMode('default');
  }

  //-------------------------------------------------------------------------------
  // Upload the file.
  //-------------------------------------------------------------------------------
  const onFileUpload = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newAoiName || newAoiName.length === 0)
    {
      ToastNotification('error', 'Please enter a valid name for the new AOI');
      return;
    }

    if(!props.uploadFile)
    {
      ToastNotification('error', 'Please select a file');
      return;
    }

    await UploadAoiFromFile(props.uploadFile, props.fileFormat, newAoiName);

    // Exit aoi selection mode
    store_setAoiUIMode('default');
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function GetAoiUploadFileFormatFriendlyName(format: TAoiUploadFileFormat): string
  {
    switch(format)
    {
      case 'geojson': return 'GeoJSON';
      case 'gpkg': return 'GeoPackage';
      case 'zip': return 'Shapefile (ZIP)';
      case 'kml': return 'KML';
      default: return 'Unknown';
    }
  }







  // Main render

  return (

    <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>

      <Typography sx={{ fontSize: '1.2rem', color: theme_textColorBlended }}>
        Import an AOI from a file
      </Typography>

      <Stack direction='row' sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: '1.0rem', color: theme_textColorMain, opacity: 0.6 }}>
          Format:
        </Typography>
        <Typography sx={{ ml: 0.6, fontSize: '1.0rem', fontWeight: 'bold', color: theme_orange, opacity: 0.8 }}>
          {GetAoiUploadFileFormatFriendlyName(props.fileFormat)}
        </Typography>
      </Stack>      

      <Stack direction='row' sx={{ mt: 0.5, mb: 2 }}>
        <Typography sx={{ fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6 }}>
          Size:
        </Typography>
        <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_orange, opacity: 0.8 }}>
          {FriendlyFileSize_Short(props.uploadFile?.size)}
        </Typography>
      </Stack>      

      {store_aoiFileIsUploading
        ?
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <MyCircularProgress />
            <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_orange, ml: 2, textTransform: 'capitalize' }}>
              Uploading AOI file...
            </Typography>
          </Stack>
        :
          // New AOI name + save button

          <RoundedInputBox title='New AOI Name' onAccept={onFileUpload}
                           value={newAoiName} setValue={setNewAoiName} 
                           buttonText='Upload' buttonWidth='90px' isDisabled={store_aoiFileIsUploading}/>
      }

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancel}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default AoiUpload;


