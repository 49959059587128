// Allows the user to change the base map

import { Stack, Typography, Tooltip, Box, Slider, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import useStore from "./store";
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import BaseMapPanelImageButton from './BaseMapPanelImageButton';
import { MyToggleButton } from "./CustomMuiStyles";
import Image_BaseMap_Streets from './Images/BaseMap_Streets.png';
import Image_BaseMap_Satelite from './Images/BaseMap_Satellite.png';
import Image_BaseMap_SateliteStreets from './Images/BaseMap_SatelliteStreets.png';
import Image_BaseMap_Outdoor from './Images/BaseMap_Outdoor.png';
import Image_BaseMap_Light from './Images/BaseMap_Light.png';
import Image_BaseMap_Dark from './Images/BaseMap_Dark.png';
import { Change3DTerrainExaggerationFactor, DisableMapbox3DTerrain, EnableFog, EnableMapbox3DTerrain, MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME } from "./Map/MapOps";
import { theme_textColorBlended } from "./Theme";
import CloseIcon from '@mui/icons-material/Close';
import { ExitAoiEditMode } from "./Aois/AoiOps";


// Large size
// const baseMapImageSize = 140;
// const buttonTextFontSize = '1rem';
// const titleFontSize = '1.6rem';

// Medium size
const baseMapImageSize = 110;
const buttonTextFontSize = '0.7rem';
const titleFontSize = '1.3rem'; 

// Small size
// const baseMapImageSize = 60;
// const buttonTextFontSize = '0.6rem';
// const titleFontSize = '1.1rem';

const TERRAIN_3D_EXAGGERATION_MIN = 1;
const TERRAIN_3D_EXAGGERATION_MAX = 5;
const TERRAIN_3D_EXAGGERATION_STEP = 0.25;


// List of all Mapbox base maps
export enum BaseMap { Streets = "Streets", Satellite = "Satellite", SatelliteStreets = "Satellite Streets", 
                      Outdoor = 'Outdoor', Light = 'Light', Dark = "Dark", NavigationDay = 'Navigation Day', 
                      NavigationNight = 'Navigation Night', Standard = "Standard" }

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface BaseMapPanelProps
{
  // setBasePanelOpacity: React.Dispatch<React.SetStateAction<number>>;
  onClose: any;
}

//-------------------------------------------------------------------------------
// Map property display component
//-------------------------------------------------------------------------------
const BaseMapPanel = (props: BaseMapPanelProps) => 
{
  // Get needed state data from the store
  const { store_map, store_setBaseMap, store_layers, store_setLayerEnabled, 
          store_threeDTerrain, store_setThreeDTerrain, store_userProfile, 
          store_setUserProfileTerrain3DExaggeration, store_setUserProfileIsDirty,
        } = useStore();

  const [fog, setFog] = useState<boolean>(false);

  
  


  //-------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
  }, [])

  //-------------------------------------------------------------------------------
  // Handler:  3D terrain on/off
  //-------------------------------------------------------------------------------
  const onThreeDTerrainChanged = () => 
  {
    if(store_threeDTerrain === false)
    {
      EnableFog(true);
      setFog(true);
      EnableMapbox3DTerrain();
      store_setThreeDTerrain(true);
    }
    else
    {
      EnableFog(false);
      setFog(false);
      DisableMapbox3DTerrain();
      store_setThreeDTerrain(false);
    }
  }

  //-------------------------------------------------------------------------------
  // Change to the specified base map.
  //
  // NOTE: Doing this wipes out ALL mapbox layers (org boundary, aoi/portfolio map,
  //       parcels, parcel selection, all user layers, etc).
  //
  //       After the style/base map is done loading, Mapbox will fire off the 
  //       'style.load' event, and all layers are manually re-added in the
  //       Map.OnMapboxStyleChanged() function.
  //-------------------------------------------------------------------------------
  function ChangeBaseMap(newBaseMap: BaseMap)
  {
    if(!store_map) return;

    // If we're editing an AOI geometry, exit that mode first
    ExitAoiEditMode(true);

    // Change the map to use the new basemap/style
    store_map.setStyle(GetMapboxStyleForBaseMap(newBaseMap));

    // Update the store
    store_setBaseMap(newBaseMap);
  }
 
  //-------------------------------------------------------------------------------
  // A base map button was clicked.
  //-------------------------------------------------------------------------------
  const onBaseMapChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => 
  {
    ChangeBaseMap(value as BaseMap);

    // Close the panel
    props.onClose();
  }
  
  //-------------------------------------------------------------------------------
  // The 3D terrain exaggeration slider was changed.
  //-------------------------------------------------------------------------------
  const on3DTerrainSliderChange = (event: Event, newValue: number | number[]) => 
  {
    if (typeof newValue !== 'number') return;
    if(!store_map) return;
    if(!store_map.getSource(MAPBOX_3D_TERRAIN_LAYER_SOURCE_NAME)) return;

    store_setUserProfileTerrain3DExaggeration(newValue);
    store_setUserProfileIsDirty(true);

    Change3DTerrainExaggerationFactor(newValue);
  }








  


  // Main render

  return (

    <Stack direction='column' sx={{ p: '10px' }}>

      {/* Base map section title + 3D buttons */}

      <Stack direction='row' sx={{ mb: '4px' }}>

        <Typography sx={{ width:'100%', fontSize: titleFontSize, ml: 0, color: '#E7A44F'  }}>
          Base Map
        </Typography>

        {store_threeDTerrain && store_userProfile
          ?
            <Stack sx={{ width: '80px', mr: '5px' }}>
              <Typography sx={{ fontSize: '0.5rem', color: theme_textColorBlended, textAlign: 'right'  }}>
                Exaggeration
              </Typography>
  
              <Slider disabled={false} onChange={on3DTerrainSliderChange}
                      min={TERRAIN_3D_EXAGGERATION_MIN} max={TERRAIN_3D_EXAGGERATION_MAX} step={TERRAIN_3D_EXAGGERATION_STEP} size='small' aria-label="3D Terrain Exaggeration Factor" 
                      value={store_userProfile.terrain_3d_exaggeration} valueLabelDisplay="auto" sx={{ mr: 3 }} />
            </Stack>
          :null
        }

        <Tooltip title='Enable 3D terrain'>
          <MyToggleButton size="small" value={store_threeDTerrain} selected={store_threeDTerrain} aria-label="list" 
                          onChange={(_)=>onThreeDTerrainChanged()} sx={{ width: '40px', height: '40px', mr: '5px' }}>
            <ThreeDRotationIcon sx={{ color: 'inherit' }} />
          </MyToggleButton>
        </Tooltip>

        <IconButton size="small" onClick={props.onClose}
                      sx={{ padding: 0, width: '32px', height: '32px' }}>
          <CloseIcon sx={{ opacity: 0.9, width: '25px', height: '25px', color: theme_textColorBlended }} />
        </IconButton>

        {/* <Tooltip title='Enable sky and fog effects'>
          <MyToggleButton size="small" value='fog' selected={fog} aria-label="list" 
                          onChange={onFogChanged} sx={{ ml: '5px', width: '40px', height: '40px' }}>
            <FilterDramaIcon sx={{ color: 'inherit' }} />
          </MyToggleButton>
        </Tooltip> */}

      </Stack>

      <Box sx={{ width: '100%', height: '2px', borderRadius: 1, bgcolor: 'white', opacity: 0.5 }}/>

      <Stack direction='column' sx={{ mt: '10px', maxHeight: '70vh', overflow: 'auto'}}>

        {/* Base map row 1 */}

        <Stack direction='row'>
          <BaseMapPanelImageButton name='Streets' baseMap={BaseMap.Streets} borderTopLeftRadius={5}
                                    image={Image_BaseMap_Streets} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
          <BaseMapPanelImageButton name='Outdoor' baseMap={BaseMap.Outdoor} borderTopRightRadius={5}
                                    image={Image_BaseMap_Outdoor} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
        </Stack>

        {/* Base map row 2 */}

        <Stack direction='row' sx={{ mu: '-1px' }}>
          <BaseMapPanelImageButton name='Satellite' baseMap={BaseMap.Satellite}
                                    image={Image_BaseMap_Satelite} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
          <BaseMapPanelImageButton name='Sat+Str' baseMap={BaseMap.SatelliteStreets}
                                    image={Image_BaseMap_SateliteStreets} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
        </Stack>

        {/* Base map row 3 */}

        <Stack direction='row' sx={{ mu: '-1px' }}>
          <BaseMapPanelImageButton name='Light' baseMap={BaseMap.Light} borderBottomLeftRadius={5}
                                    image={Image_BaseMap_Light} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
          <BaseMapPanelImageButton name='Dark' baseMap={BaseMap.Dark} borderBottomRightRadius={5}
                                    image={Image_BaseMap_Dark} onBaseMapChanged={onBaseMapChanged}
                                    imageSize={baseMapImageSize} textFontSize={buttonTextFontSize}/>
        </Stack>

      </Stack>

    </Stack>
  );
}







//-------------------------------------------------------------------------------
// Get the name of the Mapbox style for the specified base map enum.
//-------------------------------------------------------------------------------
export function GetMapboxStyleForBaseMap (baseMap: BaseMap) : string
{
  // TEMP
  //return 'mapbox://styles/mapbox/standard';  // this one requires Mapbox v3

  switch (baseMap) 
  {
    // case BaseMap.Standard: return 'mapbox://styles/mapbox/standard';  // this one requires Mapbox v3
    case BaseMap.Streets: return 'mapbox://styles/mapbox/streets-v11';
    case BaseMap.Satellite: return 'mapbox://styles/mapbox/satellite-v9';
    case BaseMap.SatelliteStreets: return 'mapbox://styles/mapbox/satellite-streets-v11';
    case BaseMap.Outdoor: return 'mapbox://styles/mapbox/outdoors-v11';
    case BaseMap.Light: return 'mapbox://styles/mapbox/light-v10';
    case BaseMap.Dark: return 'mapbox://styles/mapbox/dark-v10';
    case BaseMap.NavigationDay: return 'mapbox://styles/mapbox/navigation-day-v1';
    case BaseMap.NavigationNight: return 'mapbox://styles/mapbox/navigation-night-v1';

    // These are newer styles, but they use a "globe" 3D viewer which seems slower
    //
    // case BaseMap.Standard: return 'mapbox://styles/mapbox/standard';  // this one requires Mapbox v3
    // case BaseMap.Streets: return 'mapbox://styles/mapbox/streets-v12';
    // case BaseMap.Satellite: return 'mapbox://styles/mapbox/satellite-v9';
    // case BaseMap.SatelliteStreets: return 'mapbox://styles/mapbox/satellite-streets-v12';
    // case BaseMap.Outdoor: return 'mapbox://styles/mapbox/outdoors-v12';
    // case BaseMap.Light: return 'mapbox://styles/mapbox/light-v11';
    // case BaseMap.Dark: return 'mapbox://styles/mapbox/dark-v11';
    // case BaseMap.NavigationDay: return 'mapbox://styles/mapbox/navigation-day-v1';
    // case BaseMap.NavigationNight: return 'mapbox://styles/mapbox/navigation-night-v1';

    default: return '';
  }
};





  


export default BaseMapPanel;