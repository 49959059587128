// User management page

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange, theme_bgColorMain, theme_limeGreen, theme_bgColorLight, theme_errorRed } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { IUserManagement_AdminOrg, IUserManagement_User, IUserManagement_Data, IUserManagement_WhitelistUser } from "./UserManagementInterfaces";
import { DeleteFromAccountWhitelist, GetAdminOrgsWithRolesForUser, GetRolesForOrgForUser, LoadAdminUserData } from "./UserManagementOps";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CustomTab, CustomTabPanel, CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import SearchIcon from '@mui/icons-material/Search';
import AddToWhitelist from "./AddToWhitelist";
import MyCircularProgress from "../../Components/CircularProgress";
import TypographyWithAutoTooltip from "../../Components/TypograpyWithAutoTooltip";
import GroupIcon from '@mui/icons-material/Group';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { ToastNotification } from "../../ToastNotifications";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserEditor from "./UserEditor";
import dayjs from "dayjs";
import { FriendlyTimeSpan } from "../../Globals";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface UserManagementProps
{
  showUserManagement: boolean;
  setShowUserManagement: any;
}

//-------------------------------------------------------------------------------
// User management component
//-------------------------------------------------------------------------------
const UserManagement = (props: UserManagementProps) => 
{
  const [userData, setUserData] = useState<IUserManagement_Data|undefined>(undefined);
  const [userDataIsLoading, setUserDataIsLoading] = useState<boolean>(false);
  const [filterSearchText, setFilterBySearchText] = useState<string>('');

  // Keeps track of which items are currently visible based on the search/filter
  const [visibleUserIDs, setVisibleUserIDs] = useState<string[]>([]);
  const [visibleWhitelistEmails, setVisibleWhitelistEmails] = useState<string[]>([]);

  const [showAddToWhitelist, setShowAddToWhitelist] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<IUserManagement_User|undefined>(undefined);
  const [activeTab, setActiveTab] = useState(0); // 0=users  1=whitelist





  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load API data every time the window is opened

    if(props.showUserManagement === true)
    {
      let newUserData: IUserManagement_Data | undefined = undefined;
      async function fetchData() 
      {
        newUserData = await LoadAdminUserData(setUserDataIsLoading);
        setUserData(newUserData);
      }
      fetchData();
    }
  }, [props.showUserManagement])

  //-------------------------------------------------------------------------------
  // Close window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowUserManagement(false);
    setUserData(undefined);
    setUserDataIsLoading(false);
    setFilterBySearchText('');
    setVisibleUserIDs([]);
    setVisibleWhitelistEmails([]);
  }

  //-------------------------------------------------------------------------------
  // Get admin tool org.
  //-------------------------------------------------------------------------------
  function GetAdminToolOrg(org_id: number): IUserManagement_AdminOrg | undefined
  {
    if(org_id === undefined) return undefined;
    return userData?.orgs.find(o => o.id === org_id);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the search box.
  //-------------------------------------------------------------------------------
  const OnSearchTextChange = (valueRaw: string) => 
  {
    RefreshSearchMatches(valueRaw);
  }

  //-------------------------------------------------------------------------------
  // Refresh the search matches.
  //-------------------------------------------------------------------------------
  function RefreshSearchMatches(valueRaw: string)
  {
    if(!userData) return;

    const newVisibleUserIDs: string[] = [];
    const newVisibleWhitelistEmails: string[] = [];

    const value = valueRaw.trim().toLocaleLowerCase();

    // Search users

    for(let i=0; i < userData.users.length; i++)
    {
      const user: IUserManagement_User = userData.users[i];

      // Try to match by user id

      if(user.id.toLocaleLowerCase().includes(value))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }
          
      // Try to match by first name

      if(user.first_name.toLocaleLowerCase().includes(value))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }

      // Try to match by last name

      if(user.last_name.toLocaleLowerCase().includes(value))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }

      // Try to match by firstname + last name

      if((user.first_name + ' ' + user.last_name).toLocaleLowerCase().includes(value))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }
        
      // Try to match by email

      if(user.email.toLocaleLowerCase().includes(value))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }

      // Try to match by each org name the user is part of

      let foundMatch: boolean = false;

      for(let j=0; j < user.organizations.length; j++)
      {
        const org_id: number = user.organizations[j];
        const org: IUserManagement_AdminOrg | undefined = GetAdminToolOrg(org_id);

        if(org && org.name.toLocaleLowerCase().includes(value))
        {
          newVisibleUserIDs.push(user.id);
          foundMatch = true;
          break;
        }
      }

      // Try to match by Enabled/Disabled status

      if((user.account_state === 'Enabled' && value.startsWith('enable')) || 
         (user.account_state === 'Disabled' && value.startsWith('disable')))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }

      // Try to match by Expired status

      if(user.expired && value.startsWith('expir'))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }

      // Try to match by "active" or "inactive" status

      if((user.account_state === 'Enabled' && user.expired !== true && value === 'active') ||
         ((user.account_state === 'Disabled' || user.expired === true) && value === 'inactive'))
      {
        newVisibleUserIDs.push(user.id);
        continue;
      }
        
      if(foundMatch === true)
        continue;
    }

    // Search whitelist

    for(let i=0; i < userData.whitelist.length; i++)
    {
      const whitelistUser: IUserManagement_WhitelistUser = userData.whitelist[i];

      // Try to match by email

      if(whitelistUser.email.toLocaleLowerCase().includes(value))
      {
        newVisibleWhitelistEmails.push(whitelistUser.email);
        continue;
      }

      // Try to match by org name

      if(GetAdminToolOrg(whitelistUser.org_id)?.name.toLocaleLowerCase().includes(value))
      {
        newVisibleWhitelistEmails.push(whitelistUser.email);
        continue;
      }      
    }

    // Apply the matches

    setFilterBySearchText(valueRaw);
    setVisibleUserIDs(newVisibleUserIDs);
    setVisibleWhitelistEmails(newVisibleWhitelistEmails);
  }
  
  //-------------------------------------------------------------------------------
  // Clear the search/filter (show all items).
  //-------------------------------------------------------------------------------
  const onClearFiltersButtonClick = () => 
  {
    setFilterBySearchText('');
    setVisibleUserIDs([]);
    setVisibleWhitelistEmails([]);
  }
  
  //-------------------------------------------------------------------------------
  // Returns TRUE if the specified user is visible, FALSE otherwise.
  //-------------------------------------------------------------------------------
  function IsUserVisible(userID: string): boolean
  {
    return visibleUserIDs.find(u => u === userID) !== undefined;
  }

  //-------------------------------------------------------------------------------
  // Returns TRUE if the specified whitelist email is visible, FALSE otherwise.
  //-------------------------------------------------------------------------------
  function IsWhitelistItemVisible(email: string): boolean
  {
    return visibleWhitelistEmails.find(e => e === email) !== undefined;
  }

  //-------------------------------------------------------------------------------
  // Renders the user account state table cell.
  //-------------------------------------------------------------------------------
  function RenderUserAccountState(user: IUserManagement_User)
  {
    let bgColorStr: string = '';
    let colorStr: string = '';
    let outputStr: string = '[Unknown]';

    if(user.account_state === 'Disabled')
    {
      colorStr = '#B0B0B0';
      bgColorStr = '#505050';
      outputStr = 'Disabled';
    }
    else if(user.expired)
    {
      colorStr = '#F0A0A0';
      bgColorStr = '#500000';
      outputStr = 'Expired';
    }
    else if(user.account_state === 'Enabled')
    {
      // Active = Enabled and not expired
      colorStr = '#D5FFD5';
      bgColorStr = '#60A060';
      outputStr = 'Active';
    }
    else
      colorStr = '#A05050';

    let willExpireInStr: string | undefined = undefined;
    if(user.expiration_date && user.expired === false)
      willExpireInStr = FriendlyTimeSpan(dayjs(user.expiration_date).diff(dayjs()));
  
    return (
      <Stack sx={{alignItems: 'center' }}>
        <Typography sx={{ px: '10px', py: '1px', color: colorStr, bgcolor: bgColorStr, fontSize: '0.8rem', borderRadius: 1, textAlign:'center' }}>
          {outputStr}
        </Typography>

        {user.account_state === 'Enabled' && user.expiration_date
          ?
            user.expired
              ?
                <Typography noWrap sx={{ color: '#AA7070', fontSize: '0.6rem' }}>
                  {user.expiration_date}
                </Typography>
              :
                <Typography noWrap sx={{ color: '#70A070', fontSize: '0.6rem' }}>
                  Expires in {willExpireInStr}
                </Typography>
          :null
        }

      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // Add new items to the account whitelist.
  //-------------------------------------------------------------------------------
  function OnAddToWhitelist()
  {
    setFilterBySearchText('');
    setShowAddToWhitelist(true);
  }

  //-------------------------------------------------------------------------------
  // Switch tabs.
  //-------------------------------------------------------------------------------
  const OnTabChange = (event: React.SyntheticEvent, newValue: number) => 
  {
    setActiveTab(newValue);
  }

  //-------------------------------------------------------------------------------
  // Delete an entry from the account whitelist.
  //-------------------------------------------------------------------------------
  async function OnDeleteEntryFromWhitelist(whitelistUser: IUserManagement_WhitelistUser)
  {
    if(!userData) return;

    const ret: boolean = await DeleteFromAccountWhitelist(whitelistUser.email);
    if(ret === false)
      return;

    // Update the userData

    const updatedUserData: IUserManagement_Data =
    {
      ...userData,
      whitelist: userData.whitelist.filter(w => w.email !== whitelistUser.email)
    }

    setUserData(updatedUserData);
  }

  //-------------------------------------------------------------------------------
  // Called when new entries are added to the whitelist.
  //-------------------------------------------------------------------------------
  async function OnNotifyAddToWhitelist()
  {
    // Notify user of success
    ToastNotification('success', 'Users have been whitelisted');

    // Force a reload of the user data
    const newUserData = await LoadAdminUserData(setUserDataIsLoading);
    setUserData(newUserData);
  }

  //-------------------------------------------------------------------------------
  // A "Edit User" button was pressed.
  //-------------------------------------------------------------------------------
  function OnEditUser(user: IUserManagement_User)
  {
    setEditUser(user);
  }

  //-------------------------------------------------------------------------------
  // Called after a user is edited. 
  //-------------------------------------------------------------------------------
  function OnNotifyUserEdited(updatedUser: IUserManagement_User)
  {
    if(!userData) return;

    // Replace the user with the updated version
    const updatedUsers: IUserManagement_User[] = userData.users.map(oldUser => oldUser.id === updatedUser.id ? updatedUser : oldUser);

    // Re-sort the users (in case the edits cause the edited item to shift)
    const sortedUpdatedUsers: IUserManagement_User[] = updatedUsers.sort((a: IUserManagement_User, b: IUserManagement_User) => (a.first_name + ' ' + a.last_name).localeCompare((b.first_name + ' ' + b.last_name)));

    // Update the user data with the fresh user list
    const updatedUserData: IUserManagement_Data =
    {
      ...userData,
      users: sortedUpdatedUsers
    }

    // Update the user data
    setUserData(updatedUserData);
  }











  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.showUserManagement===true}
            onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '30%', width: '70%', maxWidth: '70%', minHeight: '90vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', px:'15px', py: '10px'}}>

        <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

            {/* Tab definitions */}

            <Tabs value={activeTab} onChange={OnTabChange}>
              <CustomTab icon={<GroupIcon sx={{ color: theme_limeGreen }} />} label='User Accounts'/>
              <CustomTab icon={<MeetingRoomIcon  sx={{ color: theme_limeGreen }}/>} label='Sign-Up Whitelist'/>
            </Tabs>

            {/* --- Search box --- */}

            <Stack direction='column' sx={{ width:'50%', alignItems: 'center', justifyContent: 'center' }}>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SearchIcon sx={{ color: theme_textColorBlended, mr: 1, my: 0.5 }} />
                <CustomTextField
                      variant='outlined' size="small" value={filterSearchText}
                      label=
                      {
                        <Typography sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                          Search
                        </Typography>
                      } 
                      onChange={(e)=>OnSearchTextChange(e.target.value)}
                      InputProps=
                      {{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ visibility: filterSearchText===''?'hidden':'visible' }}>
                              <IconButton tabIndex={-1} onClick={(_)=>onClearFiltersButtonClick()} sx={{ color: theme_textColorBlended }}>
                                <CloseIcon/>
                              </IconButton>
                            </InputAdornment>
                          )
                      }}/>
              </Box>

              <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                You can search by name, email, organization, status (active, inactive, enabled, disabled, expired)
              </Typography>

            </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      {userDataIsLoading === true
        ?
          <DialogContent sx={{ background: theme_bgColorGradient2 }}>
            <Stack sx={{ alignItems: 'center', mt: 5 }}>
              
              <Stack direction='row' sx={{ alignItems: 'center' }}>
                <MyCircularProgress />
                <Typography sx={{ width: '100%', fontSize: '1.5rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
                  Loading...
                </Typography>
              </Stack>
              
            </Stack>
          </DialogContent>
        :
          <DialogContent sx={{ p: 0, background: theme_bgColorGradient2 }}>

            <Stack sx={{ mt: 0, alignItems: 'left' }}>

              {/* --- Users --- */}

              <CustomTabPanel value={activeTab} index={0}>

                <Stack direction='row' sx={{ mt: 0, alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
                  User Accounts
                  </Typography>
                  <Typography sx={{ ml: 2, fontSize: '1.0rem', color: theme_textColorBlended }}>
                    {filterSearchText.length > 0 ? `${visibleUserIDs.length} search matches` : `${userData?.users.length} total users`}
                  </Typography>
                </Stack>

                <TableContainer component={Paper} sx={{ mt: 0.5, boxShadow: 5 }}>
                  <Table size="small" sx={{ minWidth: 650, bgcolor: theme_textColorBlended+'80' }}>
                    <TableHead>
                      <TableRow sx={{ "& .MuiTableCell-head": { fontSize: '1.0rem', color: theme_bgColorMain, fontWeight: 'bold', backgroundColor: '#8CB3D9'},}}>
                        <TableCell align='left' sx={{px:'5px', py: '5px'}}>User</TableCell>
                        <TableCell align='left' sx={{px:'5px', py: 0}}>Email</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Status</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Organizations</TableCell>
                        <TableCell/>
                        <TableCell/>
                      </TableRow>
                    </TableHead>

                  {/* Error message when no search matches are found */}
                  {filterSearchText.length > 0 && visibleUserIDs.length === 0
                    ?
                      <Typography sx={{ m:2, fontSize: '1.4rem', color: '#B05050', opacity: 0.8 }}>
                        No matches found.
                      </Typography>
                    :
                        <TableBody>
                          {userData?.users.map(function(user)
                          {
                            if(filterSearchText.length > 0 && IsUserVisible(user.id) === false)
                              return null;

                            return (

                              <TableRow key={user.id}>

                                {/* Name */}

                                <TableCell align='left' sx={{ p: 0.5 }}>
                                  <Stack direction='column'>

                                    <Typography sx={{ color: '#505070', fontSize: '1rem', fontWeight: 'bold' }}>
                                      {user.first_name} {user.last_name}
                                    </Typography>

                                    <Stack direction='row'>
                                      <Typography sx={{ color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                        {user.id}
                                      </Typography>
                                      <Typography sx={{ ml: 2, color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                        <b>{user.stats.project_count}</b> project{user.stats.project_count===1?'':'s'}
                                      </Typography>
                                    </Stack>

                                  </Stack>
                                </TableCell>

                                {/* Email */}

                                <TableCell align='left' sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.5 }}>
                                  {user.email}
                                </TableCell>

                                {/* Status */}

                                <TableCell align='center' sx={{ p: 0.5 }}>
                                  {RenderUserAccountState(user)}
                                </TableCell>

                                {/* Organizations */}

                                <TableCell align='center' sx={{ p: 0.5, maxWidth: '250px' }}>
                                  {user.organizations.length === 0 && user.account_state === 'Enabled'
                                    ?
                                      <Stack>
                                        <Typography sx={{ color: '#B05050', fontSize: '0.6rem' }}>
                                          <b>WARNING:</b>  This user is enabled but not part of any organizations
                                        </Typography>
                                        <Typography sx={{ color: 'gray', fontSize: '0.6rem' }}>
                                          (the account should be disabled, or given access to an org)
                                        </Typography>
                                      </Stack>
                                    :
                                      <TypographyWithAutoTooltip sx={{ color: theme_bgColorMain, fontSize: '0.7rem', width: '100%' }}>
                                        {user.organizations.map(function(org_id,index)
                                          {
                                            const org: IUserManagement_AdminOrg | undefined = GetAdminToolOrg(org_id);
                                            if(!org) return null;
                                            return (
                                                `${index>0?', ':''}${org.name}`
                                            )
                                          })
                                        }
                                      </TypographyWithAutoTooltip>
                                  }
                                </TableCell>

                                {/* Extra info tooltip */}

                                <TableCell align='center' valign='middle' sx={{ p: 0 }}>
                                  <CustomWidthTooltip placement='left-start' arrow 
                                    title=
                                    {
                                      <Stack direction='column'>

                                        <Typography sx={{ mb: 1, fontSize: '1.0rem', fontWeight: 'bold', color: theme_textColorMain+'E0' }}>
                                          {user.first_name} {user.last_name}
                                        </Typography>

                                        {user.created_date 
                                          ? 
                                            <Stack direction='row'>
                                              <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                                                Created
                                              </Typography>
                                              <Typography sx={{ ml: 1, fontSize: '0.8rem', color: theme_orange }}>
                                                {new Date(user.created_date).toDateString()}
                                              </Typography>
                                            </Stack>
                                          :null
                                        }

                                        {user.last_login 
                                          ? 
                                            <Stack direction='row'>
                                              <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                                                Last Login
                                              </Typography>
                                              <Typography sx={{ ml: 1, fontSize: '0.8rem', color: theme_orange }}>
                                                {new Date(user.last_login).toDateString()}
                                              </Typography>
                                            </Stack>
                                          :null
                                        }

                                        {user.last_pwd_change
                                          ? 
                                            <Stack direction='row'>
                                              <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                                                Last Password Change
                                              </Typography>
                                              <Typography sx={{ ml: 1, fontSize: '0.8rem', color: theme_orange }}>
                                                {new Date(user.last_pwd_change).toDateString()}
                                              </Typography>
                                            </Stack>
                                          :null
                                        }

                                        <Stack direction='column' sx={{ mt: 1 }}>

                                          <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                                            Roles: 
                                          </Typography>

                                          <Stack sx={{ ml: 2}}>
                                            <TableContainer sx={{ width: '100%' }}>
                                                <Table size="small" sx={{ bgcolor: 'transparent' }}>
                                                  <TableBody>

                                                    {GetAdminOrgsWithRolesForUser(user).map(org_id =>

                                                      <TableRow key={org_id}>

                                                        <TableCell sx={{ color: '#CCBB00', fontSize: '0.7rem', px: 0.4, py: 0.1 }}>
                                                          {GetAdminToolOrg(org_id)?.name}
                                                        </TableCell>

                                                        <TableCell sx={{ color: theme_bgColorMain, fontSize: '0.7rem', p: 0.1 }}>
                                                          <TypographyWithAutoTooltip placement='right' arrow sx={{ maxWidth: '300px', fontSize: '0.6rem', color: theme_textColorMain+'A0' }}>
                                                            {GetRolesForOrgForUser(user,org_id).map(function(role,index)
                                                              {
                                                                return (
                                                                  `${index>0?', ':''}${role.role}`
                                                                )
                                                              })
                                                            }
                                                          </TypographyWithAutoTooltip>
                                                        </TableCell>

                                                      </TableRow>
                                                    )}

                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </Stack>
                                        </Stack>
                                      </Stack>                                      
                                    }>
                                    <HelpOutlineIcon sx={{ p: 0, mt:'5px', color: theme_bgColorLight1 }}/>
                                  </CustomWidthTooltip>
                                </TableCell>

                                {/* Utility Buttons */}

                                <TableCell align='right' sx={{ p: 0.1 }}>
                                  <IconButton sx={{ p: 0.3 }} onClick={_=>OnEditUser(user)}>
                                    <EditIcon/>
                                  </IconButton>
                                </TableCell>

                              </TableRow>
                            )}
                          )}
                        </TableBody>
                    }
                  </Table>
                </TableContainer>
                
              </CustomTabPanel>

              {/* --- Whitelist --- */}

              <CustomTabPanel value={activeTab} index={1}>
                
                <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

                  <Stack direction='row' sx={{ mt: 0, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
                      Sign-Up Whitelist
                    </Typography>
                    <Typography sx={{ ml: 2, fontSize: '1.0rem', color: theme_textColorBlended }}>
                      {filterSearchText.length > 0 ? `${visibleWhitelistEmails.length} search matches` : `${userData?.whitelist.length} total users`}
                    </Typography>
                  </Stack>

                  <Stack direction='row' sx={{ mt: 1 }}>
                    <Button variant='outlined' startIcon={<PersonAddIcon/>} 
                            sx={{ textTransform: 'none' }} onClick={_=>OnAddToWhitelist()}>
                      Add to whitelist...
                    </Button>
                  </Stack>

                </Stack>

                <TableContainer component={Paper} sx={{ mt: 0.5, boxShadow: 5 }}>
                  <Table size='small' sx={{ minWidth: 650, bgcolor: theme_textColorBlended+'80' }}>
                    <TableHead>
                      <TableRow sx={{ "& .MuiTableCell-head": { fontSize: '1.0rem', color: theme_bgColorMain, fontWeight: 'bold', backgroundColor: '#8CB3D9'},}}>
                        <TableCell sx={{px:'5px', py: '5px'}}>Email</TableCell>
                        <TableCell sx={{px:'5px', py: '5px'}}>Organization</TableCell>
                        <TableCell sx={{px:'5px', py: '5px'}}>Expiration Date</TableCell>
                        <TableCell align="right"/>
                      </TableRow>
                    </TableHead>

                    {/* Error message when no search matches are found */}
                    {filterSearchText.length > 0 && visibleWhitelistEmails.length === 0
                    ?
                      <Typography sx={{ m:2, fontSize: '1.4rem', color: '#B05050', opacity: 0.8 }}>
                        No matches found.
                      </Typography>
                    :
                      <TableBody>

                        {userData?.whitelist.map(function(whitelistUser)
                        {
                          if(filterSearchText.length > 0 && IsWhitelistItemVisible(whitelistUser.email) === false)
                            return null;

                          return (
                            <TableRow key={whitelistUser.email}>

                              {/* Email */}
                              <TableCell sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.6 }}>
                                {whitelistUser.email}

                                {/* Warn if this email is already used for an actual user account */}
                                {userData.users.find(u => u.email === whitelistUser.email)
                                  ?
                                    <Typography sx={{ color: '#B06767', opacity: 1, fontSize: '0.6rem' }}>
                                      This user should be removed from the whitelist as they already have an account.  
                                    </Typography>
                                  :null
                                }

                              </TableCell>

                              {/* Organization */}
                              <TableCell sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.6 }}>
                                {GetAdminToolOrg(whitelistUser.org_id)?.name ?? '[Error: unable to determine org]'}
                              </TableCell>

                              {/* Expiration Date */}
                              <TableCell sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.6 }}>

                                <Stack direction='row'>
                                  {whitelistUser.expiration_date}

                                  {/* Warn if this user has expired (and can no longer sign in) */}
                                  {dayjs(whitelistUser.expiration_date).isBefore(dayjs())
                                    ?
                                    <Typography sx={{ ml: 1, fontSize: '0.8rem', color: '#C06767', fontWeight: 'bold' }}>
                                      Expired
                                    </Typography>
                                    :null
                                  }
                                </Stack>

                              </TableCell>

                              {/* Utility Buttons */}
                              <TableCell align="right" sx={{ p:0.1 }}>
                                <IconButton sx={{ p: 0.3 }} onClick={_=>OnDeleteEntryFromWhitelist(whitelistUser)}>
                                  <ClearIcon/>
                                </IconButton>
                              </TableCell>

                            </TableRow>
                          )}
                        )}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>

              </CustomTabPanel>

            </Stack>

            {/* Popup sub-dialogs */}

            <AddToWhitelist showAddToWhitelist={showAddToWhitelist} setShowAddToWhitelist={setShowAddToWhitelist}
                            userData={userData} OnNotifyAddToWhitelist={OnNotifyAddToWhitelist}/>
            <UserEditor editUser={editUser} setEditUser={setEditUser} 
                        userData={userData} setUserData={setUserData}
                        OnNotifyUserEdited={OnNotifyUserEdited}/>

          </DialogContent>
      }

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

          {/* CLOSE button */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Close
              </Button>
            </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default UserManagement;


export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});