// Org create/edit component

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight, theme_errorRed, theme_limeGreen } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { IOrgManagement_Data, IOrgManagement_Org } from "./OrgManagementInterfaces";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import { CustomSelect } from "../../LayerLibrary/EditLayer/EditLayerStyle";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FriendlyTimeSpan } from "../../Globals";
import { CreateOrUpdateOrg } from "./OrgManagementOps";
import { ToastNotification } from "../../ToastNotifications";


const ORG_NAME_MAX_LENGTH: number = 40;
const DEFAULT_BOUNDARY_ID = 2;  // CONUS is the default boundary

const NO_BOUNDARY_ID = -1;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface OrgEditorProps
{
  showOrgEditor: boolean;
  setShowOrgEditor: any;
  editOrg?: IOrgManagement_Org;   // If this is undefined, we are creating a new org
  orgData?: IOrgManagement_Data;
  NotifyCreatedOrUpdatedOrg: any;
}

//-------------------------------------------------------------------------------
// Org create/edit component
//-------------------------------------------------------------------------------
const OrgEditor = (props: OrgEditorProps) => 
{


  const [localOrg, setLocalOrg] = useState<IOrgManagement_Org|undefined>(undefined);
  const [createNewMode, setCreateNewMode] = useState<boolean>(false);
  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);
  
  // Keeping track of this separately because it needs to be a Dayjs object
  const [localExpirationDate, setLocalExpirationDate] = useState<Dayjs|null>(null);





  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Create a local copy of the org being edited every time the window is opened

    if(props.showOrgEditor === true)
    {
      if(props.editOrg) // EDIT MODE
      {
        // If the boundary_id is undefined/null, set it to -1 (that is used locally for the "No Boundary" id)
        const updatedEditOrg: IOrgManagement_Org = 
        {
          ...props.editOrg,
          boundary_id: props.editOrg.boundary_id ?? NO_BOUNDARY_ID
        }

        setLocalOrg(updatedEditOrg);
        setLocalExpirationDate(props.editOrg.expiration_date ? dayjs(props.editOrg.expiration_date) : null);
        setCreateNewMode(false);
        setChangesWereMade(false);
      }
      else // CREATE NEW MODE
      {
        setLocalOrg(
        {
          id: null, // new org - don't know ID until API call is made to return it
          name: '',
          state: 'Enabled',
          expiration_date: undefined,
          expired: false,
          settings: 
            {
              show_boundary: false,
              allow_project_sharing: true
            },
          boundary_id: DEFAULT_BOUNDARY_ID,
          stats: 
          {
            project_count: 0,
            user_count: 0,
            count_custom_layers: 0,
          }
        });
        setCreateNewMode(true);
        setChangesWereMade(true);
      }

    }

  }, [props.showOrgEditor])  

  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowOrgEditor(false);
    setLocalOrg(undefined);
    setLocalExpirationDate(null);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(!localOrg || !props.orgData) return;

    if(changesWereMade === false)
    {
      OnClose();
      return;
    }

    // NOTE:  A boundary of -1 means "no boundary"

    // Validate

    localOrg.name = localOrg.name.trim();
    if(localOrg.name.length === 0)
    {
      ToastNotification('error', 'The organization name cannot be empty');
      return;
    }

    if(localOrg.name.length < 3)
    {
      ToastNotification('error', 'The organization name must be at least 3 characters long');
      return;
    }
      
    // If we're creating a new org, the org name cannot already exist

    if(props.editOrg === undefined && props.orgData.orgs.find(o => o.name.toLowerCase().trim() === localOrg.name.toLocaleLowerCase().trim()) !== undefined)
    {
      ToastNotification('error', 'An organization with that name already exists');
      return;
    }

    if(localOrg.boundary_id === -1)
      localOrg.boundary_id = undefined;

    // Validate the expiration date

    if(localExpirationDate)
    {
      if(localExpirationDate.isValid() === false)
      {
        ToastNotification('error', 'The expiration date is not valid');
        return;
      }
      if(localExpirationDate.isBefore(dayjs()))
      {
        ToastNotification('error', 'The expiration date cannot be in the past');
        return;
      }
    }

    // Call server

    const ret: boolean = await CreateOrUpdateOrg(localOrg);
    if(ret === false)
      return;

    // Notify caller
    props.NotifyCreatedOrUpdatedOrg(localOrg, createNewMode);

    // Close this window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // The "Enabled" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnEnabledCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!localOrg) return;

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      state: checked ? 'Enabled' : 'Disabled'
    }

    setLocalOrg(updatedOrg);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The org name text field has changed.
  //-------------------------------------------------------------------------------
  function OnOrgNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localOrg) return;

    const newValue: string = event.target.value;

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      name: newValue
    }

    setLocalOrg(updatedOrg);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The boundary combo box was changed.
  //-------------------------------------------------------------------------------
  const OnBoundaryChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(!localOrg) return;

    const newBoundaryID: number = event.target.value as number;
    if(!newBoundaryID) return;

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      boundary_id: newBoundaryID
    }

    setLocalOrg(updatedOrg);
    setChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // The expiration date has changed.
  //-------------------------------------------------------------------------------
  function OnExpirationDateChanged(newExpirationDate: Dayjs | null)
  {
    if(!localOrg || !newExpirationDate) return;

    const newExpired: boolean = newExpirationDate.isBefore(dayjs());

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      expiration_date: newExpirationDate.toJSON(),
      expired: newExpired
    }

    setLocalOrg(updatedOrg);
    setLocalExpirationDate(newExpirationDate);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Clear the expiration date.
  //-------------------------------------------------------------------------------
  function OnExpirationDateClear()
  {
    if(!localOrg) return;

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      expiration_date: undefined,
      expired: false
    }

    setLocalOrg(updatedOrg);
    setLocalExpirationDate(null);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Add a number of months to the current expiration date.
  // If there is no expiration date currently set, 'today' will be assumed.
  //-------------------------------------------------------------------------------
  function OnExpirationDateAddTime(months: number)
  {
    if(!localOrg) return;

    // If there is no expiration date, or if there is but it's expired.. reset to today's date

    let currExpDate: Dayjs | null = null;
    if(localOrg.expired || !localExpirationDate || localExpirationDate.isBefore(dayjs()))
      currExpDate = dayjs();  // today
    else
      currExpDate = localExpirationDate;

    if(!currExpDate) return;

    const newExpDate: Dayjs = currExpDate.add(months, 'month');

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      expiration_date: newExpDate.toJSON(),
      expired: false
    }

    setLocalOrg(updatedOrg);
    setLocalExpirationDate(newExpDate);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Returns a string showing the number of days/months until the account expires.
  //-------------------------------------------------------------------------------
  function GetDaysToExpiration(): string | undefined
  {
    if(!localOrg || localOrg.expired || !localExpirationDate)
      return '[error]';
    
    return FriendlyTimeSpan(localExpirationDate.diff(dayjs()));
  }

  //-------------------------------------------------------------------------------
  // The "Allow project sharing" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnAllowProjectSharingChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!localOrg) return;

    const updatedOrg: IOrgManagement_Org =
    {
      ...localOrg,
      settings: 
      {
        ...localOrg.settings,
        allow_project_sharing: checked
      }
    }

    setLocalOrg(updatedOrg);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The "Show org boundary" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnShowOrgBoundaryChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
    {
      if(!localOrg) return;
  
      const updatedOrg: IOrgManagement_Org =
      {
        ...localOrg,
        settings: 
        {
          ...localOrg.settings,
          show_boundary: checked
        }
      }
  
      setLocalOrg(updatedOrg);
      setChangesWereMade(true);
    }
  















  if(!props.orgData || !localOrg) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.showOrgEditor === true}
            onClose={OnClose} maxWidth='md'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
            { createNewMode ? 'Create a New Organization': localOrg.name }
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>

          {/* Stats */}

          {createNewMode === false
            ?
              <Stack direction='row' sx={{ mb: 2 }}>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Users
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {localOrg.stats.user_count}
                </Typography>
                <Typography sx={{ ml: 3, color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Projects
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {localOrg.stats.project_count}
                </Typography>
                <Typography sx={{ ml: 3, color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Custom Layers
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {localOrg.stats.count_custom_layers}
                </Typography>
              </Stack>
            :null
          }

          {/* Enabled status checkbox */}

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Checkbox size='medium' checked={localOrg.state === 'Enabled'} 
                onChange={(e,c)=>OnEnabledCheckBoxChange(e,c)}
                sx=
                {{ 
                  mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
            <Stack>
              <Typography sx={{ color: theme_textColorMain, opacity: 0.8, fontSize: '1.0rem' }}>
                Organization Enabled
              </Typography>
              <Typography sx={{ color: theme_limeGreen, opacity: 0.5, fontSize: '0.6rem' }}>
                If disabled, users cannot load any projects from this organizations
              </Typography>
            </Stack>
          </Stack>

          {/* Name */}

          <CustomTextField variant='standard' size='small' autoComplete='off'
                            value={localOrg.name}
                            onChange={OnOrgNameChanged}
                            inputProps={{ maxLength: ORG_NAME_MAX_LENGTH }}
                            label=
                              {
                                <Typography sx={{ fontSize:'0.8rem',color: theme_textColorBlended+'B0' }}>
                                  Organization Name
                                </Typography>
                              } 
                            sx={{ mt: 2, p: 0, width: '100%' }}/>

          {/* Boundary selection */}

          <Stack direction='column' sx={{ mt: 3, width: '100%' }}>

            <Typography sx={{ color: theme_textColorBlended, fontSize: '0.7rem' }}>
              Boundary
            </Typography>

            <CustomSelect variant='standard' size='small'
                          value={localOrg.boundary_id}
                          onChange={OnBoundaryChanged}
                          sx={{ p: 0.5 }}>

              <MenuItem key={NO_BOUNDARY_ID} value={NO_BOUNDARY_ID}>
                <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '1.0rem' }}>
                  No Boundary
                </Typography>
              </MenuItem>

              {props.orgData.boundaries.map(function(boundary)
              {
                if(!boundary.id) return null;
                return (
                  <MenuItem key={boundary.id} value={boundary.id}>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.8, fontSize: '1.0rem' }}>
                      {boundary.name}
                    </Typography>
                  </MenuItem>
                )
              }
              )}

            </CustomSelect>
          </Stack>

          <Stack direction='row' sx={{ mt: 3, alignItems: 'center', justifyContent: 'left' }}>

            {/* Expiration date picker */}
            
            <Stack direction='column'>
              <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                Organization Expiration Date (optional)
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={localExpirationDate}
                            onChange={(newValue) => OnExpirationDateChanged(newValue)}
                            sx={{ mt: 0.5,
                                  input: { backgroundColor: theme_bgColorLight1+'70', color: theme_textColorMain+'A0', fontSize: '1.0rem' } 
                              }}

                              // Modify the calendar popup bg color
                              slotProps={{
                                layout: {
                                  sx: {
                                    borderRadius: '2px',
                                    backgroundColor: theme_bgColorLight+'C0',
                                  }
                                }
                              }}
                />
              </LocalizationProvider>

              {localExpirationDate && dayjs(localExpirationDate).isValid() === false
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed }}>
                    This is not a valid date.
                  </Typography>
                :null
              }
              {!localExpirationDate
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6 }}>
                    This organization has no expiration date.
                  </Typography>
                :null
              }

              {localOrg.expired !== true && localExpirationDate && dayjs(localExpirationDate).isValid() === true
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                    This organization will expire in {GetDaysToExpiration()}.
                  </Typography>
                :null
              }

              {localOrg.expired
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed }}>
                    This organization has expired.
                  </Typography>
                :null
              }

            </Stack>

            <Stack direction='column' sx={{ ml: 2, opacity: 0.8 }}>
              <Button variant='outlined' onClick={_=>OnExpirationDateAddTime(1)}
                      sx={{ textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                +1 Month
              </Button>
              <Button variant='outlined' onClick={_=>OnExpirationDateAddTime(12)}
                      sx={{ mt: '-1px', textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                +1 Year
              </Button>
              <Button variant='outlined' onClick={_=>OnExpirationDateClear()}
                      sx={{ mt: '-1px', textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                Clear
              </Button>
            </Stack>
          </Stack>

          {/* Show org boundary checkbox */}

          <Stack direction='row' sx={{ mt: 3, alignItems: 'center' }}>
            <Checkbox size='small' checked={localOrg.settings.show_boundary} 
                onChange={(e,c)=>OnShowOrgBoundaryChanged(e,c)}
                sx=
                {{ 
                  mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
            <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '0.9rem' }}>
              Show organization boundary on the map
            </Typography>
          </Stack>

          {/* Allow project sharing checkbox */}

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Checkbox size='small' checked={localOrg.settings.allow_project_sharing} 
                onChange={(e,c)=>OnAllowProjectSharingChanged(e,c)}
                sx=
                {{ 
                  mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
            <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '0.9rem' }}>
              Allow projects to be shared within this organization
            </Typography>
          </Stack>


        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

      <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '250px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                {createNewMode ? 'Create New Organization': 'Accept Changes' }
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default OrgEditor;
