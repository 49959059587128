// User management operations

import { CallServer, CallServerResults } from "../../CallServer";
import { ToastNotification } from "../../ToastNotifications";
import { IUserManagement_AdminOrg, IUserManagement_User, IUserManagement_Data, IUserManagement_UserOrgRole, IUserManagement_WhitelistUser } from "./UserManagementInterfaces";


//-------------------------------------------------------------------------------
// Load user and whitelist data.
//-------------------------------------------------------------------------------
export async function LoadAdminUserData(setIsRunning: any = undefined): Promise<IUserManagement_Data | undefined>
{
  // Call the server to get the data

  const server = new CallServer();

  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('get', '/users');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    const userData: IUserManagement_Data | undefined = result.data;
    if(!userData)
    {
      ToastNotification('error', "Unable to load admin user data")
      return undefined;
    }

    // Sort the lists

    const sortedUserData: IUserManagement_Data =
    {
      ...userData,
      users: userData.users.sort((a: IUserManagement_User, b: IUserManagement_User) => (a.first_name + ' ' + a.last_name).localeCompare((b.first_name + ' ' + b.last_name))),
      whitelist: userData.whitelist.sort((a: IUserManagement_WhitelistUser, b: IUserManagement_WhitelistUser) => a.email.localeCompare(b.email)),
      orgs: userData.orgs.sort((a: IUserManagement_AdminOrg, b: IUserManagement_AdminOrg) => a.name.localeCompare(b.name))
    }

    // Success
    return sortedUserData;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load admin user data")
    return undefined;
  }
}

//-------------------------------------------------------------------------------
// Add new entries to the account whitelist.
//-------------------------------------------------------------------------------
export async function AddToAccountWhitelist(org_id: number, emails: string[], expiration_date: string|undefined, setIsRunning: any = undefined): Promise<boolean>
{
  const server = new CallServer();
  server.Add('users',
    {
      org_id: org_id,
      emails: emails,
      expiration_date: expiration_date
    });
  
  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('post', '/account_whitelist');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to add new entries to the account whitelist")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete an email from the account whitelist.
//-------------------------------------------------------------------------------
export async function DeleteFromAccountWhitelist(email: string, setIsRunning: any = undefined): Promise<boolean>
{
  const server = new CallServer();
  server.Add('email', email);
  
  if(setIsRunning) setIsRunning(true); // Signal the UI that we are loading the user info

  const result: CallServerResults = await server.Call('delete', '/account_whitelist');

  if(setIsRunning) setIsRunning(false); // Signal the UI that we are done loading the user info

  if(result.success)
  {
    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete email from the account whitelist")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Updates a user's info.
//-------------------------------------------------------------------------------
export async function UpdateAdminUserInfo(user: IUserManagement_User, setIsRunning: any = undefined): Promise<boolean>
{
  const server = new CallServer();
  server.Add('user_id', user.id);
  server.Add('first_name', user.first_name);
  server.Add('last_name', user.last_name);
  server.Add('account_state', user.account_state);
  if(user.expiration_date)
    server.Add('expiration_date', user.expiration_date);
  server.Add('orgs', user.organizations);
  server.Add('roles', user.roles);
  
  if(setIsRunning) setIsRunning(true);

  const result: CallServerResults = await server.Call('put', '/user');

  if(setIsRunning) setIsRunning(false);

  if(result.success)
  {
    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to update user data")
    return false;
  }
}

//-------------------------------------------------------------------------------
// Returns all roles for the specified user+org combo.
//-------------------------------------------------------------------------------
export function GetRolesForOrgForUser(user: IUserManagement_User, orgID: number): IUserManagement_UserOrgRole[]
{
  return user.roles.filter(r => r.org_id === orgID);
}

//-------------------------------------------------------------------------------
// Returns TRUE if the user in in the org.
//-------------------------------------------------------------------------------
export function IsUserInOrg(localUser: IUserManagement_User, org: IUserManagement_AdminOrg): boolean
{
  return localUser.organizations.find(org_id => org_id === org.id) !== undefined;
}


//-------------------------------------------------------------------------------
// Returns all unique orgs for which the specified user has roles.
//-------------------------------------------------------------------------------
export function GetAdminOrgsWithRolesForUser(user: IUserManagement_User): number[]
{
  const uniqueOrgIDs: number[] = [...new Set(user.roles.map(item => item.org_id))];
  return uniqueOrgIDs;
}

//-------------------------------------------------------------------------------
// Returns a list of orgs for which the user has no roles.
// It also eliminates orgs the user is not part of.
//-------------------------------------------------------------------------------
export function GetAdminOrgsWithNoRolesForUser(user: IUserManagement_User): number[]
{
  const orgsWithNoRoles: number[] = [];

  // We only look through orgs the user is part of

  for(let i=0; i < user.organizations.length; i++)
  {
    // If this user already has a role defined, skip it
    if(user.roles.find(r => r.org_id === user.organizations[i]) !== undefined)
      continue;

    orgsWithNoRoles.push(user.organizations[i]);
  }

  return orgsWithNoRoles;
}