// Admin tools main page

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2 } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { useState } from "react";
import UserManagement from "./UserManagement/UserManagement";
import OrgManagement from "./OrgManagement/OrgManagement";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AdminToolsProps
{
}

//-------------------------------------------------------------------------------
// Admin tools main page component
//-------------------------------------------------------------------------------
const AdminTools = (props: AdminToolsProps) => 
{
  // Get needed state data from the store
  const { store_showAdminToolsWindow, store_setShowAdminToolsWindow, store_isLoggedIn
        } = useStore();


  const [showUserManagement, setShowUserManagement] = useState<boolean>(false);
  const [showOrgManagement, setShowOrgManagement] = useState<boolean>(true); // TEMP set to true while working on it






  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    store_setShowAdminToolsWindow(false);
  }

  //-------------------------------------------------------------------------------
  // User management was clicked.
  //-------------------------------------------------------------------------------
  function OnUserManagement()
  {
    setShowUserManagement(true);
  }

  //-------------------------------------------------------------------------------
  // Organization management was clicked.
  //-------------------------------------------------------------------------------
  function OnOrgManagement()
  {
    setShowOrgManagement(true);
  }









  if(store_isLoggedIn !== true) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={store_showAdminToolsWindow===true}
            onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '30%', width: '30%', maxWidth: '30%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          {/* <Stack> */}
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              Admin Tools
            </Typography>
            {/* <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended }}>
            </Typography>
          </Stack> */}

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'center' }}>

          <Stack sx={{ width: '300px' }}>

            <Button variant="outlined" startIcon={<GroupIcon />} onClick={(_)=>OnUserManagement()}
                    sx={{ my: 1, textTransform: 'none' }}>
              User Management
            </Button>

            <Button variant="outlined" startIcon={<MapsHomeWorkIcon />} onClick={(_)=>OnOrgManagement()}
                    sx={{ my: 1, textTransform: 'none' }}>
              Organization Management
            </Button>

          </Stack>

        </Stack>

        {/* Popup sub-dialogs */}
        <UserManagement showUserManagement={showUserManagement} setShowUserManagement={setShowUserManagement} />
        <OrgManagement showOrgManagement={showOrgManagement} setShowOrgManagement={setShowOrgManagement}/>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

          {/* CLOSE button */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Close
              </Button>
            </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default AdminTools;
