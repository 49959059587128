// Globals

import { Link } from "@mui/material";
import { appBuildDateTimeStamp } from "./App";
import { theme_textColorBlended } from "./Theme";


// This flag is only to be used during development to enable certain functionality teporarily.
// When deploying to either DEV or PROD, it should be set to FALSE.
export const TESTING_ONLY_FEATURES_ENABLED = true;



// NOTE: All env vars come in as strings (at least when deployed via BiutBucket pipeline)
export const DEV_MODE = StrToBool(process.env.REACT_APP_DEV_MODE);
export const DEV_MODE_CONSOLE_LOGGING = StrToBool(process.env.REACT_APP_DEV_MODE_CONSOLE_LOGGING); // (used in Debug.tsx)

export const CALLSERVER_BASE_URL = process.env.REACT_APP_STRATIFYX_API;


export const AOI_MAX_SIZE_ACRES = 30000;
export const AOI_BBOX_EXTENT_MAX_SIZE_ACRES = 60000;

//export const GOOGLE_TAG_MANAGER_ID = 'GTM-PG737DR';
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GTM_ID || 'GTM-5T8NMS2';

// S3 bucket containing all the layer thumbnail images (used mainly for the layer library) 
export const LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL = 'https://sfx-app-layer-info-map-images.s3.amazonaws.com/';

// S3 bucket containing layer legend images (only used for certain special layers)
export const LAYER_LEGEND_IMAGES_BASE_URL = 'https://sfx-app-layer-legend-images.s3.amazonaws.com/';

export const NRR_INFO_STATIC_IMAGES_BASE_URL = 'https://sfx-app-nrr-info-images.s3.amazonaws.com/';

// This is a special layer - the app needs to know its name to allow the user to highlight parcels
export const PARCEL_LAYER_NAME = 'Parcels';

// For now, bundles go away in the app, but we still load a default bundle 
// behind the scenes (with dev and prod having different bundles).  Each bundle
// loads in a list of all the NRRs and reference layers the app will work with.
export const DEFAULT_BUNDLE_ID: number = process.env.REACT_APP_BUNDLE_ID ? Number.parseInt(process.env.REACT_APP_BUNDLE_ID) : 6;

export const DEFAULT_TERRAIN_3D_EXAGGERATION = 1.5;


//-------------------------------------------------------------------------------
// A bunch of helper functions which are used all over the app.
//-------------------------------------------------------------------------------




//-------------------------------------------------------------------------------
// Converts a string to boolean (accepts multiple formats)
//-------------------------------------------------------------------------------
export function StrToBool(str: string | null | undefined): boolean | undefined
{
  if(str === undefined || str === null)
    return undefined;

  switch(str.toLowerCase())
  {
    case 'false':
    case 'f':
    case '0':
    case 'no':
    case 'n':
      return false;

    case 'true':
    case 't':
    case '1':
    case 'yes':
    case 'y':
      return true;
  }

  return undefined;
}

//-------------------------------------------------------------------------------
// Wait X number of milliseconds.
// Ex:  await Delay(2000);
//-------------------------------------------------------------------------------
export function Delay(delayMS: number)
{
  return new Promise( res => setTimeout(res, delayMS) );
}

//-------------------------------------------------------------------------------
// Friendly display of large numbers (with commas)
//-------------------------------------------------------------------------------
export function FriendlyNumber(num: number | undefined | null, decimals: number = 0) : string
{
  if(num === undefined || num === null) return '';
  return num.toLocaleString(undefined, { maximumFractionDigits: decimals })
}

//-------------------------------------------------------------------------------
// Friendly display of currency (USD)
//-------------------------------------------------------------------------------
export function FriendlyCurrency(num: number | undefined | null, decimals: number = 0) : string
{
  if(num === undefined || num === null) return '';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(num);
}

//-------------------------------------------------------------------------------
// Shows the specified number of milliseconds as a more friendly (and short) 
// time span.
//-------------------------------------------------------------------------------
export function FriendlyTimeSpan(milliseconds: number | undefined): string | undefined
{
  if(!milliseconds) return undefined;

  if(milliseconds < 2000) return `${Math.round(milliseconds)} ms`;

  const seconds = milliseconds/1000; if(seconds < 120) return `${Math.round(seconds)} seconds`;
  const minutes = seconds/60;        if(minutes < 120) return `${Math.round(minutes)} minutes`;
  const hours = minutes/60;          if(hours   < 48)  return `${Math.round(hours)} hours`;
  const days = hours/24;             if(days    < 14)  return `${Math.round(days)} days`;
  const weeks = days/7;              if(weeks   < 8)   return `${Math.round(weeks)} weeks`;
  const months = days/30;            if(months  < 24)  return `${Math.round(months)} months`;
  const years = days/365;                              return `${Math.round(years)} years`;
}

//-------------------------------------------------------------------------------
// Shows the number of seconds as a more friendly and short time span.
//
// This version takes up as little space as possible (at most 6 chars).
//-------------------------------------------------------------------------------
export function FriendlyTimeSpan_Short(milliseconds: number | undefined): string | undefined
{
  if(!milliseconds) return undefined;

  if(milliseconds < 1000) return `${Math.round(milliseconds)} ms`;
  
  const seconds = milliseconds/1000; if(seconds < 120) return `${Math.round(seconds)} s`;
  const minutes = seconds/60;        if(minutes < 120) return `${Math.round(minutes)} m`;
  const hours = minutes/60;          if(hours   < 48)  return `${Math.round(hours)} h`;
  const days = hours/24;             if(days    < 14)  return `${Math.round(days)} d`;
  const weeks = days/7;              if(weeks   < 8)   return `${Math.round(weeks)} w`;
  const months = days/30;            if(months  < 24)  return `${Math.round(months)} m`;
  const years = days/365;                              return `${Math.round(years)} y`;
}

//-------------------------------------------------------------------------------
// Returns a friendly file size.
//-------------------------------------------------------------------------------
export function FriendlyFileSize(sizeBytes: number | undefined): string | undefined
{
  if(!sizeBytes) return undefined;

  const b  = sizeBytes;  if(b  < 2*1024) return `${Math.round(b)} bytes`;
  const kb = b/1024;     if(kb < 2*1024) return `${Math.round(kb)} KB`;
  const mb = kb/1024;    if(mb < 2*1024) return `${Math.round(mb)} MB`;
  const gb = mb/1024;    if(gb < 2*1024) return `${Math.round(gb)} GB`;
  const tb = gb/1024;                    return `${Math.round(tb)} TB`;
}

//-------------------------------------------------------------------------------
// Returns a friendly file size.
//
// This version takes up as little space as possible (at most 6 chars).
//-------------------------------------------------------------------------------
export function FriendlyFileSize_Short(sizeBytes: number | undefined): string | undefined
{
  if(!sizeBytes) return undefined;

  const b  = sizeBytes;  if(b < 1000) return `${Math.round(b)} bytes`;
  const kb = b/1024;     if(kb < 1000) return `${Math.round(kb)} KB`;
  const mb = kb/1024;    if(mb < 1000) return `${Math.round(mb)} MB`;
  const gb = mb/1024;    if(gb < 1000) return `${Math.round(gb)} GB`;
  const tb = gb/1024;                  return `${Math.round(tb)} TB`;
}

//-------------------------------------------------------------------------------
// Formats the date to a standard/friendly format (ie: Jan 25, 2023)
//-------------------------------------------------------------------------------
export function FriendlyDate(date: Date)
{
  return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
}

//-------------------------------------------------------------------------------
// Shows how long ago the app was last updated.
//-------------------------------------------------------------------------------
export function GetAppLastUpdateInfo(): string | undefined
{
  const appDeployDate = new Date(appBuildDateTimeStamp);
  let timeSpanMS: number = (new Date().getTime() - appDeployDate.getTime());
  timeSpanMS += 4*3600*1000;  // temp - add 5 hours as apparently timezone doesn't work when app is deployed?
  return FriendlyTimeSpan(timeSpanMS);
}

//-------------------------------------------------------------------------------
// Reformats the date string to a standard/friendly format (ie: Jan 25, 2023)
//-------------------------------------------------------------------------------
export function FriendlyDateFromStr(dateStr: string)
{
  return FriendlyDate(new Date(dateStr));
}

//-------------------------------------------------------------------------------
// Capitalize every word in the specified string (with everything else lower case).
//-------------------------------------------------------------------------------
export const CapitalizeAllWords = (str: string | undefined) => 
{
  if(!str || str?.length === 0) return undefined;
  str = str.toLocaleLowerCase();

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

//-------------------------------------------------------------------------------
// Returns a friendly version of the specified lat/lng coords.
//
// Eg: 40°N, 74°W  (NYC)
//-------------------------------------------------------------------------------
export function FriendlyCoords(latitude: number, longitude: number): string
{
  const latStr = Math.abs(latitude).toFixed(3) + ' °' + (latitude >= 0 ? 'N' : 'S');
  const lngStr = Math.abs(longitude).toFixed(3) + ' °' + (longitude >= 0 ? 'E' : 'W');
  return latStr + ', ' + lngStr;
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified string is a URL.
//-------------------------------------------------------------------------------
export function IsURL(urlStr: string): boolean
{
  let url;
  try 
  {
    url = new URL(urlStr);
  } 
  catch (_) 
  {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified value is a number.
//-------------------------------------------------------------------------------
export function IsNumber(value: any): boolean
{
   return value !== undefined && isFinite(value);
}

//-------------------------------------------------------------------------------
// Returns a CSV-save/escaped version of the specified value.
//-------------------------------------------------------------------------------
export function GetEscapedCSVValue(value: string | undefined): string
{
  if(!value) return '';

  return '"' + value.replace(/"/g,'""') + '"';
}

//-------------------------------------------------------------------------------
// Auto-detects urls in a string and converts them to proper MUI Link elements.
// NOTE: The links are created to always open in a new browser tab.
//-------------------------------------------------------------------------------
export function AutoDetectURLsAndReplaceWithLinks(text: string, colorStr: string = theme_textColorBlended)
{
  if(!text) return '';

  // eslint-disable-next-line
  const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  return (
    <>
      {text.split(delimiter).map(word => 
      {
        const match = word.match(delimiter);
        if (match) 
        {
          const url = match[0];
          return (
            <Link sx={{ color: colorStr }} href={url.startsWith('http') ? url : `http://${url}`} target="_blank" rel="noopener noreferrer">{url}</Link>
          );
        }
        return word;
      })}
    </>
  )
}

export interface IImageDimensions
{
  width: number;
  height: number;
}

//-------------------------------------------------------------------------------
// Get width/height of an image file that is about to be uploaded.
//-------------------------------------------------------------------------------
export const GetImageDimensionsForFile = (file: File) => new Promise<IImageDimensions>(resolve => 
{
  const img = new Image()
  img.onload = () => 
  {
    resolve(
    {
      height: img.height,
      width: img.width
    })
  }
  img.src = window.URL.createObjectURL(file);
})

//-------------------------------------------------------------------------------
// Interpolates 2 colors.
//
// Format of both colors must be #RRGGBB (no alpha).
//-------------------------------------------------------------------------------
export function InterpolateColors(color1: string, color2: string, percent: number): string | undefined
{
  // Validate color format

  color1 = color1.trim();
  if(color1.length !== 7 || color1.substring(0,1) !== '#') return undefined;

  color2 = color2.trim();
  if(color2.length !== 7 || color2.substring(0,1) !== '#') return undefined;

  // Convert the hex colors to RGB values
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  // Interpolate the RGB values
  const r = Math.round(r1 + (r2 - r1) * percent);
  const g = Math.round(g1 + (g2 - g1) * percent);
  const b = Math.round(b1 + (b2 - b1) * percent);

  // Convert the interpolated RGB values back to a hex color
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

//-------------------------------------------------------------------------------
// Returns a random color (in #RRGGBB hex format)
//-------------------------------------------------------------------------------
export function GenerateRandomHexColor(): string 
{
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++)
    color += letters[Math.floor(Math.random() * 16)];

  return color;
}

export function HexToRgb(hex: string): { r: number; g: number; b: number } | null 
{
  const sanitizedHex = hex.replace("#", "");

  if (!/^[0-9A-F]{3}$|^[0-9A-F]{6}$/i.test(sanitizedHex))
    return null;

  const hexValue = sanitizedHex.length === 3 ?
      sanitizedHex[0] + sanitizedHex[0] + sanitizedHex[1] + sanitizedHex[1] + sanitizedHex[2] + sanitizedHex[2] :
      sanitizedHex;

  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return { r, g, b };
}

/*
  // NOTE: This works, but sometimes colors are still somewhat visually close.
  // (AI-generated).
  //
  // Switched to the 'iwanthue' external npm package, seems to work better.

  function GenerateRandomButDistinctHexColors(numColors: number): string[]
  {
    const colors: string[] = [];
    const goldenRatio = 0.618033988749895; // Use golden ratio to space colors
  
    for (let i = 0; i < numColors; i++) 
    {
      const hue = (i * goldenRatio) % 1; // Distribute hues evenly
      const saturation = 0.5 + Math.random() * 0.5; // Vary saturation
      const lightness = 0.5 + Math.random() * 0.5; // Vary lightness
  
      const rgb = hslToRgb(hue, saturation, lightness);
      const hex = rgbToHex(rgb[0], rgb[1], rgb[2]);
      colors.push(hex);
    }
  
    return colors;
  }
  
  function hslToRgb(h: number, s: number, l: number): [number, number, number] 
  {
    let r, g, b;
  
    if (s === 0) 
    {
      r = g = b = l; // achromatic
    } 
    else 
    {
      const hue2rgb = (p: number, q: number, t: number) => 
        {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1/6) return p + (q - p) * 6 * t;
          if (t < 1/2) return q;
          if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
        };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
  
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }
  
  function rgbToHex(r: number, g: number, b: number): string 
  {
    const componentToHex = (c: number) => 
      {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
  
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }
*/