// Mapbox map component

import { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MapOptions } from "mapbox-gl";
import useStore from "../store";
import Debug from "../Debug";
import { AddOrgBoundaryLayerToMap, DefaultMapView } from "../Projects/ProjectOps";
import { AddAoiLayerToMap, onMapboxDrawChange } from "../Aois/AoiOps";
import { EnableMapbox3DTerrain, MapClick, UpdateMapCompassState, UpdateMapGeolocateState, UpdateMapRulerState, UpdateMapScaleState } from "./MapOps";
import { GetMapboxStyleForBaseMap } from "../BaseMapPanel";
import { convert } from 'geo-coordinates-parser'

// NOTE: Disabling the official CSS version and using a local customized copy instead (customizes the colors of the search box)
import '../mapbox-gl-geocoder.css';
//import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

//import { MapboxExportControl, Size, PageOrientation, Format, DPI } from "@watergis/mapbox-gl-export";
//import '@watergis/mapbox-gl-export/css/styles.css'; // HAD TO COMMENT THIS OUT WHEN UPDATED mapbox-gl to 3.2.0
//import 'mapbox-gl-controls/lib/controls.css';

import 'mapbox-gl/dist/mapbox-gl.css';
	import {
		MapboxExportControl,
		Size,
		PageOrientation,
		Format,
		DPI
	} from '@watergis/mapbox-gl-export';
	import '@watergis/mapbox-gl-export/dist/mapbox-gl-export.css';


//import { RulerControl } from 'mapbox-gl-controls'; // deprecated, switched to new ruler
import RulerControl from '@mapbox-controls/ruler';
import '@mapbox-controls/ruler/src/index.css';
import toCoordinateFormat from "geo-coordinates-parser/dist/cjs/toCoordinateFormat";
import { AddLayerToMap, RemoveAllLayersAndSourcesFromMap, SetBaseMapBundleLayerVisibility } from "../Layers/LayerOps";
import { IBaseMapLayer } from "../Layers/LayersPanel";
import { IProject } from "../Projects/ProjectInterfaces";
import { AOI_PORTFOLIO_MAP_AOI_ID } from "../Aois/Aois";
import { UpdateAoiPortfolioMap } from "../Aois/AoiGroupAndPortfolioMap/PortfolioMapOps";
import { EnterParcelsMode, ExitParcelsMode, GetParcelLayer, RemoveParcelSelectionLayerFromMap, UpdateParcelSelectionLayerOnMap } from "../Parcels/ParcelOps";
import { ILayer } from "../Layers/LayerInterfaces";


export interface IMapView
{
  lng: number,
  lat: number,
  zoom: number,
  pitch: number,
  bearing: number,
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface MapProps
{
  //coords: IMapView,
  //setCoords: React.Dispatch<React.SetStateAction<IMapView>>;
}


//-------------------------------------------------------------------------------
// Mapbox wrapper component
//-------------------------------------------------------------------------------
const Map = (props: MapProps) =>
{
  const mapContainer = useRef<any>(null);

  // Get needed state data from the store
  const { store_setMap, store_setMapSearchControl, store_baseMap, 
          store_setProjectMapView, store_setProjectIsDirty, 
        } = useStore();



  //-------------------------------------------------------------------------------
  // Map initialization (runs only once).
  //-------------------------------------------------------------------------------
  useEffect(() =>
  {
    Debug.log('Map> useEffect init');

    const mapboxAccessToken: string = process.env.REACT_APP_MAPBOX_TOKEN!;

    const defaultMapView = DefaultMapView();

    const mbOptions = {} as MapOptions;
    mbOptions.accessToken = mapboxAccessToken;
    mbOptions.container = mapContainer.current;
    mbOptions.style = GetMapboxStyleForBaseMap(store_baseMap);
    mbOptions.center = [defaultMapView.lng, defaultMapView.lat];
    mbOptions.zoom = defaultMapView.zoom;
    mbOptions.pitch = defaultMapView.pitch;
    mbOptions.bearing = defaultMapView.bearing;
    mbOptions.boxZoom = false;
    mbOptions.minZoom = 1.3;

    mbOptions.locale = 
    {
      //'AttributionControl.ToggleAttribution': 'Toggle attribution',
      //'AttributionControl.MapFeedback': 'Map feedback',
      //'FullscreenControl.Enter': 'Enter fullscreen',
      //'FullscreenControl.Exit': 'Exit fullscreen',
      'GeolocateControl.FindMyLocation': 'Find my location',
      //'GeolocateControl.LocationNotAvailable': 'Location not available',
      //'LogoControl.Title': 'Mapbox logo',
      //'Map.Title': 'Map',
      'NavigationControl.ResetBearing': 'Reset bearing to north and pitch to 2D/overhead',
      // 'NavigationControl.ZoomIn': 'Zoom in',
      // 'NavigationControl.ZoomOut': 'Zoom out',
      //'ScrollZoomBlocker.CtrlMessage': 'Use ctrl + scroll to zoom the map',
      //'ScrollZoomBlocker.CmdMessage': 'Use ⌘ + scroll to zoom the map',
      //'TouchPanBlocker.Message': 'Use two fingers to move the map'      
    }    

    //mbOptions.maxBounds = [[-87.634938,24.523096],[-80.031362,31.000888]];  // Florida
    // mbOptions.projection =
    //   {
    //     name: 'albers',
    //     center: [-154, 50],
    //     parallels: [55, 65]
    //   }

    // Create the mapbox control
    const newMap = new mapboxgl.Map(mbOptions);

    // Register the mapbox control with the store
    store_setMap(newMap);

    // Add the geocoder to the map

    //const x: MapboxGeocoder.GeocoderOptions = {};

    const newMapSearchControl = new MapboxGeocoder(
    {
      accessToken: mapboxAccessToken,
      placeholder: "Search for a place", 
      //zoom: 4,

      //collapsed: true,
      clearOnBlur: false,
      
      //autocomplete: 
      //fuzzyMatch: 
      //limit: 
      //bbox: [-105.214, 40.451, -104.850, 40.841]
      //country: 

      // NOTE Sept 16 2024 - updated Mapbox, and it broke markers in the geocoder plugin.
      //      For now, turning off the marker feature to get things working.

// TEMP: Ignoring this error for now - due to mapbox switching internally to TS
// @ts-expect-error
      mapboxgl: mapboxgl,
      marker: true,

      localGeocoder: coordinatesGeocoder,
    });

    // Add the geocoder search box to the map
    newMap.addControl(newMapSearchControl, 'bottom-left');

    // Register the map search control with the store
    store_setMapSearchControl(newMapSearchControl);

    // MAP SCALE control
    UpdateMapScaleState(true, 'imperial');


    // Distance measuring control
/*
    const measureControl: MapboxDraw = new MapboxDraw(
      {
        displayControlsDefault: false,
        controls:
        {
          line_string: true,
          trash: false
        },
        boxSelect: false,
        // defaultMode: 'draw_polygon'
        // keybindings: "false",
        // userProperties: true,
        // modes: {
        //   ...MapboxDraw.modes
        // }
        // modes: Object.assign({
        //   direct_select: DrawRectangle
        // }, MapboxDraw.modes)
        //styles: []
      });
  
      newMap.addControl(measureControl, 'bottom-right');
*/

    // GEOLOCATE control
    UpdateMapGeolocateState(true);

    // COMPASS control
    UpdateMapCompassState(true);

    // RULLER control
    UpdateMapRulerState(true, 'imperial');

    // Export control

    // NOTE: Diabling this for now - it's no longer working correctly after upgrading to mapbox-gl 3.2.0
    const exportControl: MapboxExportControl = new MapboxExportControl(
      {
        accessToken: mapboxAccessToken,
        //PageSize: [420, 297], //Size.A3,
// @ts-expect-error
PageSize: Size.A3,
        PageOrientation: PageOrientation.Portrait,
        Format: Format.PNG,
        DPI: DPI[300],
        Crosshair: true,
        PrintableArea: true,
      });

// @ts-expect-error
newMap.addControl(exportControl, 'bottom-right');




    //newMap.current.addControl(new mapboxgl.FullscreenControl());



    // NOTE: For now, the draw control is auto-added when the AOI accordion
    //       is expanded, and auto-removed when it's collapsed.  This is for
    //       performance reasons, and to keep the map surface clear when the
    //       draw tool is not needed.
    //AddDrawControlToMap();




    // Register draw control events (for AOI editing)

    newMap.on('draw.create', onMapboxDrawChange);
    newMap.on('draw.delete', onMapboxDrawChange);
    newMap.on('draw.update', onMapboxDrawChange);

    // Should be called after the base map was changed (and the map is ready)
    newMap.on('style.load', OnMapboxStyleChanged);
    //newMap.on('styledata', OnMapboxStyleChanged);

    // Register map move event
    //
    // NOTE: Using 'move' incurs a big performance hit - moveend is much more efficient

    //newMap.on('move', () =>
    newMap.on('moveend', () =>
    {
      //Debug.log("Map> useEffect map on moveend")

      const updatedMapView: IMapView =
      {
        lng: newMap.getCenter().lng,
        lat: newMap.getCenter().lat,
        zoom: newMap.getZoom(),
        pitch: newMap.getPitch(),
        bearing: newMap.getBearing(),
      }

      store_setProjectMapView(updatedMapView);
      store_setProjectIsDirty(true);
    });

    // Register map click (any layer).

    newMap.on("click", async (e) =>
    {
      MapClick(e.lngLat.lng, e.lngLat.lat, e.originalEvent.ctrlKey || e.originalEvent.metaKey);
    });

  // eslint-disable-next-line
  }, []); // called only once



  // function registerControlPosition(map: mapboxgl.Map, positionName: string)
  // {
  //   if (!map || map.contr._controlPositions[positionName]) return;

  //   var positionContainer = document.createElement('div');
  //   positionContainer.className = `mapboxgl-ctrl-${positionName}`;
  //   map._controlContainer.appendChild(positionContainer);
  //   map._controlPositions[positionName] = positionContainer;
  // }

  // function onMapboxDrawChange(e: any)
  // {

  //   const x = 1;

  // }







/*
  //-------------------------------------------------------------------------------
  // Map mouse move/leave events for 'layer-3' (TEMP)
  //-------------------------------------------------------------------------------
  useEffect(() =>
  {
    if (!store_map) return;

    // topp-states-wms-geojson
    // philly-pop-geojson-2d
    // stratifyx-wyoming-wms-png
    store_map.on("click", 'stratifyx-wyoming-wms-png', (e) =>
    {
      if(e.features === undefined) return;

      // For some reason this event fires off 4 times per click (don't know why yet).
      // For now, we only allow the event to be processed if the user clicked a feature
      // different from the previous click.
      if(e.features[0].id === lastClickedFeatureID) return;
      lastClickedFeatureID = e.features[0].id;

      //const coordinates = e.features[0].geometry.coordinates.slice();
      const description = JSON.stringify(e.features[0].properties, null, 2);

      props.setMapInfo(description);

      // new mapboxgl.Popup()
      // //.setLngLat(coordinates)
      // .setHTML(description)
      // .addTo(store_map);

      Debug.log(e.features[0].properties);
    });

  //-------------------------------------------------------------------------------
  // Map mouse move/leave events for 'layer-3' (TEMP)
  //-------------------------------------------------------------------------------
  useEffect(() => {
    if (!store_map) return; // wait for map to initialize

    let hoveredStateId : string | number | undefined = undefined;

  //   map.current.on("mousemove", function(e) {
  //     //const x = 1;
  //     var features = map.current?.queryRenderedFeatures(e.point, { layers: ["layer-3"] });
  //     if (features !== undefined && features.length) {
  //       const x = 1;
  //       //map.current?.getCanvas().style.cursor = 'pointer';
  //       //map.setFilter("state-fills-hover", ["==", "name", features[0].properties.name]);
  //     } else {
  //         //map.setFilter("state-fills-hover", ["==", "name", ""]);
  //         //map.getCanvas().style.cursor = '';
  //     }
  // });

    const source = '96'; //'parcel_alabama';
    const layerName = '96'; //'parcel_alabama';


    // When the user moves their mouse over the specified layer, we'll update the
    // feature state for the feature under the mouse.
    store_map.on('mousemove', layerName, (e) => 
    {
      //var features = map.current?.queryRenderedFeatures(e.point, { layers: ["layer-3"] });

      if (e.features !== undefined && e.features.length > 0) 
      {
        if (hoveredStateId !== undefined) 
        {
          store_map.setFeatureState(
            { source: source, id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        store_map.setFeatureState(
          { source: source, id: hoveredStateId },
          { hover: true }
        );
      }
    });

    // When the mouse leaves the specified layer, update the feature state of the
    // previously hovered feature.
    store_map.on('mouseleave', layerName, (e) => 
    {
      if (hoveredStateId !== undefined) 
      {
        store_map.setFeatureState(
          { source: source, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = undefined;
    });


    // store_map.on("click", layerName,  e => 
    // {
    //   if(e.features === undefined) return;
    //   const feature = e.features[0];

    //   // NOTE: Seems to fire off once per layer and/or per feature!

    //   const prop1 = feature.properties?.COUNT_ALL_RACES_ETHNICITIES;
    //   //Debug.log(feature.properties);
    //   Debug.log(prop1);

    //   // xxx

    // });
  });
*/

//  map.current?.on('style.load', function () {
      // Triggered when setStyle is called.

      // NOTE: When the style changes (the base map), we need to auto-re-add
      //       any layers that were previously selected.

      //ReapplyAllActiveLayersToMap(layers2, map);

      //Debug.log("style.load> layer1enabled: %b | layer2enabled: %b", layer1enabled, layer2enabled);
      //DebugOut('style.load> 1');

      // if(layer1enabled)
      //     AddPhillyBloodLayer(true);
      // if(layer2enabled)
      //     AddPhillyBoatLayer(true);

  //});


  //var polygonID : string | number | undefined = '';

  // map.current?.on("click", e => {
  // });

  // map.current?.on('click', 'polygon', (e) => {
  //   if(!e.features) return;

  //   //map.current?.getCanvas().style.cursor = 'pointer';
  //   if (e.features.length > 0) {
  //     if (polygonID) {
  //       map.current?.removeFeatureState({
  //         source: "polygon",
  //         id: polygonID
  //       });
  //     }

  //     polygonID = e.features[0].id;

  //     map.current?.setFeatureState({
  //       source: 'polygon',
  //       id: polygonID,
  //     }, {
  //       clicked: true
  //     });
  //   }
  // });


  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  // map.current?.on('mouseleave', 'state-fills', () => {
  // if (hoveredStateId !== null) {
  // map.current?.setFeatureState(
  // { source: 'states', id: hoveredStateId },
  // { hover: false }
  // );
  // }
  // hoveredStateId = null;
  // });



  // Main render

  return (
    <div ref={mapContainer} style={{ height: '100%', width: '100%' }} />
    )
}

//-------------------------------------------------------------------------------
// 
//-------------------------------------------------------------------------------
function GetCoordinateFeature(lng: number, lat: number): any
{
  return { center: [lng, lat],
           geometry: 
           {
             type: 'Point',
             coordinates: [lng, lat]
           },
           place_name: 'Lat: ' + lat + ' Lng: ' + lng,
           place_type: ['coordinate'],
           properties: {},
           type: 'Feature',
           
          //  bbox: [-285.901866,-78.896501,308.238759,84.774044], // ? forced to initialize these by Typescript ?
          //  relevance: 0, // ? forced to initialize these by Typescript ?
          //  text: '', // ? forced to initialize these by Typescript ?
          //  address: '', // ? forced to initialize these by Typescript ?
          //  context: [], // ? forced to initialize these by Typescript ?
         }
}

interface IGeoCoordsParser
{
  verbatimCoordinates: string;
  decimalCoordinates: string;
  decimalLatitude: number;
  decimalLongitude: number;
  closeEnough: (arg0: string) => boolean;
  toCoordinateFormat: typeof toCoordinateFormat;
}

//-------------------------------------------------------------------------------
// Given a query in the form "lng, lat" or "lat, lng" returns the matching geographic 
// coordinate(s) as search results in carmen geojson format.
//
// https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
//-------------------------------------------------------------------------------
const coordinatesGeocoder = function (query: string): any
{
  let converted: IGeoCoordsParser;
  try 
  {
    converted = convert(query);
  }
  catch 
  {
    /*we get here if the string is not valid coordinates or format is inconsistent between lat and long*/
    return;
  }

  const geocodes: MapboxGeocoder.Result[] = [];
  geocodes.push(GetCoordinateFeature(converted.decimalLongitude, converted.decimalLatitude));
  return geocodes;

/*
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match( /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i );
  if (!matches)
    return [];//null;
   
  function coordinateFeature(lng: number, lat: number): any
  {
    return { center: [lng, lat],
             geometry: 
             {
               type: 'Point',
               coordinates: [lng, lat]
             },
             place_name: 'Lat: ' + lat + ' Lng: ' + lng,
             place_type: ['coordinate'],
             properties: {},
             type: 'Feature',
             
            //  bbox: [-285.901866,-78.896501,308.238759,84.774044], // ? forced to initialize these by Typescript ?
            //  relevance: 0, // ? forced to initialize these by Typescript ?
            //  text: '', // ? forced to initialize these by Typescript ?
            //  address: '', // ? forced to initialize these by Typescript ?
            //  context: [], // ? forced to initialize these by Typescript ?
           }
  }
   
  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes: MapboxGeocoder.Result[] = [];
   
  if (coord1 < -90 || coord1 > 90) 
  {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }
   
  if (coord2 < -90 || coord2 > 90) 
  {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }
   
  if (geocodes.length === 0) 
  {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }
   
  return geocodes;
*/
}

//-------------------------------------------------------------------------------
// Called by Mapbox after a style change, which wipes out all Mapbox custom layers.
// When this is called, it is assumed the new base map has finished loading and is
// fully ready for operations. 
//-------------------------------------------------------------------------------
function RebuildAllMapboxLayers()
{
  // NOTE: This will also be called when loading a project in for the first time,
  //       which we generally don't want.  Since the map will load before the project
  //       does, this will cause that initial call to be skipped.
  const store_project: IProject | null = useStore.getState().store_project;
  if(!store_project) return;

  // If any of the base map sub-layer groups were turned off, need to remove them again from 
  // this new base map so everything stays synced up.

  const store_baseMapLayer_roads: IBaseMapLayer = useStore.getState().store_baseMapLayer_roads;
  const store_baseMapLayer_labels: IBaseMapLayer = useStore.getState().store_baseMapLayer_labels;
  const store_baseMapLayer_states: IBaseMapLayer = useStore.getState().store_baseMapLayer_states;
  const store_baseMapLayer_water: IBaseMapLayer = useStore.getState().store_baseMapLayer_water;

  if(store_baseMapLayer_roads?.enabled === false)
    SetBaseMapBundleLayerVisibility('roads', false);
  if(store_baseMapLayer_labels?.enabled === false)
    SetBaseMapBundleLayerVisibility('labels', false);
  if(store_baseMapLayer_states?.enabled === false)
    SetBaseMapBundleLayerVisibility('states', false);
  if(store_baseMapLayer_water?.enabled === false)
    SetBaseMapBundleLayerVisibility('water', false);

  // Re-enable 3D terrain (if it was on before)

  const store_threeDTerrain = useStore.getState().store_threeDTerrain;
  if(store_threeDTerrain === true)
      EnableMapbox3DTerrain();

  // Restore all the active user layers

  RemoveAllLayersAndSourcesFromMap();

  // NOTE: They are added just below the base maps symbol layers ('admin-1-boundary-bg')
  for(let i=0; i < store_project.user_settings.layerStates.layers.length; i++)
    if(store_project.user_settings.layerStates.layers[i].enabled)
      AddLayerToMap(store_project.user_settings.layerStates.layers[i].layer_id, false);

  // Restore parcels layer

  // If the parcel layer was enabled before the base map switch, re-add the parcel layer 
  // to the map (on top of every other user layer, but just below the base maps symbol 
  // layers ('admin-1-boundary-bg')).

  if(useStore.getState().store_parcelEditMode === true)
  {
    const parcelLayer : ILayer | undefined = GetParcelLayer();
    if(parcelLayer)
      AddLayerToMap(parcelLayer.id);
  }

  // Restore parcel selection layers

  UpdateParcelSelectionLayerOnMap();

  // Restore the AOI / Portfolio Map layers

  const store_aoi = useStore.getState().store_aoi;
  if(store_aoi)
  {
    if(store_aoi.aoi_id === AOI_PORTFOLIO_MAP_AOI_ID)
      UpdateAoiPortfolioMap();
    else
      AddAoiLayerToMap();
  }

  // Restore the org boundary (if there is one)

  AddOrgBoundaryLayerToMap();
}

//-------------------------------------------------------------------------------
// Called by Mapbox after a style change, which wipes out all Mapbox custom layers.
// When this is called, it is assumed the new base map has finished loading and is
// fully ready for operations. 
//-------------------------------------------------------------------------------
function OnMapboxStyleChanged()
{
  RebuildAllMapboxLayers();
}

export default Map;