// Portfolio map color scheme editor

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import useStore from "../../../store";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_errorRed, theme_limeGreen, theme_bgColorMain, theme_orange, theme_bgColorLight } from "../../../Theme";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { CustomTextField } from "../../../LayerLibrary/EditLayer/EditLayer";
import { CustomSelect, MuiColorInputStyled } from "../../../LayerLibrary/EditLayer/EditLayerStyle";
import { MuiColorInputColors } from "mui-color-input";
import { IPortfolioMapColorScheme, IPortfolioMapProperties, IColorSchemeType, PORTFOLIO_MAP_FILTERED_OUT_DEFAULT_COLOR, IPortfolioMapColorScheme_GradientItem, IPortfolioMapColorScheme_SingleColor, IPortfolioMapColorScheme_Gradient, IPortfolioMapColorScheme_Classified } from "../PortfolioMapInterfaces";
import { IAoiGroupProperties } from "../AoiGroupInterfaces";
import { GetAoiAttributeMinValue, GetAoiAttributeMaxValue } from "../AoiGroupOps";
import { FriendlyNumber } from "../../../Globals";
import { AoiAttribExpressionEditor } from "../AoiAttribExpressionEditor";
import TypographyWithAutoTooltip from "../../../Components/TypograpyWithAutoTooltip";
import { GetAoiAttribute, GetAoiAttribUniqueValues } from "../AoiAttributeOps";
import { ToastNotification } from "../../../ToastNotifications";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { ColorSchemeEditor_Gradient, CreateColorGradientNoColors, ValidateColorScheme_Gradient } from "./ColorSchemeEditor_Gradient";
import { ColorSchemeEditor_UniqueValues, RegenerateUniqueValues, ValidateColorScheme_UniqueValues } from "./ColorSchemeEditor_SingleValue";
import { ColorSchemeEditor_Classified, CreateClassifiedRanges, NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_CLASS_COUNT, NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_PRESET_ID, ValidateColorScheme_Classified } from "./ColorSchemeEditor_Classified";
import { GetGradientPreset } from "./ColorGradientPresets";



export const PORTFOLIO_MAP_DEFAULT_COLOR_SCHEME_ID = 0; // the ID of the default menu item when selecting color schemes (colors are set manually for each AOI)


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface PortfolioMapColorSchemeEditorProps
{
  aoiGroupProps: IAoiGroupProperties|undefined;
  setAoiGroupProps: any;
  editColorSchemeID: number | undefined;    // If this is undefined, we are creating a NEW color scheme, otherwise we are editing an existing one
  setEditColorSchemeID: any; // Controls which color scheme is being edited by this component
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Portfolio map color scheme editor
//-------------------------------------------------------------------------------
export function PortfolioMapColorSchemeEditor(props: PortfolioMapColorSchemeEditorProps) 
{
  // Get needed state data from the store
  const { store_editAoiGroupProperties, 
        } = useStore();


  const [localColorScheme, setLocalColorScheme] = useState<IPortfolioMapColorScheme|undefined>(undefined);
  const [localChangesWereMade, setLocalChangesWereMade] = useState<boolean>(false);
  const [showAoiExpressionEditor, setShowAoiExpressionEditor] = useState<boolean>(false);









  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initializes the local data
    if(props.aoiGroupProps?.portfolio_map.color_schemes !== undefined)
    {
      if(props.editColorSchemeID === -1) // -1 is a special value signaling creation of a new color scheme
      {
        // CREATE NEW mode

        let maxID: number = 0;
        for(let i=0; i < props.aoiGroupProps.portfolio_map.color_schemes.length; i++)
          if(props.aoiGroupProps.portfolio_map.color_schemes[i].id > maxID)
            maxID = props.aoiGroupProps.portfolio_map.color_schemes[i].id;

        const newColorScheme: IPortfolioMapColorScheme = 
        {
          id: maxID + 1,
          name: '',
          type: 'single color',
          single_color: { color: '' },
          filtered_out_color: PORTFOLIO_MAP_FILTERED_OUT_DEFAULT_COLOR
        }
        setLocalColorScheme(newColorScheme);
        setLocalChangesWereMade(true);
      }
      else if(props.editColorSchemeID !== undefined)
      {
        // EDIT mode

        const colorScheme: IPortfolioMapColorScheme|undefined = props.aoiGroupProps?.portfolio_map.color_schemes.find(s => s.id === props.editColorSchemeID);
        setLocalColorScheme(colorScheme);
      }
    }

  }, [props.editColorSchemeID]);

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setEditColorSchemeID(undefined);
    setLocalColorScheme(undefined);
    setLocalChangesWereMade(false);
  }

  //-------------------------------------------------------------------------------
  // Apply changes and close.
  //-------------------------------------------------------------------------------
  const OnAcceptChanges = async () => 
  {
    // Validation

    if(!localColorScheme || !props.aoiGroupProps)
       return;    

    if(!localColorScheme.name || localColorScheme.name.trim().length === 0)
    {
      ToastNotification('error', 'The color scheme name cannot be empty');
      return;
    }

    // Make sure a color scheme with this name doesn't already exist
    const foundColorScheme: IPortfolioMapColorScheme | undefined = props.aoiGroupProps.portfolio_map.color_schemes.find(cs => cs.id !== localColorScheme.id && cs.name.trim().toLowerCase() === localColorScheme.name.trim().toLowerCase())
    if(foundColorScheme !== undefined)
    {
      ToastNotification('error', 'A color scheme with that name already exists');
      return;
    }

    // Validate attribute (required for all color schemes except Single Color)

    if(localColorScheme.type !== 'single color' && localColorScheme.aoi_attribute_id === undefined)
    {
      ToastNotification('error', 'An attribute has not been selected');
      return;
    }

    // Validate based on the active color scheme
    
    if(localColorScheme.type === 'single color' && 
      (localColorScheme.single_color === undefined || localColorScheme.single_color.color === undefined || localColorScheme.single_color.color === ''))
    {
      ToastNotification('error', 'A color has not been selected');
      return;
    }

    if(localColorScheme.type === 'unique values' && ValidateColorScheme_UniqueValues(localColorScheme) === false)
      return;
    if(localColorScheme.type === 'classified' && ValidateColorScheme_Classified(localColorScheme) === false)
      return;
    if(localColorScheme.type === 'gradient' && ValidateColorScheme_Gradient(localColorScheme) === false)
      return;

    // If changes were made, save the changes to the local copy.
    // (that is passed in through param mechanism).

    if(localChangesWereMade === true)
    {
      let new_portfolio_map: IPortfolioMapProperties;

      if(props.editColorSchemeID === -1)
      {
        // CREATE NEW MODE

        // Add the new color scheme
        new_portfolio_map = 
        {
          ...props.aoiGroupProps.portfolio_map,
          active_color_scheme_id: localColorScheme.id, // this auto-selects the new color scheme
          color_schemes: [...props.aoiGroupProps.portfolio_map.color_schemes, localColorScheme]
        }
      }
      else
      {
        // EDIT MODE

        // Replace the color scheme with the new updated version
        new_portfolio_map = 
        {
          ...props.aoiGroupProps.portfolio_map,
          color_schemes: props.aoiGroupProps.portfolio_map.color_schemes.map(oldColorScheme => oldColorScheme.id === localColorScheme.id ? localColorScheme : oldColorScheme)
        }
      }

      const newLocalAoiGroupProps: IAoiGroupProperties = 
      {
        ...props.aoiGroupProps,
        portfolio_map: new_portfolio_map
      }

      props.setAoiGroupProps(newLocalAoiGroupProps);
      props.setChangesWereMade(true);
    }

    // Close the window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // The color scheme text field has changed.
  //-------------------------------------------------------------------------------
  function OnColorSchemeNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localColorScheme) return;

    const newValueStr: string = event.target.value;

    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      name: newValueStr
    }

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The color scheme type combo box was changed.
  //-------------------------------------------------------------------------------
  const OnColorSchemeTypeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(!localColorScheme) return;

    const newTypeStr: IColorSchemeType = event.target.value as IColorSchemeType;

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      type: newTypeStr
    }

    // Initialize the appropriate json key depending on the selected color scheme type

    if(newTypeStr === 'single color') 
      new_color_scheme.single_color = { color: '' }
    else if(newTypeStr === 'unique values') 
      new_color_scheme.unique_values = { items: [] };
    else if(newTypeStr === 'classified') 
      new_color_scheme.classified = { items: [] };
    else if(newTypeStr === 'gradient')
        new_color_scheme.gradient = { id: 1, items: [] };
    else  // This should never happen
      return null;
        
    // Delete json keys used for the old type

    if(newTypeStr !== 'single color') delete new_color_scheme.single_color;
    if(newTypeStr !== 'unique values') delete new_color_scheme.unique_values;
    if(newTypeStr !== 'classified') delete new_color_scheme.classified;
    if(newTypeStr !== 'gradient') delete new_color_scheme.gradient;

    // Reset the selected attribute
    delete new_color_scheme.aoi_attribute_id;

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The color scheme AOI attribute combo box was changed.
  //-------------------------------------------------------------------------------
  const OnAoiAttributeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(!localColorScheme) return;

    const newAttributeID: number = event.target.value as number;

    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      aoi_attribute_id: newAttributeID
    }

    // For Unique Values mode, we initialize the list based on each unique value of this attribute
    
    if(new_color_scheme.type === 'unique values' && new_color_scheme.unique_values !== undefined)
      new_color_scheme.unique_values = RegenerateUniqueValues(props.aoiGroupProps?.attributes, newAttributeID, true);

    // For classified, we default to a 4-class Yellow-Orange-Red preset

    if(new_color_scheme.type === 'classified' && new_color_scheme.classified)
    {
      const gradient_preset: IPortfolioMapColorScheme_Gradient | undefined = GetGradientPreset(NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_PRESET_ID);
      const new_classified: IPortfolioMapColorScheme_Classified | undefined = CreateClassifiedRanges(new_color_scheme, NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_CLASS_COUNT, gradient_preset, NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_PRESET_ID);
      new_color_scheme.classified = new_classified;
    }

    // For gradient mode, we need to set up the initial 2 gradient stop entries (min and max)

    if(new_color_scheme.type === 'gradient' && new_color_scheme.gradient)
    {
      const new_gradient = CreateColorGradientNoColors(new_color_scheme, 2);
      new_color_scheme.gradient = new_gradient;
    }

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The "Single Color" color has changed.
  //-------------------------------------------------------------------------------
  function OnSingleColorColorChanged(value: string, colors: MuiColorInputColors)
  {
    if(!localColorScheme || !localColorScheme.single_color) return;

    // Create an updated single color item
    const newItem: IPortfolioMapColorScheme_SingleColor =
    {
      ...localColorScheme.single_color,
      color: value,
    }

    // Update the color scheme
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      single_color: newItem
    }

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // Renders a few stats about the active attribute.
  //-------------------------------------------------------------------------------
  function RenderActiveAttribStats()
  {
    if(!localColorScheme) return null;

    const activeAttrib = GetAoiAttribute(props.aoiGroupProps?.attributes, localColorScheme.aoi_attribute_id);
    if(!activeAttrib) return null;

    const uniqueValuesArr: string[] | undefined = GetAoiAttribUniqueValues(props.aoiGroupProps?.attributes, localColorScheme.aoi_attribute_id);
    if(!uniqueValuesArr) return null;

    let minValue: number | undefined = undefined;
    let maxValue: number | undefined = undefined;
    if(activeAttrib.type === 'number')
    {
      minValue = GetAoiAttributeMinValue(localColorScheme.aoi_attribute_id);
      maxValue = GetAoiAttributeMaxValue(localColorScheme.aoi_attribute_id);
    }

    return (
      <Stack direction='column' sx={{ height: '100%', alignItems: 'start', justifyItems: 'center', justifyContent: 'center' }}>

        <Stack direction='row'>
          <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
            Attribute Type:
          </Typography>
          <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
            {activeAttrib.type}
          </Typography>
        </Stack>

        <Stack direction='row'>
          <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
            Unique Values:
          </Typography>
          <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
            {uniqueValuesArr.length}
          </Typography>
        </Stack>

        {minValue !== undefined && maxValue !== minValue
          ?
            <Stack direction='row'>
              <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                Min:
              </Typography>
              <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                {FriendlyNumber(minValue, 2)}
              </Typography>
              <Typography sx={{ ml: 2.5, color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                Max:
              </Typography>
              <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                {FriendlyNumber(maxValue, 2)}
              </Typography>
            </Stack>
          :null
        }

      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // The edit filter button was clicked.
  //-------------------------------------------------------------------------------
  function OnEditFilter()
  {
    setShowAoiExpressionEditor(true);
  }

  //-------------------------------------------------------------------------------
  // The filtered-out color has changed.
  //-------------------------------------------------------------------------------
  function OnFilteredOutColorChanged(value: string, colors: MuiColorInputColors)
  {
    if(!localColorScheme) return;

    // Update the color scheme
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      filtered_out_color: value
    }

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // Renders a friendly version of the specified AOI attribute expression.
  //-------------------------------------------------------------------------------
  function RenderAttribFilter()
  {
    if(!localColorScheme) return null;

    if(!localColorScheme.filter || !localColorScheme.filter.clauses || localColorScheme.filter.clauses.length === 0)
      return (
        <Typography sx={{ ml: 2, color: theme_orange, opacity: 0.6, fontSize: '0.9rem', fontWeight: 'normal' }}>
          none
        </Typography>
      )

    return (

      <Stack direction='row' sx={{ ml: 1, alignItems: 'center', bgcolor: theme_bgColorLight+'30', 
                                   borderRadius: 2.5, px: 0.6, py: 0.6, overflow: 'auto' }}>

        {localColorScheme.filter.clauses.map(function(clause, index)
        {
          const clauseAoiAttrib = GetAoiAttribute(props.aoiGroupProps?.attributes, clause.attribute_id);
          if(!clauseAoiAttrib) return null;
      
          return (

            <Stack key={clause.id} direction='row' sx={{ alignItems: 'center' }}>

              {/* Clause connector (AND/OR) */}

              {index > 0
                ?
                  <Typography sx={{ mx: 0.7, color: theme_errorRed, opacity: 0.6, fontSize: '0.8rem', fontWeight: 'normal' }}>
                    {localColorScheme.filter?.operator.toUpperCase()}
                  </Typography>
                :null
              }

              {/* Search term */}

              <Stack direction='row' sx={{ mx: 0, alignItems: 'center', bgcolor: theme_textColorBlended+'20', 
                                           borderRadius: 2, px: 0.7, py: 0, boxShadow: 2 }}>

                <Typography noWrap sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '0.9rem', fontWeight: 'normal' }}>
                  {clauseAoiAttrib.name}
                </Typography>

                <Typography noWrap sx={{ ml: 0.5, color: theme_orange, opacity: 0.8, fontSize: '0.7rem', fontWeight: 'normal' }}>
                  {clause.operator}
                </Typography>

                <TypographyWithAutoTooltip noWrap placement='bottom' arrow
                                          sx={{ maxWidth: '180px', ml: 0.5, color: theme_limeGreen, opacity: 1, fontSize: '0.9rem', fontWeight: 'normal' }}>
                  {clause.value}
                </TypographyWithAutoTooltip>

                {clause.operator === 'is in range' || clause.operator === 'is not in range'
                  ?
                    <Stack direction='row' sx={{ alignItems: 'center' }}>
                      <Typography sx={{ mx: 0.4, color: theme_orange, opacity: 0.8, fontSize: '0.7rem', fontWeight: 'normal' }}>
                        to
                      </Typography>
                      <Typography noWrap sx={{ color: theme_limeGreen, opacity: 1, fontSize: '0.9rem', fontWeight: 'normal' }}>
                        {clause.value_max}
                      </Typography>
                    </Stack>
                  :null
                }

              </Stack>

            </Stack>
          )
        })}

      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // UNIQUE VALUES Clear Filter button was pushed.
  //-------------------------------------------------------------------------------
  function OnClearFilter()
  {
    if(!localColorScheme) return;

    // Update the color scheme
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...localColorScheme,
      filter: undefined
    }

    // Set the new value
    setLocalColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    setLocalChangesWereMade(true);   
  }














  if(props.editColorSchemeID === undefined || !localColorScheme || !props.aoiGroupProps) return null;

  return (

    <Dialog disablePortal open={store_editAoiGroupProperties===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '40%', width: '55%', maxWidth: '55%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              Portfolio Map Color Scheme
            </Typography>

            {localChangesWereMade === true
              ?
                <Typography sx={{ mt: '5px', width:'90px', textAlign: 'center', fontSize: '0.6rem', color: theme_errorRed, fontWeight: 'bold', bgcolor: theme_bgColorMain, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  unsaved changes
                </Typography>
              :
                <Typography sx={{ mt: '5px', width:'60px', textAlign: 'center', fontSize: '0.6rem', color: theme_limeGreen, bgcolor: theme_bgColorMain, opacity: 0.7, px: 0.4, borderRadius: 1, textTransform: 'none', boxShadow: 1 }}>
                  no changes
                </Typography>
            }

          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>

        <Stack sx={{ mt: 2, width: '100%' }}>

          <Stack direction='row' justifyContent='space-between' alignItems='center'>

            {/* NAME */}

            <CustomTextField variant='standard' size='small' autoComplete='off'
                             value={localColorScheme.name}
                             onChange={OnColorSchemeNameChanged}
                             label={<Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>Color Scheme Name</Typography>} 
                             sx={{ p: 0, width: '100%' }}/>

            {/* TYPE select */}

            <Stack direction='column' sx={{ ml: 5, width: '400px' }}>

              {/* <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                Color Scheme Type
              </Typography> */}

              <CustomSelect variant='standard' size='small'
                            value={localColorScheme.type}
                            onChange={OnColorSchemeTypeChanged}
                            sx={{ p: 0.5 }}>

                <MenuItem key='single color' value='single color'>
                  <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                      Single Color
                    </Typography>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                      Use a single color for all AOIs
                    </Typography>
                  </Stack>
                </MenuItem>

                <MenuItem key='unique values' value='unique values'>
                  <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                      Unique Values
                    </Typography>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                      Assign colors for all unique values of an attribute
                    </Typography>
                  </Stack>
                </MenuItem>

                <MenuItem key='classified' value='classified'>
                  <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                      Classified
                    </Typography>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                      Set up colors for numerical ranges of an attribute's value
                    </Typography>
                  </Stack>
                </MenuItem>

                <MenuItem key='gradient' value='gradient'>
                  <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                      Gradient
                    </Typography>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                      Set up a color gradient based on a numerical attribute's value
                    </Typography>
                  </Stack>
                </MenuItem>

              </CustomSelect>
            </Stack>

          </Stack>

          {/* Filter controls */}

          <Stack sx={{ mt: 4 }}>
          
            <Stack direction='row' sx={{ alignItems: 'center' }}>

              <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                Filter:
              </Typography>

              {RenderAttribFilter()}

              {/* Clear filter button */}
              {localColorScheme.filter && localColorScheme.filter.clauses && localColorScheme.filter.clauses.length > 0
                ?
                  <Tooltip title='Clear filter' placement='top' arrow >
                    <IconButton sx={{ p: 0.3, ml: 0.2 }} onClick={(_)=>OnClearFilter()}>
                      <CancelIcon sx={{ color: theme_textColorBlended, opacity: 0.7, p: 0.4 }}/>
                    </IconButton>
                  </Tooltip>
                :null
              }

            </Stack>

            <Typography sx={{ mt: 0.4, color: theme_textColorMain, opacity: 0.5, fontSize: '0.6rem' }}>
              Allows you to apply this color scheme only to specific AOIs based on a search expression.
            </Typography>

            <Stack direction='row' sx={{ ml: 4, mt: 1, alignItems: 'center' }}>

              <Button variant="outlined" startIcon={<EditIcon/>} onClick={(_)=>OnEditFilter()}
                      sx={{ color: theme_textColorBlended, textTransform: 'none' }}>
                Edit filter...
              </Button>

              <Stack sx={{ ml: 3 }}>
                <Typography sx={{ color: theme_textColorBlended, fontSize: '0.7rem' }}>
                  Filtered Out Color
                </Typography>
                <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                     value={localColorScheme.filtered_out_color}
                                     onChange={(v,c)=>OnFilteredOutColorChanged(v,c)}
                                     sx={{ width: '120px' }}/>
              </Stack>

            </Stack>

          </Stack>

          {/* ATTRIBUTE select - Shown for all types except Single Color */}

          {localColorScheme.type !== 'single color'
            ?
              <Stack direction='row' sx={{ width: '100%', mt: 4, justifyContent: 'space-between' }}>

                {/* Combo box */}

                <Stack direction='column' sx={{ width: '100%' }}>

                  <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                    Attribute
                  </Typography>

                  <CustomSelect variant='standard' size='small'
                                value={localColorScheme.aoi_attribute_id ?? ''}
                                onChange={OnAoiAttributeChanged}
                                sx={{ p: 0.5 }}>

                    {props.aoiGroupProps.attributes.map(function(aoiAttribute)
                    {
                      // For classified and gradient color schemes, only list numerical AOI attributes
                      if(aoiAttribute.type !== 'number' && (localColorScheme.type === 'classified' || localColorScheme.type === 'gradient')) return null

                      // Ignore admin attributes
                      if(aoiAttribute.is_admin) return null

                      return (
                        <MenuItem key={aoiAttribute.id} value={aoiAttribute.id}>
                          <Stack>
                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                              <Typography sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>
                                {aoiAttribute.name}
                              </Typography>
                              <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.7, fontSize: '0.8rem' }}>
                                {aoiAttribute.units}
                              </Typography>
                            </Stack>
                            <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                              {aoiAttribute.description}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      )
                    })}

                  </CustomSelect>
                </Stack>

                {/* Stats about the active attribute */}

                <Stack direction='column' sx={{ ml: 3, width: '200px' }}>
                  {RenderActiveAttribStats()}
                </Stack>

              </Stack>
            :null
          }

          {/* Notify user if an attribute is not selected */}

          {localColorScheme.aoi_attribute_id === undefined && localColorScheme.type !== 'single color'
            ?
              <Typography sx={{ mt: 2, fontSize: '1.0rem', color: theme_errorRed, opacity: 0.7 }}>
                Please select an attribute.
              </Typography>
            :null
          }

          {/* Editors for each color scheme type */}

          {localColorScheme.type === 'single color' && localColorScheme.single_color !== undefined
            ?
              // === Color scheme:  SINGLE COLOR ===

              <Stack direction='column' sx={{ mt: 3, alignItems: 'left' }}>

                <Typography sx={{ mb: 2, color: theme_orange, opacity: 0.8, fontSize: '1.2rem' }}>
                  Single Color
                </Typography>

                <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                      value={localColorScheme.single_color.color}
                                      onChange={(v,c)=>OnSingleColorColorChanged(v,c)}
                                      sx={{ width: '120px' }}/>

              </Stack>

            :null
          }

          <ColorSchemeEditor_UniqueValues aoiGroupProps={props.aoiGroupProps} 
                                          colorScheme={localColorScheme} setColorScheme={setLocalColorScheme}
                                          setChangesWereMade={setLocalChangesWereMade}/>

          <ColorSchemeEditor_Classified colorScheme={localColorScheme} setColorScheme={setLocalColorScheme}
                                        attribUnits={GetAoiAttribute(props.aoiGroupProps.attributes, localColorScheme.aoi_attribute_id)?.units} setChangesWereMade={setLocalChangesWereMade}/>

          <ColorSchemeEditor_Gradient colorScheme={localColorScheme} setColorScheme={setLocalColorScheme}
                                      setChangesWereMade={setLocalChangesWereMade}/>

        </Stack>

        {/* AOI Attribute Expression Editor dialog window */}

        <AoiAttribExpressionEditor showAoiExpressionEditor={showAoiExpressionEditor} 
                                   setShowAoiExpressionEditor={setShowAoiExpressionEditor} 
                                   setChangesWereMade={setLocalChangesWereMade}
                                   colorScheme={localColorScheme} 
                                   setColorScheme={setLocalColorScheme}
                                   aoiGroupProps={props.aoiGroupProps}/>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}
                      onClick={OnAcceptChanges}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>
  )
}

