import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Stack, Divider, Typography, TextField, Tooltip, IconButton, InputAdornment, Dialog, DialogTitle } from '@mui/material';
import { DialogStyled } from '../CustomMuiStyles';
import KeyIcon from '@mui/icons-material/Key';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import { COGNITO_PWD_RULE_SPECIAL_CHARS } from './UserOps';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { theme_bgColorLight1, theme_textColorBlended, theme_textColorMain, theme_bgColorGradient2, theme_errorRed } from '../Theme';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from '../LayerLibrary/EditLayer/EditLayer';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ChangePwdDialogProps
{
  changePwdDialogOpen: boolean;
  setChangePwdDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

//-------------------------------------------------------------------------------
// Change password dialog Component
//-------------------------------------------------------------------------------
const ChangePwdDialog = (props: ChangePwdDialogProps) =>
{
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [pwdRule8Chars, setPwdRule8Chars] = useState<boolean>(false);
  const [pwdRule1UpChar, setPwdRule1UpChar] = useState<boolean>(false);
  const [pwdRule1LowChar, setPwdRule1LowChar] = useState<boolean>(false);
  const [pwdRule1NumChar, setPwdRule1NumChar] = useState<boolean>(false);
  const [pwdRule1SpecialChar, setPwdRule1SpecialChar] = useState<boolean>(false);
  const [pwdRuleConfirm, setPwdRuleConfirm] = useState<boolean>(false);


  // NOTE: These are for the "show/hide password" feature
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);



  //-------------------------------------------------------------------------------
  // INIT - ONLY ONCE
  //-------------------------------------------------------------------------------
  // useEffect(() => 
  // {
  //   setCurrentPassword('');

  //   // eslint-disable-next-line
  // }, []) // <-- empty dependency array - this will only be called once

  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onCloseDialog = () => 
  {
    props.setChangePwdDialogOpen(false);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "Current Password" text field.
  //-------------------------------------------------------------------------------
  const onCurrentPasswordChange = (event: any) => 
  {
    const currentPwd: string = event.target.value as string;
    setCurrentPassword(currentPwd);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "New Password" field.
  //-------------------------------------------------------------------------------
  const onNewPasswordChange = (event: any) => 
  {
    const newPwd: string = event.target.value as string;
    setNewPassword(newPwd);

    // Update the password validation indicators

    // At least 8 characters
    setPwdRule8Chars(newPwd.length >= 8);

    // 1 uppercase character (A-Z)
    // 1 lowercase character (a-z)
    // 1 numerical character (0-9)

    let numFound = false;
    let upperCaseFound = false;
    let lowerCaseFound = false;
    let specialCharFound = false;

    for(let i=0; i < newPwd.length; i++)
    {
      const c = newPwd.charAt(i);
  
      if(c >= 'a' && c <= 'z')
        lowerCaseFound = true;
      if(c >= 'A' && c <= 'Z')
        upperCaseFound = true;
      if(c >= '0' && c <= '9')
        numFound = true;
      if(COGNITO_PWD_RULE_SPECIAL_CHARS.includes(c))
        specialCharFound = true;
    }

    setPwdRule1UpChar(upperCaseFound);
    setPwdRule1LowChar(lowerCaseFound);
    setPwdRule1NumChar(numFound);
    setPwdRule1SpecialChar(specialCharFound);

    // The confirmation pwd must match the new pwd
    setPwdRuleConfirm(newPwd === confirmPassword);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the "Confirm Password" text field.
  //-------------------------------------------------------------------------------
  const onConfirmPasswordChange = (event: any) => 
  {
    const confirmPwd: string = event.target.value as string;
    setConfirmPassword(confirmPwd);

    // The confirmation pwd must match the new pwd
    setPwdRuleConfirm(confirmPwd === newPassword);
  }

  //-------------------------------------------------------------------------------
  // Change the password.
  //-------------------------------------------------------------------------------
  const onAttemptPasswordChange = () => 
  {
    // Make sure all validation rules are met
    if(!pwdRule8Chars || !pwdRule1UpChar || !pwdRule1LowChar || !pwdRule1NumChar || !pwdRule1SpecialChar || !pwdRuleConfirm)
      return;

    // Verify the current password (this is a special case, we don't rely on the already-logged-in token)





    // Call server to change the password

    // currentPassword
    // newPassword


      


    //props.setChangePwdDialogOpen(false);



  }

  



  // Main render

  return (

    <Dialog disablePortal 
            open={props.changePwdDialogOpen===true}
            onClose={onCloseDialog} maxWidth='md' 
            PaperProps={{ sx: { maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <KeyIcon sx={{ color: theme_textColorBlended }}/>
            <Typography sx={{ ml: 1, fontSize: '1.3rem', fontWeight:'bold', color: theme_textColorMain+'F0' }}>
              Change Password
            </Typography>
          </Stack>

          <IconButton size="small" onClick={onCloseDialog}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>      

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>              

        <Stack direction='row' sx={{ mt: 2 }}>

          {/* Left side - list all the pwd rules */}

          <Stack direction='column'>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRule8Chars ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRule8Chars?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                At least 8 characters
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRule1UpChar ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRule1UpChar?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                An uppercase character (A-Z)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRule1LowChar ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRule1LowChar?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                A lowercase character (a-z)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRule1NumChar ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRule1NumChar?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                A numerical character (0-9)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRule1SpecialChar ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRule1SpecialChar?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                A special character
              </Typography>
              
              <Tooltip title='^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < &apos; : ; | _ ~ ` + ='>
                <HelpOutlineIcon sx={{ color: theme_textColorMain+'A0', ml: '5px', opacity: 0.7, height: '20px' }} />
              </Tooltip>

            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              {pwdRuleConfirm ? <CheckIcon sx={{ color: '#50F050' }}/> : <CloseIcon sx={{ color: theme_errorRed }}/> }            
              <Typography sx={{ ml: 1.3, mt: 0.7, mb: 0.7, opacity: `${pwdRuleConfirm?'0.4':'0.8'}`, fontSize: '0.9rem', color: theme_textColorBlended }}>
                Confirmation matches
              </Typography>
            </Stack>

          </Stack>

          {/* Right side - the pwd change text boxes */}

          <Stack direction='column' sx={{ ml: 5 }}>

            {/* Current password */}

            <CustomTextField label="Current Password" autoComplete='new-password'
                        type='password' sx={{ mt: 2 }} autoFocus onChange={onCurrentPasswordChange}  />

            {/* New password */}

            <CustomTextField label="New Password" autoComplete='new-password'
                        sx={{ mt: 2 }} onChange={onNewPasswordChange} 
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" tabIndex={-1}
                                          onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <Visibility sx={{ color: theme_textColorBlended }}/> : <VisibilityOff sx={{ color: theme_textColorBlended+'90' }}/>}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}/>

            {/* Confirm password */}

            <CustomTextField label="Confirm Password" autoComplete='new-password'
                        type='password' sx={{ mt: 2 }} onChange={onConfirmPasswordChange}/>

          </Stack>

        </Stack>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        {/* CLOSE button */}

        <Stack direction='row'>

          <Button autoFocus variant='contained' onClick={onAttemptPasswordChange} sx={{ mr: 3 }}>
            Change Password
          </Button>

          <Button variant='outlined' onClick={onCloseDialog}>
            Close
          </Button>

        </Stack>

      </DialogActions>

    </Dialog>
  );
}



export default ChangePwdDialog;
