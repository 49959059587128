// Parcel operations

import { AnyLayer, LngLatBounds } from "mapbox-gl";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import { ILayer, IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import useStore from "../store";
import { Feature, FeatureCollection, GeoJSON } from 'geojson';
import { DEFAULT_PARCEL_ATTRIBUTES, IParcel, IParcelAttributes, IParcelSearchClause, IParcelSearchExpression, TParcelSearchAreaFilter } from "./ParcelInterfaces";
import { ConvertFeatureToMultiPolygon, ConvertMultiPolygonToFeatureCollection, IsPointInsidePolygon, JoinAdjacentParcels } from "../GisOps";
import { MultiPolygon } from "@turf/turf";
import { CapitalizeAllWords, FriendlyCurrency, FriendlyNumber, GetEscapedCSVValue, IsNumber, PARCEL_LAYER_NAME } from "../Globals";
import { ACTIVE_AOI_MAPBOX_LAYER_NAME, CreateNewAoi, ExitAoiEditMode } from "../Aois/AoiOps";
import { AddLayerToMap, RemoveLayerFromMap } from "../Layers/LayerOps";
import { MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS, MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_INACTIVE_PARCELS } from "./ParcelPanel";
import { ToastNotification } from "../ToastNotifications";
import * as turf from '@turf/turf'
import { ZoomMapToGeojsonExtent } from "../Map/MapOps";
import { GetParcelLayerAttribute } from "../AttributeOps";
import { IProjectParcelAttribute } from "../Projects/ProjectInterfaces";

const MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME = 'parcel-select-poly-source';
const MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME = 'parcel-select-poly-layer';

const MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME = 'parcel-select-point-source';
const MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME = 'parcel-select-point-layer';

const AOI_AREA_FILTER_MAX_BUFFER_MILES = 200;

let nextParcelSearchClauseID = 1;  // 1 is used for the default/starting clause


//-------------------------------------------------------------------------------
// Handles map clicks for parcels.
//-------------------------------------------------------------------------------
export async function MapParcelClick(parcelLayer: ILayer, clickLongitude: number, clickLatitude: number, ctrlPressed: boolean)
{
  Debug.log('ParcelOps.MapParcelClick> ');

  // If the map is zoomed out too far (can't see parcels), don't allow selection or deselection

  const store_project = useStore.getState().store_project;
  if(!store_project) return null;

  if(store_project.user_settings.mapView.zoom && store_project.user_settings.mapView.zoom < MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS) 
    return null;

  // Auto-close the basemap and layer panels (otherwise the pacels UI will go under them)
  useStore.getState().store_setBaseMapPanelAnchorElement(undefined);
  useStore.getState().store_setLayersPanelAnchorElement(undefined);

  // Determine if the user clicked an already-selected parcel - if they did, de-delect that parcel

  for(let i=0; i < useStore.getState().store_selectedParcels.length; i++)
    if(IsPointInsidePolygon(clickLongitude, clickLatitude, useStore.getState().store_selectedParcels[i].geometry))
    {
      useStore.getState().store_removeSelectedParcel(useStore.getState().store_selectedParcels[i].id);
      UpdateParcelSelectionLayerOnMap();
      UpdateParcelTotalSelectedAcres();
      return;
    }

  // Select the parcel under the mouse (if there is one)
  SelectParcelAtMouseClickPoint(parcelLayer, clickLongitude, clickLatitude, ctrlPressed);
}

//-------------------------------------------------------------------------------
// Selects the parcel at the specified coordinates.
//
// If the CTRL key is not held down, then this is a single-parcel select.  We need 
// to get extra parcel attributes, and we need to deselect any previously selected 
// parcels.
//
// If the CTRL key IS held down, this is a multiple-parcel select.  We do NOT need
// to get extra parcel attributes (only the geometry), and we leave any previously
// selected parcels alone.
// 
// The API call returns the parcel data as MultiPolygon if there is a parcel at the 
// specified point.
//
// When in multi-select mode, if this particular parcel is already selected, it does
// not add it again.  This can happen if the user clicks on a parcel multiple times
// quickly before the API can return results.
//-------------------------------------------------------------------------------
export async function SelectParcelAtMouseClickPoint(layer: ILayer, longitude: number, 
                                                    latitude: number, ctrlPressed: boolean) : Promise<boolean>
{
  // Prepare the attribute list (we only want certain ones, not all of them)

  const store_project = useStore.getState().store_project;
  if(!store_project)
    return false;

  let parcelAttributes: string[] = [];

  // First add the default parcel attributes (the ones the parcel identify panel always displays)

  for(let i=0; i < DEFAULT_PARCEL_ATTRIBUTES.length; i++)
    parcelAttributes.push(DEFAULT_PARCEL_ATTRIBUTES[i]);

  // Now look through the user's active attribute list, and add in any that are not already there

  for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
  {
    const attribName: string = store_project.user_settings.parcel_attribute_list[i].name;

    if(parcelAttributes.find(v => v === attribName) === undefined)
      parcelAttributes.push(attribName);
  }

  // Call API to get the parcel under the mouse point

  const server = new CallServer();
  server.Add("layer", layer.name);
  server.Add("lng", longitude);
  server.Add("lat", latitude);
  server.Add("get_attributes", 1);
  if(parcelAttributes.length > 0)
    server.Add("parcel_attributes", JSON.stringify(parcelAttributes));

  const result = await server.Call('get', '/parcel');

  if(result.success)
  {
    //Debug.log('ParcelOps.SelectParcelAtMouseClickPoint> SUCCESS! data=' + JSON.stringify(result.data));

    if(!result.data || !result.data.geom || !result.data.ogc_fid)
    {
      Debug.error(`ParcelOps.SelectParcelAtMouseClickPoint> ${result.errorCode} - ${result.errorMessage}`);
      return false;
    }

    // Check if a parcel with this same ID is already selected - if it is, the user clicked the parcel
    // multiple times before the first API call came back and the parcel was selected.

    const foundParcel: IParcel | undefined = useStore.getState().store_selectedParcels.find(p => p.id === result.data.ogc_fid);
    if(foundParcel)
    {
      Debug.error(`ParcelOps.SelectParcelAtMouseClickPoint> Parcel with id ${result.data.ogc_fid} is already selected - ignoring second selection attempt`);
      return false;
    }

    const newParcel: IParcel = 
    {
      id: result.data.ogc_fid,
      geometry: result.data.geom,
      //acres: GetGeojsonAcres(result.data.geom),
      attributes: result.data.attributes,
    }

    // Update the state store
    if(ctrlPressed) // multi-select
      useStore.getState().store_addSelectedParcel(newParcel); // add this new parcel to existing list
    else // single-select
      useStore.getState().store_setSelectedParcels([newParcel]);  // only select this new parcel (remove the rest)

    // Refresh the map
    UpdateParcelSelectionLayerOnMap();

    // Update the total selected acres
    UpdateParcelTotalSelectedAcres();

    // Success
    Debug.log(`ParcelOps.SelectParcelAtMouseClickPoint> Parcel selected.`);
    return true;
  }
  else
  {
    // Failure
    Debug.error(`ParcelOps.SelectParcelAtMouseClickPoint> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Add the special parcel selection layer to the map (both source and layers).
// If there are selected parcels in 'store_selectedParcels', they are added.
//
// NOTE:  The parcel selection layer is added just below the active AOI layer.
//        We always want the AOI boundary to be drawn on top.
//-------------------------------------------------------------------------------
export function AddParcelSelectionLayerToMap()
{
  // Remove any previous parcel selection layer from the map (if there is one)
  RemoveParcelSelectionLayerFromMap();

  // Add parcel selection sub-layers to the map
  AddParcelSelectionPolygonLayersToMap();
  AddParcelSelectionPointLayersToMap();
}

//-------------------------------------------------------------------------------
// Adds parcel selection polygon layers.
// 
// Polygons are drawn either as:
// - a gray fill with a a thin white border at mid-zoom
// - a red fill with a thick yellow border at full zoom in.
//-------------------------------------------------------------------------------
export function AddParcelSelectionPolygonLayersToMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // If the AOI boundary layer is present, we'll add the parcel selection layer 
  // just under it.  If there is no AOI layer, we add the layer on top of everything.
  const addBeforeLayer: string | undefined = store_map.getLayer(`${ACTIVE_AOI_MAPBOX_LAYER_NAME}-1`) ? `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-1` : undefined;

  // For rendering, merge the MultiPolygons from all selected parcels into a single MultiPolygon
  const mergedParcelsGeojson = MergeParcels(useStore.getState().store_selectedParcels);

  // Add parcel polygons source (geojson)

  store_map.addSource(MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
  {
    type: 'geojson',
    data: mergedParcelsGeojson,
  })

  // Layer 1 - a dark thick blurry background polygon outline
  //
  // Active at mid zoom and full zoom-in

  const newMapboxLayer1: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-1`,
    source: MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
    type: 'line',
    minzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_INACTIVE_PARCELS,
    paint: 
    {
      'line-color': '#000000',
      'line-width': 12,
      'line-blur': 5,
      'line-opacity': 0.8,
    }
  }

  store_map.addLayer(newMapboxLayer1 as AnyLayer, addBeforeLayer);
  
  // Layer 2 - semi-transparent red fill
  //
  // Active only at full zoom-in

  const newMapboxLayer2: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-2`,
    source: MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
    type: 'fill',
    minzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'fill-color': '#d6604d',
      'fill-opacity': 0.4,
    }
  }

  store_map.addLayer(newMapboxLayer2 as AnyLayer, addBeforeLayer);

  // Layer 3 - a thick yellow border
  //
  // Active only at full zoom-in

  const newMapboxLayer3: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-3`,
    source: MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
    type: 'line',
    minzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'line-color': '#ffea00',
      'line-width': 3,
      'line-opacity': 1,
    }
  }

  store_map.addLayer(newMapboxLayer3 as AnyLayer, addBeforeLayer);

  // Layer 4 - semi-transparent grey fill
  //
  // Active only at mid zoom

  const newMapboxLayer4: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-4`,
    source: MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
    type: 'fill',
    minzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_INACTIVE_PARCELS,
    maxzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'fill-color': '#8A8A8A',
      'fill-opacity': 0.4,
    }
  }

  store_map.addLayer(newMapboxLayer4 as AnyLayer, addBeforeLayer);

  // Layer 5 - a think white border
  //
  // Active only at mid zoom

  const newMapboxLayer5: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-5`,
    source: MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME,
    type: 'line',
    minzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_INACTIVE_PARCELS,
    maxzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'line-color': '#B0B0B0',
      'line-width': 1,
    }
  }

  store_map.addLayer(newMapboxLayer5 as AnyLayer, addBeforeLayer);
}

//-------------------------------------------------------------------------------
// Adds parcel selection layers with parcels rendered as single points.
// These layers only become visible when zoomed out beyond a certain point.
// 
// Polygons draws with a red semi-transparent fill and a yellow border.
//
// NOTE:  The parcel selection layer is added just below the active AOI layer.
//        We always want the AOI boundary to be drawn on top.
//-------------------------------------------------------------------------------
export function AddParcelSelectionPointLayersToMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // If the AOI boundary layer is present, we'll add the parcel selection layer 
  // just under it.  If there is no AOI layer, we add the layer on top of everything.
  const addBeforeLayer: string | undefined = store_map.getLayer(`${ACTIVE_AOI_MAPBOX_LAYER_NAME}-1`) ? `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-1` : undefined;

  // Add parcel points source (geojson)

  store_map.addSource(MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME,
  {
    type: 'geojson',
    data: GetParcelPointsFeatureCollection(useStore.getState().store_selectedParcels),
  })
  
  // Layer 1 - a dark thick blurry background circle
  //
  // Active at mid zoom and full zoom-out

  const newMapboxLayer1: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-1`,
    source: MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME,
    type: 'circle',
    maxzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'circle-radius': 9,
      'circle-color': '#000000',
      //'circle-opacity': 0.3,
      //'circle-opacity-transition': {duration: 2000}
    }
  }

  store_map.addLayer(newMapboxLayer1 as AnyLayer, addBeforeLayer);

  // Layer 2 - red filled circle with a yellow border
  //
  // Active at mid zoom and full zoom-out

  const newMapboxLayer2: mapboxgl.Layer = 
  {
    id: `${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-2`,
    source: MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME,
    type: 'circle',
    maxzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS,
    paint: 
    {
      'circle-radius': 6,
      'circle-color': '#D60000',
      'circle-stroke-width': 1.5,
      'circle-stroke-color': '#ffea00',
    }
  }

  store_map.addLayer(newMapboxLayer2 as AnyLayer, addBeforeLayer);    
}

//-------------------------------------------------------------------------------
// Remove the special parcel selection layer from the map (both layers and source).
//-------------------------------------------------------------------------------
export function RemoveParcelSelectionLayerFromMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove the mapbox layers used to show selected parcels

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-1`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-1`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-2`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-2`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-3`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-3`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-4`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-4`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-5`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POLY_LAYER_NAME}-5`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-1`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-1`,);

  if(store_map.getLayer(`${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-2`,))
    store_map.removeLayer(`${MAPBOX_PARCEL_SELECTION_POINTS_LAYER_NAME}-2`,);

  // Remove the mapbox layer sources

  if(store_map.getSource(MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME))
    store_map.removeSource(MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME);

  if(store_map.getSource(MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME))
    store_map.removeSource(MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME);
}

//-------------------------------------------------------------------------------
// Merges the specified parcels (MultiPolygon GeoJSON) into a single GeoJSON item.
//-------------------------------------------------------------------------------
export function MergeParcels(parcels: IParcel[]): GeoJSON
{
  const mergedParcelsGeoJson: GeoJSON = 
  {
    type: "MultiPolygon",
    coordinates: [],
  }

  for(let i=0; i < parcels.length; i++)
    for(let j=0; j < parcels[i].geometry.coordinates.length; j++)
      mergedParcelsGeoJson.coordinates.push(parcels[i].geometry.coordinates[j]);
  
  return mergedParcelsGeoJson;
}

//-------------------------------------------------------------------------------
// Returns a FeatureCollection where each parcel is represented as a point.
// Used to display selected/search parcels when the map is zoomed way out.
//-------------------------------------------------------------------------------
export function GetParcelPointsFeatureCollection(parcels: IParcel[]): FeatureCollection
{
  const parcelPointsFeatureCollection: FeatureCollection =
  {
    type: 'FeatureCollection',
    features: []
  }

  for(let i=0; i < parcels.length; i++)
    //for(let j=0; j < parcels[i].geometry.coordinates.length; j++) // NOTE: Why was this here??
    {
      const centerPoint: Feature<turf.Point> = turf.center(parcels[i].geometry);
      parcelPointsFeatureCollection.features.push(centerPoint);
    }

  return parcelPointsFeatureCollection;
}

//-------------------------------------------------------------------------------
// Updates the selected parcels on the map.
//
// This is done without re-adding the source/layers, using Mapbox's 'setData'.
// If the parcel selection layer doesn't exist, it is auto-added first (source and layers).
//
// The parcel selection layer is added just below the active AOI layer - we always
// want the AOI to be drawn on top.
//-------------------------------------------------------------------------------
export function UpdateParcelSelectionLayerOnMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // If the parcel selection layer is not set up, set it up
  if(!store_map.getSource(MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME))
  {
    AddParcelSelectionLayerToMap();  // we can quit after, as this already adds in the parcels
    return;
  }

  // For rendering, merge the MultiPolygons from each selected parcel into a single MultiPolygon
  const mergedParcelsGeojson: GeoJSON = MergeParcels(useStore.getState().store_selectedParcels);

  // Convert to a FeatureCollection (what Mapbox wants)
  const parcelsFeatureCollection: GeoJSON | undefined = ConvertMultiPolygonToFeatureCollection(mergedParcelsGeojson as MultiPolygon);
  if(!parcelsFeatureCollection) return;

  // Update the data in Mapbox
  (store_map.getSource(MAPBOX_PARCEL_SELECTION_POLY_SOURCE_NAME) as mapboxgl.GeoJSONSource).setData(parcelsFeatureCollection as FeatureCollection);

  // Add a source where is parcel is shown as a single point (only used when zoomed far out)
  const parcelPointsFeatureCollection: FeatureCollection = GetParcelPointsFeatureCollection(useStore.getState().store_selectedParcels);
  (store_map.getSource(MAPBOX_PARCEL_SELECTION_POINTS_SOURCE_NAME) as mapboxgl.GeoJSONSource).setData(parcelPointsFeatureCollection);
}

//-------------------------------------------------------------------------------
// Returns the parcel layer.
//-------------------------------------------------------------------------------
export function GetParcelLayer(): ILayer | undefined
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].name === PARCEL_LAYER_NAME)
      return store_layers[i]; // found

  return undefined; // not found
}

//-------------------------------------------------------------------------------
// Returns the parcel layer if it's active.
//-------------------------------------------------------------------------------
export function GetActiveParcelLayer(): ILayer | undefined
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].name === PARCEL_LAYER_NAME && store_layers[i].geoserver && store_layers[i].enabled)
      return store_layers[i]; // found

  return undefined; // not found
}

//-------------------------------------------------------------------------------
// Updates the total selected acres.
//-------------------------------------------------------------------------------
export function UpdateParcelTotalSelectedAcres()
{
  // Calculate the total acres for all currently-selected parcels
  let totalSelectedAcres = 0;
  for(let i=0; i < useStore.getState().store_selectedParcels.length; i++)
  {
    const acres: string | number | undefined = useStore.getState().store_selectedParcels[i].attributes.ll_gisacre;
    if(acres && typeof(acres) !== 'string')
      totalSelectedAcres += acres;
  }

  // Update the state store
  useStore.getState().store_setSelectedParcelsTotalAcres(totalSelectedAcres);
}

//-------------------------------------------------------------------------------
// Create a new AOI from selected parcels.
//-------------------------------------------------------------------------------
export async function CreateAoiFromSelectedParcels(newAoiName: string | undefined)
{
  if(!newAoiName) // If there is no name specified, auto-create one (this should never happen though)
    newAoiName = `Parcel-AOI-auto-${Math.round(Math.random()*10000)}`;

  const store_userProfile = useStore.getState().store_userProfile;
  if(!store_userProfile) return;

  let newGeometry: any | undefined = undefined;

  // OPTION 1: Join any adjacent polygons
  //
  // Less polygons means faster AOI-based calculation.

  const joinedParcelsFeatureGeoJSON: Feature | MultiPolygon = JoinAdjacentParcels(useStore.getState().store_selectedParcels);

  // The save API requires MultiPolygon GeoJSON (can't be inside a Feature or FeatureCollection)
  if(joinedParcelsFeatureGeoJSON.type === 'Feature')
    newGeometry = ConvertFeatureToMultiPolygon(joinedParcelsFeatureGeoJSON);
  else
    newGeometry = joinedParcelsFeatureGeoJSON as MultiPolygon;

  // Create a new AOI (and make it the project's active AOI)
  const result: boolean = await CreateNewAoi(newAoiName, newGeometry, false);
  if(!result)
    return;
/*
  // OPTION 2: Leave each parcel as separate Polygons or MultiPolygons
  //
  // This allows users to possible delete individual parcels from the AOI later.

  // Merge the MultiPolygons from all selected parcels into a single MultiPolygon
  newGeometry = MergeParcels(useStore.getState().store_selectedParcels);

  // Create a new AOI (and make it the project's active AOI)
  const result: boolean = await CreateNewAoi(newAoiName, newGeometry);
  if(!result)
    return;
*/

  // Close the parcels UI
  ExitParcelsMode();
}

//-------------------------------------------------------------------------------
// Enables parcel mode:
// 
// - shows the parcels layer
// - allows parcels to be selected
// - shows the parcels UI
//-------------------------------------------------------------------------------
export function EnterParcelsMode()
{
  // AOI edit mode and parcel mode cannot be enabled at the same time (they each have clickable elements)
  ExitAoiEditMode(true);

  // Turn off the identify panel if it's active
  useStore.getState().store_setIdentify(undefined);

  // Clear out any currently-selected parcels
  useStore.getState().store_setSelectedParcels([]);
  useStore.getState().store_setSelectedParcelsTotalAcres(0);
  RemoveParcelSelectionLayerFromMap();

  // Auto-close the basemap and layer panels (otherwise the pacels UI will go under them)
  useStore.getState().store_setBaseMapPanelAnchorElement(undefined);
  useStore.getState().store_setLayersPanelAnchorElement(undefined);

  // Open the panel
  //
  // Set the anchor element of the Popover control to the parcels panel button.

  useStore.getState().store_setParcelEditMode(true);

  //setParcelsPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|null));

  // Add the parcel layer to the map (on top of every other layer)

  const parcelLayer : ILayer | undefined = GetParcelLayer();
  if(parcelLayer && !parcelLayer.enabled)
  {
    useStore.getState().store_setLayerEnabled(parcelLayer.id, true);
    AddLayerToMap(parcelLayer.id);
  }

  // Initialize the parcel search
  ResetParcelSearch();
}

//-------------------------------------------------------------------------------
// Disables parcel mode:
//
// - hides the parcels layer
// - parcels can no longer be selected
// - hides the parcels UI
//-------------------------------------------------------------------------------
export function ExitParcelsMode()
{
  if(useStore.getState().store_parcelEditMode === false) 
    return;  // Not in parcels mode, nothing to do

  // Clear out any currently-selected parcels
  useStore.getState().store_setSelectedParcels([]);
  useStore.getState().store_setSelectedParcelsTotalAcres(0);

  RemoveParcelSelectionLayerFromMap();

  // Close the panel

  useStore.getState().store_setParcelEditMode(false);

  // Remove the parcel layer from the map 

  const parcelLayer : ILayer | undefined = GetParcelLayer();
  if(parcelLayer && parcelLayer.enabled)
  {
    useStore.getState().store_setLayerEnabled(parcelLayer.id, false);
    RemoveLayerFromMap(parcelLayer.id);
  }

  // Exit parcel search results viewer mode
  useStore.getState().store_setParcelSearchResultsViewerMode(false);
}

//-------------------------------------------------------------------------------
// Returns a new parcel search clause.
//-------------------------------------------------------------------------------
export function GetNewParcelSearchClause()
{
  const newSearchClause: IParcelSearchClause = 
  {
    clause_id: nextParcelSearchClauseID++,
    //attribute_id: undefined,
    attribute_name: undefined,
    data_type: undefined,
    operator: 'is equal to',
    value: undefined
  }

  return newSearchClause;
}

//-------------------------------------------------------------------------------
// Resets the parcel search UI.
//-------------------------------------------------------------------------------
function ResetParcelSearch()
{
  const clauses: IParcelSearchClause[] = [];
  clauses.push(GetNewParcelSearchClause());

  const newSearchExpression: IParcelSearchExpression = 
  {
    clauses: clauses,
    operator: 'and',
    states: [],
    attributes: []
  }

  useStore.getState().store_setParcelSearchEnabled(false);
  useStore.getState().store_setParcelSearchExpression(newSearchExpression);
}

//-------------------------------------------------------------------------------
// Returns the parcel attribute info for the specified attribute ID.
//-------------------------------------------------------------------------------
/*export function GetParcelSearchAttribute(attributeID: number): IVectorLayerAttribute | undefined
{
  const parcelLayer: ILayer | undefined = GetParcelLayer();
  if(!parcelLayer || !parcelLayer.attributes) return undefined;

  for(let i=0; i < parcelLayer.attributes.length; i++)
    if(parcelLayer.attributes[i].attribute_id === attributeID)
      return parcelLayer.attributes[i];

  // const store_project = useStore.getState().store_project;
  // if(!store_project) return undefined;

  // for(let i=0; i < store_project.parcel_attributes.length; i++)
  //   if(store_project.parcel_attributes[i].attribute_id === attributeID)
  //     return store_project.parcel_attributes[i];

  return undefined; // not found
}*/

//-------------------------------------------------------------------------------
// 
//-------------------------------------------------------------------------------
export const MergeBoundingBoxes = (boundingBoxes: turf.BBox[]): turf.BBox => 
{
  let minLeft: number = 180;
  let minBottom: number = 90;
  let maxRight: number = -180;
  let maxTop: number = -90;

  boundingBoxes.forEach(([left, bottom, right, top]) => 
  {
    if (left < minLeft) minLeft = left;
    if (bottom < minBottom) minBottom = bottom;
    if (right > maxRight) maxRight = right;
    if (top > maxTop) maxTop = top;
  });

  return [minLeft, minBottom, maxRight, maxTop];
}

//-------------------------------------------------------------------------------
// Run a parcel search.
//-------------------------------------------------------------------------------
export async function RunParcelSearch(searchExpression: IParcelSearchExpression) : Promise<boolean>
{
  // Validate the search expression

  if(searchExpression.clauses.length === 0)
  {
    ToastNotification('error','No search clauses');
    return false;
  }

  for(let i=0; i < searchExpression.clauses.length; i++)
  {
    const clause: IParcelSearchClause = searchExpression.clauses[i];
    
    if(!clause.attribute_name)
    {
      ToastNotification('error','One of the search clauses does not have an attribute selected');
      return false;
    }

    if((!clause.value || clause.value.toString().trim().length === 0) && (clause.operator !== 'is empty' && clause.operator !== 'is not empty'))
    {
      ToastNotification('error','One of the search clauses does not have a value');
      return false;
    }

    if(clause.data_type && clause.data_type === 'number' && IsNumber(clause.value) === false)
    {
      ToastNotification('error',`Attribute "${GetParcelLayerAttribute(clause.attribute_name)?.display_name}" must have a numerical value`);
      return false;
    }

    if(clause.data_type && clause.data_type === 'number' && (clause.operator === 'is in range' || clause.operator === 'is not in range')  )
    {
      if(!clause.value_max)
      {
        ToastNotification('error','One of the search clauses does not have a MAX value');
        return false;
      }
  
      if(IsNumber(clause.value_max) === false)
      {
        ToastNotification('error',`Attribute "${GetParcelLayerAttribute(clause.attribute_name)?.display_name}" must have a numerical MAX value`);
        return false;
      }

      if(clause.value && Number.parseFloat(clause.value_max) < Number.parseFloat(clause.value as string))
      {
        ToastNotification('error',`The MIN value cannot exceed the MAX value for attribute "${GetParcelLayerAttribute(clause.attribute_name)?.display_name}"`);
        return false;
      }
    }

    // Trim whitespace for text values
    if(clause.data_type === 'string' && clause.value)
      clause.value = (clause.value as string).trim();
  }

  // Add the "max results" option to the search expression
  searchExpression.max_parcels = useStore.getState().store_parcelSearchMaxResults;

  // Add the "db sort" options to the search expression
  const dbSortAttribName = useStore.getState().store_parcelSearchDbSortAttribName;
  if(dbSortAttribName)
    searchExpression.sort_attribute_name = dbSortAttribName;
  else
    delete searchExpression.sort_attribute_name;

  if(dbSortAttribName)
  {
    const dbSortDesc = useStore.getState().store_parcelSearchDbSortOrderDesc;
    searchExpression.sort_order = dbSortDesc ? 'desc' : 'asc';
  }
  else
    delete searchExpression.sort_order;

  // Add the optional "area filter" option to the search expression
  
  delete searchExpression.area_filter_aoi_id;
  delete searchExpression.area_filter_bbox;
  delete searchExpression.area_filter_aoi_buffer_miles;

  const areaFilter: TParcelSearchAreaFilter = useStore.getState().store_parcelSearchAreaFilter;

  if(areaFilter === 'AOI')
  {
    searchExpression.area_filter_aoi_id = useStore.getState().store_aoi?.aoi_id;
    
    const areaFilterAOIBufferStr: string = useStore.getState().store_parcelSearchAOIBufferMiles;
    if(IsNumber(areaFilterAOIBufferStr) === false)
    {
      ToastNotification('error',`The AOI Viewport filter buffer value is not a valid number`);
      return false;
    }
    const areaFilterAOIBuffer: number = parseInt(areaFilterAOIBufferStr);
    if(areaFilterAOIBuffer < 0 || areaFilterAOIBuffer > AOI_AREA_FILTER_MAX_BUFFER_MILES)
    {
      ToastNotification('error',`The AOI Viewport filter buffer value must be between 0 and ${AOI_AREA_FILTER_MAX_BUFFER_MILES} miles`);
      return false;
    }

    if(areaFilterAOIBuffer && areaFilterAOIBuffer > 0)
      searchExpression.area_filter_aoi_buffer_miles = areaFilterAOIBuffer;
  }
  else if(areaFilter === 'Viewport')
  {
    const mapboxBounds: LngLatBounds | null | undefined = useStore.getState().store_map?.getBounds();
    if(mapboxBounds)
    {
      // left,bottom,right,top
      searchExpression.area_filter_bbox = [ mapboxBounds.getWest(), mapboxBounds.getSouth(), mapboxBounds.getEast(), mapboxBounds.getNorth() ];
    }
  }

  // Add the selected states (if any) to the search expression

  const userSelectedStates: string[] = useStore.getState().store_parcelSearchStates;
  searchExpression.states = [];
  for(let i=0; i < userSelectedStates.length; i++)
    searchExpression.states.push(userSelectedStates[i]);

  const store_project = useStore.getState().store_project;
  if(!store_project) return false;

  // Add the list of attribute names (so data will only be returned for the attributes we need)

  const attributeNames: string[] = [];
  for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
    attributeNames.push(store_project.user_settings.parcel_attribute_list[i].name);
  searchExpression.attributes = attributeNames;

  // If the user did not select any states, AND the boundary has no states associates with it,
  // AND there is no area filter set.. force the user to select at least one state.
  //
  // This is here to prevent the user from searching all 50 states, which would just be too slow.
  // NOTE:  But they still CAN do it by doing a Viewport search on the entire US.

  const haveBoundaryStatesList = store_project.boundary && store_project.boundary.settings && store_project.boundary.settings.states && store_project.boundary.settings.states.length > 0;

  if(userSelectedStates.length === 0 && !haveBoundaryStatesList && areaFilter !== 'AOI' && areaFilter !== 'Viewport')
  {
    ToastNotification('error',`Please specify a state to search, or use a viewport/AOI area filter`);
    return false;
  }

  // Call API to run the search

  const server = new CallServer();
  server.AddJson("search", searchExpression);
  if(store_project.boundary)
    server.Add("boundary_id", store_project.boundary.boundary_id);

  // Generate a unique and random "run instance ID" to identify this parcel search
  // run (used for Cancel feature), and save it in the state store before we start 
  // the server call.
  const parcelSearchRunInstanceID = Math.random();
  useStore.getState().store_setParcelSearchRunInstanceID(parcelSearchRunInstanceID);

  useStore.getState().store_setParcelSearchIsRunning(true);

  const result = await server.Call('get', '/parcel_search');

  // Now that the parcel search run has finished, check if the currently-stored "instance run ID" 
  // still matches the one saved in the state store (used for Cancel feature).
  if(useStore.getState().store_parcelSearchRunInstanceID !== parcelSearchRunInstanceID)
  {
    // This parcel search run's instance ID does NOT match what is currently in the state 
    // store.  That means either the user hit the CANCEL button or started running a new search
    // before this one finished.  In either case, we simply ignore the returned data and exit.
    Debug.warn('ParcelOps.RunParcelSearch> Ignoring returned parcel search data (assuming the user canceled)');
    return true;
  }

  // Now that this run is done successfully, we no longer need the parcel search run instance ID
  useStore.getState().store_setParcelSearchRunInstanceID(undefined);
  
  useStore.getState().store_setParcelSearchIsRunning(false);

  if(result.success)
  {
    //Debug.log('ParcelOps.RunParcelSearch> SUCCESS! data=' + JSON.stringify(result.data));

    if(!result.data)
    {
      ToastNotification('error','An error occurred while running this search (1)');
      Debug.error(`ParcelOps.RunParcelSearch> Received null or invalid data (1)`);
      return false;
    }

    const selectedParcels: IParcel[] | undefined = result.data.attributes;
    if(!selectedParcels)
    {
      ToastNotification('error','An error occurred while running this search (2)');
      Debug.error(`ParcelOps.RunParcelSearch> Received numm or invalid data (2)`);
      return false;
    }

    // Update the state store
    useStore.getState().store_setSelectedParcels(selectedParcels);

    EnterParcelSearchResultsViewerMode();
    UpdateParcelSelectionLayerOnMap();
    UpdateParcelTotalSelectedAcres();

    // Zoom to the extent of the returned parcels.
    // NOTE: For "Viewport" searching, we don't do this.

    if(!searchExpression.area_filter_bbox)
    {
      // Merge all the parcel polygons into a single polygon, and zoom the map to it
      const mergedParcelsGeoJSON = MergeParcels(selectedParcels);
      ZoomMapToGeojsonExtent(mergedParcelsGeoJSON);
    }

    // Set the initial local/client-side sort in the parcel table details viewer to match the db/server-side sort
    useStore.getState().store_setParcelTableLocalSortAttribName(searchExpression.sort_attribute_name);
    useStore.getState().store_setParcelTableLocalSortOrder(searchExpression.sort_order);

    // Reset the "current" page to 1
    useStore.getState().store_setParcelsTableCurrPage(0);


    // The parcel table viewer will show the user's regular attribute columns, but 
    // they may not include attributes they just used in this search - either in
    // search clauses, or the sort.  In that case, we add temporary "extra" attribute
    // columns - these will be displayed just for this search, and not saved to the 
    // attribute list stored in the user's project.
    //
    // Extra fields will only be added if not already part of the user's regular list 
    // of attributes.

    const newExtraParcelAttribList: IProjectParcelAttribute[] = [];

    // Check attributes in each search clause

    for(let i=0; i < searchExpression.clauses.length; i++)
    {
      const clause: IParcelSearchClause = searchExpression.clauses[i];

      if(clause.attribute_name)
      {
        const foundAttrib: IProjectParcelAttribute | undefined = store_project.user_settings.parcel_attribute_list.find(attrib => attrib.name === clause.attribute_name) ;
        if(!foundAttrib)  // If not found, add it
          newExtraParcelAttribList.push({name: clause.attribute_name});
      }
    }

    // If there is a sort attribute, check that too

    if(dbSortAttribName)
    {
      const foundAttrib: IProjectParcelAttribute | undefined = store_project.user_settings.parcel_attribute_list.find(attrib => attrib.name === dbSortAttribName) ;
      if(!foundAttrib)  // If not found, add it
      {
        // Make sure it's not already there (could have been added from search clause attributes, just above)
        const found2: IProjectParcelAttribute | undefined = newExtraParcelAttribList.find(attrib => attrib.name === dbSortAttribName) ;
        if(!found2)
          newExtraParcelAttribList.push({name: dbSortAttribName});
      }
    }

    useStore.getState().store_setParcelExtraAttributeList(newExtraParcelAttribList);










    // Success
    Debug.log(`ParcelOps.RunParcelSearch> Parcel selected.`);
    return true;
  }
  else
  {
    // Failure
    Debug.error(`ParcelOps.RunParcelSearch> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Enter parcel search results viewing mode.
//-------------------------------------------------------------------------------
export function EnterParcelSearchResultsViewerMode()
{
  useStore.getState().store_setParcelSearchResultsViewerMode(true);
}

//-------------------------------------------------------------------------------
// Exit parcel search results viewing mode.
//-------------------------------------------------------------------------------
export function ExitParcelSearchResultsViewerMode()
{
  // Clear out any currently-selected parcels
  //useStore.getState().store_setSelectedParcels([]);
  //useStore.getState().store_setSelectedParcelsTotalAcres(0);
  //RemoveParcelSelectionLayerFromMap();

  // Exit parcel search results viewer mode
  useStore.getState().store_setParcelSearchResultsViewerMode(false);
}

//-------------------------------------------------------------------------------
// Get parcel layer attributes (sorted by display_name).
//-------------------------------------------------------------------------------
export function GetParcelAttributes(): IVectorLayerAttribute[]
{
  const parcelLayer: ILayer | undefined = GetParcelLayer();
  if(!parcelLayer || !parcelLayer.attributes) return [];

  return parcelLayer.attributes.sort((a: IVectorLayerAttribute, b: IVectorLayerAttribute) => a.display_name.localeCompare(b.display_name));
}

  //-------------------------------------------------------------------------------
  // Auto-capitalize a parcel owner string.
  //-------------------------------------------------------------------------------
  export function CapitalizeParcelOwner(owner: string | undefined): string
  {
    if(!owner) return '';

    let recap = CapitalizeAllWords(owner as string);

    // Fix a few known capitalization issues

    recap = recap?.replaceAll('Iii', 'III');
    recap = recap?.replaceAll('Ii', 'II');
    recap = recap?.replaceAll('And ', 'and ');
    recap = recap?.replaceAll('Of ', 'of ');
    recap = recap?.replaceAll('For ', 'for ');
    recap = recap?.replaceAll('Llc', 'LLC');
    recap = recap?.replaceAll('Lllp', 'LLLP');
    recap = recap?.replaceAll('U.s.', 'U.S.');
    recap = recap?.replaceAll('Usa', 'USA');
    
    // Capitalize the first letter following any of these chars: \ -
    //
    // eg: Wiedel/kevin R
    // eg: City of Hebron (rose Hill Cemetery)
    recap = recap?.replace(/(^|\/|-)(\S)/g, s=>s.toUpperCase());
    if(!recap) return '';

    return recap;
  }

//-------------------------------------------------------------------------------
// Export data for selected parcels to a CSV file.
//-------------------------------------------------------------------------------
export async function ExportSelectedParcelsToCSVFile() : Promise<boolean>
{
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.user_settings || !store_project.user_settings.parcel_attribute_list) return false;

  const store_selectedParcels = useStore.getState().store_selectedParcels;
  if(!store_selectedParcels) return false;

  let csvData: string = "";

  // Add the HEADER row (list of all column names)

  // HEADER: Permanent user attribute list

  for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
  {
    const attrib: IProjectParcelAttribute = store_project.user_settings.parcel_attribute_list[i];

    csvData += GetEscapedCSVValue(attrib.name);
    if(i < store_project.user_settings.parcel_attribute_list.length - 1)
      csvData += ',';
  }

  // HEADER: Temp/extra attribute list (used to show extra fields when searching)

  const store_parcelExtraAttributeList = useStore.getState().store_parcelExtraAttributeList;
  if(store_parcelExtraAttributeList && store_parcelExtraAttributeList.length > 0)
    csvData += ',';

  for(let i=0; i < store_parcelExtraAttributeList.length; i++)
  {
    const attrib: IProjectParcelAttribute = store_parcelExtraAttributeList[i];

    csvData += GetEscapedCSVValue(attrib.name);
    if(i < store_parcelExtraAttributeList.length - 1)
      csvData += ',';
  }

  csvData += '\n';

  // Add all DATA rows

  for(let r=0; r < store_selectedParcels.length; r++)
  {
    const parcel: IParcel = store_selectedParcels[r];

    // DATA ROWS: Permanent user attribute list

    for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
    {
      const attrib: IProjectParcelAttribute = store_project.user_settings.parcel_attribute_list[i];
      const value = GetTableCellValueForExport(parcel, attrib.name);

      csvData += GetEscapedCSVValue(value as string);
      if(i < store_project.user_settings.parcel_attribute_list.length - 1)
        csvData += ',';
    }

    // DATA ROWS: Temp/extra attribute list (used to show extra fields when searching)

    const store_parcelExtraAttributeList = useStore.getState().store_parcelExtraAttributeList;
    if(store_parcelExtraAttributeList && store_parcelExtraAttributeList.length > 0)
      csvData += ',';

    for(let i=0; i < store_parcelExtraAttributeList.length; i++)
    {
      const attrib: IProjectParcelAttribute = store_parcelExtraAttributeList[i];
      const value = GetTableCellValueForExport(parcel, attrib.name);

      csvData += GetEscapedCSVValue(value as string);
      if(i < store_project.user_settings.parcel_attribute_list.length - 1)
        csvData += ',';
    }

    csvData += '\n';
  }

  // Auto-open the file in the browser (the browser should treat it as a Downloaded file)

  const filename: string = `StratifyX (${store_selectedParcels.length} parcels).csv`;

  const url = window.URL.createObjectURL(new Blob([csvData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // Success
  return true;
}

  //-------------------------------------------------------------------------------
  // Returns a table cell value (with appropriate formatting).
  //-------------------------------------------------------------------------------
  function GetTableCellValueForExport (parcelData: IParcel, attribute_name: string): string
  {
    const attrib: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(attribute_name);
    if(!attrib) return '';

    let value: string | number | undefined = parcelData.attributes[attribute_name as keyof IParcelAttributes];
    if(value === undefined) return '';

    // Formatting for some special cases

    // Auto-capitalize: county, mailadd, mail_city
    if(attribute_name === 'county' || attribute_name === 'mailadd' || attribute_name === 'mail_city')
      value = CapitalizeAllWords(value as string);

    // Auto-capitalize the owner
    if(attribute_name === 'owner')
      value = CapitalizeParcelOwner(value as string);

    // Currency attributes
    if(attribute_name === 'landval' || attribute_name === 'parval')
      value = FriendlyCurrency(value as number);
    // All other numerical attributes
    else if(attrib.data_type === 'number')  // all other numbers
      value = FriendlyNumber(value as number, attrib.decimal_places);

    if(value === undefined) return '';
    if(typeof value === 'number') return value.toString();

    return value;
  }