// Color gradient presets selection component

import { MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { ColorGradientBar } from "./ColorGradientBar";
import { ReactNode } from "react";
import { theme_textColorBlended, theme_textColorMain } from "../../../Theme";
import { IPortfolioMapColorScheme_Gradient } from "../PortfolioMapInterfaces";
import { CustomSelect } from "../EditAoiGroupProperties";

export const GRADIENT_PRESET_EMPTY_ID = -1;

const COLOR_GRADIENT_PRESETS: IPortfolioMapColorScheme_Gradient[] =
[
  { 
    id: 1,
    name: 'Bathymetry #3', 
    items: 
    [
      { id: 1, value: 0,   color: "#002673" },
      { id: 2, value: 20,  color: "#003FAA" },
      { id: 3, value: 40,  color: "#005FE4" },
      { id: 4, value: 60,  color: "#2990FF" },
      { id: 5, value: 80,  color: "#77C4FF" },
      { id: 6, value: 100, color: "#C0E9FF" },
    ]
  },
  { 
    id: 2,
    name: 'Reds (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#FFF5F0" },
      { id: 2, value: 20,  color: "#FDCCB8" },
      { id: 3, value: 40,  color: "#FC8969" },
      { id: 4, value: 60,  color: "#F14130" },
      { id: 5, value: 80,  color: "#B91419" },
      { id: 6, value: 100, color: "#67000D" },
    ]
  },
  { 
    id: 3,
    name: 'Yellow-Orange-Red (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#FFFFCC" },
      { id: 2, value: 20,  color: "#FEE289" },
      { id: 3, value: 40,  color: "#FEAA49" },
      { id: 4, value: 60,  color: "#FC572C" },
      { id: 5, value: 80,  color: "#D10D21" },
      { id: 6, value: 100, color: "#800026" },
    ]
  },
  { 
    id: 4,
    name: 'Blue-Green (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#FFFFFF" },
      { id: 2, value: 20,  color: "#D7F0EE" },
      { id: 3, value: 40,  color: "#8ED4C1" },
      { id: 4, value: 60,  color: "#46B17D" },
      { id: 5, value: 80,  color: "#127C39" },
      { id: 6, value: 100, color: "#00441B" },
    ]
  },
  { 
    id: 5,
    name: 'Blues (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#FFFFFF" },
      { id: 2, value: 20,  color: "#D1E2F3" },
      { id: 3, value: 40,  color: "#93C4DF" },
      { id: 4, value: 60,  color: "#4896C8" },
      { id: 5, value: 80,  color: "#1561A9" },
      { id: 6, value: 100, color: "#08306B" },
    ]
  },
  { 
    id: 6,
    name: 'Precipitation', 
    items: 
    [
      { id: 1, value: 0,   color: "#C2513C" },
      { id: 2, value: 20,  color: "#ED9C14" },
      { id: 3, value: 40,  color: "#F9FA0E" },
      { id: 4, value: 60,  color: "#01D909" },
      { id: 5, value: 80,  color: "#1F9692" },
      { id: 6, value: 100, color: "#0B2B7C" },
    ]
  },
  { 
    id: 7,
    name: 'Prediction', 
    items: 
    [
      { id: 1, value: 0,   color: "#7CB4CD" },
      { id: 2, value: 20,  color: "#99C3B5" },
      { id: 3, value: 40,  color: "#D5E890" },
      { id: 4, value: 60,  color: "#F5D666" },
      { id: 5, value: 80,  color: "#F58B3D" },
      { id: 6, value: 100, color: "#ED2425" },
    ]
  },
  { 
    id: 8,
    name: 'Slope', 
    items: 
    [
      { id: 1, value: 0,   color: "#6DBF44" },
      { id: 2, value: 20,  color: "#77C900" },
      { id: 3, value: 40,  color: "#CBED00" },
      { id: 4, value: 60,  color: "#FFCB00" },
      { id: 5, value: 80,  color: "#FF6200" },
      { id: 6, value: 100, color: "#E5302F" },
    ]
  },
  { 
    id: 9,
    name: 'Orange-Purple (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#803C0A" },
      { id: 2, value: 20,  color: "#DD7F13" },
      { id: 3, value: 40,  color: "#FEDEB4" },
      { id: 4, value: 60,  color: "#D6D7E9" },
      { id: 5, value: 80,  color: "#7B6BA8" },
      { id: 6, value: 100, color: "#2D004B" },
    ]
  },
  { 
    id: 10,
    name: 'Red-Yellow-Blue (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#A60228" },
      { id: 2, value: 20,  color: "#F26941" },
      { id: 3, value: 40,  color: "#FEDF8F", },
      { id: 4, value: 60,  color: "#DDF1F7" },
      { id: 5, value: 80,  color: "#6FA6CE" },
      { id: 6, value: 100, color: "#313695" },
    ]
  },
  { 
    id: 11,
    name: 'Cold to Hot Diverging', 
    items: 
    [
      { id: 1, value: 0,   color: "#5E88BB" },
      { id: 2, value: 20,  color: "#9EB2C5" },
      { id: 3, value: 40,  color: "#DBE3CD" },
      { id: 4, value: 60,  color: "#FADFAE" },
      { id: 5, value: 80,  color: "#F09568" },
      { id: 6, value: 100, color: "#DE4732" },
    ]
  },
  { 
    id: 12,
    name: 'Condition Number', 
    items: 
    [
      { id: 1, value: 0,   color: "#196B20" },
      { id: 2, value: 20,  color: "#5FA612" },
      { id: 3, value: 40,  color: "#C1E019" },
      { id: 4, value: 60,  color: "#FBDD17" },
      { id: 5, value: 80,  color: "#FD980C" },
      { id: 6, value: 100, color: "#FA3C1C" },
    ]
  },
  { 
    id: 13,
    name: 'Multipart Color Scheme', 
    items: 
    [
      { id: 1, value: 0,   color: "#0D2E7B" },
      { id: 2, value: 20,  color: "#1F9891" },
      { id: 3, value: 40,  color: "#03DA06" },
      { id: 4, value: 60,  color: "#FCFB01" },
      { id: 5, value: 80,  color: "#EB9B16" },
      { id: 6, value: 100, color: "#C2523C" },
    ]
  },  
  { 
    id: 14,
    name: 'Brown-Green (Continuous)', 
    items: 
    [
      { id: 1, value: 0,   color: "#4F3303" },
      { id: 2, value: 20,  color: "#BA7D2A" },
      { id: 3, value: 40,  color: "#F5E6BF" },
      { id: 4, value: 60,  color: "#C3E8E1" },
      { id: 5, value: 80,  color: "#2E928A" },
      { id: 6, value: 100, color: "#003C30" },
    ]
  },
]


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ColorGradientPresetsProps
{
  allowEmptySelection: boolean;
  selectedPresetID?: number;
  OnPresetChanged(gradient_preset: IPortfolioMapColorScheme_Gradient | undefined): any;
}

//-------------------------------------------------------------------------------
// Color gradient presets selection component
//-------------------------------------------------------------------------------
export const ColorGradientPresets = (props: ColorGradientPresetsProps) =>
{
  










  //-------------------------------------------------------------------------------
  // The user has selected a new gradient preset.
  //-------------------------------------------------------------------------------
  const OnPresetChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    const newPresetID: number = event.target.value as number;

    const gradient_preset: IPortfolioMapColorScheme_Gradient | undefined = GetGradientPreset(newPresetID);

    // Notify the caller that a new preset was selected
    props.OnPresetChanged(gradient_preset);
  }















  return (

    <Stack>

      <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended, opacity: 0.8 }}>
        Gradient Preset
      </Typography>

      <CustomSelect
        variant='standard' size='small'
        value={props.selectedPresetID}
        onChange={OnPresetChanged}
        sx={{ minWidth: '100px' }}
        displayEmpty={true}
        renderValue={function(selected: any)
          {
            const preset = GetGradientPreset(selected as number);

            if(!selected || !preset)
            return (
              <Typography sx={{ width: '100%', fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.4, textAlign: 'left' }}>
                Not set
              </Typography>
            )

            return (
              <Stack>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, textAlign: 'left' }}>
                  {preset.name}
                </Typography>
                <Stack sx={{ width: '100%', height: '15px' }}>
                  <ColorGradientBar gradient={preset} />
                </Stack>
              </Stack>
            )
          }
        }
      >
        {/* Optional empty item (allows select nothing) */}
        {props.allowEmptySelection === true
          ?
            <MenuItem key={GRADIENT_PRESET_EMPTY_ID} value={GRADIENT_PRESET_EMPTY_ID} sx={{ px: 1, py: 0.5, width: '100%', justifyContent: 'space-between' }}>
              <Typography sx={{ width: '180px', minWidth: '180px', maxWidth: '180px', fontSize: '0.7rem', color: theme_textColorBlended, textAlign: 'left' }}>
                None
              </Typography>
            </MenuItem>
          :null
        }

        {COLOR_GRADIENT_PRESETS.map(gradient => 
          <MenuItem key={gradient.id} value={gradient.id} sx={{ px: 1, py: 0.5, width: '100%', justifyContent: 'space-between' }}>
            <Typography sx={{ width: '180px', minWidth: '180px', maxWidth: '180px', fontSize: '0.7rem', color: theme_textColorBlended, textAlign: 'left' }}>
              {gradient.name}
            </Typography>
            <Stack sx={{ width: '100%', height: '15px' }}>
              <ColorGradientBar gradient={gradient} />
            </Stack>
          </MenuItem>
        )}

      </CustomSelect>
    </Stack>
  );
}




//-------------------------------------------------------------------------------
// Returns the gradient preset for the specified gradient preset id
//-------------------------------------------------------------------------------
export function GetGradientPreset(gradient_preset_id: number | undefined): IPortfolioMapColorScheme_Gradient | undefined
{
  if(gradient_preset_id === undefined) 
    return undefined;

  return COLOR_GRADIENT_PRESETS.find(p => p.id === gradient_preset_id);
}

