// Organization management

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight, theme_bgColorMain, theme_orange, theme_limeGreen } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { IOrgManagement_Boundary, IOrgManagement_Data, IOrgManagement_Org } from "./OrgManagementInterfaces";
import { DeleteBoundary, DeleteOrg, LoadAdminOrgAndBoundaryData } from "./OrgManagementOps";
import MyCircularProgress from "../../Components/CircularProgress";
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";
import { FriendlyTimeSpan } from "../../Globals";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomTab, CustomTabPanel } from "../../LayerLibrary/EditLayer/EditLayer";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import FenceIcon from '@mui/icons-material/Fence';
import OrgEditor from "./OrgEditor";
import TypographyWithAutoTooltip from "../../Components/TypograpyWithAutoTooltip";
import YesNoDialog from "../../Components/YesNoDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ToastNotification } from "../../ToastNotifications";
import BoundaryEditor from "./BoundaryEditor";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface OrgManagementProps
{
  showOrgManagement: boolean;
  setShowOrgManagement: any;
}

//-------------------------------------------------------------------------------
// Organization management component
//-------------------------------------------------------------------------------
const UserManagement = (props: OrgManagementProps) => 
{
  const [orgData, setOrgData] = useState<IOrgManagement_Data|undefined>(undefined);
  const [orgDataIsLoading, setOrgDataIsLoading] = useState<boolean>(false);
  const [showOrgEditor, setShowOrgEditor] = useState<boolean>(false);
  const [editOrg, setEditOrg] = useState<IOrgManagement_Org|undefined>(undefined);
  const [activeTab, setActiveTab] = useState(0); // 0=orgs  1=boundaries
  const [confirmDeleteOrg, setConfirmDeleteOrg] = useState<boolean>(false);
  const [orgToBeDeleted, setOrgToBeDeleted] = useState<IOrgManagement_Org|undefined>(undefined);
  const [confirmDeleteBoundary, setConfirmDeleteBoundary] = useState<boolean>(false);
  const [boundaryToBeDeleted, setBoundaryToBeDeleted] = useState<IOrgManagement_Boundary|undefined>(undefined);
  const [showBoundaryEditor, setShowBoundaryEditor] = useState<boolean>(false);
  const [editBoundary, setEditBoundary] = useState<IOrgManagement_Boundary|undefined>(undefined);



  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load API data every time the window is opened

    if(props.showOrgManagement === true)
    {
      let newOrgData: IOrgManagement_Data | undefined = undefined;
      async function fetchData() 
      {
        newOrgData = await LoadAdminOrgAndBoundaryData(setOrgDataIsLoading);
        setOrgData(newOrgData);
      }
      fetchData();
    }
  }, [props.showOrgManagement])

  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowOrgManagement(false);
    setOrgData(undefined);
    setOrgDataIsLoading(false);
  }

  //-------------------------------------------------------------------------------
  // A "Edit Org" button was pressed.
  //-------------------------------------------------------------------------------
  function OnEditOrg(org: IOrgManagement_Org)
  {
    setEditOrg(org);
    setShowOrgEditor(true);
  }

  //-------------------------------------------------------------------------------
  // A "Delete Org" button was pressed.
  //-------------------------------------------------------------------------------
  function OnConfirmDeleteOrg(org: IOrgManagement_Org)
  {
    if(!orgData) return;

    // We don't allow deleting orgs if there are projects in it
    if(org.stats.project_count > 0)
    {
      ToastNotification('error', 'This organization cannot be deleted because it contains one or more projects (but it can be disabled)');
      return;
    }

    setOrgToBeDeleted(org);
    setConfirmDeleteOrg(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the specified org.
  // 
  // NOTE: This will only be triggered following Yes/No confirmation from the user.
  //-------------------------------------------------------------------------------
  async function OnDeleteOrg()
  {
    if(!orgToBeDeleted || !orgData) return;

    setConfirmDeleteOrg(false);

    const ret: boolean = await DeleteOrg(orgToBeDeleted);
    if(ret !== true)
      return;

    // Remove the org from the local state

    const updatedOrgData: IOrgManagement_Data =
    {
      ...orgData,
      orgs: orgData.orgs.filter(o => o.id !== orgToBeDeleted.id)
    }

    setOrgData(updatedOrgData);
  }

  //-------------------------------------------------------------------------------
  // A "Delete Boundary" button was pressed.
  //-------------------------------------------------------------------------------
  function OnConfirmDeleteBoundary(boundary: IOrgManagement_Boundary)
  {
    if(!orgData) return;

    // We don't allow deleting boundaries if there are organizations that use it
    if(orgData.orgs.find(o=>o.boundary_id===boundary.id))
    {
      ToastNotification('error', 'This boundary cannot be deleted while it is being used by one or more organizations');
      return;
    }
  
    setBoundaryToBeDeleted(boundary);
    setConfirmDeleteBoundary(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the specified boundary.
  // 
  // NOTE: This will only be triggered following Yes/No confirmation from the user.
  //-------------------------------------------------------------------------------
  async function OnDeleteBoundary()
  {
    if(!boundaryToBeDeleted || !orgData) return;

    setConfirmDeleteBoundary(false);

    const ret: boolean = await DeleteBoundary(boundaryToBeDeleted);
    if(ret !== true)
      return;

    // Remove the boundary from the local state

    const updatedOrgData: IOrgManagement_Data =
    {
      ...orgData,
      boundaries: orgData?.boundaries.filter(b => b.id !== boundaryToBeDeleted.id)
    }

    setOrgData(updatedOrgData);    
  }

  //-------------------------------------------------------------------------------
  // A "Edit Boundary" button was pressed.
  //-------------------------------------------------------------------------------
  function OnEditBoundary(boundary: IOrgManagement_Boundary)
  {
    setEditBoundary(boundary);
    setShowBoundaryEditor(true);
  }

  //-------------------------------------------------------------------------------
  // Return the specified boundary.
  //-------------------------------------------------------------------------------
  function GetBoundary(boundary_id: number | null | undefined): IOrgManagement_Boundary | undefined
  {
    if(!boundary_id || !orgData) return undefined;
    return orgData.boundaries.find(b => b.id === boundary_id);
  }

  //-------------------------------------------------------------------------------
  // Returns the number of orgs that use the specified boundary.
  //-------------------------------------------------------------------------------
  function GetBoundaryUseCount(boundary_id: number | null): number | undefined
  {
    if(!boundary_id || !orgData) return undefined;

    let count: number = 0;
    for(let i=0; i < orgData.orgs.length; i++)
      if(orgData.orgs[i].boundary_id === boundary_id)
        count++;

    return count;
  }  

  //-------------------------------------------------------------------------------
  // Renders the org state table cell for an org.
  //-------------------------------------------------------------------------------
  function RenderOrgState(org: IOrgManagement_Org)
  {
    let bgColorStr: string = '';
    let colorStr: string = '';
    let outputStr: string = '[Unknown]';

    if(org.state === 'Disabled')
    {
      colorStr = '#B0B0B0';
      bgColorStr = '#505050';
      outputStr = 'Disabled';
    }
    else if(org.expired)
    {
      colorStr = '#F0A0A0';
      bgColorStr = '#500000';
      outputStr = 'Expired';
    }
    else if(org.state === 'Enabled')
    {
      // Active = Enabled and not expired
      colorStr = '#D5FFD5';
      bgColorStr = '#60A060';
      outputStr = 'Active';
    }
    else
      colorStr = '#A05050';

    let willExpireInStr: string | undefined = undefined;
    if(org.expiration_date && org.expired === false)
      willExpireInStr = FriendlyTimeSpan(dayjs(org.expiration_date).diff(dayjs()));
  
    return (
      <Stack sx={{alignItems: 'center' }}>
        <Typography sx={{ px: '10px', py: '1px', color: colorStr, bgcolor: bgColorStr, fontSize: '0.8rem', borderRadius: 1, textAlign:'center' }}>
          {outputStr}
        </Typography>

        {org.state === 'Enabled' && org.expiration_date
          ?
            org.expired
              ?
                <Typography noWrap sx={{ color: '#AA7070', fontSize: '0.6rem' }}>
                  {org.expiration_date}
                </Typography>
              :
                <Typography noWrap sx={{ color: '#70A070', fontSize: '0.6rem' }}>
                  Expires in {willExpireInStr}
                </Typography>
          :null
        }

      </Stack>
    )
  }  

  //-------------------------------------------------------------------------------
  // Renders the org settings table cell for an org.
  //-------------------------------------------------------------------------------
  function RenderOrgSettings(org: IOrgManagement_Org)
  {

    return (

      <Typography sx={{ color: theme_bgColorMain, fontSize: '0.6rem' }}>
        {org.settings.show_boundary === false ? 'Boundary hidden' : 'Boundary visible'}
        {', '}
        {org.settings.allow_project_sharing === false ? 'Projects cannot be shared' : 'Projects can be shared'}
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // Switch tabs.
  //-------------------------------------------------------------------------------
  const OnTabChange = (event: React.SyntheticEvent, newValue: number) => 
  {
    setActiveTab(newValue);
  }
      
  //-------------------------------------------------------------------------------
  // Open dialog to create a new organization.
  //-------------------------------------------------------------------------------
  function OnCreateNewOrg()
  {
    setEditOrg(undefined);
    setShowOrgEditor(true);
  }

  //-------------------------------------------------------------------------------
  // Open dialog to create a new boundary.
  //-------------------------------------------------------------------------------
  function OnCreateNewBoundary()
  {
    setEditBoundary(undefined);
    setShowBoundaryEditor(true);
  }

  //-------------------------------------------------------------------------------
  // Renders the boundary settings table cell for an org.
  //-------------------------------------------------------------------------------
  function RenderBoundarySettings(boundary: IOrgManagement_Boundary)
  {
    if(!boundary.settings.states || boundary.settings.states.length === 0)
      return null;

    return (
      <Stack direction='row'>
        <TypographyWithAutoTooltip sx={{ color: theme_bgColorMain, fontSize: '0.6rem' }}>
          States ({boundary.settings.states.length}): {boundary.settings.states.toString()}
        </TypographyWithAutoTooltip>
      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function GetBoundaryPointCount(boundary: IOrgManagement_Boundary): number | undefined
  {
    if(!boundary || !boundary.geom) return undefined;

    // Very dumb method for now - just counts commas.  Works ok for single-polygon boundaries...

    let count=0;
    for(let i=0; i < boundary.geom.length; i++)
      if(boundary.geom[i] === ',')
        count++;

    return count + 1;
  }

  //-------------------------------------------------------------------------------
  // Called after a new org has been created, or an existing org has been updated.
  //-------------------------------------------------------------------------------
  function NotifyCreatedOrUpdatedOrg(org: IOrgManagement_Org, createdNewOrg: boolean)
  {
    if(!orgData || !org) return;

    if(createdNewOrg)
    {
      // Add the newly-created org

      const newOrgs: IOrgManagement_Org[] = [...orgData.orgs, org];
      newOrgs.sort((a: IOrgManagement_Org, b: IOrgManagement_Org) => a.name.localeCompare(b.name)); // Re-sort

      const updatedOrgData: IOrgManagement_Data =
      {
        ...orgData,
        orgs: newOrgs
      }

      setOrgData(updatedOrgData);
    }
    else
    {
      // Update an existing org

      const newOrgs: IOrgManagement_Org[] = orgData.orgs.map(oldOrg => oldOrg.id === org.id ? org : oldOrg);
      newOrgs.sort((a: IOrgManagement_Org, b: IOrgManagement_Org) => a.name.localeCompare(b.name)); // Re-sort

      const updatedOrgData: IOrgManagement_Data =
      {
        ...orgData,
        orgs: newOrgs
      }

      setOrgData(updatedOrgData);
    }
  }

  //-------------------------------------------------------------------------------
  // Called after a new org has been created, or an existing org has been updated.
  //-------------------------------------------------------------------------------
  function NotifyCreatedOrUpdatedBoundary(boundary: IOrgManagement_Boundary, createdNewBoundary: boolean)
  {
    if(!orgData || !boundary) return;

    if(createdNewBoundary)
    {
      // Add the newly-created boundary

      // const newOrgs: IOrgManagement_Org[] = [...orgData.orgs, boundary];
      // newOrgs.sort((a: IOrgManagement_Org, b: IOrgManagement_Org) => a.name.localeCompare(b.name)); // Re-sort

      // const updatedOrgData: IOrgManagement_Data =
      // {
      //   ...orgData,
      //   orgs: newOrgs
      // }

      // setOrgData(updatedOrgData);
    }
    else
    {
      // Update an existing boundary

      // const newOrgs: IOrgManagement_Org[] = orgData.orgs.map(oldOrg => oldOrg.id === boundary.id ? boundary : oldOrg);
      // newOrgs.sort((a: IOrgManagement_Org, b: IOrgManagement_Org) => a.name.localeCompare(b.name)); // Re-sort

      // const updatedOrgData: IOrgManagement_Data =
      // {
      //   ...orgData,
      //   orgs: newOrgs
      // }

      // setOrgData(updatedOrgData);
    }
  }







        

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog open={props.showOrgManagement===true} onClose={OnClose} maxWidth='xl' disablePortal
            PaperProps={{ sx: { minWidth: '30%', width: '70%', maxWidth: '70%', minHeight: '90vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

          {/* Tab definitions */}

          <Tabs value={activeTab} onChange={OnTabChange}>
            <CustomTab icon={<MapsHomeWorkIcon sx={{ color: theme_limeGreen }} />} label='Organization Management'/>
            <CustomTab icon={<FenceIcon  sx={{ color: theme_limeGreen }}/>} label='Boundary Management'/>
          </Tabs>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      {orgDataIsLoading === true
        ?
          <DialogContent sx={{ background: theme_bgColorGradient2 }}>
            <Stack sx={{ alignItems: 'center', mt: 5 }}>
              
              <Stack direction='row' sx={{ alignItems: 'center' }}>
                <MyCircularProgress />
                <Typography sx={{ width: '100%', fontSize: '1.5rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
                  Loading...
                </Typography>
              </Stack>
              
            </Stack>
          </DialogContent>
        :
          <DialogContent sx={{ p: 0, background: theme_bgColorGradient2 }}>

            <Stack sx={{ mt: 0, alignItems: 'left' }}>

              {/* --- Organizations --- */}

              <CustomTabPanel value={activeTab} index={0}>

                <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

                  <Stack direction='row' sx={{ mt: 0, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
                      Organizations
                    </Typography>
                    <Typography sx={{ ml: 2, fontSize: '1.0rem', color: theme_textColorBlended }}>
                      {`${orgData?.orgs.length}`}
                    </Typography>
                  </Stack>

                  <Stack direction='row' sx={{ mt: 1 }}>
                    <Button variant='outlined' startIcon={<AddCircleOutlineIcon/>} 
                            sx={{ textTransform: 'none' }} onClick={_=>OnCreateNewOrg()}>
                      Create New Organization
                    </Button>
                  </Stack>

                </Stack>

                <TableContainer component={Paper} sx={{ mt: 0.5, boxShadow: 5 }}>
                  <Table size="small" sx={{ minWidth: 650, bgcolor: theme_textColorBlended+'80' }}>
                    <TableHead>
                      <TableRow sx={{ "& .MuiTableCell-head": { fontSize: '1.0rem', color: theme_bgColorMain, fontWeight: 'bold', backgroundColor: '#8CB3D9'},}}>
                        <TableCell align='left' sx={{px:'5px', py: '5px'}}>Name</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Boundary</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Status</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Settings</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {orgData?.orgs.map(function(org)
                      {
                        return (

                          <TableRow key={org.id}>

                            {/* Name */}

                            <TableCell align='left' sx={{ p: 0.5 }}>

                              <Typography sx={{ color: '#505070', fontSize: '1rem', fontWeight: 'bold' }}>
                                {org.name}
                              </Typography>

                              <Stack direction='row' sx={{ alignItems: 'center' }}>
                                <Typography sx={{ color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                  id <b>{org.id}</b>
                                </Typography>
                                <Typography sx={{ ml: 2, color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                  <b>{org.stats?.user_count ?? '?'}</b> users
                                </Typography>
                                <Typography sx={{ ml: 1.5, color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                  <b>{org.stats?.project_count ?? '?'}</b> projects
                                </Typography>
                                {org.stats && org.stats.count_custom_layers > 0
                                  ?
                                    <Typography sx={{ ml: 1.5, color: theme_bgColorLight, opacity: 0.7, fontSize: '0.6rem' }}>
                                      <b>{org.stats.count_custom_layers}</b> custom layer{org.stats.count_custom_layers===1?'':'s'}
                                    </Typography>
                                  :null
                                }
                                
                              </Stack>

                            </TableCell>

                            {/* Boundary */}

                            <TableCell align='center' sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.5 }}>
                              {org.boundary_id
                                ? 
                                  GetBoundary(org.boundary_id)?.name 
                                :
                                  <Typography sx={{ color: 'gray', opacity: 0.8, fontSize: '0.8rem' }}>
                                    No Boundary
                                  </Typography>
                              }
                            </TableCell>

                            {/* Status */}

                            <TableCell align='center' sx={{ p: 0.5 }}>
                              {RenderOrgState(org)}
                            </TableCell>

                            {/* Settings */}

                            <TableCell align='center' sx={{ p: 0.5, maxWidth: '250px' }}>
                              {RenderOrgSettings(org)}
                            </TableCell>

                            {/* Utility Buttons */}

                            <TableCell align='right' sx={{ p: 0.1 }}>
                              <IconButton sx={{ p: 0.3, opacity: 0.8 }} onClick={_=>OnConfirmDeleteOrg(org)}>
                                <DeleteIcon sx={{ width: '20px', height: '20px' }} />
                              </IconButton>
                              <IconButton sx={{ ml: '5px', p: 0.3 }} onClick={_=>OnEditOrg(org)}>
                                <EditIcon/>
                              </IconButton>
                            </TableCell>

                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </CustomTabPanel>

              {/* --- Boundaries --- */}

              <CustomTabPanel value={activeTab} index={1}>

                <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

                  <Stack direction='row' sx={{ mt: 0, alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
                      Boundaries
                    </Typography>
                    <Typography sx={{ ml: 2, fontSize: '1.0rem', color: theme_textColorBlended }}>
                      {`${orgData?.boundaries.length}`}
                    </Typography>
                  </Stack>

                  <Stack direction='row' sx={{ mt: 1 }}>
                    <Button variant='outlined' startIcon={<AddCircleOutlineIcon/>} 
                            sx={{ textTransform: 'none' }} onClick={_=>OnCreateNewBoundary()}>
                      Create New Boundary
                    </Button>
                  </Stack>

                </Stack>

                <TableContainer component={Paper} sx={{ mt: 0.5, boxShadow: 5 }}>
                  <Table size="small" sx={{ minWidth: 650, bgcolor: theme_textColorBlended+'80' }}>
                    <TableHead>

                      <TableRow sx={{ "& .MuiTableCell-head": { fontSize: '1.0rem', color: theme_bgColorMain, fontWeight: 'bold', backgroundColor: '#8CB3D9'},}}>
                        <TableCell align='left' sx={{px:'5px', py: '5px'}}>Name</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: '5px'}}>Orgs</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Geometry</TableCell>
                        <TableCell align='center' sx={{px:'5px', py: 0}}>Settings</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                    {orgData?.boundaries.map(function(boundary)
                      {
                        return (

                          <TableRow key={boundary.id}>

                            {/* Name */}

                            <TableCell align='left' sx={{ p: 0.5 }}>

                              <Stack direction='row'>

                                <Typography sx={{ color: '#505070', fontSize: '1rem', fontWeight: 'bold' }}>
                                  {boundary.name}
                                </Typography>

                                <Stack direction='row' sx={{ alignItems: 'center' }}>
                                  <Typography sx={{ ml: 2, color: theme_bgColorLight, opacity: 0.6, fontSize: '0.6rem' }}>
                                    id {boundary.id}
                                  </Typography>
                                </Stack>
                                
                              </Stack>

                            </TableCell>

                            {/* Orgs that use this boundary */}

                            <TableCell align='center' sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.5 }}>
                              {GetBoundaryUseCount(boundary.id)}
                            </TableCell>

                            {/* Geometry */}

                            <TableCell align='center' sx={{ color: theme_bgColorMain, fontSize: '0.9rem', p: 0.5 }}>
                              {GetBoundaryPointCount(boundary) ?? -1 >= 0 ? `${GetBoundaryPointCount(boundary)} points` : ''}
                            </TableCell>

                            {/* Settings */}

                            <TableCell align='center' sx={{ p: 0.5, maxWidth: '250px' }}>
                              {RenderBoundarySettings(boundary)}
                            </TableCell>

                            {/* Utility Buttons */}

                            <TableCell align='right' sx={{ p: 0.1 }}>
                              <IconButton sx={{ p: 0.3, opacity: 0.8 }} onClick={_=>OnConfirmDeleteBoundary(boundary)}>
                                <DeleteIcon sx={{ width: '20px', height: '20px' }} />
                              </IconButton>                              
                              <IconButton sx={{ ml: '5px', p: 0.3 }} onClick={_=>OnEditBoundary(boundary)}>
                                <EditIcon/>
                              </IconButton>
                            </TableCell>

                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </CustomTabPanel>

            </Stack>

            {/* Popup sub-dialogs */}

            <OrgEditor showOrgEditor={showOrgEditor} setShowOrgEditor={setShowOrgEditor}
                       editOrg={editOrg} orgData={orgData} 
                       NotifyCreatedOrUpdatedOrg={NotifyCreatedOrUpdatedOrg}/>

            <BoundaryEditor showBoundaryEditor={showBoundaryEditor} setShowBoundaryEditor={setShowBoundaryEditor}
                            editBoundary={editBoundary} orgData={orgData} 
                            NotifyCreatedOrUpdatedBoundary={NotifyCreatedOrUpdatedBoundary}/>

            {/* "Delete Org" Yes/No confirmation dialog */}
            <YesNoDialog open={confirmDeleteOrg} setOpen={setConfirmDeleteOrg} onYes={OnDeleteOrg}>
              <Stack direction='row' sx={{ alignItems: 'center' }}>
                <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended, width: '42px', height: '42px'  }} />
                <Box sx={{ color: theme_textColorMain, fontSize: '1.2rem' }}>Delete organization</Box>
                <Box sx={{ ml: 1, color: theme_orange, fontSize: '1.3rem', fontWeight: 'bold' }}>{orgToBeDeleted?.name}</Box>
                <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
              </Stack>
            </YesNoDialog>

            {/* "Delete Boundary" Yes/No confirmation dialog */}
            <YesNoDialog open={confirmDeleteBoundary} setOpen={setConfirmDeleteBoundary} onYes={OnDeleteBoundary}>
              <Stack direction='row' sx={{ alignItems: 'center' }}>
                <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended, width: '42px', height: '42px'  }} />
                <Box sx={{ color: theme_textColorMain, fontSize: '1.2rem' }}>Delete boundary</Box>
                <Box sx={{ ml: 1, color: theme_orange, fontSize: '1.3rem', fontWeight: 'bold' }}>{boundaryToBeDeleted?.name}</Box>
                <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
              </Stack>
            </YesNoDialog>

          </DialogContent>
        }

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

          {/* CLOSE button */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Close
              </Button>
            </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default UserManagement;
