// Portfolio Map operations

import Debug from "../../Debug";
import { ZoomMapToGeojsonExtent } from "../../Map/MapOps";
import useStore from "../../store";
import { DEFAULT_AOI_COLOR, DEFAULT_AOI_OPACITY } from "../../Theme";
import { IAoi } from "../AoiInterfaces";
import { ACTIVE_AOI_MAPBOX_LAYER_NAME, ACTIVE_AOI_MAPBOX_LAYER_SOURCE_NAME, MergeAllAois, RemoveAoiLayersFromMap } from "../AoiOps";
import { CheckAoiAttribExpressionMatch, GetAoiAttributeValueForAnAoi, GetAoiAttributeValueForAnAoi_StateStore } from "./AoiAttributeOps";
import { IAoiGroupProperties, IAoiAttribute, IAoiAttributeValue } from "./AoiGroupInterfaces";
import { GetClassifiedColorForValue } from "./ColorSchemeEditor/ColorSchemeEditor_Classified";
import { GetGradientColorForValue } from "./ColorSchemeEditor/ColorSchemeEditor_Gradient";
import { AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP, AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP, AOI_ADMIN_ATTRIBUTE_ID_COLOR, PORTFOLIO_MAP_DEFAULT_BORDER_HALO, PORTFOLIO_MAP_DEFAULT_BORDER_HALO_COLOR, PORTFOLIO_MAP_DEFAULT_BORDER_THICKNESS, PORTFOLIO_MAP_DEFAULT_FILL_OPACITY } from "./EditAoiGroupProperties";
import { IPortfolioMapColorScheme, IPortfolioMapColorScheme_UniqueValuesItem } from "./PortfolioMapInterfaces";
import { Feature, FeatureCollection } from 'geojson';
import * as turf from '@turf/turf'


export const MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_AOIS = 9;  // aois appear as single points instead of full polygons beyond a certain zoom level


//-------------------------------------------------------------------------------
// Returns TRUE if the specified AOI is included in the portfolio map.
// NOTE: If anything goes wrong, the default is returned.
//-------------------------------------------------------------------------------
export function IsAoiInPortfolioMap(aoi_id: number): boolean
{
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

  const store_aoi_attributes: IAoiAttribute[] = store_aoi_group_props.attributes;
  if(!store_aoi_attributes) return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

  for(let i=0; i < store_aoi_attributes.length; i++)
    if(store_aoi_attributes[i].id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP)
    {
      // Found the AOI attribute
      const attribValue: IAoiAttributeValue | undefined = store_aoi_attributes[i].values.find(v => v.aoi_id === aoi_id);
      if(attribValue === undefined)
        return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

      return attribValue.value.toUpperCase() === 'TRUE' ? true : false;
    }

  return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP; // not found
}

//-------------------------------------------------------------------------------
// Returns the active portfolio map color scheme, or undefined if none are active.
//-------------------------------------------------------------------------------
export function GetPortfolioMapActiveColorScheme(): IPortfolioMapColorScheme | undefined
{
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return undefined;

  if(!store_aoi_group_props.portfolio_map || !store_aoi_group_props.portfolio_map.active_color_scheme_id) return undefined;

  return store_aoi_group_props.portfolio_map.color_schemes.find(cs => cs.id === store_aoi_group_props.portfolio_map.active_color_scheme_id);
}

//-------------------------------------------------------------------------------
// Update the portfolio map.
//-------------------------------------------------------------------------------
export function UpdateAoiPortfolioMap()
{
  RemoveAoiLayersFromMap();

  const store_portfolioMapAois = useStore.getState().store_portfolioMapAois;
  if(!store_portfolioMapAois) return;

  // First, remove any AOIs from the list that are not included in the portfolio map

  const aois: IAoi[] = [];
  for(let i=0; i < store_portfolioMapAois.length; i++)
  {
    let include_in_PORTFOLIO_MAP: string | undefined = GetAoiAttributeValueForAnAoi_StateStore(AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP, store_portfolioMapAois[i].aoi_id)?.value;
    if(!include_in_PORTFOLIO_MAP)
      include_in_PORTFOLIO_MAP = AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP === true ? 'TRUE' : 'FALSE';

    if(include_in_PORTFOLIO_MAP.toUpperCase() === 'TRUE')
      aois.push(store_portfolioMapAois[i]);
  }

  // Merge the remaining AOIs into a single AOI
  const mergedAoi: IAoi = MergeAllAois('Portfolio Map', aois);
  useStore.getState().store_setAoi(mergedAoi);

  // Add the AOIs to the map as many individual Mapbox layers (each styled accordigly)
  AddPortfolioMapLayersToMap(aois);

  // Zoom the map to the merged AOI geometry
  if(mergedAoi.geom)
    ZoomMapToGeojsonExtent(mergedAoi.geom);
}

//-------------------------------------------------------------------------------
// Returns the appropriate color for the specified portfolio map AOI.
// If a color scheme is specified, it is used to determine the color.
//-------------------------------------------------------------------------------
export function GetColorForPortfolioMapAOI(aoi: IAoi, attributes: IAoiAttribute[] | undefined, 
                                           colorScheme: IPortfolioMapColorScheme | undefined): string | undefined
{
  // Get the color for this AOI.
  //
  // If a color scheme is active, the color needs to be based on that.  If not, we get the manual color set for the AOI.

  let color: string | undefined = undefined;

  if(colorScheme)
  {
    // A color scheme is active

    // If a filter is set, determine if this AOI is matched by the filter or not

    const passedFilterCheck: boolean | undefined = CheckAoiAttribExpressionMatch(aoi.aoi_id, colorScheme.filter);
    if(passedFilterCheck === true)
    {
      if(colorScheme.type === 'single color')
      {
        // SINGLE COLOR color scheme

        if(colorScheme.single_color !== undefined)
          color = colorScheme.single_color.color;
      }
      else if(colorScheme.type === 'unique values')
      {
        // UNIQUE VALUES color scheme

        if(colorScheme.unique_values === undefined || colorScheme.unique_values.items.length < 1)
        {
          Debug.error('The active portfolio map UNIQUE VALUES color scheme is invalid');
          return undefined; // error
        }

        const aoiAttribValueStr: string | undefined = GetAoiAttributeValueForAnAoi(attributes, colorScheme.aoi_attribute_id, aoi.aoi_id)?.value;
        if(aoiAttribValueStr === undefined)
        {
          // If the color scheme specified a color for 'empty value', use that
          const foundEmptyValueItem: IPortfolioMapColorScheme_UniqueValuesItem | undefined = colorScheme.unique_values.items.find(item => item.value.trim() === '');
          if(foundEmptyValueItem)
            color = foundEmptyValueItem.color;
          else // Fall back to the filtered-out color
            color = colorScheme.filtered_out_color;
        }
        else
        {
          // Try to find the color entry for this value

          const foundItem: IPortfolioMapColorScheme_UniqueValuesItem | undefined = colorScheme.unique_values.items.find(item => item.value.trim().toLowerCase() === aoiAttribValueStr.trim().toLowerCase());
          if(foundItem !== undefined)
            color = foundItem.color;
          else // This means the color scheme has no color defined for this particular value
            color = colorScheme.filtered_out_color;
        }
      }
      else if(colorScheme.type === 'classified')
      {
        // CLASSIFIED color scheme

        if(colorScheme.classified === undefined || colorScheme.classified.items.length === 0)
        {
          Debug.error('The active portfolio map classified color scheme is invalid');
          return undefined; // error
        }

        const aoiAttribValueStr: string | undefined = GetAoiAttributeValueForAnAoi(attributes, colorScheme.aoi_attribute_id, aoi.aoi_id)?.value;
        if(aoiAttribValueStr === undefined)
        {
          Debug.error(`Invalid AOI attribute value (aoi attribute id ${colorScheme.aoi_attribute_id} | aoi id ${aoi.aoi_id}`);
          return colorScheme.filtered_out_color; // error
        }

        const aoiAttribValueNum: number | undefined = Number.parseFloat(aoiAttribValueStr);
        if(aoiAttribValueNum === undefined)
        {
          Debug.error(`Invalid AOI attribute value - not a number (aoi attribute id ${colorScheme.aoi_attribute_id} | aoi id ${aoi.aoi_id}`);
          return colorScheme.filtered_out_color; // error
        }

        const classifiedColor: string | undefined = GetClassifiedColorForValue(aoiAttribValueNum, colorScheme.classified);
        if(classifiedColor)
          color = classifiedColor;
        else // It's possible an attribute's value was modified such that it's outside the old min/max which the color scheme was based on
          color = colorScheme.filtered_out_color;

      }
      else if(colorScheme.type === 'gradient')
      {
        // GRADIENT color scheme

        if(colorScheme.gradient === undefined || colorScheme.gradient.items.length < 2)
        {
          Debug.error('The active portfolio map gradient color scheme is invalid');
          return undefined; // error
        }

        // Figure out the color based on the value and the active gradient color scheme

        const aoiAttribValueStr: string | undefined = GetAoiAttributeValueForAnAoi(attributes, colorScheme.aoi_attribute_id, aoi.aoi_id)?.value;
        if(aoiAttribValueStr === undefined)
        {
          Debug.error(`Invalid AOI attribute value (aoi attribute id ${colorScheme.aoi_attribute_id} | aoi id ${aoi.aoi_id}`);
          return colorScheme.filtered_out_color; // error
        }

        const aoiAttribValueNum: number | undefined = Number.parseFloat(aoiAttribValueStr);
        if(aoiAttribValueNum === undefined)
        {
          Debug.error(`Invalid AOI attribute value - not a number (aoi attribute id ${colorScheme.aoi_attribute_id} | aoi id ${aoi.aoi_id}`);
          return colorScheme.filtered_out_color; // error
        }

        const gradientColor: string | undefined = GetGradientColorForValue(aoiAttribValueNum, colorScheme.gradient);
        if(gradientColor)
          color = gradientColor;
        else // It's possible an attribute's value was modified such that it's outside the old min/max which the color scheme was based on
          color = colorScheme.filtered_out_color;
      }
      else
      {
        Debug.warn('Unsupported color scheme type');
      }
    }
    else
    {
      // This AOI is filtered out
      color = colorScheme.filtered_out_color;
    }
  }
  else
  {
    // No color scheme set - try to get the color from the AOI attributes
    color = GetAoiAttributeValueForAnAoi(attributes, AOI_ADMIN_ATTRIBUTE_ID_COLOR, aoi.aoi_id)?.value;
  }

  // If a color value was not found from any source, use the default color
  if(!color) 
    color = DEFAULT_AOI_COLOR;

  // Done
  return color;
}

//-------------------------------------------------------------------------------
// Add multiple AOI layers to the map (used for the Portfolio Map).
//-------------------------------------------------------------------------------
function AddPortfolioMapLayersToMap(aois: IAoi[])
{
  if(!aois) return;

  // Remove any previous AOI layers from the map (if there are any)
  RemoveAoiLayersFromMap();

  if(aois.length === 0) return;

  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  let mapboxSourceID = 1;
  let mapboxLayerID = 1;

  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  const colorScheme: IPortfolioMapColorScheme | undefined = GetPortfolioMapActiveColorScheme();

  for(let i=0; i < aois.length; i++)
  {
    const aoi: IAoi = aois[i];
    if(!aoi.geom) return;

    const color: string | undefined = GetColorForPortfolioMapAOI(aoi, store_aoi_group_props?.attributes, colorScheme);
    if(!color)
      return;

    // -- Add the polygon layers - used when zoomed in ---

    // Add the mapbox source for this AOI (polygon)

    const mapboxSourceName: string = `${ACTIVE_AOI_MAPBOX_LAYER_SOURCE_NAME}-${mapboxSourceID++}`;

    store_map.addSource(mapboxSourceName,
    {
      type: 'geojson',
      data: aoi.geom,
    })

    // Add mapbox layer 1 (optional polygon fill)

    let portfolioMapFillOpacity: number | undefined = store_aoi_group_props?.portfolio_map.fill_opacity;
    if(portfolioMapFillOpacity === undefined)
      portfolioMapFillOpacity = PORTFOLIO_MAP_DEFAULT_FILL_OPACITY;

    if(portfolioMapFillOpacity !== 0) // Don't add the fill layer if the opacity is set to 0
    {
      const newMapboxLayer1: mapboxgl.Layer = 
      {
        'id': `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-${mapboxLayerID++}`,
        'source': mapboxSourceName,
        'type': 'fill',
        'minzoom': MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_AOIS,
        'paint': 
        {
          'fill-color': color,
          'fill-opacity': portfolioMapFillOpacity,
        }
      };

      store_map.addLayer(newMapboxLayer1);
    }

    // Add mapbox layer 2 (border halo)

    let portfolioMapBorderHalo: boolean | undefined = store_aoi_group_props?.portfolio_map.border_halo;
    if(portfolioMapBorderHalo === undefined)
      portfolioMapBorderHalo = PORTFOLIO_MAP_DEFAULT_BORDER_HALO;

    let portfolioMapBorderThickness: number | undefined = store_aoi_group_props?.portfolio_map.border_thickness;
    if(portfolioMapBorderThickness === undefined)
      portfolioMapBorderThickness = PORTFOLIO_MAP_DEFAULT_BORDER_THICKNESS;

    let portfolioMapBorderColor: string | undefined = store_aoi_group_props?.portfolio_map.border_halo_color;
    if(portfolioMapBorderColor === undefined || portfolioMapBorderColor.length <= 0)
      portfolioMapBorderColor = PORTFOLIO_MAP_DEFAULT_BORDER_HALO_COLOR;

    // Auto-calculate the halo thickness based on the border thickness.
    // NOTE: using a simple formula didn't work that well at the extremes (0.1 thickness vs 10)
    let haloBorderThickness: number;
    if(portfolioMapBorderThickness >= 0 && portfolioMapBorderThickness < 1)
      haloBorderThickness = portfolioMapBorderThickness*2.1 + 0.8;
    else if(portfolioMapBorderThickness >= 1 && portfolioMapBorderThickness < 5)
      haloBorderThickness = portfolioMapBorderThickness*1.6 + 1;
    else // >= 5
      haloBorderThickness = portfolioMapBorderThickness*1.3 + 2;

    if(portfolioMapBorderHalo === true && portfolioMapBorderThickness > 0) // Only add the halo layer if it's enabled and the border thickness is > 0
    {
      const newMapboxLayer2: mapboxgl.Layer = 
      {
        'id': `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-${mapboxLayerID++}`,
        'source': mapboxSourceName,
        'type': 'line',
        'minzoom': MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_AOIS,
        'paint': 
        {
          'line-color': portfolioMapBorderColor,
          'line-width': haloBorderThickness,
          'line-blur': 2,
          //'line-gap-width': 2,
          'line-opacity': DEFAULT_AOI_OPACITY,
        }
      };

      store_map.addLayer(newMapboxLayer2);
    }

    // Add mapbox layer 3 (main solid border)

    if(portfolioMapBorderThickness > 0) // Only add the border if it has a thickness > 0
    {
      const newMapboxLayer3: mapboxgl.Layer = 
      {
        'id': `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-${mapboxLayerID++}`,
        'source': mapboxSourceName,
        'type': 'line',
        'minzoom': MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_AOIS,
        'paint': 
        {
          'line-color': color,
          'line-width': portfolioMapBorderThickness,
          //'line-blur': 0,
          //'line-gap-width': 2,
          'line-opacity': DEFAULT_AOI_OPACITY,
        }
      };

      store_map.addLayer(newMapboxLayer3);
    }

    // -- Add the point layers - appear only when zoomed OUT ---

    // Add the mapbox source (for points)

    const mapboxPointSourceName = `${ACTIVE_AOI_MAPBOX_LAYER_SOURCE_NAME}-${mapboxSourceID++}`;

    store_map.addSource(mapboxPointSourceName,
    {
      type: 'geojson',
      data: GetPointGeoJSONForPortfolioMapAOI(aoi),
    })

    // Point layer 1
    //
    // Active at mid zoom and full zoom-out

    const newMapboxPointLayer1: mapboxgl.Layer = 
    {
      id: `${ACTIVE_AOI_MAPBOX_LAYER_NAME}-${mapboxLayerID++}`,
      source: mapboxPointSourceName,
      type: 'circle',
      maxzoom: MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_AOIS,
      paint: 
      {
        'circle-radius': 4.5,
        'circle-color': color,
        'circle-stroke-width': 1.2,
        'circle-stroke-color': portfolioMapBorderColor,
      }
    }

    store_map.addLayer(newMapboxPointLayer1);
  }
}

//-------------------------------------------------------------------------------
// Returns a FeatureCollection where the AOI is represented as a single point.
// Used to display AOIs when the map is zoomed way out.
//-------------------------------------------------------------------------------
export function GetPointGeoJSONForPortfolioMapAOI(aoi: IAoi): FeatureCollection
{
  const fc: FeatureCollection =
  {
    type: 'FeatureCollection',
    features: []
  }

  const centerPoint: Feature<turf.Point> = turf.center(aoi.geom as any);
  fc.features.push(centerPoint);

  return fc;
}