// User management interfaces



export interface IUserManagement_Data
{
  users: IUserManagement_User[];
  orgs: IUserManagement_AdminOrg[];
  whitelist: IUserManagement_WhitelistUser[];
}

export interface IUserManagement_AdminOrg
{
  id: number;
  name: string;
}

export interface IUserManagement_User
{
  id: string;   // UUID
  email: string;
  first_name: string;
  last_name: string;
  created_date?: string;
  last_login?: string;
  last_pwd_change?: string;
  expiration_date?: string;
  expired: boolean;
  organizations: number[];
  roles: IUserManagement_UserOrgRole[];
  account_state: IAdminUserAccountState;
  stats: IUserManagement_Stats;
}

export type IAdminUserAccountState = 'Enabled' | 'Disabled';

export interface IUserManagement_UserOrgRole
{
  role: TUserManagement_UserOrgRole;
  org_id: number;
}

export const UserManagement_UserOrgRoles = 
[ 
  'Default User', 
  'CreateLayerLibraryGroup', 'EditLayerLibraryGroup', 'DeleteLayerLibraryGroup',
  'CreateCustomLayer', 'EditCustomLayer', 'DeleteCustomLayer', 
  'EditMainLayer'
] as const;
export type TUserManagement_UserOrgRole = typeof UserManagement_UserOrgRoles[number];

export interface IUserManagement_Stats
{
  project_count: number;
}

export interface IUserManagement_WhitelistUser
{
  email: string;
  org_id: number;
  expiration_date?: string;
}
    