// Add to whitelist component

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { UserManagement_UserOrgRoles, IUserManagement_AdminOrg, IUserManagement_User, IUserManagement_UserOrgRole, TUserManagement_UserOrgRole } from "./UserManagementInterfaces";
import { useEffect, useState } from "react";
import { PropaneSharp } from "@mui/icons-material";




//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditRolesForOrgProps
{
  org?: IUserManagement_AdminOrg;
  isDialogVisible: boolean;
  setIsDialogVisible: any;
  user: IUserManagement_User;
  setUser: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Edit User-Org roles component
//-------------------------------------------------------------------------------
const EditRolesForOrg = (props: EditRolesForOrgProps) => 
{
  // NOTE:  Stores the list of roles (FOR THIS ONE ORG ONLY)
  const [orgRoles, setOrgRoles] = useState<TUserManagement_UserOrgRole[]>([]);

  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);
  




  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load local data every time the window is opened
    if(props.isDialogVisible)
    {
      const newLocalRoles: TUserManagement_UserOrgRole[] = [];
      props.user.roles.forEach(r => 
      {
        if(r.org_id === props.org?.id)
          newLocalRoles.push(r.role);
      });
      setOrgRoles(newLocalRoles);
      setChangesWereMade(false);
    }

  }, [props.isDialogVisible])
  
  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    setOrgRoles([]);
    props.setIsDialogVisible(false);
    setChangesWereMade(false);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(props.org === undefined) return;

    if(changesWereMade === false)
    {
      OnClose();
      return;
    }

    // We need to re-add the new roles for this org

    // First remove the old roles for the org
    const updatedLocalRoles: IUserManagement_UserOrgRole[] = props.user.roles.filter(r => r.org_id !== props.org?.id);

    // Add the updates roles back in
    for(let i=0; i < orgRoles.length; i++)
      updatedLocalRoles.push({ org_id: props.org.id, role: orgRoles[i] })

    // Update the user
    const updatedUser: IUserManagement_User =
    {
      ...props.user,
      roles: updatedLocalRoles
    }

    props.setUser(updatedUser);
    props.setChangesWereMade(true);

    // Close this window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // A role checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnRoleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, role: TUserManagement_UserOrgRole, checked: boolean) => 
  {
    let updatedRoles: TUserManagement_UserOrgRole[];
    if(checked)
      updatedRoles = [...orgRoles, role];
    else
      updatedRoles = orgRoles.filter(r => r !== role);
    
    setOrgRoles(updatedRoles);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Select all roles.
  //-------------------------------------------------------------------------------
  function OnSelectAll() 
  {
    if(!props.org) return;

    const allRoles: TUserManagement_UserOrgRole[] = [];
    UserManagement_UserOrgRoles.map(r => 
    {
      allRoles.push(r);
    });
    
    setOrgRoles(allRoles);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // De-select all roles (except the 'Default User' role, which cannot be removed).
  //-------------------------------------------------------------------------------
  function OnDeselectAll() 
  {
    setOrgRoles([ 'Default User' ]);
    setChangesWereMade(true);
  }













  if(!props.user || !props.org) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.isDialogVisible === true}
            onClose={OnClose} maxWidth='md'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='column' sx={{ justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '1.1rem', fontWeight:' bold', color: theme_textColorMain, opacity: 0.8 }}>
              {props.user.first_name} {props.user.last_name}
            </Typography>
            <Typography sx={{ fontSize: '0.9rem', fontWeight:' bold', color: theme_orange, opacity: 0.8 }}>
              {props.org.name}
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>

          <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '1.0rem', color: theme_orange }}>
              Roles
            </Typography>
            <Stack direction='row'>
              <Button variant='outlined' sx={{ fontSize: '0.8rem', px: '5px', py: '1px', textTransform: 'none' }} onClick={_=>OnSelectAll()}>
                All
              </Button>
              <Button variant='outlined' sx={{ ml: 1, fontSize: '0.8rem', px: '5px', py: '1px', textTransform: 'none' }} onClick={_=>OnDeselectAll()}>
                None
              </Button>
            </Stack>
          </Stack>

          {/* Enabled status checkbox */}

          {UserManagement_UserOrgRoles.map(userOrgAdminRoleStr => 

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <Checkbox size='small'
                  // NOTE: The 'Default User' permission cannot be removed
                  checked={userOrgAdminRoleStr==='Default User' ? true : orgRoles.find(role => role === userOrgAdminRoleStr) !== undefined} 
                  onChange={(e,c)=>OnRoleCheckBoxChange(e,userOrgAdminRoleStr as TUserManagement_UserOrgRole,c)}
                  sx=
                  {{ 
                    mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                    '&.Mui-checked': { color: theme_textColorBlended } 
                  }} />
              <Stack>
                <Typography sx={{ color: theme_textColorMain, opacity: 0.8, fontSize: '0.9rem' }}>
                  {userOrgAdminRoleStr}
                </Typography>
                {userOrgAdminRoleStr==='Default User'
                  ?
                    <Typography sx={{ mt: '-4px', color: theme_textColorBlended, opacity: 0.5, fontSize: '0.6rem' }}>
                      This role cannot be removed
                    </Typography>
                  :null
                }
              </Stack>
            </Stack>
          )}

        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default EditRolesForOrg;
