// Portfolio map color scheme editor (UNIQUE VALUES)

import { Button, IconButton, Stack, Typography } from "@mui/material";
import { theme_textColorBlended, theme_orange, theme_textColorMain } from "../../../Theme";
import { CustomTextField } from "../../../LayerLibrary/EditLayer/EditLayer";
import { MuiColorInputStyled } from "../../../LayerLibrary/EditLayer/EditLayerStyle";
import { MuiColorInputColors } from "mui-color-input";
import { IPortfolioMapColorScheme, IPortfolioMapColorScheme_UniqueValuesItem, IPortfolioMapColorScheme_UniqueValues } from "../PortfolioMapInterfaces";
import { ToastNotification } from "../../../ToastNotifications";
import { GetAoiAttribUniqueValues, GetAoiAttribute } from "../AoiAttributeOps";
import { IAoiAttribute, IAoiGroupProperties } from "../AoiGroupInterfaces";
import iwanthue from "iwanthue";
import DeleteIcon from '@mui/icons-material/Delete';


const UNIQUE_VALUES_MAX_ITEMS = 200;


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ColorSchemeEditorUniqueValueProps
{
  aoiGroupProps: IAoiGroupProperties;
  colorScheme: IPortfolioMapColorScheme;
  setColorScheme: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Portfolio map color scheme editor (UNIQUE VALUES)
//-------------------------------------------------------------------------------
export function ColorSchemeEditor_UniqueValues(props: ColorSchemeEditorUniqueValueProps) 
{


  









  
  //-------------------------------------------------------------------------------
  // A Unique Values color has changed.
  //-------------------------------------------------------------------------------
  function OnUniqueValuesColorChanged(value: string, colors: MuiColorInputColors, 
                                      uniqueValuesItem: IPortfolioMapColorScheme_UniqueValuesItem)
  {
    if(!props.colorScheme || !props.colorScheme.unique_values) return;

    // Create an updated item
    const newItem: IPortfolioMapColorScheme_UniqueValuesItem =
    {
      ...uniqueValuesItem,
      color: value,
    }

    // Replace it in the array
    const new_unique_values_items: IPortfolioMapColorScheme_UniqueValuesItem[] = props.colorScheme.unique_values.items.map(oldItem => oldItem.id === uniqueValuesItem.id ? newItem : oldItem);

    // Update the 'items' array
    const new_unique_values: IPortfolioMapColorScheme_UniqueValues = 
    {
      ...props.colorScheme.unique_values,
      items: new_unique_values_items
    }

    // Update the color scheme with the new 'unique_values'
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      unique_values: new_unique_values
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // UNIQUE VALUES Refresh All button was pushed.
  //-------------------------------------------------------------------------------
  function OnUniqueValuesRefreshAll(aoi_attribute_id: number | undefined, randomColors: boolean)
  {
    if(!aoi_attribute_id || !props.colorScheme) return;

    const new_unique_values: IPortfolioMapColorScheme_UniqueValues | undefined = RegenerateUniqueValues(props.aoiGroupProps.attributes, aoi_attribute_id, randomColors);
    if(!new_unique_values) return;

    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      unique_values: new_unique_values
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // UNIQUE VALUES Remove item button was pushed.
  //-------------------------------------------------------------------------------
  function OnUniqueValuesRemoveColorItem(uniqueValuesItem: IPortfolioMapColorScheme_UniqueValuesItem)
  {
    if(!props.colorScheme || !props.colorScheme.unique_values) return;

    // Remove the item from the array
    const new_unique_values_items: IPortfolioMapColorScheme_UniqueValuesItem[] = props.colorScheme.unique_values.items.filter(oldItem => oldItem.id !== uniqueValuesItem.id);

    // Update the 'items' array
    const new_unique_values: IPortfolioMapColorScheme_UniqueValues = 
    {
      ...props.colorScheme.unique_values,
      items: new_unique_values_items
    }

    // Update the color scheme with the new 'unique_values'
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      unique_values: new_unique_values
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);    
  }

  //-------------------------------------------------------------------------------
  // UNIQUE VALUES Update Only button was pushed.
  //-------------------------------------------------------------------------------
  function OnUniqueValuesUpdateOnly()
  {
    if(!props.colorScheme || !props.colorScheme.unique_values) return;

    const uniqueValues: string[] | undefined = GetAoiAttribUniqueValues(props.aoiGroupProps?.attributes, props.colorScheme.aoi_attribute_id, false);
    if(uniqueValues === undefined)
    {
      ToastNotification('error', 'This attribute has no unique values');
      return undefined;
    }

    if(uniqueValues.length > UNIQUE_VALUES_MAX_ITEMS)
    {
      ToastNotification('error', `This attribute has too many unique values (max is ${UNIQUE_VALUES_MAX_ITEMS})`);
      return undefined;
    }

    // Remove any items whose values no longer exist
    //
    // OLD LIST:  props.colorScheme.unique_values.items
    // NEW LIST:  uniqueValues

    let new_unique_values_items_updated: IPortfolioMapColorScheme_UniqueValuesItem[] = [];
    let maxID: number = -1;
    let entriesRemovedCount: number = 0;

    for(let i=0; i < props.colorScheme.unique_values.items.length; i++)
    {
      const foundInNewUniqueValuesList: string | undefined = uniqueValues.find(nuv => nuv.trim().toLowerCase() === props.colorScheme.unique_values?.items[i].value.trim().toLowerCase());
      if(foundInNewUniqueValuesList !== undefined)
      {
        new_unique_values_items_updated.push(props.colorScheme.unique_values?.items[i]);

        // Also keep track of the max ID
        if(props.colorScheme.unique_values?.items[i].id > maxID)
          maxID = props.colorScheme.unique_values?.items[i].id;
      }
      else
        entriesRemovedCount++;
    }

    // Add new items for any missing values

    let entriesAddedCount: number = 0;

    for(let i=0; i < uniqueValues.length; i++)
    {
      const foundExistingItem: IPortfolioMapColorScheme_UniqueValuesItem | undefined = new_unique_values_items_updated.find(nuv => nuv.value.trim().toLowerCase() === uniqueValues[i].trim().toLowerCase());
      if(foundExistingItem === undefined)
      {
        // This value does not already exist - add it as a new item
        new_unique_values_items_updated.push(
          {
            id: 1+maxID++,
            value: uniqueValues[i],
            color: ''
          })
        entriesAddedCount++;
      }
    }

    // Re-sort the updated list

    const aoiAttribute: IAoiAttribute | undefined = GetAoiAttribute(props.aoiGroupProps?.attributes, props.colorScheme.aoi_attribute_id);
    if(!aoiAttribute) return;

    if(aoiAttribute.type === 'number')
      new_unique_values_items_updated = new_unique_values_items_updated.sort((a: IPortfolioMapColorScheme_UniqueValuesItem, b: IPortfolioMapColorScheme_UniqueValuesItem) => Number.parseFloat(a.value)! - Number.parseFloat(b.value!));
    else if(aoiAttribute.type === 'text') // Sort such that empty values are at the end of the array
    new_unique_values_items_updated = new_unique_values_items_updated.sort((a: IPortfolioMapColorScheme_UniqueValuesItem, b: IPortfolioMapColorScheme_UniqueValuesItem) => {if (!a.value) return 1; if (!b.value) return -1; return a.value.localeCompare(b.value)});

    // Update the 'items' array
    const new_unique_values: IPortfolioMapColorScheme_UniqueValues = 
    {
      ...props.colorScheme.unique_values,
      items: new_unique_values_items_updated
    }

    // Update the color scheme
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      unique_values: new_unique_values
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);

    // Notify user of outcome

    if(entriesRemovedCount === 0 && entriesAddedCount === 0)
      ToastNotification('info', 'No changes needed');
    else if(entriesRemovedCount > 0 && entriesAddedCount === 0)
      ToastNotification('info', `Removed ${entriesRemovedCount} ${entriesRemovedCount===1?'entry':'entries'}`);
    else if(entriesRemovedCount === 0 && entriesAddedCount > 0)
      ToastNotification('info', `Added ${entriesAddedCount} new ${entriesAddedCount===1?'entry':'entries'}`);
    else
      ToastNotification('info', `Removed: ${entriesRemovedCount}  |  Added: ${entriesAddedCount}`);
  }











  if(!props.colorScheme || props.colorScheme.type !== 'unique values' || !props.colorScheme.unique_values || 
     !props.colorScheme.unique_values.items || props.colorScheme.aoi_attribute_id === undefined)
     return null;

  return (

    <Stack direction='column' sx={{ mt: 3, alignItems: 'left' }}>

      <Stack direction='row' sx={{ mb: 2, alignItems: 'center', justifyContent: 'space-between' }}>

        <Typography sx={{ mb: 2, color: theme_orange, opacity: 0.8, fontSize: '1.2rem' }}>
          Unique Values
        </Typography>

        {props.colorScheme.aoi_attribute_id !== undefined
          ?
            <Stack direction='row' sx={{ alignItems: 'center'}}>
              <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }} 
                      onClick={(_)=>OnUniqueValuesRefreshAll(props.colorScheme.aoi_attribute_id, false)}>
                <Stack direction='column'>
                  <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                    Refresh All
                  </Typography>
                  <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                    No colors assigned
                  </Typography>
                </Stack>
              </Button>

              <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }}
                      onClick={(_)=>OnUniqueValuesRefreshAll(props.colorScheme.aoi_attribute_id, true)}>
                <Stack direction='column'>
                  <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                    Refresh All
                  </Typography>
                  <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                    Assign random colors
                  </Typography>
                </Stack>
              </Button>

              <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }}
                      onClick={(_)=>OnUniqueValuesUpdateOnly()}>
                <Stack direction='column'>
                  <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                    Update Only
                  </Typography>
                  <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                    Remove unused, add missing, re-sort
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          :null
        }

      </Stack>

      {props.colorScheme.unique_values.items.map(function(uniqueValuesItem)
      {
        const aoiAttrib: IAoiAttribute | undefined = GetAoiAttribute(props.aoiGroupProps?.attributes, props.colorScheme.aoi_attribute_id);
        const isNumber: boolean = aoiAttrib?.type === 'number';

        return (

          <Stack key={uniqueValuesItem.id} direction='row' sx={{ my: 0.5, width: '100%', alignItems: 'center' }}>

            {/* Value edit box */}

            <Stack sx={{ width: isNumber ? '150px' : '100%' }}>
              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                Value
              </Typography>

              <CustomTextField variant='standard' size='small' autoComplete='off'
                              value={uniqueValuesItem.value}
                              inputProps={{ type: isNumber ? 'number' : 'string' }} 
                              //onChange={OnGradientValueChanged}
                              sx={{ p: 0 }}/>
            </Stack>

            {/* Color editor */}

            <Stack sx={{ ml: 2 }}>
              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                Color
              </Typography>
              <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                  disabled={props.colorScheme.unique_values === undefined}
                                  value={uniqueValuesItem.color}
                                  onChange={(v,c)=>OnUniqueValuesColorChanged(v,c,uniqueValuesItem)}
                                  sx={{ width: '120px' }}/>
            </Stack>

            {/* Delete button */}

            <IconButton sx={{p: 0.3, ml: 1}} onClick={(_)=>OnUniqueValuesRemoveColorItem(uniqueValuesItem)}>
              <DeleteIcon sx={{ color: theme_textColorBlended, opacity: 0.7, p: 0.4 }}/>
            </IconButton>

          </Stack>                    
        )
      })}
    </Stack>


  )
}






//-------------------------------------------------------------------------------
// Validates the specified unique values color scheme.
//-------------------------------------------------------------------------------
export function ValidateColorScheme_UniqueValues(colorScheme: IPortfolioMapColorScheme): boolean
{
  if(colorScheme.type !== 'unique values')
    return false;

  if(colorScheme.unique_values === undefined || colorScheme.unique_values.items.length === 0)
  {
    ToastNotification('error', 'No color definitions specified');
    return false;
  }      

  const foundBadColor: IPortfolioMapColorScheme_UniqueValuesItem | undefined = colorScheme.unique_values.items.find(item => item.color === undefined || item.color === '');
  if(foundBadColor)
  {
    ToastNotification('error', 'One of the colors is not defined');
    return false;
  }

  const foundBadValue: IPortfolioMapColorScheme_UniqueValuesItem | undefined = colorScheme.unique_values.items.find(item => item.value === undefined);
  if(foundBadValue)
  {
    ToastNotification('error', 'One of the values is not defined');
    return false;
  }

  // Validated
  return true;
}

//-------------------------------------------------------------------------------
// Regenerate unique values.
//-------------------------------------------------------------------------------
export function RegenerateUniqueValues(aoi_attributes: IAoiAttribute[] | undefined, 
                                       aoi_attribute_id: number, 
                                       generateRandomColors: boolean): IPortfolioMapColorScheme_UniqueValues | undefined
{
  let nextID: number = 1;

  const uniqueValues: string[] | undefined = GetAoiAttribUniqueValues(aoi_attributes, aoi_attribute_id, true);
  if(uniqueValues === undefined)
  {
    ToastNotification('error', 'This attribute has no unique values');
    return undefined;
  }

  if(uniqueValues.length > UNIQUE_VALUES_MAX_ITEMS)
  {
    ToastNotification('error', `This attribute has too many unique values (max is ${UNIQUE_VALUES_MAX_ITEMS})`);
    return undefined;
  }

  // If we are generating random colors, we want them to be visually distict, so
  // the colors are all generated at once using a special algorithm.
  //
  // See:  https://www.npmjs.com/package/iwanthue

  let randomColors: string[] = [];
  if(generateRandomColors) 
    randomColors = iwanthue(uniqueValues.length);

  const new_unique_values_items: IPortfolioMapColorScheme_UniqueValuesItem[] = [];
  for(let i=0; i < uniqueValues.length; i++)
  {
    const newItem: IPortfolioMapColorScheme_UniqueValuesItem = 
    {
      id: nextID++,
      value: uniqueValues[i],
      color: generateRandomColors ? randomColors[i] : '',
    }
    new_unique_values_items.push(newItem);
  }

  const new_unique_values: IPortfolioMapColorScheme_UniqueValues =
  {
    items: new_unique_values_items
  }

  return new_unique_values;
}
