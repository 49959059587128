// Wrapper class to call the back-end API
//
// Call (GET/POST/PUT/DELETE)
// UploadFile
// DownloadFile
//
// Use 'Add()' to add input parameters (number, string, boolean, json).
// Use 'AddFile()' to upload a file (only works with 'UploadFiles', only one file can be uploaded)
//
// All return CallServerParam as a Promise

import { AxiosError, AxiosResponse } from "axios";
import useStore from "./store";
import { CALLSERVER_BASE_URL, DEV_MODE } from './Globals';
import Debug from "./Debug";
import { APIDebugJSONPrep, IAPIDebugData } from "./APIDebugInfo";

let APIDebugNextID: number = 1;


export class CallServer 
{
  private baseURL = CALLSERVER_BASE_URL;
  private authenticate: boolean = true;
  private headers : CallServerParam[] = [];
  private params : CallServerParam[] = [];
  private files : File [] = [];
  public errorMessage : string = '';

  //-------------------------------------------------------------------------------
  // Constructor.
  //-------------------------------------------------------------------------------
  constructor(authenticate: boolean = true, customBaseURL: string = '') 
  {
    this.authenticate = authenticate;

    // If a custom base URL was provided, use it instead
    if(customBaseURL !== '')
      this.baseURL = customBaseURL;
  }

  //-------------------------------------------------------------------------------
  // Add an extra input header.
  //
  // NOTE:  Accepts JSON, but it auto-converts it to string.
  //-------------------------------------------------------------------------------
  AddHeader(headerName: string, headerValue: string|number|boolean|object)
  {
    if(!headerName)
    {
      Debug.warn(`CallServer.Add> Header value of '${headerName}' is null or undefined.`);
      return;
    }

    // For primitive values, we do toString, and for JSON we do JSON.stringify
    const headerValueStr = typeof headerValue === 'object' ? JSON.stringify(headerValue) : headerValue.toString();

    this.headers.push(new CallServerParam(headerName, headerValueStr));
  }  
  //-------------------------------------------------------------------------------
  // Add an input parameter.
  //
  // NOTE:  Accepts JSON, but it auto-converts it to string.
  //-------------------------------------------------------------------------------
  Add(paramName: string, paramValue: string|number|boolean|object|null)
  {
    // if(!paramValue)
    // {
    //   Debug.warn(`CallServer.Add> Param value of '${paramName}' is null or undefined.`);
    //   return;
    // }

    // For primitive values, we do toString, and for JSON we do JSON.stringify
    const paramValueStr = typeof paramValue === 'object' ? JSON.stringify(paramValue) : paramValue.toString();
    //const paramValue = typeof paramValue === 'object' ? paramValue : paramValue.toString();

    this.params.push(new CallServerParam(paramName, paramValueStr));
    //this.params.push(new CallServerParam(paramName, paramValue));
    //this.params.push(new CallServerParam(paramName, paramValue.toString()));
  }

  //-------------------------------------------------------------------------------
  // Add an input parameter (as pure JSON, not converted to string).
  // 
  // NOTE: Only works with JSON Objects, not with JSON Arrays.
  //-------------------------------------------------------------------------------
  AddJson(paramName: string, paramValue: object)
  {
    if(!paramValue)
    {
      Debug.warn(`CallServer.AddJson> Param value of '${paramName}' is null or undefined.`);
      return;
    }

    if (Array.isArray(paramValue))
    {
      Debug.error(`CallServer.AddJson> Detected a JSON Array - NOT SUPPORTED`);
      return;
    }

    this.params.push(new CallServerParam(paramName, paramValue));
  }

  //-------------------------------------------------------------------------------
  // Add a file to upload (can only add one per upload call).
  //
  // NOTE: To upload files the UploadFile method must be used (not Call).
  // NOTE: In the future, we may support multiple files being uploaded per call.
  //-------------------------------------------------------------------------------
  AddFile(file: File)
  {
    this.files.push(file);
  }

  //-------------------------------------------------------------------------------
  // Call the server.
  //-------------------------------------------------------------------------------
  async Call(httpMethod: string, module: string) : Promise<CallServerResults>
  {
    this.errorMessage = '';

    const userInfo = useStore.getState().store_userInfo;

    const APIDebugData: IAPIDebugData = 
    {
      id: APIDebugNextID++,
      httpMethod: httpMethod,
      module: module,
      status: 'running',
      start: new Date(),
      end: undefined, //new Date(),
      totalTimeMS: -1,
    }

    // Prepare header data
    let headers: {[key: string]: string|number|boolean} = { }
    headers['Content-Type'] = 'application/json';
    if(this.authenticate)
      headers['Authorization'] = useStore.getState().store_accessToken;
    if(userInfo && userInfo.id)
      headers['effective-user-id'] = userInfo.id;

    // Add any extra headers (optionally passed in using AddHeader)
    this.headers.forEach(element => { headers[element.name] = element.value; });

    // Prepare parameter data
    let params: {[key: string]: string|number|boolean|object} = { }
    this.params.forEach(element => { params[element.name] = element.value; });

    if(DEV_MODE)
    {
      APIDebugData.inputJSON = params;

      // We add the record for this API call just before the call so we can see
      // it in the UI while it's running.  Once the call ends, we will update the 
      // record.
      useStore.getState().store_addAPIDebugData(APIDebugData);
    }

    try
    {
      const axios = require('axios').default;

      let response : AxiosResponse;
      switch(httpMethod.toLowerCase())
      {
        case 'get':    response = await axios.get(this.baseURL + module, { headers: headers, params: params }); break;
        case 'post':   response = await axios.post(this.baseURL + module, params, { headers: headers });break;
        case 'put':    response = await axios.put(this.baseURL + module, params, { headers: headers }); break;
        case 'delete': response = await axios.delete(this.baseURL + module, {data: params, headers: headers}); break;
        default: return new CallServerResults(false, 0, 'Invalid http method', '');
      }

      Debug.log('CallServer.Call> URL = %s', response.request.responseURL);

      if(DEV_MODE)
      {
        APIDebugData.url = response.request.responseURL;
        APIDebugData.responseStatus = response.status;
        APIDebugData.end = new Date();
        APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();

        if(response.data)
        {
          APIDebugData.size = JSON.stringify(response.data).length;
          APIDebugData.log_guid = response.data.log_guid;
          APIDebugData.stats = response.data.stats;
          APIDebugData.outputJSON = APIDebugJSONPrep(response.data);
        }
      }

      if(response.status === 200)
      {
        if(DEV_MODE)
        {
          APIDebugData.status = 'success';
          //useStore.getState().store_updateAPIDebugData(APIDebugData);
        }

        return new CallServerResults(true, 0, '', response.data);
      }
      else  // error
      {
        const errMsg: string = response && response.data && response.data.detail ? response.data.detail : '';
        const errMsgJsonStr = JSON.stringify(errMsg);

        if(DEV_MODE)
        {
          APIDebugData.status = 'error';
          APIDebugData.responseStatus = response.status;
          APIDebugData.error = errMsgJsonStr;
          //useStore.getState().store_updateAPIDebugData(APIDebugData);
        }

        return new CallServerResults(false, response!.status, errMsgJsonStr, '');
      }
    }
    catch (err)
    {
      if(err instanceof AxiosError)
      {
        let errMsg = err && err.response && err.response.data ? err.response.data : '';
        if(errMsg === '' && err.message && err.message.length > 0)
          errMsg = err.message;

        const errMsgStr: string = JSON.stringify(errMsg);

        if(DEV_MODE)
        {
          APIDebugData.end = new Date();
          APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();
          APIDebugData.status = 'error';
          APIDebugData.responseStatus = err.response?.status;
          APIDebugData.error = errMsgStr;
          //useStore.getState().store_updateAPIDebugData(APIDebugData);
        }

        // Handle an expired access token (will cause the app to force-navigate to the login page)
        if(errMsgStr.startsWith("{\"detail\":\"invalid access token"))
        {
          useStore.getState().store_setForcedLogout(true);
          return new CallServerResults(false, 0, '', '');
        }

        const statusNumber: number | undefined = err && err.response ? err.response.status : -1;
        return new CallServerResults(false, statusNumber, errMsgStr, '');
      }
      else
      {
        if(DEV_MODE)
        {
          APIDebugData.end = new Date();
          APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();
          APIDebugData.status = 'error';
          APIDebugData.error = 'Non-Axio exception';
          //useStore.getState().store_updateAPIDebugData(APIDebugData);
        }
        return new CallServerResults(false, 0, 'CallServer.Call> ', '');
      }
    }
  }

  //-------------------------------------------------------------------------------
  // Call the server to upload a file.
  //-------------------------------------------------------------------------------
  async UploadFile(module: string) : Promise<CallServerResults>
  {
    this.errorMessage = '';

    // NOTE:  Normally this would be done by sending files through FormData using POST 
    //        and 'multipart/form-data', however turning on binary file access for AWS 
    //        API Gateway is somewhat complicated so we are putting it off for now and
    //        sending files as simple base64-encoded parameters.

    if(this.files.length === 0)
      return new CallServerResults(false, 0, 'CallServer.UploadFile> No file specified - use AddFile()', ''); 

    if(this.files.length > 1)
      return new CallServerResults(false, 0, `CallServer.UploadFile> Only one file must be specified (found ${this.files.length})`, ''); 

    const APIDebugData: IAPIDebugData = 
    {
      id: APIDebugNextID++,
      httpMethod: 'post',
      module: module,
      status: 'running',
      start: new Date(),
      end: undefined,
      totalTimeMS: -1,
    }
    
    // Prepare parameter data
    let params: {[key: string]: string|number|boolean|ArrayBuffer} = { }
    this.params.forEach(element => { params[element.name] = element.value; });

    // Add file (earlier we check that there is exactly one file)
    const fileBase64Str = await ReadBinaryFileIntoBase64Str(this.files[0]);
    params['filename'] = this.files[0].name;
    params['filedata'] = fileBase64Str;

    // Prepare header data
    let headers: {[key: string]: string|number|boolean} = { }
    headers['Content-Type'] = 'application/json';
    if(this.authenticate)
      headers['Authorization'] = useStore.getState().store_accessToken;

    if(DEV_MODE)
    {
      APIDebugData.inputJSON = params;

      // We add the record for this API call just before the call so we can see
      // it in the UI while it's running.  Once the call ends, we will update the 
      // record.
      useStore.getState().store_addAPIDebugData(APIDebugData);
    }
    
    try
    {
      const axios = require('axios').default;

      let response = await axios.post(this.baseURL + module, params, { headers: headers });

      if(DEV_MODE)
      {
        APIDebugData.url = response.request.responseURL;
        APIDebugData.responseStatus = response.status;
        APIDebugData.end = new Date();
        APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();

        if(response.data)
        {
          APIDebugData.size = JSON.stringify(response.data).length;
          APIDebugData.log_guid = response.data.log_guid;
          APIDebugData.stats = response.data.stats;
          APIDebugData.outputJSON = APIDebugJSONPrep(response.data);
        }
      }
      
      if(response.status === 200)
      {
        if(DEV_MODE) APIDebugData.status = 'success';
        return new CallServerResults(true, 0, '', response.data);
      }
      else
      {
        const errMsg : string = response && response.data && response.data.detail ? response.data.detail : '';

        if(DEV_MODE)
        {
          APIDebugData.status = 'error';
          APIDebugData.responseStatus = response.status;
          APIDebugData.error = errMsg;
        }
        
        return new CallServerResults(false, response!.status, JSON.stringify(errMsg), '');
      }
    }
    catch (err)
    {
      if(err instanceof AxiosError)
      {
        let errMsg : string = err && err.response && err.response.data && err.response.data.detail ? err.response.data.detail : '';
        if(errMsg === '' && err.message && err.message.length > 0)
          errMsg = err.message;

        if(DEV_MODE)
        {
          APIDebugData.end = new Date();
          APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();
          APIDebugData.status = 'error';
          APIDebugData.responseStatus = err.response?.status;
          APIDebugData.error = errMsg;
        }

        return new CallServerResults(false, err.response!.status, JSON.stringify(errMsg), '');
      }
      else
      {
        if(DEV_MODE)
        {
          APIDebugData.end = new Date();
          APIDebugData.totalTimeMS = APIDebugData.end.getTime() - APIDebugData.start.getTime();
          APIDebugData.status = 'error';
          APIDebugData.error = 'Non-Axio exception';
        }
      }
    }

    return new CallServerResults(false, 0, 'CallServer.UploadFile> Call failed', '');  
  }

  //-------------------------------------------------------------------------------
  // Call the server to upload one or more files.
  // 
  // NOTE:  This implements the 'proper' way to upload files, using POST and 
  //        multipart/form-data.  For now it's not in use - might be later though.
  //-------------------------------------------------------------------------------
  async UploadFiles_DO_NOT_USE(module: string) : Promise<CallServerResults>
  {
    this.errorMessage = '';

    if(this.files.length === 0)
      return new CallServerResults(false, 0, 'CallServer.UploadFiles> No file specified - use AddFile()', ''); 

    // Prepare the form data
    const formData : FormData = new FormData();
    
    // Add params
    this.params.forEach(element => { formData.append(element.name, element.value) });

    // Add files
    for(let i=0; i < this.files.length; i++)
      formData.append('file', this.files[i]);

    // Prepare header data
    let headers: {[key: string]: string|number|boolean} = { }
    headers['Content-Type'] = 'multipart/form-data'; //'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    if(this.authenticate)
      headers['Authorization'] = useStore.getState().store_accessToken;

    try
    {
      const axios = require('axios').default;

      let response = await axios.post(this.baseURL + module, formData, { headers: headers });

      if(response.status === 200) 
        return new CallServerResults(true, 0, '', response.data);
      else
      {
        const errMsg : string = response && response.data && response.data.detail ? response.data.detail : '';
        return new CallServerResults(false, response!.status, JSON.stringify(errMsg), '');
      }
    }
    catch (err)
    {
      if(err instanceof AxiosError)
      {
        let errMsg : string = err && err.response && err.response.data && err.response.data.detail ? err.response.data.detail : '';
        if(errMsg === '' && err.message && err.message.length > 0)
          errMsg = err.message;
        return new CallServerResults(false, err.response!.status, JSON.stringify(errMsg), '');
      }
    }

    return new CallServerResults(false, 0, 'CallServer.UploadFiles> Call failed', '');  
  }

  //-------------------------------------------------------------------------------
  // Call the server to download a file.
  //
  // NOTE: The file open automatically in the browser.
  //-------------------------------------------------------------------------------
  async DownloadFile(module: string) : Promise<CallServerResults>
  {
    this.errorMessage = '';

    // Prepare header data
    let headers: {[key: string]: string|number|boolean} = { }
    if(this.authenticate)
      headers['Authorization'] = useStore.getState().store_accessToken;

    // Prepare parameter data
    let params: {[key: string]: string|number|boolean} = { }
    this.params.forEach(element => { params[element.name] = element.value; });

    try
    {
      const axios = require('axios').default;

      let response = await axios.get(this.baseURL + module, { headers: headers, params: params, responseType: 'blob', });

      if(response.status === 200) 
      {
        // Parse the filename from the content-disposition header (if there is one)

        let filename = '';
        try
        {
          //content-disposition: 'attachment; filename="exampleText.txt"'

          const contentDisposition = response.headers['content-disposition'];

          filename = contentDisposition.split(/;(.+)/)[1].split(/=(.+)/)[1]
          if (filename.toLowerCase().startsWith("utf-8''"))
              filename = decodeURIComponent(filename.replace("utf-8''", ''))
          else
              filename = filename.replace(/['"]/g, '')
        } 
        catch
        { 
          filename = '';
        }

        if(filename === '')
          return new CallServerResults(false, 0, 'Missing or empty filename', '');

        // The response data will be a base64-encoded Blob

        // Extract the base64 text from the returned Blob
        let base64Str = '';
        const blob : Blob = response.data;
        await blob.text().then(text => { base64Str = text });
      
        // Decode the base64 text and put it back into a Blob
        const decodedFileBlob : Blob = ConvertBase64ToBlob(base64Str);

        // Auto-open the download file in the browser
        //const url = window.URL.createObjectURL(new Blob([response.data]));
        const url = window.URL.createObjectURL(decodedFileBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        return new CallServerResults(true, 0, '', response.data);
      }
      else
      {
        const errMsg : string = response && response.data && response.data.detail ? response.data.detail : '';
        return new CallServerResults(false, response!.status, JSON.stringify(errMsg), '');
      }
    }
    catch (err)
    {
      if(err instanceof AxiosError)
      {
        let errMsg : string = err && err.response && err.response.data && err.response.data.detail ? err.response.data.detail : '';
        if(errMsg === '' && err.message && err.message.length > 0)
          errMsg = err.message;
        return new CallServerResults(false, err.response!.status, JSON.stringify(errMsg), '');
      }
    }

    return new CallServerResults(false, 0, 'CallServer.DownloadFile> ', '');
  }
}

//-------------------------------------------------------------------------------
// Reads in a binary file and returns a base64-encoded string of its contents.
//
// NOTE: The error handling in this function needs to be looked at.
//-------------------------------------------------------------------------------
function ReadBinaryFileIntoBase64Str(file : File) : Promise<string | ArrayBuffer>
{
  return new Promise((resolve, reject) => 
  {
    let reader = new FileReader();

    reader.onload = () => 
    {
      if(reader.result)
      {
        const base64Str = ArrayBufferToBase64(reader.result as ArrayBuffer);
        resolve(base64Str);
      }
      else reject();
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

//-------------------------------------------------------------------------------
// Converts the specified ArrayBuffer to a base64 string
//-------------------------------------------------------------------------------
function ArrayBufferToBase64(buffer : ArrayBuffer) : string 
{
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;

  for (var i = 0; i < len; i++)
    binary += String.fromCharCode(bytes[i]);

  return window.btoa(binary);
}

//-------------------------------------------------------------------------------
// Decodes the specified base64-encoded string into a Blob
// NOTE: Used by the download module.
//-------------------------------------------------------------------------------
function ConvertBase64ToBlob(base64Str: string) 
{
  // Decode Base64 string
  const decodedData = window.atob(base64Str);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: 'application/octet-stream' });
}









export class CallServerResults 
{
  public success : boolean = false;
  public errorCode : number = 0;
  public errorMessage : string = ''
  public data : any;

  constructor(success: boolean, errorCode: number, errorMessage: string, data: any) 
  {
    this.success = success;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.data = data;
  }  

  get failed() { return !this.success; };
}

class CallServerParam 
{
  public name : string = '';
  public value : any = '';

  constructor(name: string, value: any) 
  {
    this.name = name;
    this.value = value;
  }
}