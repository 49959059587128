// Portfolio map color scheme editor (CLASSIFIED)

import { Button, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_textColorBlended, theme_orange, theme_errorRed, theme_textColorMain, theme_limeGreen, theme_bgColorLight } from "../../../Theme";
import { CustomSelect, MuiColorInputStyled } from "../../../LayerLibrary/EditLayer/EditLayerStyle";
import { IPortfolioMapColorScheme, IPortfolioMapColorScheme_Classified, IPortfolioMapColorScheme_ClassifiedItem, IPortfolioMapColorScheme_Gradient } from "../PortfolioMapInterfaces";
import { GetAoiAttributeMinValue, GetAoiAttributeMaxValue } from "../AoiGroupOps";
import { ColorGradientPresets, GetGradientPreset, GRADIENT_PRESET_EMPTY_ID } from "./ColorGradientPresets";
import { ReactNode } from "react";
import { ToastNotification } from "../../../ToastNotifications";
import Debug from "../../../Debug";
import { MuiColorInputColors } from "mui-color-input";
import { FriendlyNumber } from "../../../Globals";
import { GetGradientColorForValue } from "./ColorSchemeEditor_Gradient";
import BigNumber from "bignumber.js";
import iwanthue from "iwanthue";
import { GetAoiAttribute } from "../AoiAttributeOps";


const MAX_CLASSES = 10;

export const NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_CLASS_COUNT = 4;
export const NEW_CLASSIFIED_COLOR_SCHEME_DEFAULT_PRESET_ID = 3; // Yellow-Orange-Red (Continuous)

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ColorSchemeEditorClassifiedProps
{
  colorScheme: IPortfolioMapColorScheme;
  attribUnits?: string;
  setColorScheme: any;
  setChangesWereMade: any;
}

//-------------------------------------------------------------------------------
// Portfolio map color scheme editor (CLASSIFIED)
//-------------------------------------------------------------------------------
export function ColorSchemeEditor_Classified(props: ColorSchemeEditorClassifiedProps) 
{





  const minValue: number | undefined = GetAoiAttributeMinValue(props.colorScheme.aoi_attribute_id);
  const maxValue: number | undefined = GetAoiAttributeMaxValue(props.colorScheme.aoi_attribute_id);
  if(minValue === undefined || maxValue === undefined)
    return null;









  //-------------------------------------------------------------------------------
  // A new gradient preset was selected.
  //-------------------------------------------------------------------------------
  function OnPresetChanged(gradient_preset: IPortfolioMapColorScheme_Gradient | undefined)
  {
   if(!props.colorScheme.classified) return;

   const new_classified = CreateClassifiedRanges(props.colorScheme, props.colorScheme.classified.items.length, gradient_preset, gradient_preset?.id);
   if(!new_classified)
     return;

   let new_color_scheme: IPortfolioMapColorScheme =
   {
     ...props.colorScheme,
     classified: new_classified,
   }

   // Set the new value
   props.setColorScheme(new_color_scheme);

   // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
   props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The number of classes has changed.
  //-------------------------------------------------------------------------------
  const OnClassCountChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    if(!props.colorScheme || !props.colorScheme.classified) return;

    const newClassCount: number = Number.parseInt(event.target.value as string);
    if(isNaN(newClassCount) || newClassCount < 1 || newClassCount > MAX_CLASSES)
    {
      Debug.error('OnClassCountChanged> Value is invalid or out of bounds');
      return;
    }

    const new_classified = CreateClassifiedRanges(props.colorScheme, newClassCount, GetGradientPreset(props.colorScheme.classified.preset_id), props.colorScheme.classified.preset_id);
    if(!new_classified)
      return;

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      classified: new_classified,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // A classified item color has changed.
  //-------------------------------------------------------------------------------
  function OnClassifiedColorChanged(value: string, colors: MuiColorInputColors, 
                                    classifiedItem: IPortfolioMapColorScheme_ClassifiedItem)
  {
    if(!props.colorScheme || !props.colorScheme.classified) return;

    // Create an updated item
    const newItem: IPortfolioMapColorScheme_ClassifiedItem =
    {
      ...classifiedItem,
      color: value,
    }

    // Replace it in the array
    const new_classified_items: IPortfolioMapColorScheme_ClassifiedItem[] = props.colorScheme.classified.items.map(oldItem => oldItem.id === classifiedItem.id ? newItem : oldItem);

    // Update the 'items' array
    const new_classified: IPortfolioMapColorScheme_Classified = 
    {
      ...props.colorScheme.classified,
      preset_id: GRADIENT_PRESET_EMPTY_ID,
      items: new_classified_items
    }

    // Update the color scheme with the new 'classified'
    const new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      classified: new_classified,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Re-assigns ranges values based (linear scale) based on the current class count.
  // The colors are unchanged.
  //-------------------------------------------------------------------------------
  function OnRefreshValues()
  {
    if(!props.colorScheme || !props.colorScheme.classified || !props.colorScheme.classified.items ||
       props.colorScheme.classified.items.length === 0) 
      return;

    if(minValue === undefined || maxValue === undefined)
      return;

    const numRanges: number = props.colorScheme.classified.items.length;

    // Break the min/max range into sub-ranges based on the class count

    var ranges = GetClassifiedRanges(minValue, maxValue, numRanges);
    if(!ranges)
      return undefined;

    // Rebuild the classified color scheme using the new ranges

    const new_classified_items: IPortfolioMapColorScheme_ClassifiedItem[] = [];
    for(let i=0; i < numRanges; i++)
    {
      const new_item: IPortfolioMapColorScheme_ClassifiedItem =
      {
        ...props.colorScheme.classified.items[i],
        value_min: ranges[i].min,
        value_max: ranges[i].max,
      }
      new_classified_items.push(new_item);
    }

    const new_classified: IPortfolioMapColorScheme_Classified = 
    {
      ...props.colorScheme.classified,
      items: new_classified_items
    }

    let new_color_scheme: IPortfolioMapColorScheme =
    {
      ...props.colorScheme,
      classified: new_classified,
    }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }
  
  //-------------------------------------------------------------------------------
  // Reverses the current list of colors.
  // The values are unchanged.
  //-------------------------------------------------------------------------------
  function OnReverseColors()
  {
    if(!props.colorScheme || !props.colorScheme.classified || !props.colorScheme.classified.items ||
      props.colorScheme.classified.items.length === 0) 
     return;

    // Create a new array of all the colors, but in reverse order

    const reversedColors: string[] = [];
    for(let i=props.colorScheme.classified.items.length-1; i >= 0; i--)
      reversedColors.push(props.colorScheme.classified.items[i].color);

    // Rebuild the color items, but using the new reversed colors

    const new_classified_items: IPortfolioMapColorScheme_ClassifiedItem[] = [];
    for(let i=0; i < props.colorScheme.classified.items.length; i++)
      new_classified_items.push(
        { 
          ...props.colorScheme.classified.items[i],
          color: reversedColors[i]
        });

      const new_classified: IPortfolioMapColorScheme_Classified = 
      {
        ...props.colorScheme.classified,
        items: new_classified_items
      }
  
      let new_color_scheme: IPortfolioMapColorScheme =
      {
        ...props.colorScheme,
        classified: new_classified,
      }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Assigns random colors to the current ranges.
  // The values are unchanged.
  //-------------------------------------------------------------------------------
  function OnRandomColors()
  {
    if(!props.colorScheme || !props.colorScheme.classified || !props.colorScheme.classified.items ||
       props.colorScheme.classified.items.length === 0) 
     return;

    // If we are generating random colors, we want them to be visually distict, so
    // the colors are all generated at once using a special algorithm.
    //
    // See:  https://www.npmjs.com/package/iwanthue

    const randomColors: string[] = iwanthue(props.colorScheme.classified.items.length);

    // Rebuild the color items, but using the new random colors.

    const new_classified_items: IPortfolioMapColorScheme_ClassifiedItem[] = [];
    for(let i=0; i < props.colorScheme.classified.items.length; i++)
      new_classified_items.push(
        { 
          ...props.colorScheme.classified.items[i],
          color: randomColors[i]
        });

      const new_classified: IPortfolioMapColorScheme_Classified = 
      {
        ...props.colorScheme.classified,
        items: new_classified_items,
        preset_id: GRADIENT_PRESET_EMPTY_ID
      }

      let new_color_scheme: IPortfolioMapColorScheme =
      {
        ...props.colorScheme,
        classified: new_classified,
      }

    // Set the new value
    props.setColorScheme(new_color_scheme);

    // Keep track of changes made (so we know we need to save when the user hits "Accept Changes")
    props.setChangesWereMade(true);
  }















  if(!props.colorScheme || props.colorScheme.type !== 'classified' || !props.colorScheme.classified || 
     !props.colorScheme.classified.items || props.colorScheme.aoi_attribute_id === undefined)
    return null;

  return (

    <Stack direction='column' sx={{ mt: 3, alignItems: 'left' }}>

      <Stack direction='row' sx={{ mt: 0, mb: 1, alignItems: 'center', justifyContent: 'left' }}>

        <Typography sx={{ color: theme_orange, opacity: 0.8, fontSize: '1.1rem', width: '300px' }}>
          Classes
        </Typography>

        {/* Select number of classes */}

        <Stack direction='column' sx={{ ml: 5, width: '140px' }}>

          <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
            Class Count
          </Typography>

          <CustomSelect variant='standard' size='small'
                        value={props.colorScheme.classified.items.length}
                        disabled={!props.colorScheme.classified || !props.colorScheme.aoi_attribute_id}
                        onChange={OnClassCountChanged}
                        sx={{ p: 0.5 }}>

            <MenuItem key='1' value='1' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>1</MenuItem>
            <MenuItem key='2' value='2' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>2</MenuItem>
            <MenuItem key='3' value='3' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>3</MenuItem>
            <MenuItem key='4' value='4' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>4</MenuItem>
            <MenuItem key='5' value='5' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>5</MenuItem>
            <MenuItem key='6' value='6' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>6</MenuItem>
            <MenuItem key='7' value='7' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>7</MenuItem>
            <MenuItem key='8' value='8' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>8</MenuItem>
            <MenuItem key='9' value='9' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>9</MenuItem>
            <MenuItem key='10' value='10' sx={{ color: theme_textColorBlended, fontSize: '1.0rem' }}>10</MenuItem>
          </CustomSelect>
        </Stack>

        {/* Gradient presets */}

        <Stack sx={{ ml: 5, width: '100%' }}>
          <ColorGradientPresets allowEmptySelection={true}
                                selectedPresetID={props.colorScheme.classified.preset_id}
                                OnPresetChanged={OnPresetChanged} />
        </Stack>

      </Stack>

      {/* Create 2 side-by side panels, left and right */}

      <Stack direction='row'>

        {/* Left side - list of gradient stops (value/color pairs) */}

        <Stack direction='column'>

          {/* Show range interval (and units) */}

          {props.colorScheme.classified.items.length > 0
            ?
              <Stack direction='row' sx={{ mt: -1, mb: 1, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1rem', color: theme_textColorBlended }}>
                  Interval:
                </Typography>
                <Typography sx={{ ml: 0.8, fontSize: '1rem', color: theme_limeGreen, opacity: 0.7, fontWeight: 'bold' }}>
                  {FriendlyNumber(props.colorScheme.classified.items.length >= 2 ? props.colorScheme.classified.items[1].value_min - props.colorScheme.classified.items[0].value_min : maxValue-minValue, 3)}
                </Typography>
                {props.attribUnits 
                  ?
                    <Typography sx={{ ml: 0.6, fontSize: '1rem', color: theme_textColorBlended }}>
                      {props.attribUnits}
                    </Typography>
                  :null
                }
              </Stack>
            :null
          }

          {props.colorScheme.classified.items.map(function(classifiedItem, index)
          {
            return (
              
              <Stack key={classifiedItem.id} direction='column' sx={{ my: 0.5 }}>

                <Stack direction='row' sx={{ alignItems: 'center' }}>

                  {/* Value */}

                  <Stack direction='row' sx={{ width: '120px' }}>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '1rem' }}>
                      {FriendlyNumber(classifiedItem.value_min,2)}
                    </Typography>
                    <Typography sx={{ mx: 1, color: theme_textColorBlended, opacity: 0.8, fontSize: '0.9rem' }}>
                      to
                    </Typography>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.7, fontSize: '1rem' }}>
                      {FriendlyNumber(classifiedItem.value_max,2)}
                    </Typography>
                  </Stack>

                  {/* <Stack>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                      Value
                    </Typography>

                    <CustomTextField variant='standard' size='small' autoComplete='off'
                                     value={classifiedItem.value_min}
                                     inputProps={{ type: 'number' }} 
                                     //onChange={OnGradientValueChanged}
                                     sx={{ p: 0, width: '100px' }}/>
                  </Stack> */}

                  {/* Color */}

                  <Stack sx={{ ml: 1 }}>
                    <Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>
                      Color
                    </Typography>
                    <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                         disabled={props.colorScheme.classified === undefined}
                                         value={classifiedItem.color}
                                         onChange={(v,c)=>OnClassifiedColorChanged(v,c,classifiedItem)}
                                         sx={{ width: '120px' }}/>
                  </Stack>

                </Stack>

                {index === 0 && classifiedItem.value_min !== minValue
                  ?
                    <Typography sx={{ fontSize: '0.7rem', color: theme_errorRed }}>
                      This is no longer the minimum value ({minValue})
                    </Typography>
                  :null
                }

                {props.colorScheme.classified && index === props.colorScheme.classified.items.length-1 && classifiedItem.value_max !== maxValue
                  ?
                    <Typography sx={{ fontSize: '0.7rem', color: theme_errorRed }}>
                      This is no longer the maximum value ({maxValue})
                    </Typography>
                  :null
                }

              </Stack>
            )
          })}
        </Stack>

        {/* Right side */}

        <Stack sx={{ ml: 2, mt: 2, width: '100%', justifyContent: 'top', alignItems: 'start' }}>

          <Stack direction='row'>

            <Button variant='outlined' sx={{ textTransform: 'none' }} 
                    onClick={(_)=>OnRefreshValues()}>
              <Stack direction='column'>
                <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                  Refresh Values
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                  Linear, colors unchanged
                </Typography>
              </Stack>
            </Button>

            <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }} 
                    onClick={(_)=>OnReverseColors()}>
              <Stack direction='column'>
                <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                  Reverse Colors
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                  Values unchanged
                </Typography>
              </Stack>
            </Button>

            <Button variant='outlined' sx={{ ml: 2, textTransform: 'none' }} 
                    onClick={(_)=>OnRandomColors()}>
              <Stack direction='column'>
                <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                  Random Colors
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.5 }}>
                  Values unchanged
                </Typography>
              </Stack>
            </Button>
          </Stack>

        </Stack>

      </Stack>
















    </Stack>
  )
}






//-------------------------------------------------------------------------------
// Validates the specified classified color scheme.
//-------------------------------------------------------------------------------
export function ValidateColorScheme_Classified(colorScheme: IPortfolioMapColorScheme): boolean
{
  if(colorScheme.type !== 'classified')
    return false;

  if(colorScheme.classified === undefined || colorScheme.classified.items.length < 1)
  {
    ToastNotification('error', 'Classified color schemes require at least 1 color range to be defined');
    return false;
  }
  
  const foundBadColor: IPortfolioMapColorScheme_ClassifiedItem | undefined = colorScheme.classified.items.find(gci => gci.color === undefined || gci.color === '');
  if(foundBadColor)
  {
    ToastNotification('error', 'One of the colors is not defined');
    return false;
  }

  const foundBadValue_min: IPortfolioMapColorScheme_ClassifiedItem | undefined = colorScheme.classified.items.find(gci => gci.value_min === undefined || isNaN(gci.value_min));
  if(foundBadValue_min)
  {
    ToastNotification('error', 'One of the range min values is not defined (or not a number)');
    return false;
  }

  const foundBadValue_max: IPortfolioMapColorScheme_ClassifiedItem | undefined = colorScheme.classified.items.find(gci => gci.value_max === undefined || isNaN(gci.value_max));
  if(foundBadValue_max)
  {
    ToastNotification('error', 'One of the range max values is not defined (or not a number)');
    return false;
  }

  const foundBadValue_range: IPortfolioMapColorScheme_ClassifiedItem | undefined = colorScheme.classified.items.find(gci => gci.value_min > gci.value_max);
  if(foundBadValue_range)
  {
    ToastNotification('error', 'One of the range min values is higher than the range max value');
    return false;
  }
  
  // Validated
  return true;
}

//-------------------------------------------------------------------------------
// Breaks a numerical range into a specified number of sub-ranges.
// 
// NOTE:  Uses the BigNumber library in order to produce accurate ranges.
//-------------------------------------------------------------------------------
function GetClassifiedRanges(minValue: number, maxValue: number, classCount: number): { min: number, max: number}[] | undefined
{
  if(minValue === undefined  || maxValue === undefined || classCount === undefined || (classCount < 1 || classCount > MAX_CLASSES))
  {
    Debug.error('GetClassifiedRanges> Bad input');
    return undefined;
  }

  // NOTE:  Doing the math using the JS primitive 'number' often leads to weird
  //        inaccuracy in the ranges produced.  So instead we use the BigNumber
  //        external library to produce accurate number ranges.
  const minValue_big = new BigNumber(minValue);
  const maxValue_big = new BigNumber(maxValue);

  // The size of each range
  //const rangeValue: number = (maxValue - minValue) / classCount;
  const rangeValue_big: BigNumber = maxValue_big.minus(minValue_big).dividedBy(classCount);

  const ranges: { min: number, max: number}[] = [];

  for(let i=0; i < classCount; i++)
    ranges.push(
      { 
        min: minValue_big.plus(rangeValue_big.times(i)).toNumber(),
        max: minValue_big.plus(rangeValue_big.times(i+1)).toNumber(),
      });

  // Success
  return ranges;
}

//-------------------------------------------------------------------------------
// Creates ranges for the specified classified color scheme.
// If a color gradient preset is specified, colors will also be set based on it.
//-------------------------------------------------------------------------------
export function CreateClassifiedRanges(colorScheme: IPortfolioMapColorScheme, 
                                       classCount: number,
                                       gradient_preset: IPortfolioMapColorScheme_Gradient | undefined,
                                       preset_id: number | undefined): IPortfolioMapColorScheme_Classified | undefined
{
  if(!colorScheme || !colorScheme.classified || classCount === undefined || (classCount < 1 || classCount > MAX_CLASSES))
    return undefined;

  // Get min and max values

  const minValue: number | undefined = GetAoiAttributeMinValue(colorScheme.aoi_attribute_id);
  const maxValue: number | undefined = GetAoiAttributeMaxValue(colorScheme.aoi_attribute_id);
  if(minValue === undefined || maxValue === undefined)
  {
    Debug.error('CreateClassifiedRanges> Invalid min or max value');
    return undefined;
  }

  // Break the min/max range into sub-ranges based on the class count

  var ranges = GetClassifiedRanges(minValue, maxValue, classCount);
  if(!ranges)
    return undefined;

  // When a color gradient is used, we advance the value by this much each time
  // NOTE: The MIN value gets assigned the first gradient stop, and the MAX value gets 
  //       assigned the last gradient stop, with the rest being filled in linearly.
  const colorGradientValueStep: number = (maxValue - minValue) / (classCount - 1);

  const new_classified_items: IPortfolioMapColorScheme_ClassifiedItem[] = [];
  let nextID = 1;
  let colorGradientValue: number = minValue;

  for(let i=0; i < classCount; i++)
  {
    let colorStr: string = '';
    if(gradient_preset)
    {
      // Gradient presets use a percent scale (0-100), so we must rescale the value to a percentage of min/max
      // Formula:  (VALUE - min) / (max - min) * 100
      const colorGradientValueScaledAsPercent: number = (colorGradientValue-minValue) / (maxValue-minValue) * 100;

      // Now we can get the interpolated gradient color to use for this value
      const colorBasedOnGradientPreset: string | undefined = GetGradientColorForValue(colorGradientValueScaledAsPercent, gradient_preset);
      if(colorBasedOnGradientPreset)
        colorStr = colorBasedOnGradientPreset;

      colorGradientValue += colorGradientValueStep;
    }

    new_classified_items.push(
      { 
        id: nextID++, 
        value_min: ranges[i].min,// minValue_big.plus(rangeValue_big.times(i)).toNumber(),
        value_max: ranges[i].max,//minValue_big.plus(rangeValue_big.times(i+1)).toNumber(),
        color: colorStr
      });
  }

  const new_classified: IPortfolioMapColorScheme_Classified =
  {
    preset_id: preset_id,
    items: new_classified_items
  }

  // Success
  return new_classified;
}

//-------------------------------------------------------------------------------
// Returns the color for the specified value and classified color scheme.
//-------------------------------------------------------------------------------
export function GetClassifiedColorForValue(value: number, classified: IPortfolioMapColorScheme_Classified): string | undefined
{
  if(value === undefined || classified === undefined)
    return undefined;

  // Scan through the classes until we find one that contains our value.

  let foundItem: IPortfolioMapColorScheme_ClassifiedItem | undefined = undefined;

  for(let i=0; i < classified.items.length; i++)
    if(value >= classified.items[i].value_min && value <= classified.items[i].value_max)
    {
      foundItem = classified.items[i];
      break;
    }

  if(!foundItem)
    return;

  // Success
  return foundItem.color;
}