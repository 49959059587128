// User editor component

import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight, theme_orange, theme_errorRed, theme_bgColorMain, theme_limeGreen } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { UserManagement_UserOrgRoles, IUserManagement_AdminOrg, IUserManagement_User, IUserManagement_Data, IUserManagement_UserOrgRole } from "./UserManagementInterfaces";
import { ChangeEvent, useEffect, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomTextField } from "../../LayerLibrary/EditLayer/EditLayer";
import dayjs, { Dayjs } from "dayjs";
import { GetAdminOrgsWithRolesForUser, GetRolesForOrgForUser, IsUserInOrg, UpdateAdminUserInfo } from "./UserManagementOps";
import CheckIcon from "@mui/icons-material/Check";
import { FriendlyDate, FriendlyTimeSpan } from "../../Globals";
import EditIcon from '@mui/icons-material/Edit';
import TypographyWithAutoTooltip from "../../Components/TypograpyWithAutoTooltip";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditRolesForOrg from "./EditRolesForOrg";


const FIRST_NAME_MAX_LENGTH = 18;
const LAST_NAME_MAX_LENGTH = 18;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface UserEditorProps
{
  editUser: IUserManagement_User | undefined;
  setEditUser: any;
  userData?: IUserManagement_Data;
  setUserData: any;
  OnNotifyUserEdited: any;
}

//-------------------------------------------------------------------------------
// User editor component
//-------------------------------------------------------------------------------
const UserEditor = (props: UserEditorProps) => 
{


  const [localUser, setLocalUser] = useState<IUserManagement_User|undefined>(undefined);

  // Keeping track of this separately because it needs to be a Dayjs object
  const [localExpirationDate, setLocalExpirationDate] = useState<Dayjs|null>(null);

  // Keeps track of the open/closed state of the org combo box selector
  const [orgSelectorIsOpen, setOrgSelectorIsOpen] = useState(false);

  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);

  const [isRoleEditorVisible, setIsRoleEditorVisible] = useState<boolean>(false);
  const [editRolesForOrg, setEditRolesForOrg] = useState<IUserManagement_AdminOrg|undefined>(undefined);
  
  
  









  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Load local data every time the window is opened
    if(props.editUser)
    {
      // Auto-fix roles (based on what orgs the user is a member of)
      const updatedUser: IUserManagement_User =
      {
        ...props.editUser,
        roles: GetFixedRolesForUser(props.editUser),
        organizations: GetSortedOrgs(props.editUser.organizations)
      }  

      setLocalUser(updatedUser);
      setLocalExpirationDate(props.editUser.expiration_date ? dayjs(props.editUser.expiration_date) : null);
      setChangesWereMade(false);
    }

  }, [props.editUser])
  
  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setEditUser(undefined);
    setLocalUser(undefined);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(!props.userData || !localUser) return;

    // No changes detected - we can close without saving
    if(changesWereMade === false)
    {
      OnClose();
      return;
    }

    // Call server

    const ret: boolean = await UpdateAdminUserInfo(localUser);
    if(ret === false)
      return;

    // Update the user data used by the caller
    props.setUserData(localUser);

    // Notify caller
    props.OnNotifyUserEdited(localUser);

    // Close this window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // Sorts the org ID list by name.
  //-------------------------------------------------------------------------------
  function GetSortedOrgs(orgs: number[]): number[]
  {
    return orgs.sort((a: number, b: number) => 
    {
      const orgA = GetAdminToolOrg(a)?.name ?? '';
      const orgB = GetAdminToolOrg(b)?.name ?? '';
      return orgA.localeCompare(orgB);
    });
  }

  //-------------------------------------------------------------------------------
  // Get admin tool org.
  //-------------------------------------------------------------------------------
  function GetAdminToolOrg(org_id: number): IUserManagement_AdminOrg | undefined
  {
    if(org_id === undefined) return undefined;
    return props.userData?.orgs.find(o => o.id === org_id);
  }

  //-------------------------------------------------------------------------------
  // The first name text field has changed.
  //-------------------------------------------------------------------------------
  function OnFirstNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localUser) return;

    const newValue: string = event.target.value;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      first_name: newValue
    }

    setLocalUser(updatedUser);

    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The last name text field has changed.
  //-------------------------------------------------------------------------------
  function OnLastNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!localUser) return;

    const newValue: string = event.target.value;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      last_name: newValue
    }

    setLocalUser(updatedUser);

    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The "Enabled" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnEnabledCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!localUser) return;

    //const newValue: string = event.target.value;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      account_state: checked ? 'Enabled' : 'Disabled'
    }

    setLocalUser(updatedUser);
    
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // An org has been is removed.
  //-------------------------------------------------------------------------------
  const OnDeselectOrg = (deletedOrgID: number) => 
  {
    if(!localUser) return;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      organizations: localUser.organizations.filter(org_id => org_id !== deletedOrgID),
      roles: localUser.roles.filter(r => r.org_id !== deletedOrgID) // If an org is removed, so must any roles linked to that org
    }

    setLocalUser(updatedUser);

    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Auto-fixes roles based on org membership.
  // 
  // - removes all roles for any orgs the user is not a member of
  // - add the 'Default User' for any orgs the user is part of but are missing the default role.
  //-------------------------------------------------------------------------------
  function GetFixedRolesForUser(user: IUserManagement_User, updatedOrganizations: number[] = user.organizations): IUserManagement_UserOrgRole[]
  {
    const updatedRoles: IUserManagement_UserOrgRole[] = [];

    // Update the roles:  remove all roles for any orgs that were removed

    for(let i=0; i < user.roles.length; i++)
    {
      // If this non-default role is for an org that the user is no longer part of, skip it
      if(updatedOrganizations.find(org_id => org_id === user.roles[i].org_id) === undefined)
        continue;

      updatedRoles.push(user.roles[i]);
    }

    // Update the roles:  add the default role for any newly-added orgs that don't have it 

    for(let i=0; i < updatedOrganizations.length; i++)
    {
      // If the 'Default User' role is found for this org, no need to add it
      if(user.roles.find(r => r.org_id === updatedOrganizations[i] && r.role === 'Default User') !== undefined)
        continue;

      updatedRoles.push({ org_id: updatedOrganizations[i], role: 'Default User' });
    }

    // Done
    return updatedRoles;
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to the org combo box.
  //-------------------------------------------------------------------------------
  const OnOrgSelectionChanged = (event: SelectChangeEvent<number[]>) => 
  {
    if(!localUser) return;

    const { target: { value },} = event;
    const updatedOrganizations: number[] = value as number[];

    // Update the local user

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      organizations: GetSortedOrgs(updatedOrganizations),
      roles: GetFixedRolesForUser(localUser, updatedOrganizations)
    }

    setLocalUser(updatedUser);
    setOrgSelectorIsOpen(false);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // The expiration date has changed.
  //-------------------------------------------------------------------------------
  function OnExpirationDateChanged(newExpirationDate: Dayjs | null)
  {
    if(!localUser || !newExpirationDate) return;

    const newExpired: boolean = newExpirationDate.isBefore(dayjs());

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      expiration_date: newExpirationDate.toJSON(),
      expired: newExpired
    }

    setLocalUser(updatedUser);
    setLocalExpirationDate(newExpirationDate);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Clear the expiration date.
  //-------------------------------------------------------------------------------
  function OnExpirationDateClear()
  {
    if(!localUser) return;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      expiration_date: undefined,
      expired: false
    }

    setLocalUser(updatedUser);
    setLocalExpirationDate(null);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Add a number of months to the current expiration date.
  // If there is no expiration date currently set, 'today' will be assumed.
  //-------------------------------------------------------------------------------
  function OnExpirationDateAddTime(months: number)
  {
    if(!localUser) return;

    // If there is no expiration date, or if there is but it's expired.. reset to today's date

    let currExpDate: Dayjs | null = null;
    if(localUser.expired || !localExpirationDate || localExpirationDate.isBefore(dayjs()))
      currExpDate = dayjs();  // today
    else
      currExpDate = localExpirationDate;

    if(!currExpDate) return;

    const newExpDate: Dayjs = currExpDate.add(months, 'month');

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      expiration_date: newExpDate.toJSON(),
      expired: false
    }

    setLocalUser(updatedUser);
    setLocalExpirationDate(newExpDate);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Returns a string showing the number of days/months until the account expires.
  //-------------------------------------------------------------------------------
  function GetDaysToExpiration(): string | undefined
  {
    if(!localUser || localUser.expired || !localExpirationDate)
      return '[error]';
    
    return FriendlyTimeSpan(localExpirationDate.diff(dayjs()));
  }

  //-------------------------------------------------------------------------------
  // Deletes all roles for the specified org (Except 'Default User').
  //-------------------------------------------------------------------------------
  function OnDeleteRolesForOrg(org_id: number)
  {
    if(!localUser) return;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      roles: localUser.roles.filter(r => r.org_id !== org_id || r.role === 'Default User')
    }

    setLocalUser(updatedUser);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Edits the roles assigned for the specified org.
  //-------------------------------------------------------------------------------
  function OnEditRolesForOrg(org_id: number)
  {
    setEditRolesForOrg(GetAdminToolOrg(org_id));
    setIsRoleEditorVisible(true);
  }

  //-------------------------------------------------------------------------------
  // Give the user ALL available roles for ALL orgs they are part of.
  //-------------------------------------------------------------------------------
  function OnGiveAllOrgRoles()
  {
    if(!localUser) return;

    // Rebuild the roles (add all roles for all org the user is in)

    const allRoles: IUserManagement_UserOrgRole[] = [];
    for(let i=0; i < localUser.organizations.length; i++)
    {
      UserManagement_UserOrgRoles.map(r => 
      {
        allRoles.push({ org_id: localUser.organizations[i], role: r });
      });
    }

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      roles: allRoles
    }

    setLocalUser(updatedUser);
    setChangesWereMade(true);
  }

  //-------------------------------------------------------------------------------
  // Remove ALL roles from the user (except the 'Default User' roles).
  //-------------------------------------------------------------------------------
  function OnRemoveAllOrgRoles()
  {
    if(!localUser) return;

    const updatedUser: IUserManagement_User =
    {
      ...localUser,
      roles: localUser.roles.filter(r => r.role === 'Default User')
    }

    setLocalUser(updatedUser);
    setChangesWereMade(true);
  }














  if(!props.userData || !props.editUser || !localUser) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.editUser !== undefined}
            onClose={OnClose} maxWidth='sm'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1, py: 0.5 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack>
            <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain, opacity: 0.8 }}>
              {props.editUser.first_name + ' ' + props.editUser.last_name}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
              {props.editUser.email}
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>

          {/* Account create date */}

          {localUser.created_date 
            ?
              <Stack direction='row'>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Account created
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {FriendlyDate(new Date(localUser.created_date))}
                </Typography>
                <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.6, fontSize: '0.8rem' }}>
                  {`${FriendlyTimeSpan(dayjs().diff(dayjs(localUser.created_date)))} ago`}
                </Typography>
              </Stack>
            :null
          }

          {/* Last password change date */}

          {localUser.last_pwd_change
            ?
              <Stack direction='row'>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Last password change
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {FriendlyDate(new Date(localUser.last_pwd_change))}
                </Typography>
                <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.6, fontSize: '0.8rem' }}>
                  {`${FriendlyTimeSpan(dayjs().diff(dayjs(localUser.last_pwd_change)))} ago`}
                </Typography>
              </Stack>
            :null
          }

          {/* Last login date */}

          {localUser.last_login
            ?
              <Stack direction='row'>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Last login
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {FriendlyDate(new Date(localUser.last_login))}
                </Typography>
                <Typography sx={{ ml: 1, color: theme_orange, opacity: 0.6, fontSize: '0.8rem' }}>
                  {`${FriendlyTimeSpan(dayjs().diff(dayjs(localUser.last_login)))} ago`}
                </Typography>
              </Stack>
            :null
          }

          {/* Project count */}

          {localUser.stats.project_count !== undefined
            ?
              <Stack direction='row' sx={{ mt: 1 }}>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.8rem' }}>
                  Projects
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: 'bold', color: theme_textColorMain, opacity: 0.7, fontSize: '0.8rem' }}>
                  {localUser.stats.project_count}
                </Typography>
              </Stack>
            :null
          }

          {/* Enabled status checkbox */}

          <Stack direction='row' sx={{ mt: 2, alignItems: 'center' }}>
            <Checkbox size='medium' checked={localUser.account_state === 'Enabled'} 
                onChange={(e,c)=>OnEnabledCheckBoxChange(e,c)}
                sx=
                {{ 
                  mr: 0.5, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
            <Typography sx={{ color: theme_textColorMain, opacity: 0.8, fontSize: '1.0rem' }}>
              Account Enabled
            </Typography>
          </Stack>

          {/* Name */}

          <Stack direction='row' sx={{ mt: 2, justifyContent: 'space-between', alignItems: 'center' }}>

            {/* First Name */}

            <CustomTextField variant='filled' size='small' autoComplete='off'
                             value={localUser.first_name}
                             onChange={OnFirstNameChanged}
                             inputProps={{ maxLength: FIRST_NAME_MAX_LENGTH }}
                             label=
                               {
                                 <Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>
                                   First Name
                                 </Typography>
                               } 
                             sx={{ mt: 0, p: 0, width: '100%' }}/>

            <CustomTextField variant='filled' size='small' autoComplete='off'
                             value={localUser.last_name}
                             onChange={OnLastNameChanged}
                             inputProps={{ maxLength: LAST_NAME_MAX_LENGTH }}
                             label=
                               {
                                 <Typography sx={{fontSize:'0.8rem',color: theme_textColorBlended+'B0'}}>
                                   Last Name
                                 </Typography>
                               } 
                             sx={{ ml: 3, p: 0, width: '100%' }}/>
          </Stack>

          <Stack direction='row' sx={{ mt: 2, alignItems: 'center', justifyContent: 'left' }}>

            {/* Expiration date picker */}
            
            <Stack direction='column'>
              <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>
                Account Expiration Date (optional)
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={localExpirationDate}
                            onChange={(newValue) => OnExpirationDateChanged(newValue)}
                            sx={{ mt: 0.5,
                                  input: { backgroundColor: theme_bgColorLight1+'70', color: theme_textColorMain+'A0', fontSize: '1.0rem' } 
                              }}

                              // Modify the calendar popup bg color
                              slotProps={{
                                layout: {
                                  sx: {
                                    borderRadius: '2px',
                                    backgroundColor: theme_bgColorLight+'C0',
                                  }
                                }
                              }}
                />
              </LocalizationProvider>

              {localExpirationDate && dayjs(localExpirationDate).isValid() === false
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed }}>
                    This is not a valid date.
                  </Typography>
                :null
              }
              {!localExpirationDate
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6 }}>
                    This account has no expiration date.
                  </Typography>
                :null
              }

              {localUser.expired !== true && localExpirationDate && dayjs(localExpirationDate).isValid() === true
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen }}>
                    This account will expire in {GetDaysToExpiration()}.
                  </Typography>
                :null
              }

              {localUser.expired
                ?
                  <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed }}>
                    This account has expired.
                  </Typography>
                :null
              }

            </Stack>

            <Stack direction='column' sx={{ ml: 2, opacity: 0.8 }}>
              <Button variant='outlined' onClick={_=>OnExpirationDateAddTime(1)}
                      sx={{ textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                +1 Month
              </Button>
              <Button variant='outlined' onClick={_=>OnExpirationDateAddTime(12)}
                      sx={{ mt: '-1px', textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                +1 Year
              </Button>
              <Button variant='outlined' onClick={_=>OnExpirationDateClear()}
                      sx={{ mt: '-1px', textTransform: 'none', px: 0.5, py: 0, fontSize: '0.7rem' }}>
                Clear
              </Button>
            </Stack>
          </Stack>          

          {/* Organizations */}

          <Typography sx={{ mt: 3, fontSize: '1rem', color: theme_orange, opacity: 0.8 }}>
            Organizations this user is part of ({localUser.organizations.length})
          </Typography>

          {/* Organization selector (multi-select with Chips) */}

          <FormControl sx={{ mt: 1 }}>
            {/* <InputLabel id="parcel-search-states-select-label">States</InputLabel> */}
            <Select
              // labelId="parcel-search-states-select-label"
              id="parcel-search-states-select-name"
              variant='standard' size='small' multiple
              value={localUser.organizations}
              onChange={OnOrgSelectionChanged}
              open={orgSelectorIsOpen}
              onOpen={() => setOrgSelectorIsOpen(true)}
              onClose={() => setOrgSelectorIsOpen(false)}
              //input={<OutlinedInput label="States"/>}
              sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: theme_textColorBlended },
                    '& .MuiSvgIcon-root': { color: theme_textColorBlended } 
                  }}
              renderValue={(selected) => (

                  <Box sx={{ display: 'flex', gap: 0.5, overflow: 'hidden' }}>
                    {selected.map(org_id => 
                      <Chip color='primary' size='small' variant='filled' key={org_id} label={GetAdminToolOrg(org_id)?.name} 
                            onMouseDown={(event) => { event.stopPropagation(); }}
                            onClick={()=>setOrgSelectorIsOpen(true)}
                            onDelete={()=>OnDeselectOrg(org_id)}
                      />
                    )}
                  </Box>
              )}
            >
              {props.userData.orgs.map((org) => (
                <MenuItem key={org.id} value={org.id} sx={{ justifyContent: "space-between" }}>
                  {org.name}
                  {IsUserInOrg(localUser,org) ? <CheckIcon sx={{ ml: 1, color: theme_orange }} /> : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Roles */}

          <Stack direction='row' sx={{ mt: 4, justifyContent: 'space-between', alignItems: 'end' }}>
            <Typography sx={{ fontSize: '1rem', color: theme_orange, opacity: 0.8 }}>
              Organization-User Roles ({localUser.roles.length})
            </Typography>
            <Stack direction='row'>
              <Button variant='outlined' sx={{ height: '22px', color: theme_errorRed+'C0', fontSize: '0.8rem', px: '5px', py: '1px', textTransform: 'none' }} 
                      onClick={_=>OnGiveAllOrgRoles()}>
                ALL
              </Button>
              <Button variant='outlined' sx={{ ml: 1, height: '22px', color: theme_limeGreen+'C0', fontSize: '0.8rem', px: '5px', py: '1px', textTransform: 'none' }} 
                      onClick={_=>OnRemoveAllOrgRoles()}>
                None
              </Button>
            </Stack>
          </Stack>

          <Typography sx={{ mt: '-1px', mb: 1, fontSize: '0.6rem', color: theme_textColorBlended, opacity: 0.6 }}>
            The 'Default User' role is auto-added for all organizations this user is a member of.
          </Typography>          

          {GetAdminOrgsWithRolesForUser(localUser).length === 0
            ?
              <Typography sx={{ fontSize: '0.8rem', color: theme_textColorBlended, opacity: 0.8 }}>
                This user has no roles defined for any organization.
              </Typography>            
            :null
          }

          {GetSortedOrgs(GetAdminOrgsWithRolesForUser(localUser)).map(function(org_id)
          {
            const orgUserRoles = GetRolesForOrgForUser(localUser,org_id);
            const onlyHaveDefaultRole: boolean = orgUserRoles.length === 1 && orgUserRoles[0].role === 'Default User';

            return (
              <Stack direction='row' sx={{ alignItems:'center', justifyContent: 'space-between' }}>

                <TypographyWithAutoTooltip placement='left' arrow  sx={{ width: '250px', color: theme_textColorBlended, fontSize: '0.8rem' }}>
                  {GetAdminToolOrg(org_id)?.name}
                </TypographyWithAutoTooltip>

                <TypographyWithAutoTooltip placement='left' arrow sx={{ ml: 1, width: '100%', fontSize: '0.6rem', color: theme_textColorMain+'A0', px: '4px', py: '2px' }}>
                  {orgUserRoles.map(function(role,index)
                    {
                      return (
                        `${index>0?', ':''}${role.role}`
                      )
                    })
                  }
                </TypographyWithAutoTooltip>

                <Stack direction='row' sx={{ width: '90px', alignItems: 'center', justifyContent: 'end' }}>
                  {onlyHaveDefaultRole === false
                    ?
                      <IconButton size="small" onClick={_=>OnDeleteRolesForOrg(org_id)}
                                  sx={{ p: 0, width: '25px', height: '25px' }}>
                        <DeleteForeverIcon sx={{ opacity: 0.9, width: '20px', height: '20px', color: theme_textColorBlended }} />
                      </IconButton>
                    :null
                  }
                  <IconButton size="small" onClick={_=>OnEditRolesForOrg(org_id)}
                              sx={{ p: 0, width: '25px', height: '25px' }}>
                    <EditIcon sx={{ opacity: 0.9, width: '20px', height: '20px', color: theme_textColorBlended }} />
                  </IconButton>
                </Stack>

              </Stack>
            )
          }
          )}
        </Stack>

        {/* User+Org role editor */}

        <EditRolesForOrg isDialogVisible={isRoleEditorVisible} setIsDialogVisible={setIsRoleEditorVisible}
                         user={localUser} setUser={setLocalUser}
                         setChangesWereMade={setChangesWereMade} 
                         org={editRolesForOrg} />

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

      <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default UserEditor;
