// Boundary create/edit component

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_bgColorLight, theme_errorRed, theme_limeGreen } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { IOrgManagement_Boundary, IOrgManagement_Data, IOrgManagement_Org } from "./OrgManagementInterfaces";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { FriendlyTimeSpan } from "../../Globals";
import { CreateOrUpdateOrg } from "./OrgManagementOps";
import { ToastNotification } from "../../ToastNotifications";


// const ORG_NAME_MAX_LENGTH: number = 40;
// const DEFAULT_BOUNDARY_ID = 2;  // CONUS is the default boundary

// const NO_BOUNDARY_ID = -1;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface BoundaryEditorProps
{
  showBoundaryEditor: boolean;
  setShowBoundaryEditor: any;
  editBoundary?: IOrgManagement_Boundary;   // If this is undefined, we are creating a new boundary
  orgData?: IOrgManagement_Data;
  NotifyCreatedOrUpdatedBoundary: any;
}

//-------------------------------------------------------------------------------
// Boundary create/edit component
//-------------------------------------------------------------------------------
const BoundaryEditor = (props: BoundaryEditorProps) => 
{
  const [localBoundary, setLocalBoundary] = useState<IOrgManagement_Boundary|undefined>(undefined);
  const [createNewMode, setCreateNewMode] = useState<boolean>(false);
  const [changesWereMade, setChangesWereMade] = useState<boolean>(false);





  //-------------------------------------------------------------------------------
  // Init
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Create a local copy of the boundary being edited every time the window is opened

    if(props.showBoundaryEditor === true)
    {
      if(props.editBoundary) // EDIT MODE
      {
        // If the boundary_id is undefined/null, set it to -1 (that is used locally for the "No Boundary" id)
        const updatedEditBoundary: IOrgManagement_Boundary = 
        {
          ...props.editBoundary,
          //boundary_id: props.editBoundary.boundary_id ?? NO_BOUNDARY_ID
        }

        setLocalBoundary(updatedEditBoundary);
        setCreateNewMode(false);
        setChangesWereMade(false);
      }
      else // CREATE NEW MODE
      {
        setLocalBoundary(
        {
          id: null,  // new boundary - don't know ID until API call is made to return it
          name: '',
          geom: '',
          settings: 
          {
            states: []
          }
        });
        setCreateNewMode(true);
        setChangesWereMade(true);
      }
    }

  }, [props.showBoundaryEditor])  

  //-------------------------------------------------------------------------------
  // Close/cancel window.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowBoundaryEditor(false);
    setLocalBoundary(undefined);
  }

  //-------------------------------------------------------------------------------
  // Accept/OK.
  //-------------------------------------------------------------------------------
  async function OnAccept()
  {
    if(!localBoundary || !props.orgData) return;

    if(changesWereMade === false)
    {
      OnClose();
      return;
    }

    // NOTE:  A boundary of -1 means "no boundary"

    // Validate
/*
    localBoundary.name = localBoundary.name.trim();
    if(localBoundary.name.length === 0)
    {
      ToastNotification('error', 'The organization name cannot be empty');
      return;
    }

    if(localBoundary.name.length < 3)
    {
      ToastNotification('error', 'The organization name must be at least 3 characters long');
      return;
    }
      
    // If we're creating a new org, the org name cannot already exist

    if(props.editBoundary === undefined && props.orgData.orgs.find(o => o.name.toLowerCase().trim() === localBoundary.name.toLocaleLowerCase().trim()) !== undefined)
    {
      ToastNotification('error', 'An organization with that name already exists');
      return;
    }

    if(localBoundary.boundary_id === -1)
      localBoundary.boundary_id = undefined;

    // Validate the expiration date

    if(localExpirationDate)
    {
      if(localExpirationDate.isValid() === false)
      {
        ToastNotification('error', 'The expiration date is not valid');
        return;
      }
      if(localExpirationDate.isBefore(dayjs()))
      {
        ToastNotification('error', 'The expiration date cannot be in the past');
        return;
      }
    }

    // Call server

    const ret: boolean = await CreateOrUpdateBoundary(localBoundary);
    if(ret === false)
      return;
*/
    // Notify caller
    props.NotifyCreatedOrUpdatedBoundary(localBoundary, createNewMode);

    // Close this window
    OnClose();
  }


  















  if(!props.orgData || !localBoundary) return null;

  //-------------------------------------------------------------------------------
  // Main render
  //-------------------------------------------------------------------------------

  return (
    
    <Dialog disablePortal 
            open={props.showBoundaryEditor === true}
            onClose={OnClose} maxWidth='md'
            PaperProps={{ sx: { minHeight: '30vh', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
            { createNewMode ? 'Create a New Boundary': 'Edit Boundary' }
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ background: theme_bgColorGradient2 }}>
        <Stack sx={{ mt: 2, alignItems: 'left' }}>





















        </Stack>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

      <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '250px', fontWeight: 'bold', textTransform: 'none' }}
                      onClick={_=>OnAccept()}>
                {createNewMode ? 'Create New Boundary': 'Accept Changes' }
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>          
  )
}


export default BoundaryEditor;
